import { createZustandStoreContext } from "create-zustand-store-context";

type EditorStore = {
  editorId?: string;
  ref: HTMLElement | null;
};

type Props = {
  editorId: string;
};

const defaultState: EditorStore = {
  ref: null,
};

const {
  storeContext,
  useState: useEditorState,
  useStore: useEditorStore,
} = createZustandStoreContext<EditorStore, keyof Props>({
  defaultState,
});

const EditorStoreContext = storeContext;

function EditorProvider({ children, editorId }: WithChildren<Props>) {
  const currentStore = useEditorStore(editorId, { editorId });

  return (
    <EditorStoreContext.Provider value={currentStore}>
      {children}
    </EditorStoreContext.Provider>
  );
}

export { EditorProvider, useEditorState };

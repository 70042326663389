import { useCallback, useMemo, useRef } from "react";

import { MenuProps, components } from "react-select";

import { Recipient } from "@utility-types";
import RecipientsResults from "components/views/search/quick-search/RecipientsResults";
import SearchResults, {
  SelectedSearchResult,
} from "components/views/search/quick-search/SearchResults";
import { UserEdgeStatus } from "generated/graphql";
import useMenuKeyboardShortcuts from "hooks/useMenuKeyboardShortcuts";
import useSafeAreaInsetsStore from "store/useSafeAreaInsetsStore";

const RecipientsMenu = (props: MenuProps<Recipient, true>): JSX.Element => {
  const {
    children,
    selectProps: {
      contacts,
      contactsPermission,
      getContactsPermission,
      onSelectRecipient,
      searchResults,
    },
  } = props;

  const selectedItemRef = useRef<HTMLLIElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleItemClick = useCallback(
    (item: SelectedSearchResult) => {
      if (item.__typename === "Thread" || item.__typename === "Message") return;
      onSelectRecipient([item]);
    },
    [onSelectRecipient]
  );

  const orderedResults = useMemo(() => {
    if (!searchResults) return [];

    return [
      ...searchResults.instantResults.flatMap(r => r.edges.map(e => e.node)),
      ...(contacts ? contacts : []),
      ...(searchResults.moreResults?.flatMap(r => r.edges.map(e => e.node)) ??
        []),
    ];
  }, [contacts, searchResults]);

  const { selectedItem, setSelectedIndex } = useMenuKeyboardShortcuts({
    data: orderedResults,
    onSelectItem: handleItemClick,
    selectedItemRef,
  });

  const bottom = useSafeAreaInsetsStore(({ bottom }) => bottom);

  const resultsCount =
    (searchResults?.totalCount || 0) + (contacts?.length || 0);

  const selectedResultID = selectedItem?.id;

  const setSelectedResultID = useCallback(
    (id: string) => {
      const index = orderedResults.findIndex(result => result.id === id);

      setSelectedIndex(index);
    },
    [orderedResults, setSelectedIndex]
  );

  return (
    <components.Menu
      {...props}
      maxMenuHeight={
        window.innerHeight -
        (menuRef.current?.getBoundingClientRect().top || 0) -
        bottom -
        // Separation from the bottom edge of the screen
        10
      }
    >
      <div
        ref={menuRef}
        className="flex overflow-y-scroll overscroll-contain flex-col w-full"
      >
        {resultsCount ? (
          <SearchResults
            className="my-4"
            onClickResult={handleItemClick}
            searchResults={searchResults}
            selectedResultID={selectedResultID}
            selectedResultRef={selectedItemRef}
            setSelectedResultID={setSelectedResultID}
          >
            {contacts?.length ? (
              <RecipientsResults
                label="Contacts"
                onClickResult={handleItemClick}
                results={{
                  edges: contacts.map(c => ({
                    __typename: "UserEdge" as const,
                    id: c.id,
                    node: c,
                    persistentChatEdge: null,
                    status: UserEdgeStatus.None,
                    lastInteractionAt: null,
                    unreadThreadCounts: {
                      __typename: "UnreadCounts" as const,
                      mentioned: 0,
                      total: 0,
                      unseen: 0,
                    },
                  })),
                  resultType: "users",
                  totalCount: contacts?.length,
                }}
                selectedResultID={selectedResultID}
                selectedResultRef={selectedItemRef}
              />
            ) : null}
          </SearchResults>
        ) : (
          <div className="italic text-footnote">{children}</div>
        )}
        {contactsPermission === false ? (
          <button
            onPointerDown={e => {
              // Must use pointer-down since this blurs the react-select and prevents onClick
              e.preventDefault();
              getContactsPermission();
            }}
            style={{
              borderTop: "0.5px solid hsl(var(--background-subtle))",
              padding: "10px 20px",
              textAlign: "left",
              width: "100%",
            }}
          >
            Search Contacts...
          </button>
        ) : null}
      </div>
    </components.Menu>
  );
};

export default RecipientsMenu;

import { useMemo } from "react";

import {
  Message,
  Thread,
  ThreadPreview,
  ThreadSimple,
  nodeAs,
} from "@utility-types";
import { useFetchThreadEdgeSimpleQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";

type ReplyTo = {
  message: Message;
  thread: ThreadSimple | ThreadPreview;
};

const useFetchThreadReplyTo = (thread?: Thread): ReplyTo | undefined => {
  const { authData } = useAuthData();
  const replyToMessage = thread?.replyToMessage;

  const { data: replyToThreadEdge } = useFetchThreadEdgeSimpleQuery({
    skip: !authData?.me.id || !replyToMessage?.threadID,
    variables: { id: `${replyToMessage?.threadID}-${authData?.me.id}` },
  });

  const replyToThread = nodeAs(replyToThreadEdge?.node, [
    "ThreadEdge",
    "ThreadPreviewEdge",
  ])?.node;

  return useMemo(
    () =>
      replyToMessage?.threadID && replyToThread
        ? { message: replyToMessage, thread: replyToThread }
        : undefined,
    [replyToThread, replyToMessage]
  );
};

export default useFetchThreadReplyTo;

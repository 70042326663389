import { DOMAttributes, useState } from "react";
import { useHistory } from "react-router-dom";

import { Recipient, nodeAs } from "@utility-types";
import Tooltip from "components/design-system/FloatingUi/Tooltip";
import {
  routeToGroup,
  routeToThread,
  routeToUser,
} from "components/routing/utils";
import { FetchRecipientQuery, useFetchRecipientQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import tw from "utils/tw";

import { RecipientProfileItem } from "components/ProfileItem";
import ThreadItem from "components/threads-list/ThreadItem/ThreadItem";

type Props = {
  children: JSX.Element;
  className?: string;
  clickEnabled?: boolean;
  elementDOMAttributes?: DOMAttributes<HTMLSpanElement> & {
    [key: `data-${string}`]: string;
  };
  id: string;
};

const RecipientTooltip = ({
  children,
  className,
  clickEnabled = false,
  elementDOMAttributes,
  id,
}: Props): JSX.Element | null => {
  const { authData } = useAuthData();
  const history = useHistory();

  const [recipientData, setRecipientData] = useState<FetchRecipientQuery>();
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const { data } = useFetchRecipientQuery({
    fetchPolicy: "cache-first",
    onCompleted: setRecipientData,
    skip: !recipientData && !tooltipVisible,
    variables: { id: id.startsWith("thr") ? `${id}-${authData?.me.id}` : id },
  });

  const threadEdge = nodeAs(data?.node, ["ThreadEdge", "ThreadPreviewEdge"]);

  const onRecipientClick = (_: string, id: string) => {
    history.push(
      id.startsWith("usr")
        ? routeToUser({ to: "secondary", userID: id })
        : routeToGroup({ groupID: id, to: "secondary" })
    );
  };

  const onThreadClick = (id: string) =>
    history.push(routeToThread({ threadID: id, to: "secondary" }));

  const tooltipContent = id.startsWith("thr") ? (
    <ThreadItem
      avatarComponent={null}
      className={`${clickEnabled ? "cursor-pointer" : ""}`}
      elementDOMAttributes={{
        onClick: clickEnabled
          ? () => threadEdge && onThreadClick(threadEdge.node.id)
          : undefined,
      }}
      item={threadEdge?.node}
    />
  ) : data?.node ? (
    <RecipientProfileItem
      className={`py-10 ${clickEnabled ? "cursor-pointer" : ""} px-15`}
      onClick={clickEnabled ? onRecipientClick : undefined}
      recipient={data.node as Recipient}
      size="medium"
    />
  ) : (
    <RecipientProfileItem className="py-10 px-15" size="medium" />
  );

  return (
    <Tooltip
      content={tooltipContent}
      isOpenListener={setTooltipVisible}
      placement="top-start"
      keepOpenOnHover
    >
      <span className={tw("inline", className)} {...elementDOMAttributes}>
        {children}
      </span>
    </Tooltip>
  );
};

export default RecipientTooltip;

import { memo, useCallback, useEffect, useRef } from "react";

import { useChannelActionContext, useChatContext } from "stream-chat-react";

import useAuthData from "hooks/useAuthData";
import useComponentMounted from "hooks/useComponentMounted";
import { StreamMessageLimit } from "providers/StreamClientProvider";

import useRefreshChannelState from "../hooks/useRefreshChannelState";

const MaxActiveChannels = 100;

export const ThreadChannelWatcher = memo((): null => {
  const { authData } = useAuthData();
  const { channel: activeChannel, client: streamClient } = useChatContext();
  const { dispatch } = useChannelActionContext();
  const isMounted = useComponentMounted();

  useRefreshChannelState();

  const watchChannel = useCallback(
    (channel: (typeof streamClient.activeChannels)[0]) => {
      if (!streamClient.wsConnection?.isHealthy || !authData?.me.id) {
        return; // will be tried again on recovery
      }

      return channel
        .watch({ messages: { limit: StreamMessageLimit } })
        .then(() =>
          // Force the Channel component to copy state from the channel after
          // querying new messages. Otherwise it will only do on next event.
          // More info: https://github.com/GetStream/stream-chat-react/issues/1229
          dispatch({
            channel,
            type: "copyStateFromChannelOnEvent",
          })
        )
        .catch(err => {
          console.error("Error: [ThreadChannel] -", err);
        });
    },
    [authData?.me.id, dispatch, streamClient]
  );

  const unwatchChannel = useCallback(
    (channel: (typeof streamClient.activeChannels)[0]) => {
      if (!authData?.me.id) return;

      if (authData.me.id in channel.state.watchers) {
        channel.stopWatching().catch(err => {
          console.error("Error: [unwatchChannel] -", err);
        });
        delete channel.state.watchers[authData.me.id];
      }
      delete streamClient.activeChannels[channel.cid];
      streamClient.state.deleteAllChannelReference(channel.cid);
    },
    [authData?.me.id, streamClient]
  );

  // Stop watching and cleanup oldest activeChannel if > 100
  // Prevents us from having too many active channels (leak).
  const purgeOldChannels = useCallback(() => {
    const activeChannels = Object.values(streamClient.activeChannels);
    if (activeChannels[0] && activeChannels.length > MaxActiveChannels) {
      unwatchChannel(activeChannels[0]);
    }
  }, [unwatchChannel, streamClient]);

  // Watch / un-watch channel around component lifecycle
  const pendingWatchRef = useRef<ReturnType<typeof watchChannel>>();
  useEffect(() => {
    if (!activeChannel) return;

    purgeOldChannels();

    // Only need to re-watch if initialized, Stream watches the first time:
    if (activeChannel.initialized && !pendingWatchRef.current) {
      pendingWatchRef.current = watchChannel(activeChannel)?.finally(
        () => (pendingWatchRef.current = undefined)
      );
    }

    // Restore state after connection recovery
    const { unsubscribe } = streamClient.on(
      "connection.changed",
      ({ online }) => isMounted.current && online && watchChannel(activeChannel)
    );

    return () => unsubscribe();
  }, [activeChannel, streamClient, watchChannel, purgeOldChannels, isMounted]);

  useEffect(() => (pendingWatchRef.current = undefined), [activeChannel]);

  return null;
});

import { createZustandStoreContext } from "create-zustand-store-context";

type EditorContentStore = {
  hasAttachments: boolean;
  hasTextContent: boolean;
  hasUploads: boolean;
  isProcessing: boolean;
  isResetting: boolean;
  readOnly: boolean;
};

type Props = {
  editorId: string;
};

const defaultState: EditorContentStore = {
  hasAttachments: false,
  hasTextContent: false,
  hasUploads: false,
  isProcessing: false,
  isResetting: false,
  readOnly: false,
};

const {
  storeContext,
  useState: useEditorContentState,
  useStore: useEditorContentStore,
} = createZustandStoreContext<EditorContentStore, keyof Props>({
  defaultState,
});

const EditorContentStoreContext = storeContext;

function EditorContentProvider({ children, editorId }: WithChildren<Props>) {
  const currentStore = useEditorContentStore(editorId);

  return (
    <EditorContentStoreContext.Provider value={currentStore}>
      {children}
    </EditorContentStoreContext.Provider>
  );
}

export { EditorContentProvider, useEditorContentState };

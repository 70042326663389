import { nodeAs } from "@utility-types";
import { useFetchGroupOrPreviewEdgeQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";

const useGroupEdge = (groupID?: string) => {
  const { authData, authReady } = useAuthData();

  const { data, loading } = useFetchGroupOrPreviewEdgeQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !authData?.me.id || !groupID,
    variables: { id: `${groupID}-${authData?.me.id}` },
  });

  const edge = nodeAs(data?.node, [
    "GroupEdge",
    "GroupPreviewEdge",
    "WorkspaceEdge",
    "WorkspacePreviewEdge",
  ]);

  return { edge, loading };
};

export default useGroupEdge;

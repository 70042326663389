import { Recipient, ThreadEdge, ThreadPreviewEdge } from "@utility-types";
import { ReactComponent as LLMGlueLogo } from "assets/llm-logo-glue.svg";
import { Button, ToggleButton } from "components/design-system/Button";
import { Form } from "components/design-system/Forms";
import { RecipientsSelect } from "components/design-system/Forms/RecipientsSelect";
import SelectDropdown from "components/design-system/Forms/SelectDropdown";
import { Icon } from "components/design-system/icons";
import { routePath } from "components/routing/utils";
import { routeParams as getRouteParams } from "components/routing/utils";
import AutoSuggestSubjectInput from "components/threads/ThreadCompose/AutoSuggestSubjectInput";
import { State } from "components/threads/ThreadCompose/DraftReducer";
import { DraftForm } from "components/threads/ThreadCompose/types";
import {
  Addable,
  FetchThreadEdgeDocument,
  UserSettingsQuery,
  useFetchLlmModelsQuery,
  useUpdateSettingsMutation,
  useUpdateThreadMutation,
  useUserSettingsQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router";
import { formatNameEmoji } from "utils/formatNameEmoji";
import glueImageURL from "utils/glueImageURL";
import env from "utils/processEnv";
import tw from "utils/tw";

type Props = {
  autoSuggestSubject?: boolean;
  compose: State;
  onAddableChange?: (addable: Addable) => void;
  onRecipientsChange?: (recipients: Recipient[]) => void;
  onSubjectChange?: (subject: string) => void;
  onClose?: (() => void) | undefined;
  readOnly?: boolean;
  secondaryPane?: boolean;
  threadEdge?: ThreadEdge | ThreadPreviewEdge | undefined;
};

const AIModel = ({
  d,
  options,
  userSettings,
}: {
  d?: string;
  options: { iconSrc: string; label: string; value: string }[];
  userSettings?: UserSettingsQuery;
}) => {
  const currentModel = userSettings?.settings.llmSettings.chatModel;
  const { getFieldState, watch } = useFormContext();
  const chatModel = watch("chatModel");

  const [updateSettings] = useUpdateSettingsMutation({
    errorPolicy: "all",
  });

  useEffect(() => {
    if (
      chatModel === currentModel ||
      !getFieldState("chatModel").isDirty ||
      !userSettings
    )
      return;

    // currently sets this globally, impacting user preferences;
    // in the future, we may want to set this per-thread.
    const payload = {
      ...userSettings.settings,
      llmSettings: {
        ...userSettings.settings.llmSettings,
        chatModel: chatModel,
      },
    };

    updateSettings({
      // optimisticResponse: {
      //   updateSettings: payload,
      // },
      variables: {
        settings: payload,
      },
    });
  }, [chatModel, currentModel, getFieldState, updateSettings, userSettings]);

  return (
    <SelectDropdown
      className="!h-28"
      mode={d ? "icon-only" : "default"}
      name="chatModel"
      options={[
        {
          icon: LLMGlueLogo,
          label: "Automatic",
          value: "auto",
        },
        ...options,
      ]}
      placement="bottom-end"
    />
  );
};

const SetFormDefaults = ({
  onRecipientsChange,
  threadEdge,
  userSettings,
}: {
  onRecipientsChange?: (recipients: Recipient[]) => void;
  threadEdge: ThreadEdge | ThreadPreviewEdge | undefined;
  userSettings?: UserSettingsQuery;
}) => {
  const { getFieldState, setValue, watch } = useFormContext();
  const chatModel = watch("chatModel");
  const subject = watch("subject");
  const recipients = watch("recipients");

  useEffect(() => {
    if (!threadEdge) return;

    const otherRecipients =
      threadEdge?.node.recipients.edges.filter(
        e => e.node.id !== env.glueAIBotID
      ) ?? [];

    const chatModelSetting = userSettings?.settings.llmSettings.chatModel;
    if (!getFieldState("chatModel").isDirty && chatModelSetting) {
      setValue("chatModel", chatModelSetting);
    }
    if (
      !subject &&
      !getFieldState("subject").isDirty &&
      threadEdge.node.subject
    ) {
      setValue("subject", threadEdge.node.subject);
    }
    if (
      recipients.length === 0 &&
      !getFieldState("recipients").isDirty &&
      otherRecipients.length > 0
    ) {
      setValue(
        "recipients",
        otherRecipients.map(e => e.node)
      );
    }
  }, [
    chatModel,
    getFieldState,
    recipients.length,
    setValue,
    subject,
    threadEdge,
    userSettings?.settings.llmSettings.chatModel,
  ]);

  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      if (type !== "change") return;
      switch (name) {
        case "recipients":
          onRecipientsChange?.(values.recipients as Recipient[]);
          break;
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [getFieldState, onRecipientsChange, watch]);

  return null;
};

const SubmitButton = () => {
  const { authData } = useAuthData();
  const { watch } = useFormContext<DraftForm>();
  const recipients = watch("recipients");
  const otherRecipients = recipients.filter(
    r => r.id !== env.glueAIBotID && r.id !== authData?.me.id
  );

  return (
    <Button
      buttonFont="subheadBold"
      buttonStyle="primary"
      disabled={!otherRecipients.length}
      type="submit"
    >
      Send
    </Button>
  );
};

const AIThreadHeader = ({
  autoSuggestSubject = true,
  compose,
  onAddableChange,
  onRecipientsChange,
  onSubjectChange,
  onClose,
  readOnly,
  secondaryPane,
  threadEdge,
}: Props) => {
  const { authData } = useAuthData();
  const history = useHistory();

  const { pathname, search } = history.location;
  const { d } = getRouteParams({ pathname, search });

  const workspaceID = authData?.me.workspaceIDs[0] ?? "";

  const [makeThread, setMakeThread] = useState(false);

  const { data } = useUserSettingsQuery({
    fetchPolicy: "cache-and-network",
  });

  const { data: llmModelsData } = useFetchLlmModelsQuery({
    fetchPolicy: "cache-and-network",
    skip: !workspaceID,
    variables: {
      workspaceID,
    },
  });

  const options = useMemo(() => {
    return (
      llmModelsData?.llmModels.edges.map(({ node }) => {
        return {
          iconSrc: glueImageURL(node.providerLogoURL, {
            fit: "max",
            w: 40,
            h: 40,
          }),
          label: node.name,
          value: node.id,
        };
      }) ?? []
    );
  }, [llmModelsData?.llmModels.edges]);

  const subject = threadEdge?.node.subject;
  const addableNone = compose?.draftForm?.recipientsAddable === Addable.None;

  const [updateThread] = useUpdateThreadMutation({
    errorPolicy: "all",
  });

  const handleSend = (data: {
    chatModel: string;
    recipients: Recipient[];
    subject: string;
  }) => {
    if (threadEdge?.node.__typename !== "Thread") {
      return Promise.resolve();
    }

    const input = {
      recipients: [...data.recipients.map(r => r.id)],
      subject: formatNameEmoji({ name: data.subject }).nameWithEmoji,
    };

    return updateThread({
      refetchQueries: [FetchThreadEdgeDocument],
      variables: {
        id: threadEdge.node.id,
        input,
      },
    })
      .then(() => {
        setMakeThread(false);
        if (!d && !secondaryPane) {
          history.push(
            routePath({
              d: threadEdge.node.id,
              superTab: "ai",
              view: "compose",
            })
          );
        }
      })
      .catch(err => {
        console.warn("Error: [onSendMessage] -", err);
      });
  };

  const otherRecipients =
    threadEdge?.node.recipients.edges.filter(
      e => e.node.id !== env.glueAIBotID
    ) ?? [];

  return (
    <Form
      onSubmit={handleSend}
      useFormProps={{
        defaultValues: {
          chatModel: data?.settings.llmSettings.chatModel,
          recipients: otherRecipients?.map(e => e.node) ?? [],
          subject: threadEdge?.node.subject,
        },
      }}
    >
      <SetFormDefaults
        onRecipientsChange={onRecipientsChange}
        threadEdge={threadEdge}
        userSettings={data}
      />
      <div
        className="border-b border-border-container flex flex-col items-start shrink-0 w-full relative"
        data-testid="PaneHeader"
      >
        <div className="flex flex-col w-full">
          <div
            className={tw(
              "flex items-center gap-12 grow min-w-0 mb-8 pt-16 pr-16",
              {
                "pb-8": !makeThread,
              }
            )}
          >
            <div
              className={tw(
                "flex grow min-w-0 w-full",
                makeThread && !secondaryPane ? "px-12" : "px-16"
              )}
            >
              {!!onClose && (
                <Button
                  buttonStyle="subtle"
                  buttonType="text"
                  className={tw("px-8", makeThread ? "mr-8" : "mr-12")}
                  icon="Close"
                  iconSize={20}
                  iconStroke={2}
                  onClick={e => {
                    e.stopPropagation();
                    onClose();
                  }}
                />
              )}
              <div className="text-headline-bold text-text-primary truncate w-full h-28">
                {makeThread || !subject ? (
                  <AutoSuggestSubjectInput
                    autoSuggestSubject={autoSuggestSubject}
                    compose={compose}
                    onSubjectChange={onSubjectChange}
                    readOnly={readOnly}
                    isGlueAI={!makeThread}
                  />
                ) : (
                  subject
                )}
              </div>
            </div>

            {!makeThread && (
              <div className="flex gap-8 justify-end grow-0 shrink-0 h-28 relative z-1">
                <AIModel d={d} options={options} userSettings={data} />
                <Button
                  buttonStyle="icon-secondary"
                  buttonType="none"
                  className="border-1 border-border-container hover:border-border-container-hover focus-visible-shadow h-28 pr-8 pl-4 rounded-md"
                  onClick={() => {
                    setMakeThread(true);
                  }}
                  type="button"
                >
                  <Icon className="mr-4" icon="UserAdd" size={20} />
                  <span className="text-footnote">Add</span>
                </Button>
              </div>
            )}
          </div>

          {makeThread && (
            <>
              <div className="flex items-end h-32 mt-4 mb-4 px-16">
                <RecipientsSelect<DraftForm>
                  autoFocus={false}
                  borderWidth={0}
                  className="z-2 w-full border-none"
                  disabled={readOnly}
                  filterMe={true}
                  maxLines={3}
                  name="recipients"
                  placeholder="To groups, users, or emails..."
                  wrapperClassName="!my-0 grow min-w-0 text-subhead"
                  includeGlueAI={false}
                />
                <ToggleButton
                  buttonStyle="none"
                  className="!p-8 ml-8 mb-2 mr-2"
                  disabledState={{
                    icon: "Unlock",
                    iconClassName: "text-icon-subtle",
                    title: "Lock recipient list",
                  }}
                  enabledState={{
                    icon: "Lock",
                    iconClassName: "text-accent-badge",
                    title: "Unlock recipient list",
                  }}
                  iconSize={18}
                  onClick={() => {
                    onAddableChange?.(
                      addableNone ? Addable.Anyone : Addable.None
                    );
                  }}
                  toggled={addableNone}
                />
              </div>

              <div className="border-t-1 border-border-container flex items-center justify-between px-16">
                <div className="flex items-center h-48 text-subtle text-text-secondary">
                  <Icon
                    className="mr-8 text-icon-action"
                    icon="Info"
                    size={20}
                  />
                  Add recipients to start a thread.
                </div>
                <div className="flex items-center gap-8">
                  <Button
                    buttonFont="subheadBold"
                    buttonStyle="icon-secondary"
                    onClick={() => {
                      setMakeThread(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <SubmitButton />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Form>
  );
};

export default AIThreadHeader;

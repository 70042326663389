import { Button } from "components/design-system/Button";
import { useHistory } from "react-router";
import tw from "utils/tw";
import { ProfileMenu } from "../Menus/ProfileMenu";
import WorkspaceMenu from "../Menus/WorkspaceMenu";

type Props = {
  buttons?: React.ReactNode | boolean;
  detailChildren?: React.ReactNode;
  headerContent?: React.ReactNode;
  name?: string;
};

const AppTabDetail = ({
  children,
  isOpen,
}: WithChildren<{ isOpen: boolean }>) => {
  return (
    <div
      className={tw(
        "bg-background-body flex flex-col grow min-h-0 pt-safe-area",
        "absolute top-0 right-0 bottom-0 -mr-[50px] w-full z-2",
        "transition-transform",
        isOpen ? "translate-x-[0%]" : "translate-x-[100%]"
      )}
      data-testid="app-tab-detail"
    >
      {children}
    </div>
  );
};

const AppTabHeaderDiv = ({
  children,
  margin,
}: WithChildren<{ margin?: string }>) => (
  <div
    className={tw(
      "flex items-center h-60 justify-between shrink-0 mt-safe-area",
      margin
    )}
  >
    {children}
  </div>
);

const AppTabHeader = ({
  buttons,
  detailOpen,
  name,
}: Props & {
  detailOpen: boolean;
}) => {
  const history = useHistory();

  return (
    <AppTabHeaderDiv margin="ml-20 mr-8">
      {name && (
        <h2
          className={tw(
            "duration-350 text-lg font-bold transition-colors",
            detailOpen ? "text-text-primary" : "text-text-action-inverse"
          )}
        >
          {name}
        </h2>
      )}
      {buttons !== false && (
        <div className="flex gap-8">
          {buttons}
          <Button
            buttonStyle="action-inverse"
            className="flex items-center justify-center h-44 w-40"
            icon="Search"
            onClick={() => history.push("/search")}
          />
          <ProfileMenu />
          <WorkspaceMenu />
        </div>
      )}
    </AppTabHeaderDiv>
  );
};

const AppTabLayout = ({
  buttons,
  children,
  detailChildren,
  headerContent,
  name,
}: WithChildren<Props>) => {
  return (
    <div
      className={tw(
        "duration-350 flex flex-col grow min-h-0 relative transition-all w-full",
        detailChildren
          ? "bg-background-body -translate-x-[50px]"
          : "bg-background-app-base translate-x-0"
      )}
    >
      {headerContent ? (
        <AppTabHeaderDiv>{headerContent}</AppTabHeaderDiv>
      ) : (
        <AppTabHeader
          buttons={buttons}
          detailOpen={!!detailChildren}
          headerContent={headerContent}
          name={name}
        />
      )}

      <div
        className="bg-background-body flex flex-col grow min-h-0 rounded-t-xl overflow-hidden"
        data-testid="app-tab-main"
      >
        {children}
      </div>

      <AppTabDetail data-testid="app-tab-detail" isOpen={!!detailChildren}>
        {detailChildren}
      </AppTabDetail>
    </div>
  );
};

export default AppTabLayout;

import { nodeAs } from "@utility-types";
import Avatar from "components/design-system/Avatar/Avatar";
import { Button } from "components/design-system/Button";
import { Icon } from "components/design-system/icons";
import {
  NotInThreadDocument,
  useCancelRequestJoinThreadMutation,
  useNotInThreadQuery,
  useRequestJoinThreadMutation,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import { useSnackbar } from "providers/SnackbarProvider";

type Props = {
  threadID: string;
};

const NotInThread = ({ threadID }: Props): JSX.Element => {
  const { authData, authReady } = useAuthData();

  const { openSnackbar } = useSnackbar();

  const { data } = useNotInThreadQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    variables: { id: `${threadID}-${authData?.me.id}`, joinableID: threadID },
  });

  const [requestJoin] = useRequestJoinThreadMutation({
    onError: () => {
      openSnackbar("error", "Something went wrong.");
    },
    refetchQueries: [NotInThreadDocument],
  });

  const [cancelRequest] = useCancelRequestJoinThreadMutation({
    onError: () => {
      openSnackbar("error", "Something went wrong.");
    },
    refetchQueries: [NotInThreadDocument],
  });

  const requestToJoin = () => {
    requestJoin({ variables: { input: { joinableID: threadID } } });
  };

  const cancel = (requestID: string) => {
    cancelRequest({ variables: { id: requestID } });
  };

  const joinRequestID = data?.joinRequest?.id;
  const threadEdge = nodeAs(data?.node, ["ThreadEdge", "ThreadPreviewEdge"]);
  const admin = nodeAs(threadEdge?.node, ["ThreadPreview"])?.admin;

  return (
    <div className="join-group">
      <div className="icons">
        <Icon icon="Mail" size={60} strokeWidth={4} />
        <Icon className="-ml-16 bg-background-body" icon="Lock" size={28} />
      </div>
      <span className="text-title">You're not in this thread.</span>
      <div className="controls">
        {admin ? (
          <>
            <Avatar {...admin} />
            <div className="admin">
              <span className="name">{admin?.name}</span>
              <span className="role">Thread Owner</span>
            </div>
          </>
        ) : null}
        {joinRequestID ? (
          <Button
            buttonStyle="simpleSecondary"
            onClick={() => cancel(joinRequestID)}
          >
            Cancel Request
          </Button>
        ) : (
          <Button buttonStyle="simplePrimary" onClick={() => requestToJoin()}>
            Request to Join
          </Button>
        )}
      </div>
    </div>
  );
};

export default NotInThread;

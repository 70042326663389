import BlockActions from "./BlockActions";
import BlockButton from "./BlockButton";
import BlockCheckboxes from "./BlockCheckboxes";
import BlockContext from "./BlockContext";
import BlockDivider from "./BlockDivider";
import BlockImage from "./BlockImage";
import BlockInput from "./BlockInput";
import BlockSection from "./BlockSection";
import BlockStaticSelect from "./BlockStaticSelect";
import {
  Block,
  Control,
  ExternalSelectControl,
  MultiExternalSelectControl,
  Option,
  OptionGroup,
} from "./blockTypes";

export type BlockOnAction = (
  block: Control,
  value: string | string[] | undefined
) => void;

export type BlockOnLink = (path: string) => void;

export type BlockOnValueChanged = (
  block: Control,
  value: string | string[] | undefined
) => void;

export type BlockOnOptionsLoad = (
  block: ExternalSelectControl | MultiExternalSelectControl,
  value: string
) => Promise<{ optionGroups?: OptionGroup[]; options?: Option[] }>;

export type BlockInteractionHandlers = {
  onLink: BlockOnLink;
  onAction: BlockOnAction;
  onOptionsLoad: BlockOnOptionsLoad;
  onValueChanged: BlockOnValueChanged;
};

export type BlockComponentProps = {
  block: Block;
  handlers: BlockInteractionHandlers;
  parentType?: Block["type"];
};

const BlockComponent = (props: BlockComponentProps) => {
  const block = props.block;
  const type = block.type;
  switch (type) {
    case "section":
      return <BlockSection {...{ ...props, block }} />;
    case "divider":
      return <BlockDivider {...{ ...props, block }} />;
    case "image":
      return <BlockImage {...{ ...props, block }} />;
    case "input":
      return <BlockInput {...{ ...props, block }} />;
    case "staticSelect":
      return <BlockStaticSelect {...{ ...props, block }} />;
    case "actions":
      return <BlockActions {...{ ...props, block }} />;
    case "button":
      return <BlockButton {...{ ...props, block }} />;
    // case "header":
    //   return <BlockHeader {...{ ...props, block }} />;
    // case "file":
    //   return <BlockFile {...{ ...props, block }} />;
    case "context":
      return <BlockContext {...{ ...props, block }} />;
    case "checkboxes":
      return <BlockCheckboxes {...{ ...props, block }} />;
    // case "urlTextInput":
    //   return <BlockUrlTextInput {...{ ...props, block }} />;
    // case "numberInput":
    //   return <BlockNumberInput {...{ ...props, block }} />;
    // case "plainTextInput":
    //   return <BlockPlainTextInput {...{ ...props, block }} />;
    // case "emailTextInput":
    //   return <BlockEmailTextInput {...{ ...props, block }} />;
    // case "radioButtons":
    //   return <BlockRadioButtons {...{ ...props, block }} />;
    // case "multiStaticSelect":
    //   return <BlockMultiStaticSelect {...{ ...props, block }} />;
    // case "externalSelect":
    //   return <BlockExternalSelect {...{ ...props, block }} />;
    // case "multiExternalSelect":
    //   return <BlockMultiExternalSelect {...{ ...props, block }} />;
    default:
      return null;
  }
};

export default BlockComponent;

import {
  InboxSidebarSectionsProvider,
  useInboxSidebarSectionsState,
} from "components/views/inbox/providers/InboxSidebarSectionsProvider";
import { useEffect } from "react";
import useAppStateStore from "store/useAppStateStore";
import { INBOX_SIDEBAR_ID } from "./InboxMain";
import Apps from "./InboxMain/Apps";
import DirectMessages from "./InboxMain/DirectMessages";
import Drafts from "./InboxMain/Drafts";
import FollowingThreadsList from "./InboxMain/FollowingThreadsList";
import GroupChats from "./InboxMain/GroupChats";
import NewRequests from "./InboxMain/NewRequests";
import Starred from "./InboxMain/Starred";

const InboxSidebarSections = ({
  scrollRef,
}: { scrollRef?: HTMLDivElement | HTMLUListElement | null }) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  return (
    <InboxSidebarSectionsProvider>
      <NewRequests containerID={INBOX_SIDEBAR_ID} />
      <Drafts />
      <FollowingThreadsList skipAutoSelect={!breakpointMD} />
      <Starred />
      <GroupChats />
      <DirectMessages />
      <Apps />
      {scrollRef ? <ResetSwipe scrollRef={scrollRef} /> : null}
    </InboxSidebarSectionsProvider>
  );
};

export default InboxSidebarSections;

function ResetSwipe({
  scrollRef,
}: { scrollRef: HTMLDivElement | HTMLUListElement | null }) {
  const { swipedOpenItemId, setState } = useInboxSidebarSectionsState(
    ({ swipedOpenItemId }) => ({
      swipedOpenItemId,
    })
  );

  useEffect(() => {
    const list = scrollRef;
    if (!list || !swipedOpenItemId) return;

    const prevTop = list.scrollTop;
    const handler = () => {
      if (!scrollRef) return;

      // don't close swiped open item if scrolled less than 30px
      if (Math.abs(prevTop - scrollRef.scrollTop) < 30) return;
      setState({ swipedOpenItemId: undefined });
    };

    list.addEventListener("scroll", handler);

    return () => {
      list.removeEventListener("scroll", handler);
    };
  }, [scrollRef, setState, swipedOpenItemId]);
  return null;
}

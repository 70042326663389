import { Stripe, loadStripe } from "@stripe/stripe-js";
import { createContext, useEffect, useState } from "react";
import env from "utils/processEnv";

type StripeValue = Stripe | null;

export const StripeContext = createContext<{ stripe: StripeValue }>({
  stripe: null,
});

export default function StripeContextProvider({ children }: WithChildren) {
  const [stripe, setStripe] = useState<StripeValue>(null);

  useEffect(() => {
    (async () => {
      const stripe = await loadStripe(env.stripePublicKey);
      setStripe(stripe);
    })();
  }, []);

  return (
    <section className="section">
      <StripeContext.Provider value={{ stripe }}>
        {children}
      </StripeContext.Provider>
    </section>
  );
}

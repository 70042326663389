import { RefObject } from "react";

import tw from "utils/tw";

import { Recipient } from "@utility-types";
import { parseItemData } from "components/ProfileItem/parseItemData";
import Avatar from "components/design-system/Avatar/Avatar";
import IdentityBadge from "components/design-system/ui/IdentityBadge/IdentityBadge";
import useRecipientDomains from "hooks/workspace/useRecipientDomains";
import useAppStateStore from "store/useAppStateStore";
import { formatGroupName } from "utils/group/formatGroupName";
import { ResultEdgeType, Results } from "../types";
import ResultList from "./ResultList";

type Props<T extends "users" | "groups"> = {
  isReversed?: boolean;
  label: string;
  onClickResult?: (
    item: ResultEdgeType<T>["node"],
    index: number,
    event: React.MouseEvent<HTMLDivElement | HTMLLIElement, MouseEvent>
  ) => void;
  results: Results<T>;
  searching?: boolean;
  selectedResultID?: string;
  selectedResultRef?: RefObject<HTMLLIElement>;
  setSelectedResultID?: (id: string) => void;
};

export const RecipientProfileLine = ({
  recipient,
}: {
  recipient?: Recipient;
}) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  const data = parseItemData(recipient);
  const { emoji, name: formattedName } = formatGroupName({
    id: data.id,
    name: data.name,
  });

  const { workspace } = useRecipientDomains(recipient);

  const description = recipient?.bio || workspace?.name;
  const showSkeleton = !data.id;

  return (
    <div
      className={tw("flex items-center justify-start", "h-56 md:h-36 px-16")}
    >
      <Avatar
        avatarURL={data.avatarURL}
        emojiProps={{ emoji }}
        margin="mr-8"
        name={data.name}
        background={data.avatarURL || emoji ? "transparent" : "subtle"}
        size={breakpointMD ? "x-small" : "small"}
      />

      <div className="flex flex-wrap md:flex-nowrap items-center justify-start grow min-w-0 overflow-hidden">
        <div className="text-body-bold text-text-primary truncate w-full md:w-auto">
          {formattedName}
        </div>

        <div className="flex flex-nowrap overflow-hidden">
          {description && (
            <div className="ml-6 text-footnote text-text-subtle truncate">
              {description}
            </div>
          )}

          {!showSkeleton && (
            <div className="flex items-center ml-6">
              <IdentityBadge recipient={recipient} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const RecipientsResults = <T extends "users" | "groups">({
  isReversed,
  label,
  onClickResult,
  results,
  searching = false,
  selectedResultID,
  selectedResultRef,
  setSelectedResultID,
}: Props<T>): JSX.Element | null => {
  if (!results.edges.length) return null;
  return (
    <ResultList isReversed={isReversed} label={label} searching={searching}>
      {results.edges.map((edge, index) => {
        const isSelected = selectedResultID === edge.node.id;
        const className = tw("cursor-pointer select-none", {
          "bg-accent-highlight/25": isSelected,
        });

        return (
          <li
            key={`search-results-${edge.node.id}`}
            ref={isSelected ? selectedResultRef : undefined}
            className={className}
            onClick={e => onClickResult?.(edge.node, index, e)}
            onFocus={() => setSelectedResultID?.(edge.node.id)}
            onMouseEnter={() => setSelectedResultID?.(edge.node.id)}
          >
            <RecipientProfileLine recipient={edge.node} />
          </li>
        );
      })}
    </ResultList>
  );
};
export default RecipientsResults;

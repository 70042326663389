import type { FetchResult } from "@apollo/client";
import { CapacitorWebAuth } from "@gluegroups/capacitor-web-auth";
import {
  type MasonryRequestMutation,
  type MasonrySessionOriginationInput,
  useAuthorizeWorkspaceAppMutation,
  useMasonryRequestMutation,
  useMasonrySessionMutation,
} from "generated/graphql";
import useEphemeralMessage from "hooks/state/useEphemeralMessage";
import useInterval from "hooks/useInterval";
import useOnce from "hooks/useOnce";
import { useRef, useState } from "react";
import useModalStore from "store/useModalStore";
import generateRandomId from "utils/generateRandomId";
import { isNative } from "utils/platform";
import env from "utils/processEnv";
import type { SurfaceBlock } from "./Blocks/blockTypes";
import ConsentModal from "./Consent/ConsentModal";
import MasonrySurfaceManager from "./MasonrySurfaceManager";
import type {
  AuthorizeScopesAction,
  FinishAppUnfurlSetup,
  MasonryAuthorizeScopesResultRequest,
  MasonryOpenURLResultRequest,
  MasonryResponse,
  OpenURLResponseAction,
  Session,
} from "./masonryTypes";
import type { MasonryInitialRequestInput } from "./useMasonrySessionStore";

type SurfaceElement = { surface: SurfaceBlock; key: string };

export const capacitorWebAuthURLScheme = `${env.glueAppId}.capacitor-web-auth`;

const MasonrySessionManager = (props: {
  origination: MasonrySessionOriginationInput;
  input: MasonryInitialRequestInput;
}) => {
  const [masonryRequest] = useMasonryRequestMutation();
  const [masonrySession] = useMasonrySessionMutation();
  const [authorizeWorkspaceApp] = useAuthorizeWorkspaceAppMutation();
  const { updateEphemeralMessage } = useEphemeralMessage();

  const { openModal } = useModalStore(({ closeModal, openModal }) => ({
    closeModal,
    openModal,
  }));

  const popupPathRef = useRef<string | null>(null);
  const popupSurfaceIDRef = useRef<string | null>(null);
  const sessionRef = useRef<Session | null>(null);
  const popupRef = useRef<Window | null>(null);
  const [surfaceElements, setSurfaceElements] = useState<SurfaceElement[]>([]);

  const commonMasonryInput = (session: Session, surface: SurfaceBlock) => {
    return {
      session: session,
      surface: {
        controlValues: {},
        id: surface.id,
        metadata: null,
        type: surface.type,
      },
      timestamp: new Date().toISOString(),
    };
  };

  const removeSurface = (surfaceID: string) => {
    setSurfaceElements(
      surfaceElements.filter(element => element.surface.id !== surfaceID)
    );
  };

  const deliverOpenURLResult = async (
    path: string,
    surface: SurfaceBlock,
    canceled: boolean,
    search?: string
  ) => {
    const session = sessionRef.current;
    if (!session) {
      throw "Cannot deliver open URL result without session";
    }
    const input: MasonryOpenURLResultRequest = {
      data: {
        canceled,
        search,
      },
      path,
      requestType: "openURLResult",
      ...commonMasonryInput(session, surface),
    };

    const result = await masonryRequest({
      variables: {
        input,
      },
    });

    handleMasonryResponse(surface, result);
  };

  const handleMasonryResponse = (
    existingSurface: SurfaceBlock | undefined,
    result: FetchResult<MasonryRequestMutation>
  ) => {
    const session = sessionRef.current;
    if (!session) {
      throw "Cannot handle masonry response without session";
    }

    const response = result.data?.masonryRequest as MasonryResponse;

    const contextUpdate = response.context;
    if (contextUpdate) {
      let newContext = { ...session.context };
      contextUpdate.delete.forEach(element => {
        delete newContext[element];
      });
      newContext = { ...newContext, ...contextUpdate.set };

      const newSession = {
        ...session,
        context: newContext,
      };
      sessionRef.current = newSession;
    }

    let surface: SurfaceBlock | undefined = existingSurface;
    switch (response.responseType) {
      case "updateSurface":
      case "replaceSurface":
        if (!existingSurface) {
          throw new Error(
            "updateSurface or replaceSurface with no existing surface"
          );
        }
        const newSurface = {
          ...(response.data as object),
          id: existingSurface.id,
        } as SurfaceBlock;
        surface = newSurface;
        const newSurfaces = surfaceElements.map(element => {
          if (element.surface.id === existingSurface.id) {
            const key =
              response.responseType === "replaceSurface"
                ? generateRandomId()
                : element.key;
            return { surface: newSurface, key };
          }
          return element;
        });
        setSurfaceElements(newSurfaces);
        break;

      case "closeSurface":
        if (!existingSurface) {
          throw new Error("closeSurface with no existing surface");
        }
        removeSurface(existingSurface.id);
        break;

      case "closeAll":
        setSurfaceElements([]);
        break;

      case "newSurface":
        surface = response.data as SurfaceBlock;
        if (!surface.id) {
          surface = { ...surface, id: generateRandomId() };
        }
        const newSurfaceElements = [
          ...surfaceElements,
          { surface, key: generateRandomId() },
        ];
        setSurfaceElements(newSurfaceElements);
        break;

      default:
        throw new Error(`Unexpected responseType=${response.responseType}`);
    }

    const openURLAction = response.actions?.find(
      (action): action is OpenURLResponseAction => {
        return action.actionType === "openURLForResult";
      }
    );
    if (openURLAction) {
      if (!surface) {
        throw "Cannot handle openURLForResult action without surface";
      }

      popupPathRef.current = openURLAction.path;
      popupSurfaceIDRef.current = surface.id;

      if (isNative()) {
        (async () => {
          let search: string | undefined;

          // We need to bounce the user through our app in the browser popup to set a flag
          // indicating that we're doing native masonry auth.
          // This flag is used at the end of the process to invoke the native app.
          const url = new URL(env.glueAppUrl);
          url.pathname = `/${session.origination.appID}/redirect`;

          const params = new URLSearchParams();
          params.append("location", openURLAction.url);
          url.search = params.toString();
          try {
            const { value } = await CapacitorWebAuth.openURLForResult({
              url: url.toString(),
              scheme: capacitorWebAuthURLScheme,
            });
            search = new URL(value).search;
          } catch {
            // Treat it as canceled if there is an error
          }
          deliverOpenURLResult(openURLAction.path, surface, !search, search);
        })();
      } else {
        const popup = window.open(
          openURLAction.url,
          "popup",
          "popup=true,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=800"
        );
        popupRef.current = popup;
      }
    }

    const finishAppUnfurlSetup = response.actions?.find(
      (action): action is FinishAppUnfurlSetup => {
        return action.actionType === "finishAppUnfurlSetup";
      }
    );
    if (finishAppUnfurlSetup) {
      (async () => {
        const messageID = session.origination.messageID;
        const threadID = session.origination.thread?.id;

        if (!messageID || !threadID) {
          return;
        }

        const updatedAt = new Date().toISOString();
        await updateEphemeralMessage({
          id: messageID,
          text: "App is now connected",
          threadID,
          updatedAt,
        });
      })();
    }

    const authorizeScopesAction = response.actions?.find(
      (action): action is AuthorizeScopesAction => {
        return action.actionType === "authorizeScopes";
      }
    );
    if (authorizeScopesAction) {
      if (!surface) {
        throw "Cannot handle authorizeScopes action without surface";
      }
      const theSurface = surface;

      const appID = session.origination.appID;
      const workspaceID = session.origination.workspace.id;

      const onAllow = () => {
        (async () => {
          const { data } = await authorizeWorkspaceApp({
            variables: {
              input: {
                appID,
                scopes: authorizeScopesAction.scopes,
                workspaceID,
              },
            },
          });

          const input: MasonryAuthorizeScopesResultRequest = {
            data: {
              canceled: false,
              code: data?.authorizeWorkspaceApp?.authorizationCode,
            },
            path: authorizeScopesAction.path,
            requestType: "authorizeScopesResult",
            ...commonMasonryInput(session, theSurface),
          };

          const result = await masonryRequest({
            variables: {
              input,
            },
          });

          handleMasonryResponse(surface, result);
        })();
      };

      openModal(
        <ConsentModal
          appID={session.origination.appID}
          onAllow={onAllow}
          scopes={authorizeScopesAction.scopes}
          workspaceID={session.origination.workspace.id}
        />
      );
    }
  };

  useInterval(() => {
    const path = popupPathRef.current;
    const popup = popupRef.current;
    if (!popup || !path) {
      return;
    }

    const session = sessionRef.current;
    if (!session) {
      throw "Cannot handle popup without session";
    }

    const surfaceID = popupSurfaceIDRef.current;
    const surface = surfaceID
      ? surfaceElements.find(element => element.surface.id === surfaceID)
          ?.surface
      : null;
    if (!surface) {
      throw "Cannot handle popup without surface";
    }

    let pathname: string;
    try {
      pathname = popup.window.location.pathname;
    } catch {
      // We will get an error when accessing the window location while
      // the window location does not match our domain
      // Just keep trying until we reach the callback URL
      return;
    }

    if (pathname === `/${session.origination.appID}/callback`) {
      popup.close();
      popupRef.current = null;
      deliverOpenURLResult(path, surface, false, popup.window.location.search);
    } else if (popup.closed) {
      popupRef.current = null;
      deliverOpenURLResult(path, surface, true, popup.window.location.search);
    }
  }, 100);

  const initialized = useRef(false);

  useOnce(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;

    (async () => {
      const sessionResult = await masonrySession({
        variables: { input: props.origination },
      });

      const input = {
        ...props.input,
        timestamp: new Date().toISOString(),
        session: sessionResult.data?.masonrySession,
      };

      sessionRef.current = sessionResult.data?.masonrySession as Session;

      const requestResult = await masonryRequest({
        variables: {
          input: input,
        },
      });

      handleMasonryResponse(undefined, requestResult);
    })();
  });

  const session = sessionRef.current;

  if (!session) return null;

  return (
    <>
      {surfaceElements.map(element => {
        return (
          <MasonrySurfaceManager
            key={element.surface.id}
            session={session}
            surface={element.surface}
            surfaceKey={element.key}
            handleMasonryResponse={result =>
              handleMasonryResponse(element.surface, result)
            }
            onClose={() => removeSurface(element.surface.id)}
          />
        );
      })}
    </>
  );
};

export default MasonrySessionManager;

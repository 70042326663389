type Props = {
  children: React.ReactNode;
  title?: string;
};

export const DropdownActionButtonGroup = ({ children, title }: Props) => {
  return (
    <div className="border-b-thin border-background-subtle flex flex-col max-w-full min-w-0 py-8 last:border-none">
      {title && (
        <div className="text-footnote-bold text-text-subtle px-16 py-3">
          {title}
        </div>
      )}
      {children}
    </div>
  );
};

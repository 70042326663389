import { useWorkspaceSubscriptionQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import { useEffect, useRef } from "react";
import useAppStateStore from "store/useAppStateStore";

const useWorkspaceSubscription = (workspaceID: string) => {
  const { authData, authReady } = useAuthData();

  const { data, refetch } = useWorkspaceSubscriptionQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-and-network",
    skip: !authData?.me.id || !workspaceID,
    variables: { workspaceID },
  });

  const { appStatus } = useAppStateStore(({ appStatus }) => ({ appStatus }));

  const refetchRef = useRef(refetch);
  refetchRef.current = refetch;

  useEffect(() => {
    if (appStatus === "active") {
      refetchRef.current();
    }
  }, [appStatus]);

  return { data, refetch };
};

export default useWorkspaceSubscription;

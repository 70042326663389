import { GlueFile, LinksCategory, nodeIs } from "@utility-types/graphql";
import FilePreview, {
  FilePreviewRef,
} from "components/FilePreview/FilePreview";
import { useRef } from "react";
import clickTemporaryLink from "utils/clickTemporaryLink";
import formatFileSize from "utils/formatFileSize";
import { isNativeMobile } from "utils/platform";
import tw from "utils/tw";
import EmptyState from "./EmptyState";
import SharedListHeader from "./SharedListHeader";
import SharedListItem from "./SharedListItem";
import useDownloadFileOnMobile from "./hooks/useDownloadFileOnMobile";
import useSharedList from "./hooks/useSharedList";
import { FiltersKeys, SharedFilters } from "./types";

type ItemsType = GlueFile & { createdAt: string };
const Files = ({
  groupID,
  isWide,
  filter: selectedFilter,
  openDetailedView,
}: {
  groupID: string;
  isWide: boolean;
  filter: FiltersKeys;
  openDetailedView: () => void;
}) => {
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const filePreviewRef = useRef<FilePreviewRef>(null);
  const { items, groupedItems, loadMoreRef, loading, isRecent, hasNextPage } =
    useSharedList({
      category: LinksCategory.File,
      groupID,
      selectedFilter,
    });

  const files =
    (isRecent
      ? items?.filter((i): i is GlueFile => nodeIs(i, ["File"]))
      : groupedItems.flatMap(g =>
          g.items.filter((i): i is ItemsType => nodeIs(i, ["File"]))
        )
    )
      ?.filter(f => f.previewable)
      ?.map(f => ({
        id: f.id,
        name: f.name,
        url: f.url,
      })) ?? [];

  const handleOpenFilePreview = (id: string, name: string, url: string) => {
    filePreviewRef.current?.openPreview({ id, name, url });
  };

  const downloadFileOnMobile = useDownloadFileOnMobile();

  if (selectedFilter !== SharedFilters.Files && !isRecent) {
    return null;
  }

  if (!groupedItems?.length && !loading) {
    return !isRecent ? <EmptyState filter={SharedFilters.Files} /> : null;
  }

  const handleDownloadFile = (name: string, url: string) => {
    if (isNativeMobile()) {
      downloadFileOnMobile(name, url);
      return;
    }
    if (!anchorRef.current) return;
    clickTemporaryLink(url, name);
  };

  return (
    <div
      className={tw("pb-4 bg-background-body shadow-level1 overflow-hidden", {
        "rounded-lg": isWide,
      })}
    >
      <FilePreview files={files} ref={filePreviewRef} />
      {/* biome-ignore lint/a11y/useAnchorContent: Since it's hidden, we don't need to add content */}
      <a className="hidden" ref={anchorRef} />
      {loading ? (
        <>
          <div className="px-20">
            <SharedListHeader name="Files" />
          </div>
          <div className="flex flex-col">
            <SharedListItem leftIndent="20px" showSkeleton />
          </div>
        </>
      ) : isRecent ? (
        <>
          <div className="px-20">
            <SharedListHeader
              name="Files"
              onShowAll={isRecent ? openDetailedView : undefined}
            />
          </div>
          {items
            ?.filter((i): i is GlueFile => nodeIs(i, ["File"]))
            .map(doc => (
              <SharedListItem
                key={doc.id}
                name={doc.name}
                mimeType={doc.contentType}
                description={formatFileSize(Number(doc.contentLength))}
                onClick={
                  doc.previewable
                    ? () => handleOpenFilePreview(doc.id, doc.name, doc.url)
                    : () => handleDownloadFile(doc.name, doc.url)
                }
                leftIndent="20px"
                actionType={doc.previewable ? "preview" : "download"}
              />
            ))}
        </>
      ) : (
        groupedItems.map(g => (
          <div className="flex flex-col" key={g.group}>
            <div className="px-20">
              <SharedListHeader name={g.group} />
            </div>
            {g.items
              .filter((i): i is ItemsType => nodeIs(i, ["File"]))
              .map(doc => (
                <SharedListItem
                  key={doc.id}
                  name={doc.name}
                  mimeType={doc.contentType}
                  description={formatFileSize(Number(doc.contentLength))}
                  onClick={
                    doc.previewable
                      ? () => handleOpenFilePreview(doc.id, doc.name, doc.url)
                      : () => handleDownloadFile(doc.name, doc.url)
                  }
                  leftIndent="20px"
                  actionType={doc.previewable ? "preview" : "download"}
                />
              ))}
          </div>
        ))
      )}
      {hasNextPage && (
        <div ref={loadMoreRef}>
          <SharedListItem leftIndent="20px" showSkeleton />
        </div>
      )}
    </div>
  );
};

export default Files;

import {
  MemberRole as MemberRoleValue,
  useAddGroupMembersMutation,
  useAddWorkspaceMembersMutation,
} from "generated/graphql";

type MemberRole = { member: string; role: MemberRoleValue };

const useMemberItemActions = (recipientID?: string) => {
  const isWorkspace = recipientID?.startsWith("wks");
  const [updateGroupMembers] = useAddGroupMembersMutation();

  const [updateWorkspaceMembers] = useAddWorkspaceMembersMutation();

  const onUpdateMembers = (members: MemberRole[]) => {
    if (!recipientID) return;

    const variables = { id: recipientID, members: members };
    if (isWorkspace) {
      updateWorkspaceMembers({ variables });
    } else {
      updateGroupMembers({ variables });
    }
  };

  return {
    onUpdateMembers,
  };
};

export default useMemberItemActions;

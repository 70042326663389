const formatFileSize = (bytes: number) => {
  const gigabytes = bytes / (1024 * 1024 * 1024);
  if (gigabytes > 1) return `${gigabytes.toFixed(2)} GB`;
  const megabytes = bytes / (1024 * 1024);
  if (megabytes > 1) return `${megabytes.toFixed(2)} MB`;
  const kilobytes = bytes / 1024;
  if (kilobytes > 1) return `${kilobytes.toFixed(2)} KB`;
  return `${bytes} B`;
};

export default formatFileSize;

export default function useTrapFocus(trap = true) {
  return trap
    ? {
        onKeyDown: (event: React.KeyboardEvent) => {
          if (event.key !== "Tab") return;

          const focusableElements = event.currentTarget.querySelectorAll(
            "a[href], button:not([disabled]), textarea, input, select, [tabindex]"
          );
          const firstElement = focusableElements[0];
          const lastElement = focusableElements[focusableElements.length - 1];

          if (!(firstElement instanceof HTMLElement)) return;
          if (!(lastElement instanceof HTMLElement)) return;

          if (!event.shiftKey && document.activeElement === lastElement) {
            firstElement?.focus();
            return event.preventDefault();
          }

          if (event.shiftKey && document.activeElement === firstElement) {
            lastElement?.focus();
            return event.preventDefault();
          }
        },
      }
    : {};
}

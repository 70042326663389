import { ThreadEdgeSimple, UnreadCountsTypes } from "@utility-types";

type UnreadCounts = { mentioned: number; total: number; unseen: number };

type RecipientEdge = {
  persistentChatEdge?: { unreadMessageCounts?: UnreadCounts } | null;
  unreadGroupThreadCounts?: UnreadCounts;
  unreadThreadCounts?: UnreadCounts;
};

export const edgeUnreadThreadCount = (
  edge: RecipientEdge,
  type: undefined | UnreadCountsTypes = "total"
): number =>
  (edge.unreadThreadCounts?.[type] || 0) +
  ((edge.persistentChatEdge?.unreadMessageCounts?.[type] || 0) > 0 ? 1 : 0);

export const edgeUnreadThreadGroupCount = (
  edge: RecipientEdge,
  type: undefined | UnreadCountsTypes = "total"
) =>
  (edge.unreadThreadCounts?.[type] || 0) +
  (edge.unreadGroupThreadCounts?.[type] || 0) +
  ((edge.persistentChatEdge?.unreadMessageCounts?.[type] || 0) > 0 ? 1 : 0);

const sumUnreadCount = (
  edges: RecipientEdge[] = [],
  type: undefined | UnreadCountsTypes = "total"
) => edges.reduce((a, b) => a + edgeUnreadThreadCount(b, type), 0);

export const unreadThreadEdges = (
  threadEdges: ThreadEdgeSimple[],
  type: undefined | UnreadCountsTypes = "total"
) => {
  if (type === "total") {
    return threadEdges.reduce(
      (c, e) =>
        c + ((e.unreadMessageCounts.total ? 1 : 0) || !e.isRead ? 1 : 0),
      0
    );
  }
  return threadEdges.reduce(
    (c, e) => c + (e.unreadMessageCounts[type] ? 1 : 0),
    0
  );
};

export default sumUnreadCount;

import UnreadFilter from "components/SideBar/SidebarHeader/UnreadFilter";

import ComposeButton from "components/SideBar/SidebarHeader/ComposeButton";

import Sidebar from "components/SideBar/Sidebar";

import ThreadsMain from "./ThreadsMain";

export const SIDEBAR_ID = "mega-tab-sidebar--threads";

const ThreadsSidebarDesktop = () => {
  return (
    <Sidebar id={SIDEBAR_ID}>
      <ThreadsMain
        buttons={
          <>
            <UnreadFilter />
            <ComposeButton />
          </>
        }
        headerType="search"
        title="Threads"
        desktopHeader
      />
    </Sidebar>
  );
};

export default ThreadsSidebarDesktop;

import { Helper } from "remirror";
import { MarkdownExtension } from "remirror/extensions";

import { htmlToMarkdown, markdownToHtml } from "md";

class GlueMarkdownExtension extends MarkdownExtension {
  getMarkdown(): Helper<string> {
    return htmlToMarkdown(this.store.helpers.getHTML());
  }
}

GlueMarkdownExtension.defaultOptions.markdownToHtml = (
  markdown: string
): string => markdownToHtml(markdown);

export default GlueMarkdownExtension;

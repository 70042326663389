import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import { find, uniqBy } from "lodash-es";
import { SubmitHandler, useFormContext, useWatch } from "react-hook-form";

import type { Group, Recipient } from "@utility-types";
import type { RecipientValue } from "components/RecipientsSelect/types";
import {
  Form,
  InputButton,
  Select,
  SubmitButton,
  TextArea,
  Toggle,
  useDisableSubmit,
} from "components/design-system/Forms";
import { JoinableBy, MemberRole as MemberRoleValue } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useModalStore from "store/useModalStore";
import { formatNameEmoji } from "utils/formatNameEmoji";

import { ConfirmationModal } from "components/Modals";
import NameAndEmojiInputs from "../NameAndEmojiInputs";

type MemberRole = { member: string; role: MemberRoleValue };

type GroupInput = {
  description: string;
  joinableBy: JoinableBy;
  members: MemberRole[];
  name: string;
  workspaceID: string | null;
};

type Props = {
  disabled?: boolean;
  group: Group;
  onDelete: () => Promise<void>;
  onLeave: () => Promise<void>;
  onSave: (input: GroupInput) => Promise<void>;
  isAdmin: boolean;
  setFocusOnField?: "description" | "name";
};

type FormData = {
  admins: RecipientValue[];
  description: string;
  emoji: string;
  joinableBy: boolean;
  members: RecipientValue[];
  name: string;
  workspaceID: string;
};

type FormContentProps = {
  groupWorkspaceID?: string;
  handleDeleteGroup?: () => void;
  handleLeaveGroup?: () => void;
  onSubmit: SubmitHandler<FormData>;
  isAdmin: boolean;
};

export type FormRef = {
  submitDisabled: boolean;
  submitForm: () => void;
};

const memberRoles = (
  recipients: Recipient[],
  role = MemberRoleValue.Default
): MemberRole[] =>
  uniqBy(
    recipients.map(r => ({ member: r.id, role })),
    "member"
  );

const FormContent = forwardRef<FormRef, FormContentProps>(
  (
    {
      groupWorkspaceID,
      handleDeleteGroup,
      handleLeaveGroup,
      onSubmit,
      isAdmin,
    }: FormContentProps,
    ref
  ) => {
    const [disabledWorkspace, setDisabledWorkspace] = useState(false);

    const { authData } = useAuthData();
    const {
      handleSubmit,
      formState: { isSubmitting },
    } = useFormContext<FormData>();

    useEffect(() => {
      if (groupWorkspaceID) {
        setDisabledWorkspace(
          !authData?.me.workspaceIDs.includes(groupWorkspaceID)
        );
      }
    }, [authData?.me, groupWorkspaceID]);

    const workspaceID = useWatch({ name: "workspaceID" });

    const groupWorkspaceOptions = [
      ...(authData?.workspaces.edges.map(e => ({
        label: e.node.name,
        value: e.node.id,
      })) || []),
    ];

    const selectedWorkspace = find(
      groupWorkspaceOptions,
      workspace => workspace.value === workspaceID
    );

    const submitForm = useCallback(() => {
      handleSubmit(onSubmit)();
    }, [handleSubmit, onSubmit]);

    const disabled = useDisableSubmit();

    useImperativeHandle(ref, () => ({ submitDisabled: disabled, submitForm }), [
      disabled,
      submitForm,
    ]);

    return (
      <div className="flex flex-col h-full">
        <div className="mt-20">
          <NameAndEmojiInputs formSubmitting={isSubmitting} />
          <TextArea<FormData>
            name="description"
            placeholder="Description (optional)"
          />

          {isAdmin &&
            (disabledWorkspace || groupWorkspaceOptions.length > 0) && (
              <div
                className="flex flex-col md:flex-row md:justify-between md:items-end"
                data-testid="workspace-select"
              >
                <Select<FormData>
                  disabled={disabledWorkspace}
                  label="Workspace"
                  name="workspaceID"
                  options={groupWorkspaceOptions}
                  wrapperClassName="max-w-[35%] mt-0"
                />
                <Toggle<FormData>
                  disabled={disabledWorkspace}
                  label={`Allow anyone in ${
                    selectedWorkspace?.label || "workspace"
                  } to join?`}
                  labelClassName="text-md"
                  name="joinableBy"
                  wrapperClassName="shrink-0 max-w-[60%] mt-0"
                />
              </div>
            )}
        </div>

        <div className="flex items-center mb-16">
          <div className="flex items-center">
            {handleDeleteGroup && (
              <InputButton
                buttonStyle="simpleDestructive"
                buttonType="text"
                className="mr-16"
                icon="Trash"
                onClick={handleDeleteGroup}
                type="button"
              >
                Delete Group
              </InputButton>
            )}
            {handleLeaveGroup && (
              <InputButton
                buttonStyle="simpleDestructive"
                buttonType="text"
                icon="Leave"
                onClick={handleLeaveGroup}
                type="button"
              >
                Leave Group
              </InputButton>
            )}
          </div>
          <div className="hidden ml-auto md:flex">
            <SubmitButton>Save</SubmitButton>
          </div>
        </div>
      </div>
    );
  }
);

const EditGroupForm = forwardRef<FormRef, Props>(
  (
    { group, onDelete, onLeave, onSave, isAdmin, setFocusOnField }: Props,
    ref
  ) => {
    const { authData } = useAuthData();
    const { openModal } = useModalStore(({ openModal }) => ({
      openModal,
    }));

    const handleDeleteGroup = () => {
      openModal(
        <ConfirmationModal
          confirmLabel="Delete"
          header={`Delete "${group.name}" group?`}
          message="All group users will lose access to threads sent to the group."
          onConfirm={onDelete}
          isDestructive
        />
      );
    };

    const handleLeaveGroup = () => {
      openModal(
        <ConfirmationModal
          confirmLabel="Leave"
          header={`Leave "${group.name}" group?`}
          message="You'll lose access to threads sent to the group."
          onConfirm={onLeave}
          isDestructive
        />
      );
    };

    const onSubmit = (data: FormData) => {
      if (!data.workspaceID) return;

      const { description, emoji, joinableBy, name, workspaceID } = data;

      const members = [
        ...memberRoles(data.admins, MemberRoleValue.Admin),
        ...memberRoles(data.members, MemberRoleValue.Default),
      ];

      return onSave({
        description,
        joinableBy: joinableBy ? JoinableBy.Workspace : JoinableBy.Approval,
        members,
        name: formatNameEmoji({ name: `${emoji}${name}` }).nameWithEmoji,
        workspaceID,
      });
    };

    const hasMultipleAdmins =
      (group.members.edges.filter(e => e.memberRole === "admin").length || 0) >
      1;

    const canDelete = isAdmin && !!group.id;
    const canLeave = (!isAdmin || hasMultipleAdmins) && !!group.id;

    const { emoji, name } = formatNameEmoji({ name: group?.name });
    const members = group.members.edges;

    return (
      <Form
        className="h-full"
        onSubmit={onSubmit}
        resetDefault={false}
        useFormProps={{
          defaultValues: {
            name: name ?? "",
            description: group.description ?? "",
            emoji: emoji ?? "",
            joinableBy: group.joinableBy !== JoinableBy.Approval,
            members:
              members
                .filter(
                  ({ memberRole }) => memberRole !== MemberRoleValue.Admin
                )
                .map(e => e.node) ?? [],
            admins:
              members
                .filter(
                  ({ memberRole }) => memberRole === MemberRoleValue.Admin
                )
                .map(e => e.node) ?? (authData ? [authData.me] : []),
            workspaceID: group.workspaceID ?? undefined,
          },
        }}
        initialFocusOnField={setFocusOnField}
        onSubmitReset
      >
        <FormContent
          ref={ref}
          groupWorkspaceID={group.workspaceID ?? ""}
          handleDeleteGroup={canDelete ? handleDeleteGroup : undefined}
          handleLeaveGroup={canLeave ? handleLeaveGroup : undefined}
          onSubmit={onSubmit}
          isAdmin={isAdmin}
        />
      </Form>
    );
  }
);

export default EditGroupForm;

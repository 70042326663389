import { useFormContext } from "react-hook-form";

const useRequiredFields = () => {
  const {
    control: { _fields },
  } = useFormContext();

  return Object.keys(_fields).filter(f => _fields[f]?._f.required);
};

export default useRequiredFields;

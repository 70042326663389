import { SubscriptionPlan } from "@utility-types/graphql";
import SelectDropdown from "components/design-system/Forms/SelectDropdown";
import { Option } from "components/design-system/Forms/types";
import { planDisplayName } from "./utils/planDisplayName";

const ChoosePlanDropdown = ({ plans }: { plans?: SubscriptionPlan[] }) => {
  const options: Option[] =
    plans?.map(plan => ({
      label: planDisplayName(plan),
      value: plan.id,
    })) ?? [];

  return (
    <SelectDropdown
      key={JSON.stringify(options)}
      name="plan"
      options={options}
      wrapperClassName="grow min-w-0 !my-0"
    />
  );
};

export default ChoosePlanDropdown;

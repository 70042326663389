import { MessageEdge, ThreadEdgeFeed } from "@utility-types";
import useAuthData from "hooks/useAuthData";

type LastReadIDs = {
  id: string | null;
  streamID: string | null;
};

const useFeedItemCounts = ({
  showFirstMessage,
  readMessages,
  unreadMessages,
  threadEdge,
  updatedEdge,
  lastReadIDs,
}: {
  showFirstMessage: boolean;
  readMessages?: MessageEdge[];
  unreadMessages?: MessageEdge[];
  threadEdge: ThreadEdgeFeed;
  updatedEdge?: ThreadEdgeFeed;
  lastReadIDs: LastReadIDs;
}) => {
  const { authData } = useAuthData();

  const recent = threadEdge.node.recentMessages;
  const recentNew = updatedEdge?.node.recentMessages;
  const newRepliesAvailableCount =
    updatedEdge?.node.lastMessage?.user.id === authData?.me.id
      ? 0
      : (recentNew?.replyCount ?? recent.replyCount) - recent.replyCount;

  // Total replies count - shown read messages above new line - 1(first message)
  const olderRepliesCount =
    (recentNew?.replyCount ?? 0) -
    (readMessages?.length || 0) -
    (unreadMessages?.length || (updatedEdge?.unreadMessageCounts.total ?? 0)) -
    (showFirstMessage ? 1 : 0) -
    (unreadMessages?.length ? newRepliesAvailableCount : 0);

  // Total unread messages - shown unread messages below new line
  const moreRepliesCount = Math.max(
    (updatedEdge?.unreadMessageCounts.total ?? 0) -
      (unreadMessages?.length ?? 0) -
      (readMessages?.find(
        m => m.node.id === lastReadIDs.id || m.node.id === lastReadIDs.streamID
      )
        ? 1
        : 0) -
      (unreadMessages?.find(
        m => m.node.id === updatedEdge?.node.firstMessage?.id
      ) || !updatedEdge?.lastReadID
        ? 1
        : 0) -
      newRepliesAvailableCount,
    0
  );

  return {
    newRepliesAvailableCount,
    moreRepliesCount,
    olderRepliesCount: olderRepliesCount - moreRepliesCount,
  };
};

export default useFeedItemCounts;

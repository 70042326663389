import { App as CapApp } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";

type VersionInfo = {
  buildNumber?: string;
  buildSha: string;
  fullVersionString: string;
  version: string;
};

// Note: because this file is also used by scripts/sentry-release, we
// can't import anything from src/* here (such as utils/processEnv).

export default (async (): Promise<VersionInfo> => {
  let version: string | undefined;
  let buildNumber: string | undefined;
  // Use Capacitor.isNativePlatform() instead of isNative() to avoid local imports
  // This is used with ts-node via scripts/sentry-release.ts and it does
  // not properly resolve those paths

  if (Capacitor.isNativePlatform()) {
    ({ build: buildNumber, version } = await CapApp.getInfo());
  } else {
    version =
      process.env.npm_package_version || // Vercel scripts (Sentry release)
      process.env.VITE_VERSION || // Build / runtime
      "0.0";
  }

  const buildSha =
    process.env.VERCEL_GIT_COMMIT_SHA || // Vercel scripts (Sentry release)
    process.env.VITE_VERCEL_GIT_COMMIT_SHA || // Build / runtime
    "unknown";

  const buildNumberPart = buildNumber ? `-${buildNumber}` : "";
  const fullVersionString = version
    ? `${version}${buildNumberPart} (${buildSha.substr(0, 7)})`
    : "";
  return {
    buildNumber,
    buildSha,
    fullVersionString,
    version,
  };
})();

import ChannelLoading from "components/thread/ThreadView/components/ChannelLoading";
import ThreadPane from "components/threads/ThreadPane";
import AIThreadPane from "components/views/ai/AIMain/AIThreadPane";
import useIsGlueAIThread from "hooks/glueAI/useIsGlueAIThread";
import { ComponentProps } from "react";
import ThreadView from "./ThreadView";

const ThreadPaneSwitch = (
  props: Partial<ComponentProps<typeof ThreadView>>
) => {
  const isGlueAIThread = useIsGlueAIThread(props.threadID);

  if (isGlueAIThread === undefined)
    return (
      <div className="flex flex-col grow min-h-0">
        <div className="border-b border-border-container flex flex-col items-start shrink-0 w-full h-[61px] relative" />
        <div className="flex grow min-h-0 items-center justify-center h-full">
          <ChannelLoading />
        </div>
      </div>
    );

  return isGlueAIThread ? (
    <AIThreadPane {...props} />
  ) : (
    <ThreadPane {...props} />
  );
};

export default ThreadPaneSwitch;

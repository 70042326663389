import { User } from "@utility-types";
import { routeToGroup, routeToThread } from "components/routing/utils";
import { formatGroupName } from "utils/group/formatGroupName";
import ActivityItemLayout from "./ActivityItemLayout";
type DefaultTarget = { __typename: string; id: string };

type Props = {
  actor: User;
  isRead: boolean;
  onClick: (e: React.MouseEvent<HTMLLIElement>) => void;
  selected: boolean;
  target?: DefaultTarget | null;
  text: string;
  timestamp: string;
};

const ActivityItemDefault = ({
  actor,
  target,
  text,
  ...props
}: Props): JSX.Element => {
  let linkTo = (_to: boolean) => "";
  let name = actor.name;
  let emoji: string | undefined;
  const avatarURL = actor.avatarURL;

  switch (target?.__typename) {
    case "GroupPreview":
      const { name: groupName, emoji: groupEmoji } = formatGroupName(target);
      name = groupName;

      emoji = groupEmoji;
      linkTo = toSecondary =>
        routeToGroup({
          groupID: target.id,
          to: toSecondary ? "secondary" : undefined,
        });
      break;
    case "ThreadPreview":
      linkTo = toSecondary =>
        routeToThread({
          superTab: "activity",
          threadID: target.id,
          to: toSecondary ? "secondary" : "primary",
        });
      break;
  }

  return (
    <ActivityItemLayout
      avatarProps={{
        emojiProps: emoji ? { emoji } : undefined,
        name,
        avatarURL: avatarURL,
        background: emoji ? "transparent" : undefined,
      }}
      linkTo={linkTo}
      title={`A notification from ${actor.name}`}
      {...props}
    >
      {text}
    </ActivityItemLayout>
  );
};

export default ActivityItemDefault;

import { ComponentProps } from "react";

import { Flipper } from "react-flip-toolkit";

type Props = {
  collapsed?: boolean;
  flipKey?: ComponentProps<typeof Flipper>["flipKey"];
  moreCollapsed?: boolean;
  sectionFooter?: JSX.Element;
  sectionItems: JSX.Element[];
  selectedItem?: JSX.Element;
};

/**
 * @param children Element that will be shown between the items and the footer
 * @param collapsed Determines if the section is collapsed
 * @param flipKey Unique ID used in the Flipper component
 * @param moreCollapsed Determines if the expandable section is collapsed
 * @param sectionFooter An element to be shown below the items
 * @param sectionItems List of items to be displayed
 * @param selectedItem Use this if the selected item in the list is in the `show more` section
 */
const SectionBody = ({
  children,
  collapsed = false,
  flipKey,
  moreCollapsed,
  sectionFooter,
  sectionItems,
  selectedItem,
}: WithChildren<Props>) => {
  return (
    <Flipper className="overflow-hidden" element="ol" flipKey={flipKey}>
      {sectionItems}

      {/* Ensure selected item is shown above the fold */}
      {(moreCollapsed || collapsed) && selectedItem}

      {!collapsed && children}

      <li>{sectionFooter}</li>
    </Flipper>
  );
};

export default SectionBody;

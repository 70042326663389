import { ComponentProps, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { Button } from "components/design-system/Button";
import { currentPathWithSearch } from "components/routing/utils";
import {
  ChatType,
  ThreadsOrder,
  UsersOrder,
  useFetchUsersQuery,
  usePersistentChatsQuery,
} from "generated/graphql";

type ElementsType = {
  buttonProps: ComponentProps<typeof Button>;
  primaryText: string;
  secondaryText: string;
};

const EmptyDMs = ({ resetForm }: { resetForm: () => void }) => {
  const history = useHistory();

  const { data: usersData } = useFetchUsersQuery({
    fetchPolicy: "cache-first",
    variables: {
      order: UsersOrder.Name,
    },
  });

  const { data: dmsData } = usePersistentChatsQuery({
    fetchPolicy: "cache-first",
    variables: {
      chatType: ChatType.User,
      order: ThreadsOrder.LastMessage,
    },
  });

  const elements: ElementsType = useMemo(() => {
    const openCompose = () =>
      history.push(currentPathWithSearch({ v: "compose-dm" }));
    if (!usersData?.users.edges.length) {
      return {
        buttonProps: { children: "Add coworkers", onClick: openCompose },
        primaryText: "No one here yet!",
        secondaryText: "Get started by adding your coworkers to Glue.",
      };
    }
    if (!dmsData?.persistentChats.edges.length) {
      return {
        buttonProps: { children: "New DM", onClick: openCompose },
        primaryText: "Send your first DM",
        secondaryText: "Connect one-on-one with your coworkers.",
      };
    }
    return {
      buttonProps: {
        buttonStyle: "secondary",
        children: "Show all DMs",
        onClick: resetForm,
      },
      primaryText: "You've read everything!",
      secondaryText: "Take a cookie break.",
    };
  }, [
    dmsData?.persistentChats.edges,
    history,
    resetForm,
    usersData?.users.edges,
  ]);

  return (
    <div className="flex flex-col items-center px-24 py-16">
      <span className="font-semibold text-lg leading-6">
        {elements.primaryText}
      </span>
      <span className="text-text-secondary">{elements.secondaryText}</span>
      <Button className="mt-16" {...elements.buttonProps} />
    </div>
  );
};

export default EmptyDMs;

import { Recipient } from "@utility-types";
import { useFetchWorkspaceProfile } from "hooks/workspace";

const useRecipientDomains = (recipient?: Recipient) => {
  const { workspace } = useFetchWorkspaceProfile(
    recipient?.__typename?.startsWith("Workspace")
      ? recipient.id
      : recipient?.workspaceID || ""
  );

  const domains =
    (recipient?.__typename === "User"
      ? recipient.addressDomains
      : recipient?.__typename.startsWith("Group") ||
          recipient?.__typename.startsWith("Workspace")
        ? workspace?.domains
        : []) || [];

  return { domains, workspace };
};

export default useRecipientDomains;

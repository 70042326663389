import { memo, useState } from "react";

import tw from "utils/tw";

export type CustomNotificationProps = {
  active?: boolean;
  type: string;
};

const UnMemoizedCustomNotification = (
  props: WithChildren<CustomNotificationProps>
) => {
  const { active, children, type } = props;
  const [isActive, setIsActive] = useState(active);

  return active || isActive ? (
    <div
      className={tw(
        `str-chat__custom-notification notification-${type}`,
        `${active ? "animate-fadeIn" : "animate-fadeOut"}`
      )}
      onAnimationEnd={() => setIsActive(active)}
    >
      {children}
    </div>
  ) : null;
};

export const CustomNotification = memo(UnMemoizedCustomNotification);

import { hasValidProtocol } from "./matchURL";

/**
 * Adds a default protocol if input URL does not have a protocol yet
 *
 * @param {string} input URL string
 * @param {"http:" | "https:" | "ftp:" | "ftps:" | undefined} defaultProtocol default is a relative protocol
 * @returns {string} URL with protocol
 */
const addProtocol = (
  input: string,
  defaultProtocol?: "http:" | "https:" | "ftp:" | "ftps:"
): string =>
  hasValidProtocol(input)
    ? input
    : input.includes("@")
      ? `mailto:${input}`
      : `${defaultProtocol || ""}//${input}`;

export default addProtocol;

import { ComponentProps } from "react";

import AppsItem from "./AppsItem";

type Props = {
  items: ComponentProps<typeof AppsItem> & { id?: string }[];
  title: string;
};

const AppsSection = ({ items, title }: Props) => {
  if (!items.length) return null;
  return (
    <div className="mt-16">
      <span className="font-bold">{title}</span>
      <div className="mt-10">
        {items.map(app => (
          <AppsItem key={app.id} {...app} />
        ))}
      </div>
    </div>
  );
};

export default AppsSection;

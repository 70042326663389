type SignInState = {
  view: "SignIn";
  email?: string;
};

type VerificationCodeState = {
  view: "VerificationCode";
  email: string;
};

type ResendEmailState = {
  view: "ResendEmail";
  email: string;
};

export type State = SignInState | VerificationCodeState | ResendEmailState;

export const InitialState: State = {
  view: "SignIn",
};

export type Action =
  | { type: SignInState["view"]; email?: string }
  | { type: VerificationCodeState["view"]; email: string }
  | { type: ResendEmailState["view"]; email: string };

export const Reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SignIn":
      return { view: action.type, email: action.email };
    case "VerificationCode":
    case "ResendEmail":
      return { view: action.type, email: action.email };
    default:
      return state;
  }
};

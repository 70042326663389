import { nodeAs } from "@utility-types/graphql";
import { EditorMessage } from "components/MessageEditor/types";
import { useFetchGroupOrPreviewEdgeQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useModalStore from "store/useModalStore";
import env from "utils/processEnv";
import ThreadComposeModal from "../ThreadComposeModal";

/**
 * @summary Glue Sales and Support static group IDs.
 * In DEV you'll need to update your .env.local vars VITE_HELP_GROUP_ID and VITE_SALES_GROUP_ID
 * with local group IDs for the recipients field to populate correctly.
 */

const channels = {
  sales: env.salesGroupId,
  support: env.helpGroupId,
};

/**
 * @summary Compose a thread to Glue Sales or Support groups.
 */

const useComposeToGlue = (channel: keyof typeof channels) => {
  const { authData } = useAuthData();
  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));

  const { data } = useFetchGroupOrPreviewEdgeQuery({
    fetchPolicy: "cache-first",
    skip: !channels[channel] || !authData?.me.id,
    variables: {
      id: `${channels[channel]}-${authData?.me.id}`,
    },
  });

  const group = nodeAs(data?.node, ["GroupEdge", "GroupPreviewEdge"])?.node;

  const composeThreadToGlue = (subject?: string, message?: EditorMessage) => {
    const initialDraft = {
      ...(group ? { recipients: [group] } : {}),
      message: message ?? { attachments: [], text: "" },
      subject,
    };
    return openModal(<ThreadComposeModal initialDraft={initialDraft} />);
  };

  return { group, composeThreadToGlue };
};

export default useComposeToGlue;

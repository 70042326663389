import { FieldValues, useFormContext } from "react-hook-form";

import tw from "utils/tw";

import type { CheckboxProps } from "./types";

type WhenState = {
  className?: string;
  text: string;
};

export const TextToggle = <TFieldValues extends FieldValues>({
  config,
  label,
  labelClassName,
  name,
  whenChecked,
  whenUnchecked,
  wrapperClassName,
  ...props
}: CheckboxProps<TFieldValues> & {
  whenChecked?: WhenState;
  whenUnchecked?: WhenState;
}) => {
  const {
    formState: { isSubmitting },
    register,
    watch,
  } = useFormContext<TFieldValues>();

  const isChecked = watch(name);
  const disabled = props.disabled || isSubmitting;

  return (
    <div className={tw("flex items-center my-20", wrapperClassName)}>
      <input
        checked={!!isChecked}
        className="hidden"
        id={name}
        type="checkbox"
        {...props}
        disabled={disabled}
        {...register(name, config)}
      />
      <label
        className={tw(
          "flex items-center mb-5 text-base font-normal text-text-subtle select-none",
          labelClassName
        )}
        htmlFor={name}
      >
        <div className={tw("mr-15", disabled ? "" : "cursor-pointer")}>
          {label}
        </div>

        <div className="cursor-pointer">
          {isChecked ? (
            <div className={whenChecked?.className}>{whenChecked?.text}</div>
          ) : (
            <div className={whenUnchecked?.className}>
              {whenUnchecked?.text}
            </div>
          )}
        </div>
      </label>
    </div>
  );
};

import { MutableRefObject, useEffect } from "react";

import { animateScrollTo } from "utils/animate";

export const useStatusTapScrollToTop = (
  scrollView: MutableRefObject<HTMLOListElement | undefined>
): void => {
  useEffect(() => {
    let animation: { stop: () => void };

    const onStatusTap = () => {
      if (!scrollView.current) return;
      animation = animateScrollTo(scrollView.current, 0);
    };

    window.addEventListener("statusTap", onStatusTap);

    return () => {
      animation?.stop();
      window.removeEventListener("statusTap", onStatusTap);
    };
  }, [scrollView]);
};

export default useStatusTapScrollToTop;

import { useLocation } from "react-router-dom";

import { nodeAs } from "@utility-types";
import { useFetchGroupOrPreviewEdgeQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";

import { routeParams } from "components/routing/utils";
import useThreadRecipients from "./useThreadRecipients";

const useGroupRecipients = () => {
  const { authData, authReady } = useAuthData();

  const { recipientID } = routeParams(useLocation());
  const { threadEdge } = useThreadRecipients({});

  const id =
    recipientID ||
    (threadEdge?.node.isPersistentChat
      ? threadEdge.node.recipients.edges.map(e => e.node)
      : []
    )?.find(r => r.id !== authData?.me.id)?.id;

  const isGroupID = id?.startsWith("grp_") || id?.startsWith("wks_");
  const { data } = useFetchGroupOrPreviewEdgeQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !isGroupID || !authData?.me.id,
    variables: { id: `${id}-${authData?.me.id}` },
  });

  const groupEdge = nodeAs(data?.node, [
    "GroupEdge",
    "GroupPreviewEdge",
    "WorkspaceEdge",
    "WorkspacePreviewEdge",
  ]);

  return groupEdge?.node ? [groupEdge.node] : [];
};

export default useGroupRecipients;

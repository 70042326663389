import { Link } from "react-router-dom";

import { Tooltip } from "components/design-system/FloatingUi";

const OptionalLink = ({
  children,
  enabled,
  to,
  tooltip,
}: {
  children: React.ReactNode;
  enabled: boolean;
  to?: string;
  tooltip?: string;
}) => {
  if (!to || !enabled) {
    return <span>{children}</span>;
  }
  const link = <Link to={to}>{children}</Link>;
  return tooltip ? <Tooltip content={tooltip}>{link}</Tooltip> : link;
};

export default OptionalLink;

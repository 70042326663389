import { useCallback } from "react";
import { useHistory } from "react-router";

import { useApolloClient } from "@apollo/client";
import { useChatContext } from "stream-chat-react";

import { StreamGlueMessage } from "@utility-types";
import { readThreadEdge } from "apollo/cache/threadHelpers";
import useAppDrawerStore from "components/AppDrawer/useAppDrawerStore";
import { routeToThread } from "components/routing/utils";
import { useThreadViewState } from "components/thread/ThreadView/provider/ThreadViewProvider";
import ThreadComposeModal from "components/threads/ThreadComposeModal";
import { useIsGroupMember } from "hooks/group/useIsGroupMember";
import useAuthData from "hooks/useAuthData";
import useAppStateStore from "store/useAppStateStore";
import useModalStore from "store/useModalStore";
import { getReplyThreadID } from "utils/stream/message";

const useReplyInThreadModal = (message: StreamGlueMessage) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const { authData } = useAuthData();
  const cache = useApolloClient().cache;
  const { channel } = useChatContext();
  const history = useHistory();
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));
  const isGroupMember = useIsGroupMember();

  const { openDrawer } = useAppDrawerStore(({ openDrawer }) => ({
    openDrawer,
  }));

  const { recipientID } = useThreadViewState(({ recipientID }) => ({
    recipientID,
  }));

  const handleReplyInThread: () => void = useCallback(async () => {
    const messageID = message.id;
    const replyThreadID = getReplyThreadID(message);

    const threadID = channel?.id;
    const threadEdge = readThreadEdge(`${threadID}-${authData?.me.id}`, cache);

    const thread = threadEdge?.node;
    let recipients = (thread?.recipients.edges || [])
      .map(e => e.node)
      .filter(r => r.id !== authData?.me.id);

    if (!thread?.isPersistentChat && recipients[0]?.__typename === "User") {
      const starter = recipients[0];
      for (const group of recipients.filter(r => r.__typename !== "User")) {
        try {
          if (await isGroupMember(group, starter)) {
            recipients = recipients.slice(1);
            break;
          }
        } catch (e) {
          console.error("[useReplyInThreadModal] isGroupMember:", e);
          break;
        }
      }
    }

    if (!replyThreadID && messageID && threadID) {
      if (breakpointMD) {
        openDrawer({
          action: "reply",
          initialRecipients: recipients,
          message: { id: messageID, threadID },
        });
      } else {
        openModal(
          <ThreadComposeModal
            initialDraft={{ recipients }}
            replyToMessage={{ id: messageID, threadID }}
          />
        );
      }

      return;
    }

    history.push(
      routeToThread({
        recipientID,
        threadID: replyThreadID || "",
        to: "secondary",
      })
    );
  }, [
    authData?.me.id,
    cache,
    channel?.id,
    history,
    isGroupMember,
    message,
    openDrawer,
    openModal,
    recipientID,
    breakpointMD,
  ]);

  return { handleReplyInThread };
};

export default useReplyInThreadModal;

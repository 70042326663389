const getElementBounds = (
  el: HTMLElement | Element
): { XOffset: number; YOffset: number; height: number; width: number } => {
  const { pageXOffset, pageYOffset } = window;
  const {
    documentElement: { scrollLeft, scrollTop },
  } = document;
  const pageXScroll = pageXOffset || scrollLeft;
  const pageYScroll = pageYOffset || scrollTop;
  const rect = el.getBoundingClientRect();
  return {
    height: rect.height,
    width: rect.width,
    XOffset: rect.left + pageXScroll,
    YOffset: rect.top + pageYScroll,
  };
};

export default getElementBounds;

import { useCallback } from "react";

import useShortcut from "hooks/useShortcut";
import useFeatureFlagStore from "store/useFeatureFlagStore";
import useModalStore from "store/useModalStore";

import { FeatureFlagModal } from "./FeatureFlagModal";
import useMobileTabSequence from "./hooks/useMobileTabSequence";

import { FlagName, environmentFlags } from ".";

const MODAL_ID = "feature-flag-modal";

export default function FeatureFlags() {
  const { modalIsOpen, openModal } = useModalStore(
    ({ modalIsOpen, openModal }) => ({
      modalIsOpen,
      openModal,
    })
  );

  const { flags } = useFeatureFlagStore(({ flags }) => ({
    flags,
  }));

  const isKey = (x: typeof flags, k: PropertyKey): k is FlagName => k in x;

  Object.keys(flags).forEach(k => {
    if (!isKey(flags, k)) return;
    if (environmentFlags.includes(k)) return;

    useFeatureFlagStore.setState(state => {
      delete state.flags[k];
      return state;
    });
  });

  const showModal = useCallback(() => {
    if (environmentFlags.length === 0 || modalIsOpen(MODAL_ID)) return;

    openModal(<FeatureFlagModal environmentFlags={environmentFlags} />, {
      id: MODAL_ID,
    });
  }, [modalIsOpen, openModal]);

  useMobileTabSequence({
    isModalOpen: modalIsOpen(),
    showModal,
  });

  // onToggleShortcut Press control + CMD + option + ;
  useShortcut(["Control", "Meta", "Alt", ";"], () => {
    showModal();
  });

  return null;
}

import { useState } from "react";

import {
  FloatingArrow,
  arrow,
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { animated, useTransition } from "@react-spring/web";
import { MessageContextValue, useChatContext } from "stream-chat-react";

import { GlueDefaultStreamChatGenerics } from "@utility-types";
import useFeatureFlagStore from "store/useFeatureFlagStore";
import useSafeAreaInsetsStore from "store/useSafeAreaInsetsStore";
import tw from "utils/tw";

import { Portal } from "components/Portal";

import Avatar from "components/design-system/Avatar/Avatar";
import { getReadByTooltipText } from "../utils";

const isTestEnv = process.env.NODE_ENV === "test";

export const ReadReceipt = <T extends GlueDefaultStreamChatGenerics>(
  props: Omit<
    Pick<
      MessageContextValue<T>,
      "isMyMessage" | "lastReceivedId" | "message" | "readBy"
    >,
    "isMyMessage"
  > & {
    isMyMessage: boolean;
  }
): JSX.Element | null => {
  const [arrowRef, setArrowRef] = useState<SVGSVGElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const padding = useSafeAreaInsetsStore();

  const { client } = useChatContext<T>();

  const { context, floatingStyles, refs } = useFloating({
    middleware: [
      offset(4),
      flip({ padding }),
      shift({ padding }),
      arrow({
        element: arrowRef,
      }),
    ],
    onOpenChange: setIsOpen,
    open: isOpen,
    placement: "top",
    strategy: "fixed",
    whileElementsMounted: autoUpdate,
  });

  const { getFloatingProps, getReferenceProps } = useInteractions([
    useDismiss(context),
    useHover(context, {
      delay: { close: 0, open: isTestEnv ? 0 : 500 },
      handleClose: safePolygon({ blockPointerEvents: false }),
      mouseOnly: false,
      move: false,
    }),
    useRole(context, { role: "tooltip" }),
  ]);

  const transitions = useTransition(isOpen, {
    config: { friction: 100, mass: 2, tension: 2000 },
    enter: { opacity: 1, scale: 1 },
    from: { opacity: 0, scale: 0.75 },
    leave: { opacity: 0, scale: 0.75 },
  });

  const { READ_RECEIPTS } = useFeatureFlagStore(
    ({ flags: { READ_RECEIPTS } }) => ({
      READ_RECEIPTS,
    })
  );

  if (!READ_RECEIPTS) {
    return null;
  }

  const { isMyMessage, message, readBy } = props;
  if (!readBy?.length || !isMyMessage || message.type === "error") {
    return null;
  }

  const userId = client.user?.id || "";
  const justReadByMe = readBy.length === 1 && readBy[0]?.id === userId;

  if (readBy.length > 0 && !justReadByMe) {
    const othersReadBy = readBy.filter(item => item.id !== userId);
    if (!othersReadBy[0]) return null;

    return (
      <>
        <div className="str-chat-message-read-receipt flex absolute z-0 top-0 right-0 items-end pb-3 h-full">
          <div
            className="relative top-3 py-3 pl-2"
            data-testid="str-chat-message-read-receipt-trigger"
            {...getReferenceProps({ ref: refs.setReference })}
          >
            <Avatar
              avatarURL={othersReadBy[0].image}
              data-testid="str-chat-message-read-receipt-trigger"
              name={othersReadBy[0].name}
              size="tiny"
              rounded="rounded-r-none rounded-md"
            />
            {othersReadBy.length > 1 && (
              <>
                <div
                  className="absolute -right-3 bottom-7 -z-1 w-16 h-16 bg-interactive-subtle rounded-md rounded-r-none border-thin border-r-none border-background"
                  data-testid="message-glue-read-by-many"
                />
                <div className="absolute -right-1 bottom-4 -z-1 w-16 h-16 bg-background rounded-md rounded-r-none" />
              </>
            )}
          </div>
        </div>

        <Portal id="overlays" zIndex="100">
          {transitions(
            (styles, open) =>
              open && (
                <animated.div
                  ref={refs.setFloating}
                  className={tw(
                    "bg-background-modal rounded-md border-thin border-interactive-strong/30 shadow-strong-ui",
                    "w-max max-w-[256px] py-5 px-10 text-sm leading-5 text-center"
                  )}
                  data-testid="tooltip"
                  style={{
                    ...floatingStyles,
                    ...styles,
                  }}
                  {...getFloatingProps()}
                >
                  {getReadByTooltipText(othersReadBy)}
                  {
                    // `!isTestEnv` prevents error when running tests,
                    // "NaN is an invalid value for the left css style property"
                    !isTestEnv && (
                      <FloatingArrow
                        ref={setArrowRef}
                        className="[&>path:first-of-type]:stroke-interactive-strong/15
                    fill-background-modal"
                        context={context}
                        strokeWidth={0.5}
                      />
                    )
                  }
                </animated.div>
              )
          )}
        </Portal>
      </>
    );
  }

  return null;
};

import { Skeleton } from "components/Skeleton";
import { Button } from "components/design-system/Button";
import useAuthData from "hooks/useAuthData";
import tw from "utils/tw";
import WorkspaceItem from "../../ui/ProfilePane/WorkspaceItem";

export default function Main({
  buttonAction,
  buttonStyle = "primary",
  buttonText,
  description,
  loading,
  secondaryButton,
  workspaces,
}: {
  buttonAction: () => void;
  buttonStyle?: "secondary" | "primary";
  buttonText: JSX.Element | string;
  description?: { title: "Description" | "Title"; text?: string };
  secondaryButton?: { text: string; onClick: () => void };
  loading: boolean;
  workspaces?: string[];
}) {
  const { authReady } = useAuthData();
  const className = {
    button: tw(
      "mt-24 justify-center w-full",
      "rounded-md",
      {
        "!bg-background-action hover:!bg-background-action-hover focus:!bg-background-action disabled:!bg-background-action-disabled":
          buttonStyle === "primary",
      },
      {
        "!text-text-action-inverse !text-subhead-bold":
          buttonStyle === "primary",
      },
      {
        "!border-border-action hover:!border-border-action-hover focus:!border-border-action-secondary":
          buttonStyle === "secondary",
      },
      {
        "!text-text-action hover:!text-text-action-hover !text-subhead-bold":
          buttonStyle === "secondary",
      }
    ),
    groupSettingsButton: tw(
      "mt-10 justify-center w-full",
      "rounded-md !border-border-action hover:!border-border-action-hover focus:!border-border-action-secondary",
      "!text-text-action hover:!text-text-action-hover !text-subhead-bold"
    ),
  };
  const buttonProps = {
    buttonStyle: "none" as const,
    className: className.button,
  };

  const groupSettingsButtonProps = {
    buttonStyle: "none" as const,
    className: className.groupSettingsButton,
    onClick: secondaryButton?.onClick,
  };

  const workspaceSection =
    workspaces?.map(w => <WorkspaceItem key={w} workspaceID={w} />) ?? null;

  return (
    <main className="px-24 max-w-[600px] relative left-1/2 transform -translate-x-1/2 select-none">
      {description?.text && (
        <section className="mt-24">
          <h4 className="font-semibold my-0 leading-6">
            {loading ? <Skeleton width={"20px"} /> : description.title}
          </h4>
          <div className="text-base leading-[22px] truncate select-text">
            {loading ? <Skeleton width={"80px"} /> : description.text}
          </div>
        </section>
      )}
      {workspaceSection ? (
        <section className="mt-24">
          <h5 className="font-semibold my-0 leading-6">Workspace</h5>
          {loading ? (
            <div className="mt-8 flex h-40">
              <Skeleton width="32px" height="36px" className="mr-8" />
              <div>
                <Skeleton width="100px" height="18px" className="block" />
                <Skeleton width="200px" height="15px" className="block" />
              </div>
            </div>
          ) : (
            workspaceSection
          )}
        </section>
      ) : null}
      <div className="my-24">
        <Button {...buttonProps} onClick={buttonAction} disabled={loading}>
          {authReady ? buttonText : <Skeleton width={"20px"} />}
        </Button>
        {secondaryButton ? (
          <Button {...groupSettingsButtonProps}>{secondaryButton.text}</Button>
        ) : null}
      </div>
    </main>
  );
}

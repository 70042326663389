import { useApolloClient } from "@apollo/client";

import { Mailbox } from "@utility-types";
import analytics from "analytics";
import { ThreadListSorted } from "analytics/events/thread";
import { Button } from "components/design-system/Button";
import { Dropdown } from "components/design-system/FloatingUi";
import { DropdownActions } from "components/design-system/FloatingUi/DropdownActions";
import { ThreadListDocument, ThreadsOrder } from "generated/graphql";
import useNativeHaptics from "hooks/native/useNativeHaptics";
import useLocalSettingsStore from "store/useLocalSettingsStore";

type Props = {
  mailbox: Mailbox;
  recipientID?: string;
};

export default function SortThreadListButton({
  mailbox,
  recipientID,
  ...props
}: Props) {
  const apolloClient = useApolloClient();
  const { lightImpactHaptic } = useNativeHaptics();

  const { currentThreadSort } = useLocalSettingsStore(
    ({ currentThreadSort }) => ({ currentThreadSort })
  );

  const update = (order: ThreadsOrder) => {
    lightImpactHaptic();

    if (order === currentThreadSort) {
      apolloClient.refetchQueries({
        include: [ThreadListDocument],
      });
      return;
    }

    // - [] remove when we allow different settings for Mailbox and Chats
    useLocalSettingsStore.setState({ currentThreadSort: order });

    analytics.track(ThreadListSorted, {
      mailbox,
      order,
      recipientID,
    });
  };

  return (
    <Dropdown
      content={
        <DropdownActions
          actions={[
            {
              items: [
                {
                  icon: "Clock",
                  onClick: () => update(ThreadsOrder.LastMessage),
                  selected: currentThreadSort === ThreadsOrder.LastMessage,
                  text: "Recent",
                },
                {
                  icon: "Unread",
                  onClick: () => update(ThreadsOrder.Unread),
                  selected: currentThreadSort === ThreadsOrder.Unread,
                  text: "Unread",
                },
              ],
              name: "sort-thread-list-button",
            },
          ]}
        />
      }
      placement="bottom-end"
    >
      <Button icon="Sort" buttonStyle="subtle" className="!py-9" {...props} />
    </Dropdown>
  );
}

export type Selections = {
  includeChannels?: string[];
  skipChannels?: string[];
  groups?: string[];
};

export type State = {
  step?: number;
} & Selections;

export type Action =
  | { type: "nextView" }
  | { type: "prevView" }
  | {
      type: "updateSelections";
      includeChannels: string[];
      skipChannels: string[];
      groups: string[];
    };

const Reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "nextView":
      return { ...state, step: (state.step ?? 0) + 1 };
    case "prevView":
      return { ...state, step: (state.step ?? 0) - 1 };
    case "updateSelections":
      return {
        ...state,
        groups: action.groups,
        includeChannels: action.includeChannels,
        skipChannels: action.skipChannels,
      };
    default:
      return state;
  }
};

export default Reducer;

import { useCallback, useMemo, useState } from "react";

import useInfiniteScroll from "react-infinite-scroll-hook";

import { nodeAs } from "@utility-types";
import { Button } from "components/design-system/Button";
import { Form } from "components/design-system/Forms";
import { Pill } from "components/design-system/Pill";
import CreateGroupModal from "components/group/CreateGroup/CreateGroupModal";
import {
  GroupsOrder,
  useFetchWorkspaceGroupsDirectoryQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";

import {
  WORKSPACE_GENERAL_DESCRIPTION,
  WORKSPACE_GENERAL_NAME,
} from "../consts";
import GroupDirectorySkeletons from "./GroupDirectorySkeletons";
import GroupsDirectoryRow, {
  GroupsDirectoryRowPrimitive,
} from "./GroupsDirectoryRow";
import GroupsDirectorySearchInput from "./GroupsDirectorySearchInput";

enum PillNames {
  All = "All",
  New = "New",
  MyGroups = "My groups",
  Recent = "Recent",
}

const PillOrders = {
  [PillNames.All]: GroupsOrder.Name,
  [PillNames.New]: GroupsOrder.Added,
  [PillNames.MyGroups]: GroupsOrder.Name,
  [PillNames.Recent]: GroupsOrder.Interaction,
};

const GroupDirectory = ({
  searchState,
  workspaceID,
}: {
  searchState: [string, React.Dispatch<React.SetStateAction<string>>];
  workspaceID: string;
}) => {
  const { authData, authReady } = useAuthData();

  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const [selectedPill, setSelectedPill] = useState(PillNames.All);

  const pageSize = Math.ceil(window.innerHeight / 64); // 64px is the height of a row

  const [searchTerm, setSearchTerm] = searchState;

  const { data, fetchMore, loading } = useFetchWorkspaceGroupsDirectoryQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !(workspaceID && authData),
    variables: {
      ...(selectedPill === PillNames.Recent
        ? {
            groupsLast: pageSize,
          }
        : {
            groupsFirst: pageSize,
          }),
      groupsOrder: PillOrders[selectedPill],
      id: `${workspaceID}-${authData?.me.id}`,
      match: searchTerm,
      member: selectedPill === PillNames.MyGroups ? true : undefined,
    },
  });

  const workspaceEdge = nodeAs(data?.node, ["WorkspaceEdge"]);

  const {
    endCursor: afterCursor,
    hasNextPage,
    hasPreviousPage,
    startCursor: beforeCursor,
  } = workspaceEdge?.node.groups.pageInfo ?? {
    endCursor: null,
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
  };

  const loadGroups = useCallback(
    async (cursor: string) => {
      if (selectedPill === PillNames.Recent) {
        fetchMore({ variables: { groupsBefore: cursor } }).catch(err => {
          console.warn("Error: [GroupDirectory] - ", err.message);
        });
        return;
      }

      fetchMore({ variables: { groupsAfter: cursor } }).catch(err => {
        console.warn("Error: [GroupDirectory] - ", err.message);
      });
    },
    [fetchMore, selectedPill]
  );

  const [scrollSentryRef, { rootRef: listRef }] = useInfiniteScroll({
    hasNextPage:
      selectedPill === PillNames.Recent ? hasPreviousPage : hasNextPage,
    loading,
    onLoadMore: useCallback(() => {
      if (selectedPill === PillNames.Recent) {
        if (!beforeCursor) return;
        loadGroups(beforeCursor);
        return;
      }

      if (!afterCursor) return;
      loadGroups(afterCursor);
    }, [afterCursor, beforeCursor, loadGroups, selectedPill]),
    rootMargin: "0px 0px 200px 0px",
  });

  const handleCreateGroup = () => {
    if (!workspaceEdge) return;
    openModal(<CreateGroupModal workspaceID={workspaceEdge.node.id} />);
  };

  const orderedGroups = useMemo(() => {
    const groupIDs =
      workspaceEdge?.node.groups.edges.map(group => group.node.id) ?? [];

    return selectedPill === PillNames.Recent
      ? groupIDs.slice().reverse()
      : groupIDs;
  }, [selectedPill, workspaceEdge?.node.groups.edges]);

  return (
    <div ref={listRef} className="grow overflow-auto">
      <div className="flex items-center justify-between max-w-[832px] mx-auto px-16 w-full">
        <div className="mt-16 w-full">
          <Form useFormProps={{ defaultValues: { search: searchTerm } }}>
            <GroupsDirectorySearchInput onChange={setSearchTerm} />
          </Form>
        </div>
      </div>

      <div className="flex items-center justify-between max-w-[832px] mx-auto px-16 w-full overflow-x-auto">
        <ul
          className="flex items-center justify-start my-16 gap-8"
          data-testid="Groups Directory Pills"
        >
          {Object.values(PillNames).map(pillName => (
            <Pill
              key={pillName}
              name={pillName}
              onClick={setSelectedPill}
              selected={pillName === selectedPill}
            />
          ))}
        </ul>

        <Button
          buttonStyle="simplePrimary"
          className="font-black text-sm !px-0 text-nowrap ml-8"
          icon="Plus"
          iconClassName="mr-6"
          iconSize={20}
          onClick={handleCreateGroup}
          type="button"
        >
          Create Group
        </Button>
      </div>

      <div className="max-w-[832px] mx-auto px-16 w-full">
        <div
          className={tw(
            "bg-background-body mb-16 rounded-lg shadow-level1 overflow-hidden",
            "[&>div]:border-b-1 [&>div]:border-border-subtle", // Row borders
            "[&>div:last-child]:border-none" // Remove last row border
          )}
        >
          {!!workspaceEdge && (
            <>
              {(!searchTerm ||
                (!!searchTerm &&
                  WORKSPACE_GENERAL_NAME.toLowerCase().startsWith(
                    searchTerm.toLowerCase()
                  ))) && (
                <GroupsDirectoryRowPrimitive
                  description={WORKSPACE_GENERAL_DESCRIPTION}
                  edge={workspaceEdge}
                  title={WORKSPACE_GENERAL_NAME}
                />
              )}

              {orderedGroups?.map(groupID => (
                <GroupsDirectoryRow key={groupID} groupID={groupID} />
              ))}
            </>
          )}

          <GroupDirectorySkeletons
            ref={scrollSentryRef}
            count={4}
            on={
              (loading && (!data || !!searchTerm)) ||
              (selectedPill === PillNames.Recent
                ? hasPreviousPage
                : hasNextPage)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default GroupDirectory;

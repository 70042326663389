import tw from "utils/tw";

const className = {
  editor: tw(
    "grow md:min-h-56 p-16 md:p-10 str-chat-message-content font-message text-message text-text-strong",
    "relative whitespace-pre-wrap hyphens-manual break-words outline-0 outline-none",
    "drag:min-h-[var(--attachmentHeight)]"
  ),
  placeholder: tw(
    "before:text-interactive-subtle before:content-[attr(data-placeholder)]",
    "before:absolute before:h-0 before:pointer-events-none"
  ),
};

export default className;

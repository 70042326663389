import { cloneElement } from "react";

import Skeleton from "components/Skeleton/Skeleton";
import { SectionItem } from "components/design-system/ui/sections-sidebar";

type Props = {
  count?: number;
  on: boolean;
  skeletonClassName?: string;
  wrapperClassName?: string;
};

const InboxSkeletons = ({
  count = 1,
  on,
  skeletonClassName = "h-[22px] w-full",
  wrapperClassName = "px-16 py-6",
}: Props) => {
  return (
    <>
      {[...(on ? Array(count) : [])].map((_, i) =>
        cloneElement(
          <SectionItem
            key={`skeleton-${
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              i
            }`}
            className={wrapperClassName}
            flipId={`skeleton${i}`}
          >
            <Skeleton className={skeletonClassName} flex />
          </SectionItem>
        )
      )}
    </>
  );
};

export default InboxSkeletons;

import GroupProfilePane from "components/design-system/ui/ProfilePane/GroupProfilePane";
import UserProfilePane from "components/design-system/ui/ProfilePane/UserProfilePane";

const MobileProfilePane = ({ id }: { id?: string }) => {
  if (id) {
    switch (id.slice(0, 4)) {
      case "grp_":
      case "wks_":
        return <GroupProfilePane groupID={id} />;
      case "usr_":
        return <UserProfilePane userID={id} />;
    }
  }

  return null;
};

export default MobileProfilePane;

import { MarkPasteRule } from "@remirror/pm/paste-rules";
import { ItalicExtension } from "remirror/extensions";

class GlueItalicExtension extends ItalicExtension {
  createPasteRules(): MarkPasteRule[] {
    return []; // disable converting _..._ to italic when pasting
  }
}

export default GlueItalicExtension;

/**
 * @summary Creates and clicks a temporary link to download a file.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLAnchorElement/download}
 * @param {string} href
 * @param {string | undefined} name the default file name for use in labeling the resource in a local file system
 */

const clickTemporaryLink = (href: string, name?: string) => {
  const anchor = document.createElement("a");
  anchor.href = href;
  anchor.style.display = "none";
  anchor.target = "_blank";
  // if a name is provided, the browser will download the resource as the specified name
  if (name) anchor.download = name;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

export default clickTemporaryLink;

import { Button } from "components/design-system/Button";
import useUnreadSidebarItemStore from "store/useUnreadSidebarItemStore";
import tw from "utils/tw";

const MoreUnreadButton = ({ isTop }: { isTop?: boolean }) => {
  const { resetUnreadItems, unreadItems, unreadMentions } =
    useUnreadSidebarItemStore();

  const items = isTop ? unreadItems.above : unreadItems.beneath;
  const mentions = isTop ? unreadMentions.above : unreadMentions.beneath;

  const item = items.values().next().value;
  if (!item) return null;

  return (
    <Button
      buttonStyle="none"
      className={tw(
        "h-34 !px-12 text-footnote-bold rounded-[16px] whitespace-nowrap",
        "absolute left-1/2 -translate-x-1/2 z-1",
        isTop ? "top-16" : "bottom-16",
        mentions.size > 0
          ? "bg-background-alert-strong text-text-action-inverse"
          : "bg-background-action-strong text-text-inverse"
      )}
      icon={isTop ? "ArrowUp" : "ArrowDown"}
      iconClassName="ml-0 mr-4"
      iconSize={16}
      onClick={() => {
        item.scrollToItem();
        resetUnreadItems(isTop ? "above" : "beneath");
      }}
      type="button"
    >
      {mentions.size > 0 ? "Unread mentions" : "More unreads"}
    </Button>
  );
};

export const MoreUnreadButtons = () => {
  return (
    <>
      <MoreUnreadButton isTop />
      <MoreUnreadButton />
    </>
  );
};

export default MoreUnreadButton;

import BackStackButton from "components/App/AppLayoutMobile/BackStackButton";
import { MouseEvent, useState } from "react";
import { useHistory } from "react-router";

import { ThreadEdge, ThreadPreviewEdge } from "@utility-types";
import PaneHeader from "components/design-system/ui/PaneHeader";
import { routeToGroup, routeToUser } from "components/routing/utils";
import useAuthData from "hooks/useAuthData";
import useElementBreakpoint from "hooks/useElementBreakpoint";
import useMemberEdge from "hooks/useMemberEdge";
import { formatNameEmoji } from "utils/formatNameEmoji";
import threadChatType from "utils/thread/threadChatType";

import OptionalLink from "components/OptionalLink";
import Avatar from "components/design-system/Avatar/Avatar";

import ThreadActionMenu from "../ThreadActionMenu";

import ThreadComposeTopButton from "../ThreadCompose/ThreadComposeTopButton";
import ChatThreadInfo from "./ChatThreadInfo";
import PinnedButton from "./PinnedButton";
import ThreadMembersCount from "./ThreadMembersCount";
import useThreadComposeMenuAction from "./hooks/useThreadComposeMenuAction";

const desktopWidth = 480;

type Props = {
  onClose?: () => void;
  threadEdge: ThreadEdge | ThreadPreviewEdge | undefined;
};

const ChatThreadHeader = ({ onClose, threadEdge }: Props) => {
  const { authData } = useAuthData();
  const { threadComposeAction, handleCompose } = useThreadComposeMenuAction();

  const [headerRef, setHeaderRef] = useState<HTMLDivElement | null>(null);

  const desktopBreakpoint = useElementBreakpoint(headerRef, desktopWidth);

  const history = useHistory();

  const { memberEdge } = useMemberEdge(threadEdge);

  if (memberEdge && !memberEdge.node.isPersistentChat) {
    console.warn("ThreadChatHeader - threadEdge is not a persistent chat");
  }

  const chatType = threadChatType(memberEdge?.node);

  const recipients = threadEdge?.node?.recipients.edges.map(e => e.node) || [];
  const otherRecipient =
    recipients?.find(r => r.id !== authData?.me.id) || recipients?.[0];

  const openUserPane = () => {
    if (!otherRecipient) return;
    history.push(routeToUser({ to: "secondary", userID: otherRecipient.id }));
  };

  const openGroup = () => {
    if (!otherRecipient) return;
    history.push(routeToGroup({ groupID: otherRecipient.id }));
  };

  const onClickHeader = (e: MouseEvent) => {
    e.stopPropagation();
    chatType === "user" ? openUserPane() : openGroup();
  };

  const formattedName = formatNameEmoji(otherRecipient);

  return (
    <PaneHeader ref={setHeaderRef} onClose={onClose}>
      <div className="flex grow items-center min-h-[52px]">
        <BackStackButton />

        <div className="flex items-center ml-4 md:ml-0">
          <OptionalLink
            enabled={!!otherRecipient}
            to={
              chatType === "user"
                ? routeToUser({
                    to: "secondary",
                    userID: otherRecipient?.id || "",
                  })
                : routeToGroup({
                    to: "secondary",
                    groupID: otherRecipient?.id || "",
                  })
            }
          >
            <Avatar
              {...otherRecipient}
              emojiProps={{
                emoji: formattedName?.emoji,
              }}
              margin="mr-12"
              name={formattedName?.name}
              rounded="rounded-md"
              size="large"
            />
          </OptionalLink>
          <ChatThreadInfo
            chatType={chatType}
            onClick={onClickHeader}
            recipient={otherRecipient}
            showExternalLabel={!!desktopBreakpoint}
          />
        </div>
      </div>
      <div className="relative flex shrink-0 items-center justify-end pl-16 gap-8">
        {desktopBreakpoint && (
          <ThreadComposeTopButton onClick={handleCompose} />
        )}

        {chatType === "group" && desktopBreakpoint ? (
          <ThreadMembersCount edge={memberEdge} onClick={onClickHeader} />
        ) : null}

        {threadEdge && <PinnedButton threadID={threadEdge.node.id} />}
        <ThreadActionMenu
          threadEdge={threadEdge}
          additionalActions={!desktopBreakpoint ? [threadComposeAction] : []}
        />
      </div>
    </PaneHeader>
  );
};

export default ChatThreadHeader;

import { matchPath } from "react-router";

import AppTabLayout from "components/App/AppLayoutMobile/AppTabLayout";
import NewDirectMessageButton from "components/design-system/ui/NewDirectMessageButton";
import MobileProfilePane from "components/design-system/ui/ProfilePane/MobileProfilePane";
import SidebarAccessoryContainer from "components/design-system/ui/SidebarAccessoryContainer";
import ViewScrollContainer from "components/design-system/ui/ViewScrollContainer";
import { usePartitionState } from "components/routing/RoutingPartition";
import {
  PathMatch,
  PathnameParams,
  TabName,
  routeParams as getRouteParams,
  locationFromRoute,
} from "components/routing/utils";
import DirectMessage from "components/views/dms/DirectMessage";
import DirectMessagesMainTabs from "./DirectMessagesMain/DirectMessagesMainTabs";

const DirectMessagesMobile = ({
  scrollRef,
}: {
  scrollRef: (el: HTMLDivElement | null) => void;
}) => {
  const { route } = usePartitionState(({ route }) => ({ route }));
  const { pathname, search } = locationFromRoute(route);
  const { d, messageID } = getRouteParams({ pathname, search });

  const params = matchPath<PathnameParams>(pathname, PathMatch.dms)?.params;

  const detailContent: React.ReactNode = (() => {
    if (d) {
      return <MobileProfilePane id={d} />;
    }

    if (params?.userID) {
      return <DirectMessage messageID={messageID} userID={params.userID} />;
    }

    return null;
  })();

  return (
    <AppTabLayout detailChildren={detailContent} name={TabName.DMs}>
      <div className="flex flex-col grow h-full min-h-0 relative w-full">
        <ViewScrollContainer className="pt-8" scrollRef={scrollRef}>
          <DirectMessagesMainTabs />
        </ViewScrollContainer>
      </div>

      <SidebarAccessoryContainer>
        <NewDirectMessageButton />
      </SidebarAccessoryContainer>
    </AppTabLayout>
  );
};

export default DirectMessagesMobile;

import { useRef, useState } from "react";

import { nodeAs } from "@utility-types";
import { ModalProps } from "components/ModalKit/Modal";
import { Header, Main } from "components/ModalKit/Parts";
import { MemberRole, useFetchGroupOrPreviewEdgeQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useMemberEdge from "hooks/useMemberEdge";

import { ProfileItemHeader, StandardModal } from "components/Modals";
import { Tabs } from "components/Tabs";
import { ThreadsApps } from "components/apps";

import GroupMembers from "../GroupMembers/GroupMembers";

import { FormRef } from "./EditGroupForm";
import GroupNotificationsSettings from "./GroupNotificationsSettings";
import GroupSettings from "./GroupSettings";
import useGroupApps from "./hooks/useGroupApps";
import useShowAppsOption from "./hooks/useShowAppsOption";

export enum GroupProfileTab { // in order of UI appearance
  Members = "members",
  Settings = "settings",
  Notifications = "notifications",
  Apps = "apps",
}

type Props = {
  defaultTab?: GroupProfileTab;
  groupID: string;
  isAdmin?: boolean;
  focusOnDescriptionField?: boolean;
} & ModalProps;

const GroupProfileModal = ({
  defaultTab = GroupProfileTab.Members,
  groupID,
  isAdmin: isAdminProp,
  ...props
}: Props) => {
  const { authData, authReady } = useAuthData();

  const { data, error, fetchMore, loading } = useFetchGroupOrPreviewEdgeQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !authData?.me.id,
    variables: { id: `${groupID}-${authData?.me.id}` },
  });

  const groupEdge = nodeAs(data?.node, ["GroupEdge", "GroupPreviewEdge"]);
  const { memberEdge } = useMemberEdge(groupEdge);
  const isAdmin =
    isAdminProp !== undefined
      ? isAdminProp
      : memberEdge?.__typename === "GroupEdge"
        ? memberEdge.memberRole === MemberRole.Admin
        : false;

  const showApps = useShowAppsOption({
    groupEdge: memberEdge,
    isAdmin,
  });

  const tabs = [
    {
      tab: GroupProfileTab.Members,
    },
    ...(showApps
      ? [
          {
            tab: GroupProfileTab.Apps,
          },
        ]
      : []),
    {
      tab: GroupProfileTab.Settings,
    },
    {
      tab: GroupProfileTab.Notifications,
    },
  ];

  const [selectedTab, setSelectedTab] = useState(
    tabs.find(t => t.tab === defaultTab)?.tab ?? GroupProfileTab.Members
  );
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const formRef = useRef<FormRef | null>();

  const setFormRef = (node: FormRef | null) => {
    formRef.current = node;
    const value = node?.submitDisabled ?? false;
    if ((submitDisabled && !value) || (!submitDisabled && value)) {
      setSubmitDisabled(value);
    }
  };

  const group = groupEdge?.node;
  const appsAdded = memberEdge?.node.apps.edges;
  const persistentChatID = memberEdge?.persistentChatEdge.node.id;

  const { addApp, appsAvailable, editApp, removeApp } = useGroupApps(
    group,
    persistentChatID
  );

  return (
    <StandardModal
      header={
        <Header
          className="!justify-start md:h-48 md:min-h-0 md:pt-16"
          mobileCtaLabel={
            selectedTab === GroupProfileTab.Settings ? "Save" : undefined
          }
          mobileCtaProps={{
            disabled: submitDisabled,
            onClick: () => {
              formRef.current?.submitForm();
            },
          }}
          variant="borderless"
        >
          {group && (
            <ProfileItemHeader target={{ recipient: group, size: "large" }} />
          )}
        </Header>
      }
      mdHeightAuto={false}
      {...props}
    >
      <Main className="relative">
        <div className="bg-background-modal sticky top-0 z-1">
          <Tabs<GroupProfileTab>
            buttonClassName="border-b-2 capitalize h-36 mr-24 z-1"
            className="px-16 md:px-32 w-full"
            onClickTab={setSelectedTab}
            selectedTab={selectedTab}
            tabs={tabs}
          />
          <div className="border-b-thin md:border-b-1 border-background-subtle relative -top-1 z-0" />
        </div>

        {data && group && (
          <div className="px-16 md:px-32">
            {selectedTab === GroupProfileTab.Members &&
              group?.__typename === "Group" && (
                <GroupMembers
                  group={group}
                  isAdmin={isAdmin}
                  hasError={!!error}
                  fetchMore={fetchMore}
                  isLoading={loading}
                />
              )}
            {selectedTab === GroupProfileTab.Apps && (
              <ThreadsApps
                addApp={addApp}
                appsAdded={appsAdded}
                appsAvailable={appsAvailable}
                canChangeApps={isAdmin}
                configureApp={app => editApp(app, group.id, group.workspaceID)}
                name={group.name}
                removeApp={removeApp}
                workspaceID={group.workspaceID}
              />
            )}
            {selectedTab === GroupProfileTab.Settings &&
              group?.__typename === "Group" && (
                <GroupSettings
                  ref={setFormRef}
                  group={group}
                  isAdmin={isAdmin}
                  {...props}
                />
              )}
            {selectedTab === GroupProfileTab.Notifications &&
              memberEdge?.__typename === "GroupEdge" && (
                <GroupNotificationsSettings groupEdge={memberEdge} />
              )}
          </div>
        )}
      </Main>
    </StandardModal>
  );
};

export default GroupProfileModal;

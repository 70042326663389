/* ignore coverage */
/* istanbul ignore */

import { DropzoneInputProps } from "react-dropzone";

import {
  Attachment,
  Message,
  Recipient,
  StreamGlueAdditionalProps,
  StreamGlueAttachment,
  StreamGlueMessage,
} from "@utility-types";
import { AppUnfurlSetup } from "generated/graphql";

export type Mode = "compose" | "edit";
export type SendButtonMode = "send" | "post";

export type EditorMessage = {
  attachments: Attachment[];
  quoted_message?: Message;
  text: string;
};

export type EditorStreamMessage = {
  attachments: StreamGlueAttachment[];
  quoted_message?: StreamGlueMessage;
  quoted_message_id?: string;
  text: string;
} & StreamGlueAdditionalProps;

export type DropZone = {
  getInputProps: (props?: DropzoneInputProps | undefined) => DropzoneInputProps;
  isDragActive: boolean;
};

export enum MentionSearchName {
  All = "all",
  Threads = "threads",
}

export enum MentionTriggers {
  ALL = "@",
  THREADS = "[",
}

export const GLUE_URI_SCHEME = "glue:";

export const isMention = (link: string) => link.startsWith(GLUE_URI_SCHEME);

export type EditorProps = {
  accessory?: React.ReactNode;
  bottomBarSections?: JSX.Element[];
  mode: Mode;
  sendButtonMode?: SendButtonMode;
  onChange: () => void;
  onMention?: (mentioned: Recipient) => void;
  placeholder?: string;
  readOnly: boolean;
  showMagic?: boolean;
  showSendButton?: boolean;
  showThreadActions?: boolean;
  submitForm: () => void;
  workspaceID?: string;
};

export type MessageEditor = {
  focusEditor: (position?: "start" | "end") => void;
  getMessage: () => EditorMessage;
  getAppUnfurlSetups: () => AppUnfurlSetup[];
  getStreamMessage: () => EditorStreamMessage;
  hasAttachments: () => boolean;
  addAttachments: (files: File[]) => void;
  hasFocus: () => boolean;
  hasText: () => boolean;
  mode: Mode;
  readOnly: () => boolean;
  reset: () => void;
  setIsProcessing: (value: boolean) => void;
  setMessage: (message: EditorMessage) => { focus: () => void } | undefined;
  setQuotedMessage: (message: Message | undefined) => void;
  setReadOnly: (value: boolean) => void;
  setStreamMessage: (message: StreamGlueMessage) => void;
};

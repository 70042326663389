import { getThumbnailDimensions } from "components/thread/ThreadView/stream-components/MessageAttachments/components/Image/GalleryImage";
import { FileFieldsFragment } from "generated/graphql";

export const parseImage = (
  file: FileFieldsFragment,
  defaultImageSize = 192
) => {
  const imageAlt = file.name;
  const imageSrc = file.url;
  const { blurHash, height, width } = file.metadata ?? {};
  const imageHeight = height ?? defaultImageSize;
  const imageWidth = width ?? defaultImageSize;

  const ratio = imageWidth / imageHeight;
  const targetHeight = Math.min(defaultImageSize, imageHeight);
  const targetWidth = Math.min(defaultImageSize, imageWidth);

  const { height: thumbnailHeight, width: thumbnailWidth } =
    getThumbnailDimensions({
      imageWidth,
      ratio,
      targetHeight,
      targetWidth,
    });

  const maxHeight = Math.min(imageHeight, window.innerHeight);
  const maxWidth = Math.min(imageWidth, window.innerWidth);
  const isLandScape = ratio >= 1;

  const imageResize = {
    fit: "max" as const,
    h: isLandScape ? Math.round(maxWidth / ratio) : maxHeight,
  };

  const thumbnailResize = {
    fit: "max" as const,
    h: thumbnailHeight,
    w: thumbnailWidth,
  };

  return {
    blurHash,
    defaultImageSize,
    height: height ?? defaultImageSize,
    imageAlt,
    imageSrc,
    imageResize,
    thumbnailHeight,
    thumbnailResize,
    thumbnailWidth,
    width: width ?? defaultImageSize,
  };
};

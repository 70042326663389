export const truncateSubject = (subject: string, length: number) => {
  if (subject.length <= length) return subject.trim();

  const truncatedSubject = subject
    .trim()
    .split(" ")
    .reduce((prev, current) => {
      const next = prev.concat(` ${current}`);
      return next.length <= length ? next : prev;
    }, "");

  // Check whether the last character in subject is punctuation
  const match = truncatedSubject.match(/[^a-zA-Z0-9]+$/);

  // No match found
  if (!match?.index) {
    return `${truncatedSubject}...`;
  }

  // Return sliced truncatedSubject
  return `${truncatedSubject.slice(0, match.index)}...`;
};

export default truncateSubject;

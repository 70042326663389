import tw from "utils/tw";
import ThreadItemContentPreview from "./ThreadItemContentPreview";

import { ComponentProps } from "react";

type Props = {
  avatarComponent: JSX.Element | null;
} & ComponentProps<typeof ThreadItemContentPreview>;

const ThreadItemContent = ({ avatarComponent, ...props }: Props) => {
  return (
    <div
      className={tw(
        "flex min-w-0 grow items-center",
        "border-b-1 border-border-container",
        "ml-12 py-12 pr-16",
        "md:border-b-none md:ml-6 md:py-0 md:pr-0"
      )}
    >
      {avatarComponent && <div className="mr-8">{avatarComponent}</div>}
      <ThreadItemContentPreview {...props} />
    </div>
  );
};

export default ThreadItemContent;

import { useDraftListQuery, useSaveDraftMutation } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import saveDraftsOption from "utils/drafts/saveDraftsOptions";

const useDraftsUtils = ({ pageSize = 100 }: { pageSize?: number } = {}) => {
  const { authReady } = useAuthData();

  const { data, fetchMore, refetch } = useDraftListQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    variables: { last: pageSize },
  });

  const [saveDraft] = useSaveDraftMutation();

  const drafts = data?.drafts.edges ?? [];

  const handleUndo = (draftID: string) => {
    const index = drafts.findIndex(d => d.node.id === draftID);
    const draft = drafts.find(d => d.node.id === draftID);

    if (!draft) return;
    saveDraft({
      ...saveDraftsOption(index),
      variables: {
        id: draft.node.id,
        input: {
          ...draft.node,
          message: {
            ...draft.node.message,
            attachments: draft.node.message.attachments.map(a => a.id),
          },
          recipients: draft.node.recipients.map(r => r.id),
        },
      },
    });
  };
  return { data, fetchMore, handleUndo, refetch };
};

export default useDraftsUtils;

import { useMemo } from "react";

import { SearchResults } from "../types";

const useFlattenedResults = ({
  searchResults,
  reverse = false,
}: {
  searchResults?: SearchResults;
  reverse?: boolean;
}) =>
  useMemo(() => {
    if (!searchResults) return [];
    const { instantResults, moreResults } = searchResults;

    const instantNodes = instantResults.flatMap(r => r.edges.map(e => e.node));
    const moreNodes = moreResults?.flatMap(r => r.edges.map(e => e.node));

    return reverse
      ? [...(moreNodes ?? []), ...instantNodes]
      : [...instantNodes, ...(moreNodes ?? [])];
  }, [searchResults, reverse]);

export default useFlattenedResults;

import { ComponentProps, ForwardedRef, useEffect, useRef } from "react";

import { CodeBlockLanguageSelect } from "@remirror/extension-react-language-select";

import { useEditorContentState } from "components/MessageEditor/providers/EditorContentProvider";
import { useEditorState } from "components/MessageEditor/providers/EditorProvider";
import useLocalSettingsStore from "store/useLocalSettingsStore";
import tw from "utils/tw";

import { PlaceholderExtension, useExtension } from "@remirror/react";
import { useAttachments, useQuotedMessage } from "../../hooks";
import { EditorProps, MessageEditor } from "../../types";
import { EmojiSuggester } from "../EmojiPicker";
import { ImperativeHandle } from "../ImperativeHandle";
import { MagicLoading } from "../MagicLoading";
import { MentionsSuggester } from "../MentionsSuggester";
import { ProseMirrorWrapper } from "../ProseMirrorWrapper";
import { ButtonBar, PrimaryButtonBar, SendButton } from "../controls";

type Props = ComponentProps<typeof ProseMirrorWrapper> &
  EditorProps &
  Pick<ComponentProps<typeof ImperativeHandle>, "isMounted"> & {
    forwardedRef: ForwardedRef<MessageEditor>;
  };

const CODE_BLOCK_LANG = [{ displayName: "html", value: "markup" }];

export default function EditorComponents({
  accessory: accessoryComponent,
  bottomBarSections,
  enterKeyBehavior,
  forwardedRef,
  isMounted,
  mode,
  sendButtonMode = "send",
  onChange,
  onMention,
  placeholder,
  readOnly,
  showMagic = false,
  showSendButton = true,
  showThreadActions = false,
  submitForm,
  workspaceID,
}: Props) {
  const ref = useRef<HTMLDivElement | null>(null);

  const editorUpdater = useEditorState().setState;

  const { isResetting } = useEditorContentState(({ isResetting }) => ({
    isResetting,
  }));

  const { quotedMessage, quotedMessageComponent, setQuotedMessageRef } =
    useQuotedMessage(mode, onChange);

  const {
    appUnfurlSetups,
    attachmentComponents,
    attachmentState,
    getFinishedAttachments,
    onAttachFiles,
    setAttachmentsRef,
  } = useAttachments({
    onChange,
    readOnly,
    workspaceID,
  });

  useEffect(() => {
    editorUpdater({ ref: ref.current?.closest(".message-editor") });
  }, [editorUpdater]);

  useExtension(PlaceholderExtension, { placeholder });

  return (
    <>
      <div
        ref={ref}
        className="relative flex flex-col grow min-h-0 max-h-full drag:h-auto overflow-hidden"
      >
        {quotedMessageComponent}
        <ProseMirrorWrapper
          enterKeyBehavior={enterKeyBehavior}
          submitForm={submitForm}
        />
        {attachmentComponents}
        <MentionsSuggester onMention={onMention} />
        <EmojiSuggester />
        {!isResetting && (
          <CodeBlockLanguageSelect
            className={tw(
              "code-block-language",
              "outline-none cursor-pointer appearance-none",
              "bg-transparent bg-[right_0_center] bg-no-repeat",
              "text-interactive-strong hover:text-interactive-strong-hover rounded-none",
              "w-[var(--w)] top-[var(--y)] left-[var(--x)] absolute"
            )}
            languages={CODE_BLOCK_LANG}
            offset={{ x: 10, y: 5 }}
            onSelectChange={e => {
              useLocalSettingsStore.setState({
                codeblockLanguageSetting:
                  e.target.dataset.value || e.target.value,
              });

              return false;
            }}
          />
        )}
        {accessoryComponent}
        <PrimaryButtonBar
          buttonBarSections={[
            <ButtonBar
              onAttachFiles={onAttachFiles}
              readOnly={readOnly}
              showThreadActions={showThreadActions}
            />,
            ...(bottomBarSections ?? []),
          ]}
          sendButton={
            showSendButton ? (
              <SendButton
                mode={mode}
                sendButtonMode={sendButtonMode}
                readOnly={readOnly}
                submitForm={submitForm}
              />
            ) : undefined
          }
        />
        {showMagic && <MagicLoading className="absolute top-12 left-80" />}
      </div>

      <ImperativeHandle
        {...{
          appUnfurlSetups,
          attachmentState,
          onAttachFiles,
          forwardedRef,
          getFinishedAttachments,
          isMounted,
          mode,
          quotedMessage,
          setAttachmentsRef,
          setQuotedMessageRef,
        }}
      />
    </>
  );
}

import { ComponentProps, DOMAttributes, forwardRef } from "react";

import { nodeIs } from "@utility-types";
import useAuthData from "hooks/useAuthData";
import tw from "utils/tw";

import { ThreadActionsFloatingMenu } from "../ThreadActions";

import ThreadItemAvatar from "./ThreadItemAvatar";
import ThreadItemContent from "./ThreadItemContent";
import { parseData } from "./utils";

type Props = ComponentProps<typeof ThreadItemAvatar> &
  Omit<ComponentProps<typeof ThreadItemContent>, "itemData"> & {
    canArchive?: ComponentProps<typeof ThreadActionsFloatingMenu>["canArchive"];
    className?: string;
    elementDOMAttributes?: DOMAttributes<HTMLDivElement | HTMLLIElement>;
    itemData?: Omit<
      ComponentProps<typeof ThreadItemContent>["itemData"],
      "avatarComponent"
    >;
  };

const ThreadItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      canArchive,
      avatarComponent,
      avatarSize,
      className,
      compact,
      elementDOMAttributes,
      isBulkEditMode,
      isRead = true,
      isStarred = false,
      isSubscribed = true,
      item,
      itemBulkMode,
      itemData,
      recipientID,
      showSkeleton = false,
      ...rest
    }: Props,
    ref
  ) => {
    const { authData } = useAuthData();

    const data = itemData || parseData({ authData, item, recipientID });

    const hasClick = !!(
      elementDOMAttributes?.onClick || elementDOMAttributes?.onPointerDown
    );

    return (
      <div
        ref={ref}
        className={tw(
          "group/thread-item select-none outline-none",
          "flex items-center min-w-0 overflow-hidden relative w-full",
          "md:py-12 md:px-20 pl-8",
          { "md:!pl-12": !itemBulkMode },
          "md:border-b-1 md:border-border-container",
          {
            "cursor-pointer": hasClick,
            "show-skeleton": showSkeleton,
            unread: !!(!isRead || data.unreadCount),
          },
          className
        )}
        {...elementDOMAttributes}
        {...rest}
      >
        <ThreadItemContent
          avatarComponent={
            itemBulkMode ? (
              <ThreadItemAvatar // <= bulk select UI for Threads view
                avatarComponent={avatarComponent}
                avatarSize={avatarSize}
                isBulkEditMode={isBulkEditMode}
                itemBulkMode={itemBulkMode}
                showSkeleton={data.showSkeleton}
              />
            ) : (
              avatarComponent
            )
          }
          compact={compact}
          isRead={isRead}
          isStarred={isStarred}
          isSubscribed={isSubscribed}
          item={item}
          itemData={data}
          recipientID={recipientID}
        />

        {nodeIs(item, ["ThreadEdge"]) && (
          <ThreadActionsFloatingMenu
            canArchive={canArchive}
            date={data.date ?? undefined}
            threadEdge={item}
          />
        )}
      </div>
    );
  }
);

export default ThreadItem;

import { devtools, subscribeWithSelector } from "zustand/middleware";

import { glueCreateZustand } from "store/helper/glueCreate";

import { SuperTabKeys } from "components/routing/utils";

const STORE_NAME = "SuperTabSideEffectStore";

type SideEffects = Record<string, () => void>;

type SuperTabSideEffectStore = {
  tabSideEffects: SideEffects;
  setTabSideEffects: (name: SuperTabKeys, sideEffect: () => void) => void;
};

const useSuperTabSideEffectStore = glueCreateZustand<SuperTabSideEffectStore>({
  name: STORE_NAME,
})(
  subscribeWithSelector(
    devtools<SuperTabSideEffectStore>(
      set => ({
        tabSideEffects: {},
        setTabSideEffects: (name, sideEffect) =>
          set(state => ({
            tabSideEffects: { ...state.tabSideEffects, [name]: sideEffect },
          })),
      }),
      {
        name: STORE_NAME,
      }
    )
  )
);

export default useSuperTabSideEffectStore;

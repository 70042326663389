import { Main } from "components/ModalKit";
import { ModalProps } from "components/ModalKit/Modal";
import { StandardModal } from "components/Modals";
import Payment from "components/Payment/Payment";
import { SubscriptionPlan } from "generated/graphql";

export type Props = ModalProps & {
  onComplete?: () => void;
  planID?: SubscriptionPlan["id"];
  refetchSubscription?: () => void;
  workspaceID?: string;
};

const PaymentModal = ({
  planID,
  refetchSubscription,
  workspaceID,
  ...props
}: Props) => (
  // afterClose, fire `refetchSubscription` again for redundancy; stripe.initEmbeddedCheckout onComplete has been flaky
  <StandardModal {...props} afterClose={refetchSubscription}>
    <Main className="py-12">
      <Payment
        workspaceID={workspaceID}
        planID={planID}
        onComplete={refetchSubscription}
        destroying={props.state === "closing"}
      />
    </Main>
  </StandardModal>
);

export default PaymentModal;

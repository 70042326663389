import { useHistory } from "react-router-dom";

import {
  searchParams as queryParamsCyan,
  routeParams,
  routeToThread,
} from "components/routing/utils";
import { useThreadViewState } from "components/thread/ThreadView/provider/ThreadViewProvider";

/**
 * This hook returns a function that resets the thread in the currently active
 * pane. It achieves this by removing the messageID from the path, if it exists,
 * and subsequently scrolling the thread to the bottom.
 **/
const useGoToThreadRecent = (): (() => void) => {
  const history = useHistory();

  const { threadPane } = useThreadViewState(({ threadPane }) => ({
    threadPane,
  }));

  return () => {
    const currentQueryParams = queryParamsCyan(window.location);
    const params = routeParams(window.location);
    const qParams = routeParams({
      pathname: `/${currentQueryParams.d}`,
      search: "",
    });

    if (params.messageID || qParams.messageID) {
      const { recipientID, threadID: paramsThreadID } = params;
      const threadID =
        qParams.threadID && threadPane === "secondary"
          ? qParams.threadID
          : paramsThreadID ?? "";

      if (!threadID && !recipientID) return;

      history.push(
        routeToThread({
          recipientID,
          threadID,
          forceTo: threadPane,
        })
      );
    }
  };
};

export default useGoToThreadRecent;

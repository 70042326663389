import { MouseEvent } from "react";

import { ThreadEdge } from "@utility-types";
import FacePileCount from "components/design-system/ui/FacePileCount";
import useValidateRecipientOrigin from "hooks/useValidateRecipientOrigin";

type Props = {
  edge?: ThreadEdge;
  onClick: (e: MouseEvent) => void;
};

const ThreadMembersCount = ({ edge, onClick }: Props) => {
  const { containsExternalRecipients } = useValidateRecipientOrigin();
  const { externalRecipients } = containsExternalRecipients(
    edge?.node.recipients.edges.map(e => e.node) ?? [],
    true
  );

  const totalCount = edge?.node.users.totalCount ?? 0;
  const hasExternal = !!externalRecipients.length;
  const tooltip = hasExternal
    ? "Includes guests from outside your workspace"
    : undefined;

  return (
    <FacePileCount
      count={totalCount}
      hasExternal={hasExternal}
      tooltip={tooltip}
      onClick={onClick}
      disabled={!edge}
    />
  );
};

export default ThreadMembersCount;

import { useState } from "react";

import { ModalProps } from "components/ModalKit/Modal";
import { Button } from "components/design-system/Button";
import useModalStore from "store/useModalStore";

import DeleteAccountModal from "./DeleteAccountModal";

const AccountOptions = ({ modalId }: { modalId: ModalProps["modalId"] }) => {
  const [collapsedOptions, setCollapseOptions] = useState(false);
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  return (
    <>
      <Button
        buttonStyle="subtle"
        buttonType="text"
        className="flex justify-center items-center mb-15"
        data-testid="collapseButton"
        icon="ChevronRight"
        iconClassName={`text-interactive-subtle ease-spring duration-350 transform ${
          collapsedOptions && "rotate-90"
        }`}
        onClick={() => setCollapseOptions(!collapsedOptions)}
        type="button"
      >
        Account options
      </Button>
      {collapsedOptions && (
        <Button
          buttonStyle="none"
          className="flex justify-center items-center p-5 px-12 ml-20 font-bold text-text-accent bg-interactive-alert rounded"
          onClick={() =>
            openModal(<DeleteAccountModal parentModalId={modalId} />)
          }
          type="button"
        >
          Delete Account
        </Button>
      )}
    </>
  );
};

export default AccountOptions;

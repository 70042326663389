import { useEffect, useState } from "react";

import { KeyBindingNames } from "@remirror/core-types";
import { useRemirrorContext } from "@remirror/react";
import { FlatEmoji } from "remirror/extensions";

import useEditorIsProcessing from "components/MessageEditor/hooks/useEditorIsProcessing";
import { useEmoji } from "components/MessageEditor/hooks/useEmoji";
import { EmojiList } from "components/design-system/EmojiList";

import { Floater } from "../Floater";

const dismissKeys: KeyBindingNames[] = ["Escape", "Shift-Tab"];
const submitKeys: KeyBindingNames[] = ["Enter", "Tab"];

/**
 * This component renders the emoji suggestion dropdown for the user.
 */
const EmojiSuggester = () => {
  const { view } = useRemirrorContext();
  const { getItemProps, getMenuProps, index, state } = useEmoji({
    dismissKeys,
    submitKeys,
  });

  const [show, setShow] = useState(true);

  const hasFocus = view.hasFocus();
  useEffect(() => {
    setShow(hasFocus);
  }, [view, hasFocus]);

  // Two letters after :
  const showMenu = state && state.query.length >= 1;

  useEditorIsProcessing(!!showMenu);

  if (!showMenu) return null;

  const itemProps = (index: number, emoji: FlatEmoji) =>
    getItemProps({
      index,
      item: emoji,
    });

  return (
    <Floater enabled={show} positioner="cursor">
      <EmojiList
        containerProps={getMenuProps()}
        emojis={state?.list}
        itemProps={itemProps}
        selectedIndex={index}
      />
    </Floater>
  );
};

export default EmojiSuggester;

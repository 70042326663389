import {
  Mutate,
  StateCreator,
  StoreApi,
  StoreMutatorIdentifier,
  UseBoundStore,
  create,
} from "zustand";

type GlueCreateOptions = {
  name: string;
};

export const zustandStoreResetFns = new Set<{
  func: () => void;
  name: string;
}>();

// when creating a store, we get its initial state, create a reset function and add it in the set
export const glueCreateZustand = ((options: GlueCreateOptions) =>
  <S>(createState: StateCreator<S>) => {
    const store = create(createState);
    const initialState = store.getInitialState();
    zustandStoreResetFns.add({
      func: () => store.setState(initialState, true),
      name: options.name,
    });
    return store;
  }) as {
  <T, Mos extends [StoreMutatorIdentifier, unknown][] = []>(
    initializer: StateCreator<T, [], Mos>
  ): UseBoundStore<Mutate<StoreApi<T>, Mos>>;
  <TT>(
    options: GlueCreateOptions
  ): <Mos extends [StoreMutatorIdentifier, unknown][] = []>(
    initializer: StateCreator<TT, [], Mos>
  ) => UseBoundStore<Mutate<StoreApi<TT>, Mos>>;
  <S extends StoreApi<unknown>>(store: S): UseBoundStore<S>;
};

import { SVGAttributes } from "react";
import * as Icons from "./all-icons";

export type IconName = keyof typeof Icons;

export interface IconProps extends SVGAttributes<SVGElement> {
  color?: string;
  size?: string | number;
}

type Props = {
  icon: IconName | React.FunctionComponent<IconProps>;
  size?: number | string;
  strokeWidth?: number | string;
} & React.SVGProps<SVGSVGElement>;

export const Icon = ({
  className = "",
  icon,
  size = 24,
  strokeWidth = 1.5,
  ...props
}: Props) => {
  const IconComponent = typeof icon === "string" ? Icons[icon] : icon;
  return (
    <IconComponent
      className={`glue-icon select-none shrink-0 ${className}`}
      height={size}
      strokeWidth={strokeWidth}
      title={typeof icon === "string" ? icon : undefined}
      width={size}
      {...props}
    />
  );
};

export default Icon;

import { memo } from "react";

import { StreamImageAttachment } from "@utility-types";

import {
  PhotoSwipeGallery,
  defaultPhotoSwipeGalleryOptions,
} from "components/PhotoSwipe";

import GalleryImage from "./GalleryImage";
import SingleImage from "./SingleImage";

type Props = {
  galleryWidth?: number;
  id: string;
  images: StreamImageAttachment[];
};

function Image({ galleryWidth = 768, id, images }: Props): JSX.Element | null {
  const isGallery = images.length > 1;
  const galleryCols = 3;
  const lastRow = images.length % galleryCols;

  return (
    <div className="overflow-hidden my-6 max-w-screen-md">
      <div className="flex relative flex-wrap">
        <PhotoSwipeGallery
          id={id}
          options={{
            ...defaultPhotoSwipeGalleryOptions,
            showAnimationDuration: isGallery ? 0 : 580,
            showHideOpacity: isGallery,
          }}
        >
          {isGallery
            ? images.map((image, index, images) => {
                const widthDivisor =
                  images.length - index > lastRow ? galleryCols : lastRow;
                return (
                  <GalleryImage
                    key={image.image_url}
                    galleryWidth={galleryWidth}
                    image={image}
                    widthDivisor={widthDivisor}
                  />
                );
              })
            : images.map(image => (
                <SingleImage
                  key={image.image_url}
                  galleryWidth={galleryWidth}
                  image={image}
                />
              ))}
        </PhotoSwipeGallery>
      </div>
    </div>
  );
}

export default memo(
  Image,
  (prev, next) =>
    prev.images.map(({ file_id }) => file_id).toString() ===
    next.images.map(({ file_id }) => file_id).toString()
);

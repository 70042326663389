import { Button } from "components/design-system/Button";
import { WorkspaceSubscriptionFieldsFragment } from "generated/graphql";
import useManageBilling from "../hooks/useManageBilling";
import Badge from "./Badge";
import { formatAmount, planDate } from "./utils";

const Invoices = ({
  subscription,
  workspaceID,
}: {
  subscription: WorkspaceSubscriptionFieldsFragment;
  workspaceID: string;
}) => {
  const { fetchingBillingURL, openManageBilling } =
    useManageBilling(workspaceID);

  if (!subscription.plan) return null;

  const latestInvoice = subscription.latestInvoice;
  const nextInvoice = subscription.nextInvoice;

  return (
    <div className="text-body">
      <div className="flex">
        <div className="grow min-w-0 text-body-bold">Invoices</div>
        <Button
          buttonFont="subheadBold"
          buttonStyle="action"
          buttonType="none"
          disabled={fetchingBillingURL}
          onClick={openManageBilling}
          type="button"
        >
          View all
        </Button>
      </div>

      <table className="text-subhead text-text-secondary w-full">
        <tbody>
          {nextInvoice && (
            <tr
              className="align-middle h-32 [&>td]:pr-10"
              data-testid="next-invoice"
            >
              <td>
                {planDate(nextInvoice.periodEnd)}
                <Badge margin="ml-10" type="scheduled" />
              </td>
              <td>{`${nextInvoice.description}`}</td>
              <td className="text-right">
                {formatAmount(nextInvoice.total, true)}
              </td>
            </tr>
          )}

          {latestInvoice && (
            <tr
              className="align-middle h-32 [&>td]:pr-10"
              data-testid="last-invoice"
            >
              <td>
                {planDate(latestInvoice.periodEnd)}
                <Badge
                  margin="ml-10"
                  type={latestInvoice.status === "paid" ? "paid" : "unpaid"}
                />
              </td>
              <td>{latestInvoice.description}</td>
              <td className="text-right">
                {formatAmount(latestInvoice.total, true)}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Invoices;

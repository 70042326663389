import { useState } from "react";

import Feed from "components/Feed/Feed";
import NewThreadFeedWidget from "components/design-system/ui/NewThreadFeedWidget";

import MyFeedHeader from "components/views/groups/MyFeedHeader";

const FeedViewDesktop = () => {
  const [scrollEl, setScrollEl] = useState<HTMLDivElement | null>(null);

  return (
    <div
      ref={setScrollEl}
      className="w-full grow min-h-0 bg-background-app-secondary overflow-auto"
    >
      <div className="w-full max-w-[832px] mx-auto">
        <div className="w-full p-16">
          <MyFeedHeader edge={undefined} />
          <NewThreadFeedWidget className="my-16" />
          <Feed feedID={"groups"} scrollEl={scrollEl} />
        </div>
      </div>
    </div>
  );
};

export default FeedViewDesktop;

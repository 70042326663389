import Icon from "components/design-system/icons/Icon";
import { forwardRef } from "react";
import tw from "utils/tw";

type Props = {
  children: string;
  isOpen?: boolean;
};

export const SectionHeaderTitleButton = forwardRef<HTMLButtonElement, Props>(
  ({ children, isOpen, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={tw(
          "group flex items-center rounded-md text-footnote-bold",
          "p-4 -ml-4",
          "text-text-secondary hover:text-text-secondary-hover hover:bg-background-app-subtle",
          { "bg-background-app-subtle": isOpen }
        )}
        {...props}
      >
        {children}
        <Icon
          icon="MenuVertical"
          size={20}
          className={tw(
            "text-icon-secondary group-hover:text-icon-secondary-hover",
            {
              "text-icon-secondary-hover": isOpen,
            }
          )}
        />
      </button>
    );
  }
);

import { ExtensionPriority } from "remirror";
import { OrderedListExtension } from "remirror/extensions";

import GlueListItemExtension from "./GlueListItemExtension";

export default class GlueOrderedListExtension extends OrderedListExtension {
  createExtensions() {
    return [
      new GlueListItemExtension({
        priority: ExtensionPriority.High,
      }),
    ];
  }
}

import { devtools, persist, subscribeWithSelector } from "zustand/middleware";

import { SearchQuery, SearchVars } from "components/views/search/types";

import { glueCreateZustand } from "./helper/glueCreate";

const STORE_NAME = "SearchStore";

type SearchStore = {
  addSearchHistory: (searchTerm: string) => void;
  removeSearchHistory: (searchTerm: string) => void;
  searchHistory: string[];
  setState: (values: Partial<SearchStore>) => void;

  instantSearchData?: SearchQuery;
  moreSearchData?: SearchQuery;
  searching?: boolean;
  searchVars?: SearchVars;
};

const useSearchStore = glueCreateZustand<SearchStore>({
  name: STORE_NAME,
})(
  subscribeWithSelector(
    devtools(
      persist(
        set => ({
          addSearchHistory: (searchTerm: string) => {
            set(state => {
              const searchHistory = state.searchHistory.filter(
                search => search !== searchTerm
              );
              searchHistory.unshift(searchTerm);
              return { searchHistory: searchHistory.slice(0, 5) };
            });
          },
          removeSearchHistory: (searchTerm: string) => {
            set(state => ({
              searchHistory: state.searchHistory.filter(
                search => search !== searchTerm
              ),
            }));
          },
          searchHistory: [],
          setState: values => set(state => ({ ...state, ...values })),
        }),
        {
          name: STORE_NAME,
          partialize: ({ searchHistory }) => ({ searchHistory }),
          version: 1,
        }
      ),
      {
        name: STORE_NAME,
        serialize: true,
      }
    )
  )
);

export default useSearchStore;

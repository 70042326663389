import useLocalSettingsStore from "store/useLocalSettingsStore";
import useModalStore from "store/useModalStore";

import NotificationPreAlertModal from "../NotificationPreAlertModal";
import { hasDefaultPermission } from "../permissions";

const useRequestPermission = () => {
  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));
  const { notificationAskedAt: askedAt = 0 } = useLocalSettingsStore(
    ({ notificationAskedAt }) => ({ notificationAskedAt })
  );

  const requestPermission = async () => {
    if (!(await hasDefaultPermission())) return;

    const daysSince = Math.floor((Date.now() - askedAt) / 1000 / 60 / 60 / 24);
    if (daysSince >= 7) openModal(<NotificationPreAlertModal />);
  };

  return { requestPermission };
};

export default useRequestPermission;

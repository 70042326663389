import { ModalProps } from "components/ModalKit/Modal";
import {
  Form,
  Select,
  SubmitButton,
  TextArea,
  TextInput,
} from "components/design-system/Forms";
import { LambdasDocument, useCreateLambdaMutation } from "generated/graphql";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";

import { Footer, Header, Main } from "components/ModalKit";
import { StandardModal } from "components/Modals";

import { LambdaFullScreenModal } from "./LambdaFullScreenModal";

export type FormData = {
  description: string;
  name: string;
  preview: string;
  source: string;
  template: string;
};

export type Props = ModalProps & {
  appId: string;
};

export const EditLambdaModal = ({ appId, ...props }: Props) => {
  const { closeModal, openModal } = useModalStore(
    ({ closeModal, openModal }) => ({
      closeModal,
      openModal,
    })
  );
  const [createLambda] = useCreateLambdaMutation();

  const handleFormSubmit = async (data: FormData) => {
    const { description, name, source } = data;
    closeModal();

    const l = await createLambda({
      refetchQueries: [LambdasDocument],
      variables: {
        appID: appId,
        description,
        event: "message",
        name,
        source: source,
      },
    });

    if (l.data?.createLambda) {
      openModal(<LambdaFullScreenModal lambda={l.data?.createLambda} />);
    }
  };

  const FormInput = () => {
    return (
      <Main className="p-16 md:px-32">
        <div className="flex-col grow">
          {/* name */}
          <TextInput<FormData>
            config={{ required: true }}
            label="Name"
            labelClassName="text-interactive-strong"
            name="name"
            wrapperClassName="mt-0 mb-20"
          />
          {/* description */}
          <TextInput<FormData>
            config={{ required: false }}
            label="Description"
            labelClassName="text-interactive-strong"
            name="description"
            wrapperClassName="mt-0 mb-20"
          />

          <Select<FormData>
            label="Template"
            name="template"
            options={[
              { label: "Hello World Template", value: "hello" },
              { label: "Post Message Template", value: "post" },
              { label: "Advanced Template", value: "advanced" },
            ]}
          />

          <TextArea<FormData>
            label="Preview"
            name="source"
            style={{
              height: "190px",
            }}
          />
        </div>
      </Main>
    );
  };

  return (
    <StandardModal {...props}>
      <Header variant="bordered">
        <h3 className="m-0">Create Lambda</h3>
      </Header>
      <Form
        onSubmit={handleFormSubmit}
        useFormProps={{
          defaultValues: {
            description: "",
            name: "untitled",
            source:
              "console.log('this is code!');\nconsole.log('this is code2!');",
            template: "default template",
          },
        }}
      >
        <FormInput />
        <Footer flexboxClassName={tw("justify-end")}>
          <SubmitButton>{"Create"}</SubmitButton>
        </Footer>
      </Form>
    </StandardModal>
  );
};

export default EditLambdaModal;

import InformationBubble from "components/design-system/InformationBubble/InformationBubble";
import useComposeToGlue from "components/threads/hooks/useComposeToGlue";

const NeedHelpBubble = () => {
  const { composeThreadToGlue } = useComposeToGlue("support");
  return (
    <InformationBubble>
      Need help?{" "}
      <span className="text-text-link" onClick={() => composeThreadToGlue()}>
        Contact support.
      </span>
    </InformationBubble>
  );
};

export default NeedHelpBubble;

import { useEffect, useRef } from "react";

function useOnClickOutside(
  refs: React.RefObject<HTMLElement>[],
  handler: (e: PointerEvent) => void
): void {
  const refsRef = useRef(refs);
  refsRef.current = refs;

  const handlerRef = useRef(handler);
  handlerRef.current = handler;

  useEffect(() => {
    const listener = (e: PointerEvent) => {
      if (!refsRef.current?.length) return;

      const { target } = e;
      if (!target || !("nodeValue" in target)) return;

      if (
        target instanceof Node &&
        refsRef.current.find(({ current }) => current?.contains(target))
      ) {
        // Do nothing if clicking any ref's element or descendent elements
        return;
      }
      handlerRef.current(e);
    };

    document.addEventListener("pointerdown", listener);

    return () => {
      document.removeEventListener("pointerdown", listener);
    };
  }, []);
}

export default useOnClickOutside;

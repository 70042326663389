import { useApolloClient } from "@apollo/client";
import { Clipboard } from "@capacitor/clipboard";
import { useState } from "react";

import { Button } from "components/design-system/Button";
import { AppOrigin } from "components/routing/utils";
import {
  WorkspaceInviteLinkSignedDocument,
  WorkspaceInviteLinkSignedQuery,
} from "generated/graphql";
import tw from "utils/tw";

type Props = {
  workspaceID: string;
  width?: "full" | "half";
};

const CopyInviteButton = ({ workspaceID, width = "full" }: Props) => {
  const [inFlight, setInFlight] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const client = useApolloClient();

  const handleCopyLink = () => {
    setInFlight(true);
    client
      .query<WorkspaceInviteLinkSignedQuery>({
        query: WorkspaceInviteLinkSignedDocument,
        variables: { workspaceID },
      })
      .then(({ data: { workspaceInviteLinkSigned } }) => {
        const inviteLink = new URL(AppOrigin);
        // i stands for Invite, we don't want to add a new valid search parameter because we never want to navigate to a route using ?i
        inviteLink.searchParams.append("i", workspaceInviteLinkSigned);
        Clipboard.write({
          url: inviteLink.href,
        });
        setLinkCopied(true);
      })
      .finally(() => {
        setInFlight(false);
        setTimeout(() => setLinkCopied(false), 1200);
      });
  };
  return (
    <Button
      className={tw(
        "justify-center",
        { "w-full": width === "full" },
        { "w-1/2": width === "half" }
      )}
      disabled={inFlight || !workspaceID}
      icon={!linkCopied ? "Link" : "Check"}
      iconSize={20}
      type="button"
      onClick={handleCopyLink}
    >
      {!linkCopied ? "Copy invite link" : "Link copied!"}
    </Button>
  );
};

export default CopyInviteButton;

import { ComponentProps, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import { Recipient, nodeAs } from "@utility-types";
import ThreadChannel from "components/thread/ThreadView/components/ThreadChannel";
import { ThreadViewProvider } from "components/thread/ThreadView/provider/ThreadViewProvider";
import { useFetchThreadEdgeQuery } from "generated/graphql";
import useFetchThreadReplyTo from "hooks/thread/useFetchThreadReplyTo";
import useAuthData from "hooks/useAuthData";
import useMemberEdge from "hooks/useMemberEdge";
import useAppStateStore from "store/useAppStateStore";
import { addToHistory, createHistoryPath } from "store/useHistoryStore";
import { formatGroupName } from "utils/group/formatGroupName";

import { usePartitionState } from "components/routing/RoutingPartition";
import {
  ThreadsTabs,
  currentPathWithoutDrawer,
  useRouteParams,
  useRoutePartition,
} from "components/routing/utils";

import AddMentionToThread from "components/thread/AddMentionToThread/AddMentionToThread";
import NotInThread from "components/thread/NotInThread/NotInThread";
import useChatRecipient from "hooks/useChatRecipient";
import ChatThreadHeader from "./ThreadHeader/ChatThreadHeader";
import ThreadHeader from "./ThreadHeader/ThreadHeader";
import ThreadReply from "./ThreadReply";

type Props = Omit<
  ComponentProps<typeof ThreadViewProvider>,
  "threadID" | "persistentChatType"
> & {
  headerType?: "none" | "simple" | "full";
  onSubmit?: () => void;
  secondaryPane?: boolean;
  setFocusedThreadId?: (id: string) => void;
  threadID?: string;
};

export const ThreadViewWithoutFocus = ({
  headerType = "full",
  mailbox,
  messageID,
  onSubmit,
  recipientID,
  secondaryPane,
  setFocusedThreadId,
  stackPriority,
  threadID,
}: Props) => {
  const { authData, authReady } = useAuthData();
  const history = useHistory();
  const routeParams = useRouteParams();

  const { data, loading } = useFetchThreadEdgeQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !authData?.me.id || !threadID,
    variables: { id: `${threadID}-${authData?.me.id}` },
  });

  const { memberEdge, previewEdge } = useMemberEdge(
    nodeAs(data?.node, ["ThreadEdge", "ThreadPreviewEdge"])
  );

  const replyTo = useFetchThreadReplyTo(memberEdge?.node);

  const [lastMention, setLastMention] = useState<Recipient>();

  useEffect(() => {
    setLastMention(undefined);
  }, [memberEdge]);

  const persistentChatType = memberEdge?.node.isPersistentChat
    ? memberEdge.node.recipients.edges.length === 2
      ? "user"
      : "group"
    : undefined;

  const ref = useRef<HTMLDivElement>(null);
  const [isActiveThread, setIsActiveThread] = useState(true);

  useEffect(() => {
    if (!ref.current) return;
    const el = ref.current;
    const pointerHandler = (e: PointerEvent) => {
      // we maybe want to check what was clicked?
      e && setIsActiveThread(true);
    };

    const focusHandler = () => setIsActiveThread(true);

    el.addEventListener("pointerdown", pointerHandler);
    el.addEventListener("focusin", focusHandler);

    return () => {
      el.removeEventListener("focusin", focusHandler);
      el.removeEventListener("pointerdown", pointerHandler);
    };
  }, [isActiveThread, threadID]);

  useEffect(() => {
    if (!isActiveThread) return;
    setFocusedThreadId?.(threadID ?? "");
  }, [isActiveThread, setFocusedThreadId, threadID]);

  useEffect(
    () =>
      useAppStateStore.subscribe(
        ({ activeThreadId }) =>
          activeThreadId !== threadID && setIsActiveThread(false)
      ),
    [threadID]
  );

  const onThreadClose = secondaryPane
    ? () => history.push(currentPathWithoutDrawer())
    : undefined;

  const { superTab } = useRoutePartition();
  const memberEdgeNode = memberEdge?.node;
  const isPersistentChat = memberEdgeNode?.isPersistentChat;
  const title = memberEdgeNode?.subject || previewEdge?.node.subject;
  const icon =
    superTab === "threads" || (superTab === "inbox" && !isPersistentChat)
      ? "thread"
      : undefined;
  let name: string | undefined;
  let emoji: string | undefined;
  let avatarURL: string | undefined | null;

  if (persistentChatType === "user") {
    const userNode = persistentChatType
      ? memberEdgeNode?.users.edges.find(u => u.node.id !== authData?.me.id)
          ?.node
      : undefined;
    avatarURL = userNode?.avatarURL;
    name = userNode?.name;
  }

  if (persistentChatType === "group") {
    const group = memberEdgeNode?.recipients.edges[0]?.node;
    const { emoji: groupEmoji, name: groupName } = formatGroupName(group);
    name = groupName;
    emoji = groupEmoji;
  }

  const chatRecipient = useChatRecipient();

  const path = createHistoryPath(routeParams);

  useEffect(() => {
    if (path.includes("dft_")) return;

    if (superTab === "threads" && icon === "thread" && title) {
      addToHistory({
        icon,
        path,
        primaryType: "threads",
        secondaryType: routeParams.t as ThreadsTabs,
        title,
      });
    }

    if (superTab === "inbox" && name && persistentChatType === "user") {
      addToHistory({
        avatarURL,
        recipient: chatRecipient(memberEdgeNode),
        chatType: persistentChatType,
        name,
        path,
        primaryType: "inbox",
      });
    }

    if (superTab === "inbox" && name && persistentChatType === "group") {
      addToHistory({
        chatType: persistentChatType,
        emoji,
        recipient: memberEdgeNode?.recipients.edges[0]?.node,
        name,
        path,
        primaryType: "inbox",
      });
    }

    if (superTab === "inbox" && title && !name) {
      addToHistory({
        icon,
        path,
        primaryType: "inbox",
        title,
      });
    }
  }, [
    avatarURL,
    emoji,
    icon,
    name,
    path,
    persistentChatType,
    routeParams.t,
    superTab,
    title,
    chatRecipient,
    memberEdgeNode,
  ]);

  return (
    <div ref={ref} className="flex flex-col grow h-full w-full">
      {headerType !== "none" ? (
        memberEdge?.node.isPersistentChat ? (
          <ChatThreadHeader
            onClose={onThreadClose}
            threadEdge={memberEdge || previewEdge}
          />
        ) : (
          <ThreadHeader
            headerType={headerType}
            onClose={onThreadClose}
            replyTo={replyTo}
            threadEdge={memberEdge || previewEdge}
          />
        )
      ) : null}

      {threadID ? (
        previewEdge ? ( // don't show "not in thread" until determined
          <NotInThread threadID={threadID} />
        ) : memberEdge || loading ? (
          <ThreadViewProvider
            mailbox={mailbox}
            messageID={messageID}
            persistentChatType={persistentChatType}
            recipientID={recipientID}
            recipientRole={memberEdge?.recipientRole}
            replyTo={replyTo}
            stackPriority={stackPriority}
            threadStartedAt={memberEdge?.node.firstMessage?.createdAt}
            threadPane={secondaryPane ? "secondary" : "primary"}
            threadID={threadID}
            threadWorkspaceID={memberEdge?.node.workspaceID ?? undefined}
          >
            <ThreadChannel>
              <AddMentionToThread
                mention={lastMention}
                threadEdge={memberEdge}
              />
              <ThreadReply onMention={setLastMention} onSubmit={onSubmit} />
            </ThreadChannel>
          </ThreadViewProvider>
        ) : null
      ) : null}
    </div>
  );
};

/**
 * use the default export in most cases;
 * use the named export (above) when needing to use the Thread outside of the partition context, such as in a modal.
 */
const ThreadView = (props: Props) => {
  const { setFocusedThreadId } = usePartitionState(
    ({ setFocusedThreadId }) => ({
      setFocusedThreadId,
    })
  );

  return (
    <ThreadViewWithoutFocus
      setFocusedThreadId={setFocusedThreadId}
      {...props}
    />
  );
};

export default ThreadView;

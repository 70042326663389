import { useEffect, useRef } from "react";

import { Button } from "components/design-system/Button";
import "photoswipe/dist/photoswipe.css";
import usePhotoSwipeStore from "store/usePhotoSwipeStore";
import { isNativeMobile } from "utils/platform";

import { Portal } from "components/Portal";

import PhotoSwipeFullItem from "./PhotoSwipeFullItem";

type Props = {
  /**
   * Show .pswp__button--fs
   *
   * Default: true
   */
  fullscreenButton?: boolean;

  /**
   * Show .pswp__button--zoom
   *
   * Default: true
   */
  zoomButton?: boolean;
};

/**
 * Default PhotoSwipe layout
 */
const PhotoSwipeLayout = ({
  fullscreenButton,
  zoomButton,
  ...props
}: Props): JSX.Element => {
  const photoSwipeLayoutRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!photoSwipeLayoutRef.current) return;
    usePhotoSwipeStore.setState({
      photoSwipeLayoutElement: photoSwipeLayoutRef.current,
    });
  }, [photoSwipeLayoutRef]);

  return (
    <Portal id="pswp-image-gallery">
      <div
        aria-hidden="true"
        className="pswp"
        role="dialog"
        tabIndex={-1}
        {...props}
        ref={photoSwipeLayoutRef}
      >
        <div className="pswp__bg" />
        <div className="pswp__scroll-wrap">
          <div className="pswp__container">
            <PhotoSwipeFullItem />
            <PhotoSwipeFullItem />
            <PhotoSwipeFullItem />
          </div>
          <div className="pswp__ui pswp__ui--hidden">
            <div className="pswp__top-bar">
              <div className="pswp__counter" />
              <Button
                buttonStyle="none"
                className="pswp__button pswp__button--close"
                title="Close (Esc)"
                type="button"
              />
              {fullscreenButton && (
                <Button
                  buttonStyle="none"
                  className="pswp__button pswp__button--fs"
                  title="Toggle fullscreen"
                  type="button"
                />
              )}
              {zoomButton && (
                <Button
                  buttonStyle="none"
                  className="pswp__button pswp__button--zoom"
                  title="Zoom in/out"
                  type="button"
                />
              )}
              {!isNativeMobile() && (
                <Button
                  buttonStyle="none"
                  className="pswp__button pswp__button--share"
                  title="Download"
                  type="button"
                />
              )}
              <div className="pswp__preloader">
                <div className="pswp__preloader__icn">
                  <div className="pswp__preloader__cut">
                    <div className="pswp__preloader__donut" />
                  </div>
                </div>
              </div>
            </div>
            <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
              <div className="pswp__share-tooltip" />
            </div>
            <Button
              buttonStyle="none"
              className="pswp__button pswp__button--arrow--left"
              title="Previous (arrow left)"
              type="button"
            />
            <Button
              buttonStyle="none"
              className="pswp__button pswp__button--arrow--right"
              title="Next (arrow right)"
              type="button"
            />
            <div className="pswp__caption">
              <div className="pswp__caption__center" />
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default PhotoSwipeLayout;

import { baseKeymap } from "@remirror/pm/commands";
import {
  ExtensionPriority,
  type KeyBindings,
  PlainExtension,
  type PrioritizedKeyBindings,
  chainCommands,
  convertCommand,
  entries,
  object,
} from "remirror";
import { ENTER_REPLACEMENT_KEY } from "../utils";

class GlueKeymapExtension extends PlainExtension {
  get name() {
    return "glueKeymap" as const;
  }

  public createKeymap(): PrioritizedKeyBindings {
    const baseKeyBindings: KeyBindings = object();

    for (const [key, value] of entries(baseKeymap)) {
      if (key === "Enter") {
        baseKeyBindings[ENTER_REPLACEMENT_KEY] = chainCommands(
          convertCommand(value)
        );
      } else {
        baseKeyBindings[key] = convertCommand(value);
      }
    }

    return [ExtensionPriority.Default, baseKeyBindings];
  }
}

export default GlueKeymapExtension;

import { Recipient } from "@utility-types";
import BadgedAvatar from "components/design-system/Avatar/BadgedAvatar";
import Icon from "components/design-system/icons/Icon";
import { ComponentProps } from "react";
import useAppStateStore from "store/useAppStateStore";
import tw from "utils/tw";

const InboxAvatar = ({
  avatarName,
  avatarURL,
  badgeUrl,
  bulkMode = false,
  emoji,
  icon,
  isRead,
  onBulkClick,
  recipient,
}: {
  avatarName?: string;
  avatarURL?: string;
  badgeUrl?: string;
  bulkMode: false | "default" | "selected" | "unselected";
  emoji?: string;
  icon?: ComponentProps<typeof Icon>["icon"];
  isRead?: boolean;
  onBulkClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  recipient?: Recipient;
}) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  if (!recipient && !icon && !emoji && !avatarURL) return null;

  avatarName = avatarName ?? recipient?.name ?? undefined;
  avatarURL = avatarURL ?? recipient?.avatarURL ?? undefined;

  const bulkModeEnabled = bulkMode !== false;
  const bulkModeOff = !bulkMode || bulkMode === "default";

  return (
    <button
      className={tw(
        "group/inbox-avatar",
        "h-full pl-12 relative z-0",
        "flex items-center justify-center shrink-0",
        isRead ? "text-icon-secondary" : "text-icon-primary"
      )}
      onPointerDown={bulkModeEnabled ? e => e.stopPropagation() : undefined}
      onClick={onBulkClick}
      type="button"
    >
      {bulkModeOff ? (
        <>
          <div
            className={tw({
              "group-hover/inbox-avatar:hidden": bulkModeEnabled,
            })}
          >
            <BadgedAvatar
              avatarURL={avatarURL}
              background={avatarURL || emoji || icon ? "transparent" : "subtle"}
              badgeProps={{
                avatarURL: badgeUrl,
                background: "transparent",
                rounded: "rounded-sm",
                size: "x-tiny",
              }}
              badgeVisible={!!badgeUrl}
              emojiProps={{ emoji }}
              iconProps={{ icon }}
              name={avatarName}
              priority={["avatar", "emoji", "icon", "name"]}
              size={breakpointMD ? "x-small" : "small"}
            />
          </div>
          <div
            className={tw("hidden", {
              "hidden group-hover/inbox-avatar:flex": bulkModeEnabled,
            })}
          >
            <Icon icon="Checkbox" size={breakpointMD ? 20 : 24} />
          </div>
        </>
      ) : (
        <div
          className={tw(
            bulkMode === "selected"
              ? "text-icon-primary-selected"
              : "text-icon-secondary hover:text-icon-secondary-hover"
          )}
        >
          <Icon
            icon={bulkMode === "selected" ? "ThreadSelected" : "Checkbox"}
            size={breakpointMD ? 20 : 24}
          />
        </div>
      )}
    </button>
  );
};

export default InboxAvatar;

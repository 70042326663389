import { StreamImageAttachment } from "@utility-types";
import glueImageURL from "utils/glueImageURL";

import ImageElement from "components/Media/Image";
import { PhotoSwipeItem } from "components/PhotoSwipe";
import { Button } from "components/design-system/Button";
import { Icon } from "components/design-system/icons";
import tw from "utils/tw";

const DownloadButton = ({ alt, src }: { alt: string; src: string }) => (
  <a
    className="p-6 rounded-md flex items-center select-none bg-background-player-controls hover:bg-background-player-controls-hover text-icon-action-inverse opacity-50 hover:opacity-100"
    download={alt}
    href={glueImageURL(src, { dl: alt }, 0)}
    onClick={e => {
      e.stopPropagation();
    }}
  >
    <Icon icon="Download" size={20} />
  </a>
);

const PhotoSwipeImageWrapper = ({
  alt,
  blurHash,
  className,
  figureClassName,
  height,
  image,
  resize,
  src,
  width,
}: {
  alt: string;
  blurHash: string | undefined;
  className: string;
  figureClassName: string;
  height: number;
  image: StreamImageAttachment;
  resize: Parameters<typeof glueImageURL>[1];
  src: string;
  width: number;
}): JSX.Element => {
  const {
    fallback,
    file_id: fileId,
    image_height: imageHeight = 768,
    image_url: imageURL,
    image_width: imageWidth = 768,
  } = image;

  const maxWidth = Math.min(imageWidth, window.innerWidth);
  const maxHeight = Math.min(imageHeight, window.innerHeight);

  const ratio = imageWidth / imageHeight;
  const isLandScape = ratio >= 1;

  const fullSizeResize = {
    fit: "max" as const,
    h: isLandScape ? Math.round(maxWidth / ratio) : maxHeight,
  };

  return (
    <PhotoSwipeItem
      key={fileId}
      fullSizeURL={glueImageURL(imageURL, fullSizeResize, 2)}
      height={imageHeight}
      id={fileId}
      originalURL={imageURL}
      thumbnail={glueImageURL(imageURL, resize, 2)}
      title={fallback}
      width={imageWidth}
    >
      {({ bindLongPress, open, ref }) => (
        <ImageElement
          ref={ref}
          alt={alt}
          blurHash={blurHash}
          className={className}
          height={height}
          {...bindLongPress}
          figureClassName={figureClassName}
          onClick={open}
          resize={resize}
          src={src}
          width={width}
        >
          <div
            className={tw("absolute top-8 right-8 hidden", {
              "group-hover/figure:md:flex gap-8": height >= 48 && width >= 88,
            })}
          >
            <DownloadButton alt={alt} src={imageURL} />
            <Button
              buttonStyle="mediaControls"
              buttonType="icon"
              className="p-6 rounded-md"
              icon="Maximize"
              iconSize={20}
              onClick={e => {
                e.stopPropagation();
                open?.();
              }}
              type="button"
            />
          </div>
        </ImageElement>
      )}
    </PhotoSwipeItem>
  );
};

export default PhotoSwipeImageWrapper;

import { Recipient } from "@utility-types";
import { Icon } from "components/design-system/icons";

import { RecipientProfileItem } from "components/ProfileItem";

type Props = {
  item: Recipient;
};

const RecipientListItem = ({ item }: Props): JSX.Element | null => {
  const user =
    item.__typename === "User" || item.__typename === "Address"
      ? { ...item, __typename: item.__typename }
      : undefined;
  if (!user) return null;
  return (
    <div
      className="flex overflow-hidden flex-row items-center"
      data-testid="recipientListItem"
    >
      <div className="overflow-hidden">
        <RecipientProfileItem recipient={user} />
      </div>
      {item.__typename === "Address" && (
        <span>
          <Icon
            className="ml-10 text-interactive-subtle"
            data-testid="recipientListItemMail"
            icon="Mail"
            size="24"
          />
        </span>
      )}
    </div>
  );
};

export default RecipientListItem;

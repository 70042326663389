import { BlockComponentProps } from "./BlockComponent";
import BlockKitPlainText from "./BlockPlainText";
import { ImageBlock } from "./blockTypes";

type BlockImageProps = BlockComponentProps & {
  block: ImageBlock;
};

const BlockImage = ({ block, parentType }: BlockImageProps) => {
  const className = (() => {
    switch (parentType) {
      case "section":
        return "object-cover w-40 h-40 md:w-80 md:h-80";
      case "context":
        return "w-20 h-20 object-cover";
      default:
        return "mx-auto";
    }
  })();

  return (
    <div className="p-4">
      {block.title && <BlockKitPlainText text={block.title} />}
      <img alt={block.altText} className={className} src={block.imagePath} />
    </div>
  );
};

export default BlockImage;

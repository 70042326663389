import { SpringValue, useSpring } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import { ReactDOMAttributes } from "@use-gesture/react/dist/declarations/src/types";

import { defaultSpring } from "components/Animated/utils";
import useModalStore from "store/useModalStore";

import type { ModalElement } from "./types";

const overlapValue = 64; // when dragging upwards, extra height gives us some overlap to prevent visual tearing between elements

export const useModalDragging = (
  modalId: ModalElement["id"] | undefined
): {
  bind: () => ReactDOMAttributes;
  dragStyles: {
    height?: SpringValue<number>;
    x?: SpringValue<number>;
    y?: SpringValue<number>;
  };
} => {
  const { closeModal } = useModalStore(({ closeModal }) => ({
    closeModal,
  }));

  const [dragStyles, api] = useSpring(() => ({
    config: defaultSpring,
    height: 0,
    y: 0,
  }));

  const bind = useDrag(
    ({
      direction: [, directionY],
      down,
      movement: [, movementY],
      velocity: [, velocityY],
    }) => {
      const isDismissing = directionY === 1 && !down && velocityY >= 1.0;
      if (isDismissing) {
        closeModal(`${modalId}`);
      }
      api.start({
        height: -movementY + overlapValue,
        immediate: down,
        y: down || isDismissing ? movementY : 0,
      });
    },
    {
      axis: "y",
      bounds: { top: 0 },
      rubberband: 0.1,
    }
  );

  if (!modalId) {
    return {
      bind: () => ({}),
      dragStyles: {},
    };
  }

  return {
    bind,
    dragStyles,
  };
};

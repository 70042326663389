import { ComponentProps, useCallback } from "react";

import { Button } from "components/design-system/Button";
import tw from "utils/tw";

import buttonStyles from "../../buttonStyles";

type MarkTypes = "toggleBold" | "toggleItalic";

type Props = ComponentProps<typeof Button> & {
  active: boolean;
  commands: (method: MarkTypes) => void;
  disabled: boolean;
  toggle: MarkTypes;
};

export const MarkButton = ({
  active,
  commands,
  disabled,
  icon,
  toggle,
}: Props): JSX.Element => (
  <Button
    buttonStyle="subtle"
    className={tw(buttonStyles(active))}
    disabled={disabled}
    icon={icon}
    iconSize={20}
    onClick={useCallback(() => commands(toggle), [commands, toggle])}
    onMouseDown={event => event.preventDefault()}
  />
);

export default MarkButton;

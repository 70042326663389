import BackStackButton from "components/App/AppLayoutMobile/BackStackButton";
import { Skeleton } from "components/Skeleton";
import Avatar from "components/design-system/Avatar/Avatar";
import { Button } from "components/design-system/Button";
import { Close } from "components/design-system/icons/all-icons";
import { ComponentProps, PropsWithChildren } from "react";
import tw from "utils/tw";

export default function Header({
  avatarName,
  avatarURL,
  children,
  emoji,
  loading,
  onClick,
  pane,
  title,
}: PropsWithChildren<{
  avatarName: string;
  avatarURL?: string;
  emoji?: Pick<ComponentProps<typeof Avatar>, "emojiProps">["emojiProps"];
  loading?: boolean;
  onClick: () => void;
  pane?: "secondary";
  title: string | JSX.Element;
}>) {
  return (
    <header className="relative flex flex-col items-center w-full px-24 border-border-container border-b-1">
      {pane === "secondary" ? (
        <Button
          buttonStyle="subtle"
          buttonType="text"
          className="absolute left-16 top-16 p-10 -ml-6 mr-8"
          icon={Close}
          iconSize={20}
          onClick={onClick}
        />
      ) : null}

      <BackStackButton position="profile" />

      <Avatar
        avatarURL={avatarURL}
        background={emoji && !loading ? "transparent" : "subtle"}
        emojiProps={emoji}
        name={avatarName}
        margin="mt-32"
        rounded="rounded-lg"
        size="xx-large"
      />
      <h2
        className={tw(
          "flex items-center text-2xl leading-8 mt-10 mb-0 font-bold",
          { "max-h-32": loading }
        )}
      >
        {!loading ? title : <Skeleton width="180px" height="32px" />}
      </h2>
      <div className="mb-24">{children}</div>
    </header>
  );
}

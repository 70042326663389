import tw from "utils/tw";

import type { ModalElement } from "./types";

type Props = {
  children: React.ReactNode;
  className?: string;
  modalId?: ModalElement["id"]; // added by ModalProvider
};

export const Container = ({ children, className, modalId }: Props) => (
  <div
    className={tw(
      "overflow-hidden fixed inset-0 text-[0px] leading-[1em] outline-none", // container
      "before:inline-block before:w-0 before:h-full before:align-middle before:content-['']", // ::before
      className
    )}
    data-modalid={modalId}
    data-testid="modal-container"
    style={{
      backfaceVisibility: "hidden",
      touchAction: "manipulation",
      WebkitTapHighlightColor: "transparent",
    }}
  >
    {children}
  </div>
);

import { ComponentProps, PropsWithChildren } from "react";

import tw from "utils/tw";
import Draft from "./Draft";
import Thread from "./Thread";
import { className } from "./styles";
import { Styles } from "./types";

type ThreadComponentProps = Omit<ComponentProps<typeof Thread>, "dismissDelay">;

type ThreadArchive = ThreadComponentProps & {
  canArchive: true;
  dismissOnRightSwipe: boolean;
};

type DraftComponentProps = Omit<ComponentProps<typeof Draft>, "dismissDelay">;

type Props =
  | ({
      canFollow?: boolean;
      setSwipedOpenItemId?: never;
      swipedOpenItemId?: string | undefined;
    } & Styles)
  | DraftComponentProps
  | (ThreadComponentProps | ThreadArchive);

const DISMISS_DELAY = 200;

/**
 * @param children Content of the item
 * @param setSwipedOpenItemId providing this function wraps swipeable thread actions
 * around the children
 * @param canArchive enables right swipe with dismiss action e.g. delete draft or archive
 */
export default function SwipeAction(
  props: PropsWithChildren<Props>
): JSX.Element | null {
  if (!("setSwipedOpenItemId" in props) || !props.setSwipedOpenItemId) {
    return (
      <div
        className={tw(className.listItem, props.borderRadius || "rounded-md")}
      >
        {props.children}
      </div>
    );
  }

  if ("canArchive" in props) {
    return <Thread {...props} dismissDelay={DISMISS_DELAY} />;
  }

  return <Draft {...props} dismissDelay={DISMISS_DELAY} />;
}

import { Button } from "components/design-system/Button";
import tw from "utils/tw";
import buttonStyles from "../../buttonStyles";

type Props = {
  showSubjectSuggestion: () => void;
  readOnly: boolean;
};

const CreateThreadButton = ({ showSubjectSuggestion, readOnly }: Props) => {
  return (
    <Button
      buttonStyle="subtle"
      className={tw(buttonStyles())}
      disabled={readOnly}
      icon="CreateThread"
      iconSize={20}
      name="createThread"
      onClick={showSubjectSuggestion}
      type="button"
    />
  );
};

export default CreateThreadButton;

import { ComponentType } from "react";

type ComposableComponents =
  | ComponentType
  | [ComponentType<{ children: React.ReactNode }>, { [key: string]: unknown }];

type Props = {
  components: ComposableComponents[];
};

export const Compose = ({ children, components }: WithChildren<Props>) => (
  <>
    {components.reverse().reduce((accumulator, current) => {
      const [Provider, props] = Array.isArray(current)
        ? [current[0], current[1]]
        : [current, {}];
      return <Provider {...props}>{accumulator}</Provider>;
    }, children)}
  </>
);

export default Compose;

import { useEffect } from "react";

import { createZustandStoreContext } from "create-zustand-store-context";

import { Message, ThreadPreview, ThreadSimple } from "@utility-types";
import { DraftListQuery, MemberRole, ThreadsMailbox } from "generated/graphql";
import { useLayerState } from "providers/LayerProvider";
import useActiveThreadStore, {
  StackPriority,
} from "store/useActiveThreadStore";
import useAppStateStore from "store/useAppStateStore";

import useThreadDrafts from "../hooks/useThreadDrafts";

type ReplyTo = {
  message: Message;
  thread: ThreadSimple | ThreadPreview | undefined;
};

type ThreadDrafts = {
  drafts?: DraftListQuery["drafts"]["edges"];
  replyDraft?: DraftListQuery["drafts"]["edges"][0];
};

type ThreadViewStore = {
  editorIsActive: boolean;
  pauseLoadMore: boolean;
  mailbox: ThreadsMailbox;
  messageID?: string;
  persistentChatType?: "user" | "group";
  prevScrollTop?: number;
  recipientID?: string;
  recipientRole?: MemberRole;
  replyTo?: ReplyTo;
  scrollHeight: number;
  scrollTo?: Element["scrollTo"];
  scrollToBottom?: () => void;
  scrollTop: number;
  scrolledToEnd: boolean;
  scrolledToLinkedMessage?: boolean;
  stackPriority: StackPriority;
  threadDrafts?: ThreadDrafts;
  threadID: string;
  threadStartedAt?: string;
  threadIsActive: boolean;
  threadPane: "primary" | "secondary";
  threadWorkspaceID?: string;
};

type Props = {
  mailbox?: ThreadsMailbox;
  messageID?: string;
  persistentChatType?: "user" | "group";
  recipientID?: string;
  recipientRole?: MemberRole;
  replyTo?: ReplyTo;
  stackPriority: StackPriority;
  threadStartedAt?: string;
  threadDrafts?: ThreadDrafts;
  threadID: string;
  threadPane?: "primary" | "secondary";
  threadWorkspaceID?: string;
};

const defaultState: ThreadViewStore = {
  editorIsActive: true,
  pauseLoadMore: false,
  mailbox: ThreadsMailbox.All,
  persistentChatType: undefined,
  scrolledToEnd: true,
  scrolledToLinkedMessage: false,
  scrollHeight: 0,
  scrollTop: 0,
  stackPriority: 0,
  threadID: "",
  threadIsActive: true,
  threadPane: "primary",
};

const {
  storeContext,
  useState: useThreadViewState,
  useStore: useThreadViewStore,
} = createZustandStoreContext<ThreadViewStore, keyof Props>({
  defaultState,
  name: "ThreadViewProvider",
});

const ThreadViewStoreContext = storeContext;

function ThreadViewProvider({
  children,
  mailbox,
  messageID,
  persistentChatType,
  recipientID,
  recipientRole,
  replyTo,
  stackPriority,
  threadStartedAt,
  threadPane,
  threadID,
  threadWorkspaceID,
}: WithChildren<Props>) {
  const { activeThreadId, addThread, removeThread, threads } =
    useActiveThreadStore(
      ({ activeThreadId, addThread, removeThread, threads }) => ({
        activeThreadId,
        addThread,
        removeThread,
        threads,
      })
    );
  const { appStatus } = useAppStateStore(({ appStatus }) => ({ appStatus }));

  const threadDrafts = useThreadDrafts({
    threadID,
  });

  const currentStore = useThreadViewStore(threadID, {
    mailbox,
    messageID,
    persistentChatType,
    recipientID,
    recipientRole,
    replyTo,
    stackPriority,
    threadStartedAt,
    threadDrafts,
    threadID,
    threadPane,
    threadWorkspaceID,
  });

  const { layerIsActive } = useLayerState(({ layerIsActive }) => ({
    layerIsActive,
  }));

  const activeThread = threads.get(threadID);
  const threadIsActive =
    appStatus === "active" && activeThread?.stackPriority === stackPriority;
  const editorIsActive =
    layerIsActive &&
    !(
      threadID === activeThreadId &&
      stackPriority !== activeThread?.stackPriority
    );

  useEffect(() => {
    addThread({ stackPriority, threadID });

    return () => {
      removeThread(threadID);
    };
  }, [addThread, stackPriority, removeThread, threadID]);

  useEffect(() => {
    currentStore.setState({
      editorIsActive,
      mailbox,
      messageID,
      persistentChatType,
      recipientID,
      recipientRole,
      replyTo,
      stackPriority,
      threadStartedAt,
      threadDrafts,
      threadIsActive,
      threadWorkspaceID,
    });
  }, [
    currentStore,
    editorIsActive,
    mailbox,
    messageID,
    persistentChatType,
    recipientID,
    recipientRole,
    replyTo,
    stackPriority,
    threadDrafts,
    threadIsActive,
    threadStartedAt,
    threadWorkspaceID,
  ]);

  return (
    <ThreadViewStoreContext.Provider value={currentStore}>
      {children}
    </ThreadViewStoreContext.Provider>
  );
}

export { ThreadViewProvider, useThreadViewState };

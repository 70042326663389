import { Icon } from "components/design-system/icons";

const ServerErrorSnackbar = (): JSX.Element => (
  <>
    <Icon className="icon" icon="AlertTriangle" size={18} />
    <p>
      <span className="font-semibold">Server Error.</span>&nbsp;We're sorry!
      Something went wrong, but we're looking into it.
    </p>
  </>
);

export default ServerErrorSnackbar;

import { HTMLAttributes } from "react";
import tw from "utils/tw";

type SnackbarTypes =
  | "connectivity"
  | "error"
  | "server"
  | "info"
  | "magic"
  | "success";

type Props = {
  className?: string;
  containerClassName?: string;
  type?: SnackbarTypes;
} & WithChildren &
  HTMLAttributes<HTMLDivElement>;

const BaseSnackbar = ({
  children,
  className,
  containerClassName,
  type = "info",
  ...rest
}: Props): JSX.Element => (
  <div className={tw("snackbar-container", containerClassName)} {...rest}>
    <div className={tw("snackbar", type, className)}>{children}</div>
  </div>
);

export default BaseSnackbar;

import { marked } from "marked";

import { parseMarkdown, unescapeCodeFenceInCodeElements } from "md/util";

const markdownToPlainText = (markdown?: string) =>
  getHTMLTextContent(
    // Get HTML string from markdown
    unescapeCodeFenceInCodeElements(
      marked.parse(parseMarkdown(markdown || "", "<br>"), {
        gfm: true,
        smartLists: true,
        xhtml: true,
      })
    )
  );

const getHTMLTextContent = (htmlString: string): string =>
  [...new DOMParser().parseFromString(htmlString, "text/html").body.childNodes]
    .map(child => child.textContent)
    .join("");

export default markdownToPlainText;

type Props = {
  children: string;
};

export const SectionHeaderTitle = ({ children, ...props }: Props) => {
  return (
    <div
      className="text-footnote-bold p-4 -ml-4 text-text-secondary"
      {...props}
    >
      {children}
    </div>
  );
};

import { ElementRef, useEffect, useRef, useState } from "react";

import { useChannelActionContext, useChatContext } from "stream-chat-react";

import { GlueDefaultStreamChatGenerics } from "@utility-types";
import MessageEditorContainer from "components/MessageEditor/MessageEditorContainer";
import { usePartitionState } from "components/routing/RoutingPartition";
import { useThreadViewState } from "components/thread/ThreadView/provider/ThreadViewProvider";
import type { MessageInputProps } from "components/thread/ThreadView/stream-components/MessageInput";
import useNativeKeyboardStore from "store/useNativeKeyboardStore";
import { removeLonelyLinks } from "utils/message/removeLonelyLinks";

import { MessageEditor } from "components/MessageEditor";

const EditMessageForm = <T extends GlueDefaultStreamChatGenerics>({
  clearEditingState,
  message,
  messageWrapperRef,
}: MessageInputProps<T>): JSX.Element => {
  const { editMessage } = useChannelActionContext();
  const { channel } = useChatContext();
  const editor = useRef<ElementRef<typeof MessageEditor> | null>(null);
  const [messageState] = useState(message);
  const { scrollTo, scrollTop, threadPane } = useThreadViewState(
    ({ scrollTo, scrollTop, threadPane }) => ({
      scrollTo,
      scrollTop,
      threadPane,
    })
  );

  const { partitionId } = usePartitionState(({ partitionId }) => ({
    partitionId,
  }));

  const close = () => {
    if (!channel) return;
    channel.stopTyping();
    clearEditingState?.();
  };
  const secondaryBarButton = {
    secondaryBarButtons: {
      cancelEditButton: {
        onClick: close,
        text: "Cancel",
        title: "Cancel Edit",
        type: "button" as const,
      },
    },
  };

  useEffect(() => {
    if (scrollTo && scrollTop) {
      setTimeout(() => {
        scrollTo(0, scrollTop);
      }, 0);
    }
  }, [scrollTo, scrollTop]);

  useEffect(
    () =>
      useNativeKeyboardStore.subscribe(
        ({ keyboardHeight }) => keyboardHeight,
        () => {
          if (!editor.current?.hasFocus()) return;
          messageWrapperRef.current?.scrollIntoView();
        }
      ),
    [messageWrapperRef]
  );

  useEffect(() => {
    if (!editor.current || !messageState) return;
    editor.current.setStreamMessage(messageState);
  }, [messageState]);

  return (
    <MessageEditorContainer variant="edit-message" {...secondaryBarButton}>
      <MessageEditor
        ref={editor}
        editorId={`${partitionId}-${threadPane}-${message?.id}`}
        mode="edit"
        placeholder="Message..."
        submitForm={() => {
          if (!editor.current) return;

          let updatedMessage = editor.current.getStreamMessage();
          // Ensures previous properties are saved
          updatedMessage = { ...message, ...updatedMessage };

          updatedMessage.content_updated_at = new Date().toISOString();
          if (channel) {
            channel.stopTyping();
          }

          updatedMessage.text = removeLonelyLinks(
            updatedMessage.text,
            updatedMessage.attachments
          );

          clearEditingState?.();
          editMessage?.({ ...updatedMessage, id: message?.id });

          if (message?.text) {
            message.text = updatedMessage.text;
          }

          if (message?.attachments) {
            message.attachments = updatedMessage.attachments;
          }
        }}
      />
    </MessageEditorContainer>
  );
};

export default EditMessageForm;

import { useState } from "react";

import { Icon } from "components/design-system/icons";
import { FileIcon } from "components/helper";

import glueImageURL from "utils/glueImageURL";
import env from "utils/processEnv";

type MentionIconsProps = {
  children: React.ReactNode | undefined;
  url: string;
};

const truncateString = (str: string, maxLength: number): string => {
  if (str.length <= maxLength) return str;
  return `${str.slice(0, maxLength)}...`;
};

/**
 * @summary Renders inline icons for mentions of files and objects based on the url prop.
 */

const MentionIcons = ({ children, url }: MentionIconsProps) => {
  const [missingFavicon, setMissingFavicon] = useState(false);

  const className = "align-baseline top-2 inline-block relative size-16 mr-2";

  const id = url.replace("glue:", "");
  const prefix = id.split("_")[0];

  switch (prefix) {
    case "fil":
      const fileType = new URL(url).searchParams.get("fileType") ?? undefined;
      return (
        <>
          <span className={className}>
            <FileIcon
              className="!text-icon-link"
              fileType={fileType}
              iconSize={16}
              strokeWidth={1}
            />
          </span>
          {children}
        </>
      );
    // ExternalObjects require two icons to be displayed, favicon and external link arrow
    case "obj":
      const objUrl = `${env.glueApiUrl}/external/${id}/icon`;

      return (
        <>
          {missingFavicon ? (
            <Icon
              className="text-icon-link inline mb-3 mr-1"
              icon="Link"
              size={16}
              strokeWidth={1}
            />
          ) : (
            <img
              className="mb-4 mr-2 p-1 size-16 inline-block"
              src={glueImageURL(objUrl, { w: 14, h: 14, fit: "max" })}
              onError={() => setMissingFavicon(true)}
            />
          )}

          {Array.isArray(children) && typeof children[0] === "string"
            ? truncateString(children[0], 44)
            : children}

          <Icon
            className="text-icon-link inline mb-3 mr-1"
            icon="ExternalLinkMinimal"
            size={16}
            strokeWidth={1}
          />
        </>
      );
    case "thr":
      return (
        <>
          <span className={className}>
            <Icon icon="Thread" size={16} strokeWidth={1} />
          </span>
          {children}
        </>
      );
    default:
      return <>{children}</>;
  }
};

export default MentionIcons;

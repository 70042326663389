import { useEffect, useRef } from "react";

import { RefetchQueryDescriptor, useApolloClient } from "@apollo/client";

import useAppStateStore from "store/useAppStateStore";

const useRefetchOnAppActive = (refetchQueries: RefetchQueryDescriptor[]) => {
  const refetchQueriesRef = useRef(refetchQueries);
  refetchQueriesRef.current = refetchQueries;

  const apolloClient = useApolloClient();

  const { appStatus } = useAppStateStore(({ appStatus }) => ({ appStatus }));

  useEffect(() => {
    if (appStatus === "inactive") return;
    apolloClient.refetchQueries({ include: refetchQueriesRef.current });
  }, [apolloClient, appStatus]);
};

export default useRefetchOnAppActive;

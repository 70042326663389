import { Panel } from "react-resizable-panels";
import tw from "utils/tw";

type Props = {
  id?: string;
  className?: string;
  minSize?: number;
  order?: number;
  rounded?: "right" | "all" | "none";
};

const Pane = ({
  children,
  className,
  id,
  minSize = 1,
  order,
  rounded = "right",
}: WithChildren<Props>) => (
  <Panel
    data-testid="pane"
    className={tw(
      "flex flex-col grow min-w-0 h-full mr-4",
      "bg-background-body overflow-hidden shadow-level1",
      {
        "rounded-lg": rounded === "all",
        "rounded-r-lg": rounded === "right",
      },
      className
    )}
    minSize={minSize}
    order={order}
    id={id}
  >
    {children}
  </Panel>
);

export default Pane;

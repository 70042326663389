import { Icon } from "components/design-system/icons";
import { FeedType } from "generated/graphql";

export default function EmptyView({ type }: { type: FeedType }) {
  const typeIcon = () => {
    switch (type) {
      case FeedType.Mentions:
        return (
          <Icon
            icon="AtSign"
            className="text-icon-brand w-24 h-24 mb-8"
            strokeWidth={2}
          />
        );
      case FeedType.Reactions:
        return (
          <Icon
            icon="Reaction"
            className="text-icon-brand w-24 h-24 mb-8"
            strokeWidth={2}
          />
        );
      case FeedType.Groups:
        return (
          <Icon
            icon="Users"
            className="text-icon-brand w-24 h-24 mb-8"
            strokeWidth={2}
          />
        );
      case FeedType.Requests:
        return (
          <Icon
            icon="Request"
            className="text-icon-brand w-24 h-24 mb-8"
            strokeWidth={2}
          />
        );
      default:
        return (
          <Icon
            icon="Bell"
            className="text-icon-brand w-24 h-24 mb-8"
            strokeWidth={2}
          />
        );
    }
  };

  return (
    <div className="flex justify-center items-center h-full">
      <div className="flex items-center text-body-bold text-text-primary flex-col">
        {typeIcon()}
        No {type === FeedType.All ? "activity" : type}... yet!
      </div>
    </div>
  );
}

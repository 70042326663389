import { ReactNode } from "react";

import {
  PhotoSwipeGallery,
  PhotoSwipeItem,
  defaultPhotoSwipeGalleryOptions,
} from "components/PhotoSwipe";

import MentionIcons from "components/MessageElements/MentionIcons";
import glueImageURL from "utils/glueImageURL";
import { parseImage } from "../utils/parseImage";

import { GlueFileSimple } from "components/MessageElements/utils";
import useFileById from "../hooks/useFileById";

type ImageModalProps = {
  children: ReactNode;
  file: GlueFileSimple;
  mentionUrl: string;
};

const ImageModal = ({
  children,
  file,
  mentionUrl,
  ...props
}: ImageModalProps) => {
  const glueFile = useFileById(file.id);

  if (!glueFile) return null;

  const {
    height,
    imageAlt,
    imageSrc,
    imageResize,
    thumbnailHeight,
    thumbnailResize,
    thumbnailWidth,
    width,
  } = parseImage(glueFile);

  return (
    <PhotoSwipeGallery
      id="ImageModal"
      options={{ ...defaultPhotoSwipeGalleryOptions }}
    >
      <PhotoSwipeItem
        key={file.id}
        fullSizeURL={glueImageURL(imageSrc, imageResize, 2)}
        height={height}
        id={file.id}
        originalURL={imageSrc}
        thumbnail={glueImageURL(imageSrc, thumbnailResize, 2)}
        title={imageAlt}
        width={width}
      >
        {({ bindLongPress, open, ref }) => {
          return (
            <span className="cursor-pointer" onClick={open}>
              <MentionIcons url={mentionUrl}>
                {children}
                <span className="hidden" {...bindLongPress} {...props}>
                  {imageSrc ? (
                    <picture className="absolute inset-0">
                      <source
                        srcSet={`${glueImageURL(
                          imageSrc,
                          thumbnailResize
                        )} 1x, ${glueImageURL(
                          imageSrc,
                          thumbnailResize,
                          2
                        )} 2x`}
                      />
                      <img
                        alt={imageAlt || ""}
                        className="object-cover opacity-0 transition-opacity duration-300 w-full h-full"
                        height={thumbnailHeight}
                        loading="lazy"
                        ref={ref}
                        src={glueImageURL(imageSrc, thumbnailResize, 0)}
                        width={thumbnailWidth}
                        onLoad={({ currentTarget }) =>
                          currentTarget.classList.remove("opacity-0")
                        }
                      />
                    </picture>
                  ) : null}
                </span>
              </MentionIcons>
            </span>
          );
        }}
      </PhotoSwipeItem>
    </PhotoSwipeGallery>
  );
};

export default ImageModal;

import { Recipient } from "@utility-types";

import useAuthData from "./useAuthData";

type IsExternalRecipientProps = Pick<
  Recipient,
  "id" | "workspaceIDs" | "commonWorkspaceIDs"
>;

const useValidateRecipientOrigin = () => {
  const { authData } = useAuthData();

  function containsExternalRecipients(recipients: Recipient[]): boolean;

  function containsExternalRecipients(
    recipients: Recipient[],
    returnExternal: boolean
  ): { externalRecipients: Recipient[]; value: boolean };

  function containsExternalRecipients(
    recipients: Recipient[],
    returnExternal?: boolean
  ): { externalRecipients?: Recipient[]; value: boolean } | boolean {
    if (returnExternal) {
      const temp = recipients.filter(r => isExternalRecipient(r));
      return { externalRecipients: temp, value: temp.length !== 0 };
    }

    return recipients.some(r => isExternalRecipient(r));
  }

  const isExternalRecipient = ({
    commonWorkspaceIDs,
    id,
    workspaceIDs,
  }: IsExternalRecipientProps): boolean => {
    if (!authData?.me.workspaceIDs.length) return false;

    const allWorkspaceIDs = [
      ...(id ? [id] : []),
      ...(workspaceIDs || []),
      ...(commonWorkspaceIDs || []),
    ];

    return !authData.me.workspaceIDs.find(id => allWorkspaceIDs.includes(id));
  };

  return { containsExternalRecipients, isExternalRecipient };
};

export default useValidateRecipientOrigin;

import {
  IdentifierSchemaAttributes,
  SchemaAttributesObject,
} from "@remirror/core";

import { Styles as MessageElementsStyles } from "components/MessageElements";

const overrideDOM = (value: string): SchemaAttributesObject => ({
  default: value,
  parseDOM: () => null,
});

/**
 * Get array with TW util classes added to our editor nodes
 *
 * Tw util classes are shared with MessageElements.
 *
 */
export default function useExtraAttributes(): IdentifierSchemaAttributes[] {
  return [
    {
      attributes: { class: overrideDOM(MessageElementsStyles.blockquote) },
      identifiers: ["blockquote"],
    },
    {
      attributes: { class: overrideDOM(MessageElementsStyles.strong) },
      identifiers: ["bold"],
    },
    {
      attributes: { class: overrideDOM(MessageElementsStyles.unorderedList) },
      identifiers: ["bulletList"],
    },
    {
      attributes: { class: overrideDOM(MessageElementsStyles.code) },
      identifiers: ["code"],
    },
    {
      attributes: {
        class: overrideDOM(`${MessageElementsStyles.codeBlock} py-25`),
      },
      identifiers: ["codeBlock"],
    },
    {
      attributes: { class: overrideDOM(MessageElementsStyles.horizontalRule) },
      identifiers: ["horizontalRule"],
    },
    {
      attributes: { class: overrideDOM(MessageElementsStyles.hyperLink) },
      identifiers: ["link"],
    },
    {
      attributes: { class: overrideDOM(MessageElementsStyles.listItem) },
      identifiers: ["listItem"],
    },
    {
      attributes: { class: overrideDOM(MessageElementsStyles.orderedList) },
      identifiers: ["orderedList"],
    },
    {
      attributes: { class: overrideDOM(MessageElementsStyles.paragraph) },
      identifiers: ["paragraph"],
    },
  ];
}

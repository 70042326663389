import { devtools } from "zustand/middleware";

import { UseBoundStoreOmitSetState } from "@utility-types/zustand";
import { LayerStackPriority } from "providers/LayerProvider";

import { glueCreateZustand } from "./helper/glueCreate";

const STORE_NAME = "LayerStackStore";

type Layer = {
  layerID: string;
  zIndex: LayerStackPriority;
};

type LayerStackStore = {
  activeLayerId: string;
  addLayer: ({ layerID, zIndex }: Layer) => void;
  layers: Map<string, Layer>;
  removeLayer: (layerID: string) => void;
  updateActiveLayerId: (input: LayerStackStore["layers"]) => string;
};

const useLayerStackStore: UseBoundStoreOmitSetState<LayerStackStore> =
  glueCreateZustand<LayerStackStore>({ name: STORE_NAME })(
    devtools(
      set => ({
        activeLayerId: "",

        addLayer: ({ layerID, zIndex }) =>
          set(state => {
            const nextLayers = new Map(state.layers);

            nextLayers.set(layerID, { layerID, zIndex });

            return {
              activeLayerId: state.updateActiveLayerId(nextLayers),
              layers: nextLayers,
            };
          }),

        layers: new Map(),

        removeLayer: layerID =>
          set(state => {
            const nextLayers = new Map(state.layers);

            nextLayers.delete(layerID);

            return {
              activeLayerId: state.updateActiveLayerId(nextLayers),
              layers: nextLayers,
            };
          }),

        updateActiveLayerId: input => {
          let activeLayerId = "";
          let currentIndex = 0;

          const temp = [...input.values()];
          activeLayerId = temp[0]?.layerID || "";

          temp.forEach(({ layerID, zIndex }) => {
            if (zIndex <= currentIndex) return;

            activeLayerId = layerID;
            currentIndex = zIndex;
          });

          // useful for testing
          // const enumKey = LayerStackPriority[currentIndex];
          // console.info("Active layer is:", enumKey, activeLayerId);

          return activeLayerId;
        },
      }),
      {
        name: STORE_NAME,
        serialize: true,
      }
    )
  );

export default useLayerStackStore;

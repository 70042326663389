import { memo } from "react";

import { HorizontalScrollingList } from "components/HorizontalScrollingList";
import { EmojiPicker } from "../../EmojiPicker";
import { FileAttachButton } from "../../FileUploader";
import { MentionButton } from "../MentionButton";
import { ThreadActions } from "../ThreadActions";

type Props = {
  onAttachFiles: (newFiles: File[]) => void;
  readOnly: boolean;
  showThreadActions: boolean;
};

const ButtonBar = ({
  onAttachFiles,
  readOnly,
  showThreadActions,
}: Props): JSX.Element => {
  const buttons = [
    <EmojiPicker key="emoji-picker" readOnly={readOnly} />,
    <MentionButton key="mention-button" readOnly={readOnly} />,
    <FileAttachButton
      key="file-attach-button"
      onAttachFiles={onAttachFiles}
      readOnly={readOnly}
    />,
  ];

  if (showThreadActions) {
    buttons.push(<ThreadActions key="thread-actions" readOnly={readOnly} />);
  }

  return (
    <HorizontalScrollingList
      itemWidth={28}
      scrollSnapType="none"
      showTail={false}
    >
      {buttons}
    </HorizontalScrollingList>
  );
};

ButtonBar.displayName = "ButtonBar";

/**
 * Memoized control button bar
 *
 * Emoji Picker Button
 *
 * File Attach Button
 *
 * Send/Save Button
 */
export default memo(
  ButtonBar,
  (prev, next) => prev.onAttachFiles === next.onAttachFiles
);

import tw from "utils/tw";

const Pill = <T extends string>({
  name,
  count,
  onClick,
  selected,
}: {
  name: T;
  count?: number;
  onClick: (pillName: T) => void;
  selected?: boolean;
}) => (
  <li
    className={tw(
      "px-12 py-5 rounded-half text-nowrap",
      "text-footnote border-1 select-none",
      selected
        ? "bg-background-action border-transparent text-text-action-inverse"
        : "cursor-pointer bg-background-body border-border-container text-text-secondary hover:bg-background-list-hover"
    )}
    onClick={() => (!selected ? onClick(name) : null)}
  >
    {name}
    {!!count && (
      <span
        className={tw("ml-4 text-text-subtle", {
          "!text-text-action-inverse": selected,
        })}
      >
        {count > 999 ? "1K+" : count}
      </span>
    )}
  </li>
);

export default Pill;

import { BlockComponentProps } from "./BlockComponent";
import { DividerBlock } from "./blockTypes";

type BlockDividerProps = BlockComponentProps & {
  block: DividerBlock;
};

const BlockDivider = (_props: BlockDividerProps) => {
  return <hr className="border-t-border-container" />;
};

export default BlockDivider;

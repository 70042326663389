import { Button } from "components/design-system/Button";
import { DropdownActionButton } from "components/design-system/FloatingUi/DropdownActionButton";
import { DropdownActionButtonGroup } from "components/design-system/FloatingUi/DropdownActionButtonGroup";
import { Icon } from "components/design-system/icons";
import { useSnackbar } from "providers/SnackbarProvider";
import useModalStore from "store/useModalStore";
import ThreadReminderCustomModal from "./ThreadReminderCustomModal";

enum ReminderOptions {
  IN_1_HOUR = "In 1 hour",
  IN_3_HOURS = "In 3 hours",
  TOMORROW = "Tomorrow at 9:00 AM",
  NEXT_WEEK = "Monday at 9:00 AM",
  CUSTOM = "Custom...",
}

const reminderOptions = Object.values(ReminderOptions);

const ThreadReminderMenu = ({
  setParentDropdownOpen,
}: { setParentDropdownOpen?: (open: boolean) => void }) => {
  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));
  const { openSnackbar, closeSnackbar } = useSnackbar();

  const handleSelectReminder = (reminder: ReminderOptions) => {
    setParentDropdownOpen?.(false);
    if (reminder === ReminderOptions.CUSTOM) {
      return openModal(<ThreadReminderCustomModal />);
    }
    openSnackbar(
      "info",
      <div className="flex items-center text-text-action-inverse">
        <Icon icon="ClockAlarm" className="mr-8" size={24} />
        Glue will remind you {reminder.toLowerCase()}
        <Button
          className="ml-8"
          buttonStyle="action-inverse"
          buttonType="text"
          onClick={closeSnackbar}
        >
          Undo
        </Button>
      </div>,
      5000,
      "!bg-background-list-done",
      true
    );
  };

  return (
    <>
      <DropdownActionButtonGroup>
        <span className="flex items-center text-footnote-bold text-text-subtle h-32 px-16">
          Remind me
        </span>
        {reminderOptions.map(option => (
          <DropdownActionButton
            key={option}
            onClick={() => handleSelectReminder(option)}
          >
            {option}
          </DropdownActionButton>
        ))}
      </DropdownActionButtonGroup>
      <DropdownActionButtonGroup>
        <DropdownActionButton disabled>Remove reminder</DropdownActionButton>
      </DropdownActionButtonGroup>
    </>
  );
};

export default ThreadReminderMenu;

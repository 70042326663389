import emojiRegex from "emoji-regex";

const emojiRe = emojiRegex();

export const formatNameEmoji = (item?: {
  name?: string;
}): { emoji?: string; name: string; nameWithEmoji: string } => {
  let name = item?.name || "";
  const emojiMatch = Array.from(name.matchAll(emojiRe) || [])[0];
  const emoji = emojiMatch?.index === 0 ? emojiMatch[0] : undefined;

  if (emoji) {
    name = name.replace(emojiRe, "").trimStart();
  }

  return {
    emoji,
    name,
    nameWithEmoji: emoji ? `${emoji} ${name}` : name,
  };
};

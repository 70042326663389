import { FieldValues, useFormContext } from "react-hook-form";

import tw from "utils/tw";

import Error from "./Error";
import { Label } from "./Label";
import useRequiredErrors from "./hooks/useRequiredErrors";
import { TextAreaProps } from "./types";

export const TextArea = <TFieldValues extends FieldValues>({
  className,
  config,
  label,
  labelClassName,
  name,
  wrapperClassName,
  ...props
}: TextAreaProps<TFieldValues>) => {
  const {
    formState: { errors, isSubmitting },
    register,
  } = useFormContext<TFieldValues>();

  const disabled = props.disabled || isSubmitting;

  useRequiredErrors({ name });

  return (
    <div className={tw("my-20", wrapperClassName)}>
      {label && (
        <Label className={labelClassName} htmlFor={name}>
          {label}
        </Label>
      )}
      <textarea
        className={tw(
          "min-h-55 box-border p-10 w-full disabled:bg-background-ghost rounded border border-background-subtle focus:outline-none",
          {
            "border-accent-alert": !!errors[name],
            "cursor-default": disabled,
          },
          className
        )}
        id={name}
        {...props}
        disabled={disabled}
        {...register(name, config)}
      />

      <Error name={name} />
    </div>
  );
};

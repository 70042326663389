import { createPortal } from "react-dom";

import { usePortal } from "./usePortal";

export const Portal = ({
  attachment,
  children,
  element,
  id,
  zIndex,
}: {
  attachment?: (root: Element, element: Element) => void;
  children: React.ReactNode;
  element?: string;
  id?: string;
  zIndex?: string;
}) => {
  const target = usePortal({ attachment, element, id, zIndex });
  return createPortal(children, target);
};

import { animated, useTransition } from "@react-spring/web";

import useModalStore from "store/useModalStore";

import { modalOpacityTransitions } from "./ModalRenderer";
import type { ModalElement } from "./types";

type Props = {
  modalId?: ModalElement["id"];
};

export const Veil = ({ modalId }: Props) => {
  const { backdropId, topModalId } = useModalStore(
    ({ backdropId, topModalId }) => ({
      backdropId,
      topModalId,
    })
  );

  const transitions = useTransition(
    backdropId !== modalId && topModalId !== modalId,
    modalOpacityTransitions
  );

  return transitions(
    (styles, open) =>
      open && (
        <animated.div
          className="absolute inset-0 select-none z-100 bg-background-black/25 dark:bg-background-black/50"
          data-testid="modal-veil"
          style={{
            ...styles,
          }}
        />
      )
  );
};

import {
  DownloadAppBanner,
  MobileReminder,
} from "components/SideBar/DownloadAppQr";
import Sidebar from "components/SideBar/Sidebar";
import ComposeButton from "components/SideBar/SidebarHeader/ComposeButton";
import SidebarHeader from "components/SideBar/SidebarHeader/SidebarHeader";
import ViewScrollContainer from "components/design-system/ui/ViewScrollContainer";

import { routeToView } from "components/routing/utils";
import useGroupRecipients from "hooks/useGroupRecipients";
import { useHistory } from "react-router";
import { INBOX_SIDEBAR_ID } from "./InboxMain";
import InboxSidebarSections from "./InboxSidebarSections";
import { MoreUnreadButtons } from "./MoreUnreadButton";

const InboxSidebarDesktop = () => {
  const groupRecipients = useGroupRecipients();
  const history = useHistory();

  return (
    <Sidebar id={INBOX_SIDEBAR_ID}>
      <SidebarHeader
        buttons={
          <>
            <MobileReminder />
            <ComposeButton
              onClick={() =>
                history.push(routeToView({ view: "compose" }), {
                  recipients: groupRecipients,
                })
              }
            />
          </>
        }
        title="Inbox"
      />

      <ViewScrollContainer
        accessories={<MoreUnreadButtons />}
        className="px-8 pt-8"
      >
        <InboxSidebarSections />
      </ViewScrollContainer>

      <DownloadAppBanner />
    </Sidebar>
  );
};

export default InboxSidebarDesktop;

import tw from "utils/tw";

type Props = {
  className?: string;
  count?: number;
  important?: boolean;
};

const UnreadBadge = ({ className = "", count, important = false }: Props) => {
  if (!count) return null;
  return (
    <div
      className={tw(
        "min-w-[20px] py-2 px-6 rounded-half",
        "font-semibold text-[11px] leading-3",
        "bg-background-alert-subtle text-text-action-inverse",
        {
          "!bg-background-alert-strong !border-background-alert-strong !text-text-action-inverse":
            important,
        },
        className
      )}
    >
      <span className="flex items-center justify-center min-w-[7px]">
        {count > 99 ? "99+" : count}
      </span>
    </div>
  );
};

export default UnreadBadge;

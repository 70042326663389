import { useEffect, useRef } from "react";

import { useAbortController } from "use-abort-controller-hook";

import ProgressModal from "components/Modals/ProgressModal";
import { useLongPress } from "hooks/useLongPress";
import useModalStore from "store/useModalStore";
import usePhotoSwipeStore from "store/usePhotoSwipeStore";
import useProgressStore from "store/useProgressStore";
import shareFileNativeMobile from "utils/shareFileNativeMobile";

const PhotoSwipeFullItem = () => {
  const abortController = useAbortController();
  const firstRenderRef = useRef(true);

  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const { currentImageOriginalURL } = usePhotoSwipeStore(
    ({ currentImageOriginalURL }) => ({ currentImageOriginalURL })
  );

  const bindLongPress = useLongPress<HTMLImageElement>(
    event => {
      if (!event) return;

      const showProgress = () => {
        openModal(
          <ProgressModal
            autoCloseDelay={0}
            header={"Downloading image"}
            onCancel={abortController.abort}
          />
        );
      };

      const target = event.target as HTMLImageElement;
      const name = target
        .closest(".pswp__scroll-wrap")
        ?.querySelector(".pswp__caption__center")?.textContent;
      const url = currentImageOriginalURL;

      if (!url || !name) return;

      shareFileNativeMobile({
        abortSignal: abortController.signal,
        dialogTitle: "Share image",
        fileName: name,
        onError: () => {
          useProgressStore.setState({
            message: {
              text: "Unknown error while sharing image.",
              type: "error",
            },
          });
        },
        showProgress,
        url,
      });
    },
    { cancelOnMovement: 10 }
  ).handlers;

  useEffect(() => {
    if (!firstRenderRef.current) return;
    firstRenderRef.current = false;
    const firstRender = firstRenderRef.current;

    return () => {
      !firstRender && abortController.abort();
    };
  }, [abortController]);

  return <div {...bindLongPress} className="pswp__item" />;
};

export default PhotoSwipeFullItem;

import tw from "utils/tw";

type Props = {
  badge?: JSX.Element;
  badgeClassName?: string;
  className?: string;
};

const AvatarWithBadge = ({
  badge,
  badgeClassName,
  children,
  className,
}: WithChildren<Props>) => (
  <div className={tw("relative", className)}>
    {children}
    {badge && (
      <div className={tw("absolute -bottom-5 right-5", badgeClassName)}>
        {badge}
      </div>
    )}
  </div>
);

export default AvatarWithBadge;

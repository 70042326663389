import { ExtensionPriority } from "remirror";
import { BulletListExtension } from "remirror/extensions";

import GlueListItemExtension from "./GlueListItemExtension";

export default class GlueBulletListExtension extends BulletListExtension {
  createExtensions() {
    return [
      new GlueListItemExtension({
        enableCollapsible: this.options.enableSpine,
        enterKeyBehavior: this.options.enterKeyBehavior,
        priority: ExtensionPriority.High,
      }),
    ];
  }
}

import { Tooltip } from "components/design-system/FloatingUi";

import { VerifiedBadge } from "components/Icons";
import { ProfileItemProps } from "./types";

type Props = Pick<ProfileItemProps, "domains" | "type">;

const VerifiedDomains = ({ domains, type }: Props): JSX.Element => {
  let title = "";

  switch (type) {
    case "User":
      title = `This user has verified membership in the ${domains?.join(
        ", "
      )} domain${!!domains && domains?.length > 1 ? "s" : ""}.`;
      break;
    case "Group":
    case "GroupPreview":
      title = `This group is in a workspace that manages the ${domains?.join(
        ", "
      )} domain${!!domains && domains?.length > 1 ? "s" : ""}.`;
      break;
    case "Workspace":
    case "WorkspacePreview":
      title = `This workspace manages the ${domains?.join(", ")} domain${
        !!domains && domains?.length > 1 ? "s" : ""
      }.`;
      break;
  }

  return (
    <div
      className="h-18 inline-block whitespace-nowrap"
      data-testid="description-domains"
    >
      <div className="flex justify-start">
        <Tooltip content={title}>
          <span data-testid="verified-badge" className="shrink-0">
            <VerifiedBadge className={"inline-block w-10 h-10 mb-1 mr-4"} />
          </span>
        </Tooltip>

        <span className="truncate">{domains?.join(", ")}</span>
      </div>
    </div>
  );
};

export default VerifiedDomains;

import { AppConfigureMethod } from "generated/graphql";

export const workspaceAppEdge = {
  __typename: "WorkspaceAppConnection" as const,
  edges: [
    {
      __typename: "WorkspaceAppEdge" as const,
      appUser: {
        __typename: "User" as const,
        addressDomains: [],
        avatarURL: null,
        bio: null,
        id: "2",
        name: "Test (App)",
        workspaceIDs: [""],
      },
      creator: {
        __typename: "User" as const,
        addressDomains: ["gluegroups.com"],
        avatarURL: "",
        bio: "",
        id: "usr_1",
        name: "Test User",
        workspaceIDs: [""],
      },
      id: "wks_1-app_1",
      node: {
        __typename: "App" as const,
        avatarURL: "",
        configureGroup: AppConfigureMethod.Add,
        configureThread: AppConfigureMethod.Configure,
        configureWorkspace: AppConfigureMethod.Add,
        configureGroupPath: null,
        configureThreadPath: null,
        configureWorkspacePath: null,
        description: "",
        docsURL: "",
        homeURL: "",
        instructions: "",
        id: "app_1",
        name: "Test",
        publishedAt: "2023-01-24T19:26:26Z",
        threadActions: [
          {
            __typename: "ThreadAction" as const,
            description: "",
            label: "",
            name: "",
            path: null,
          },
        ],
        workspace: {
          __typename: "WorkspacePreview" as const,
          id: "wks_1",
          name: "Test",
        },
      },
      webhooks: [
        {
          __typename: "WorkspaceAppWebhook" as const,
          id: "wbh_1",
          url: "",
        },
      ],
      workspaceID: "",
    },
  ],
};

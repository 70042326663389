import { Recipient } from "@utility-types";
import { Button } from "components/design-system/Button";

import { RecipientListItem } from ".";

type Props = {
  items?: Recipient[];
  onDeleteItem: (id?: string) => void;
};

const RecipientsList = ({ items = [], onDeleteItem }: Props): JSX.Element => (
  <ul className="flex flex-col space-y-10">
    {items?.map(item => (
      <li key={item.id} className="flex flex-row justify-between w-full">
        <RecipientListItem item={item} />
        <Button
          buttonStyle="simpleSecondary"
          buttonType="text"
          icon="Close"
          iconSize={24}
          onClick={() => onDeleteItem(item.id)}
        />
      </li>
    ))}
  </ul>
);

export default RecipientsList;

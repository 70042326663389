import { CSSProperties } from "react";

import { TransitionValues, animated } from "@react-spring/web";

import usePointerClick from "hooks/usePointerClick";
import tw from "utils/tw";

import type { ModalElement } from "./types";

type Props = {
  modalId?: ModalElement["id"]; // added by ModalProvider
  onClick?: (event: React.PointerEvent) => void;
  styles: TransitionValues<CSSProperties>;
} & Partial<HTMLDivElement>;

export const Backdrop = ({ className, onClick, styles }: Props) => {
  const pointerClick = usePointerClick<HTMLDivElement>(onClick);

  return (
    <animated.div
      className={tw(
        "overflow-hidden fixed inset-0 select-none",
        "bg-background-black/25 dark:bg-background-black/50",
        className
      )}
      data-testid="modal-backdrop"
      style={{
        ...styles,
      }}
      {...pointerClick}
    />
  );
};

import { Workspace } from "@utility-types";
import { Header, Main } from "components/ModalKit";
import { ModalProps } from "components/ModalKit/Modal";
import { StandardModal } from "components/Modals/StandardModal";
import Paywall from "components/Payment/Paywall";

type Props = {
  workspace: Pick<Workspace, "id" | "name" | "avatarURL">;
  onComplete: () => void;
};

const PaywallModal = ({
  workspace,
  onComplete,
  ...props
}: Props & ModalProps) => (
  <StandardModal contentClassName="md:max-w-[90vw]" {...props}>
    <Header variant="bordered">Start trial</Header>
    <Main className="!overflow-hidden">
      <Paywall
        containerClassName="max-h-[80vh]"
        onComplete={onComplete}
        workspace={workspace}
      />
    </Main>
  </StandardModal>
);

export default PaywallModal;

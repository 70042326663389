import { nodeIs } from "@utility-types";
import {
  AppConfigureMethod,
  AppFieldsFragment,
  FetchGroupOrPreviewEdgeDocument,
  GroupFieldsFragment,
  GroupPreviewFieldsFragment,
  MemberRole,
  useAddGroupMembersMutation,
  useRemoveGroupMembersMutation,
} from "generated/graphql";
import useRefetchOnAppActive from "hooks/useRefetchOnAppActive";

import { useConfigureApps } from "components/apps";

const useGroupApps = (
  group?: GroupFieldsFragment | GroupPreviewFieldsFragment,
  persistentChatID?: string
) => {
  const [addGroupMembers] = useAddGroupMembersMutation();
  const [removeGroupMembers] = useRemoveGroupMembersMutation();

  useRefetchOnAppActive([FetchGroupOrPreviewEdgeDocument]);

  const isPreview = nodeIs(group, ["GroupPreview"]);

  const { configureApp, editApp, getAppsAvailable } = useConfigureApps();

  const appsAvailable = getAppsAvailable(
    !isPreview ? group?.apps.edges || [] : []
  )?.filter(
    app => app.node.configureGroup && app.workspaceID === group?.workspaceID
  );

  const addApp = (app: AppFieldsFragment) => {
    if (!group || !persistentChatID || !group.workspaceID) return;
    const workspaceApp = appsAvailable?.find(a => a.node.id === app.id);
    if (!workspaceApp) return;

    if (app.configureGroup === AppConfigureMethod.Add) {
      addGroupMembers({
        variables: {
          id: group.id,
          members: [
            { member: workspaceApp.appUser.id, role: MemberRole.Default },
          ],
        },
      });
      return;
    }
    configureApp({
      app,
      closeModalProp: false,
      configurableID: group.id,
      workspaceID: group.workspaceID,
    });
  };

  const removeApp = (appID: string) => {
    if (!group || isPreview) return Promise.resolve();
    const workspaceApp = group.apps.edges?.find(a => a.node.id === appID);
    if (!workspaceApp) return Promise.resolve();
    return removeGroupMembers({
      variables: { id: group.id, memberIDs: workspaceApp.appUser.id },
    });
  };

  return {
    addApp,
    appsAvailable,
    editApp,
    removeApp,
  };
};

export default useGroupApps;

import { Skeleton } from "components/Skeleton";

const LoadingIndicator = (): JSX.Element => (
  <div data-testid="uploading">
    <Skeleton
      className="overflow-hidden absolute top-0 m-0 w-full h-80 rounded opacity-80"
      flex={true}
    />
  </div>
);

export default LoadingIndicator;

import emojiRegex from "emoji-regex";
import deepequal from "react-fast-compare";
import type { MessageContextValue, StreamMessage } from "stream-chat-react";
import type { DefaultMessageType } from "stream-chat-react/dist/types/types";
import type { Mute } from "stream-chat/dist/types/types";

import type { GlueDefaultStreamChatGenerics } from "@utility-types";
import { RenderMarkdown } from "md";
import { parseMarkdown, removeSubjectMarkdown } from "md/util";

export type StreamGlueMessageType = DefaultMessageType & {
  isUnreadMark?: boolean;
};

export const areMessagePropsEqual = <T extends GlueDefaultStreamChatGenerics>(
  prevProps: MessageContextValue<T> & {
    mutes?: Mute<T>[];
    showDetailedReactions?: boolean;
  },
  nextProps: MessageContextValue<T> & {
    mutes?: Mute<T>[];
    showDetailedReactions?: boolean;
  }
): boolean => {
  const { lastReceivedId: prevLastReceivedId, message: prevMessage } =
    prevProps;
  const { lastReceivedId: nextLastReceivedId, message: nextMessage } =
    nextProps;

  if (nextProps.editing !== prevProps.editing) {
    return false;
  }

  if (nextProps.showDetailedReactions !== prevProps.showDetailedReactions) {
    return false;
  }

  if (!messagesAreEqual(prevMessage, nextMessage)) {
    return false;
  }

  if (
    (prevMessage.id === prevLastReceivedId ||
      prevMessage.id === nextLastReceivedId) &&
    prevLastReceivedId !== nextLastReceivedId
  ) {
    return false;
  }

  const deepEqualProps =
    deepequal(nextProps.readBy, prevProps.readBy) &&
    deepequal(nextProps.groupStyles, prevProps.groupStyles) && // last 3 messages can have different group styles
    deepequal(nextProps.mutes, prevProps.mutes) &&
    deepequal(nextProps.lastReceivedId, prevProps.lastReceivedId);

  return deepEqualProps;
};

export const messagesAreEqual = <T extends GlueDefaultStreamChatGenerics>(
  prevMessage: StreamMessage<T>,
  nextMessage: StreamMessage<T>
) =>
  prevMessage.latest_reactions?.length ===
    nextMessage.latest_reactions?.length &&
  prevMessage.own_reactions?.length === nextMessage.own_reactions?.length &&
  prevMessage.pinned === nextMessage.pinned &&
  prevMessage.reply_count === nextMessage.reply_count &&
  prevMessage.status === nextMessage.status &&
  prevMessage.text === nextMessage.text &&
  prevMessage.type === nextMessage.type &&
  new Date(prevMessage.updated_at || 0).valueOf() ===
    new Date(nextMessage.updated_at || 0).valueOf() &&
  new Date(prevMessage.user?.updated_at || 0).valueOf() ===
    new Date(nextMessage.user?.updated_at || 0).valueOf();

export const isOnlyEmojis = (text?: string): boolean => {
  if (!text) return false;

  const noEmojis = text.replace(emojiRegex(), "");
  const noSpace = noEmojis.replace(/[\s\n]/gm, "");

  return !noSpace;
};

export const renderText = (text?: string) => {
  return renderTextWithOptions(text);
};

export const renderTextWithOptions = (
  text?: string,
  options?: { handleMasonryLink?: (url: string) => void }
): JSX.Element | null => {
  if (text) {
    const markdown = parseMarkdown(removeSubjectMarkdown(text), "<br>");
    return RenderMarkdown(markdown, options);
  }
  return null;
};

export const getReadByTooltipText = (
  users: GlueDefaultStreamChatGenerics["userType"][]
): string => {
  let outStr = "";

  const slicedArr = users.slice(0, 5).map(item => item.name || item.id);
  const restLength = users.length - slicedArr.length;

  if (slicedArr.length === 1) {
    outStr = `${slicedArr[0]} `;
  } else if (slicedArr.length === 2) {
    // joins all with "and" but =no commas
    // example: Alice and Bob
    outStr = `${slicedArr[0]} and ${slicedArr[1]}`;
  } else if (slicedArr.length > 2) {
    // joins all with commas, but last one gets ", and" (oxford comma!)
    // example: "Bob, Joe, Alice and 4 more"
    if (restLength === 0) {
      // mutate slicedArr to remove last user to display it separately
      const lastUser = slicedArr.splice(slicedArr.length - 2, 1);
      outStr = `${slicedArr.join(", ")}, and ${lastUser}`;
    } else {
      outStr = `${slicedArr.join(", ")} and ${restLength} more`;
    }
  }

  return outStr;
};

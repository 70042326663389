import { useHistory } from "react-router-dom";

import { nodeAs } from "@utility-types";
import { useFetchUserEdgeQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";

import { Skeleton } from "components/Skeleton";
import { Icon } from "components/design-system/icons";
import ViewScrollContainer from "components/design-system/ui/ViewScrollContainer";
import {
  currentPathWithoutDrawer,
  routeToUser,
} from "components/routing/utils";
import { ComponentProps } from "react";
import Header from "./Header";
import Main from "./Main";

type Props = { userID: string } & Pick<ComponentProps<typeof Header>, "pane">;

export default function UserProfilePane({ userID, pane }: Props) {
  const { authData, authReady } = useAuthData();
  const history = useHistory();

  const { data: userData } = useFetchUserEdgeQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !authData?.me.id || !userID,
    variables: { id: `${userID}-${authData?.me.id}` },
  });

  const userEdge = nodeAs(userData?.node, ["UserEdge"]);
  const { avatarURL, bio, id, name } = userEdge?.node || {};

  return (
    <>
      <Header
        avatarName={name || ""}
        avatarURL={avatarURL || ""}
        title={name ?? <Skeleton width="80px" height="28px" />}
        onClick={() => history.push(currentPathWithoutDrawer())}
        pane={pane}
      />
      <ViewScrollContainer padding="pb-16">
        <Main
          loading={!userEdge}
          buttonAction={() => {
            id && history.push(routeToUser({ to: "canonical", userID: id }));
          }}
          buttonText={
            <>
              <Icon icon="ChatRounded" className="mr-6" size={20} /> Message
            </>
          }
          description={{ text: bio || undefined, title: "Title" }}
          workspaces={userEdge?.node.workspaceIDs}
        />
      </ViewScrollContainer>
    </>
  );
}

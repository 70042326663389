import { RefObject, useEffect } from "react";

import { Draft } from "@utility-types";
import { MessageEditor } from "components/MessageEditor/types";
import { Action, State } from "../ThreadCompose/DraftReducer";

type Props = {
  draft?: Draft;
  draftLoading: boolean;
  editor: RefObject<MessageEditor>;
  compose: State;
  dispatch: (value: Action) => void;
  draftID?: string;
  onLoadStart?: () => void;
  onLoadEnd?: () => void;
};

const useRestoreDraft = ({
  draft,
  draftLoading,
  editor,
  compose,
  dispatch,
  draftID,
  onLoadStart,
  onLoadEnd,
}: Props) => {
  useEffect(() => {
    if (!editor.current) return;

    // Trigger load
    if (draftLoading && draft?.id !== draftID) {
      editor.current.reset();
      onLoadStart?.();
      dispatch({ type: "reset", pending: "load" });
      return;
    }

    // Draft loaded
    if (
      draft &&
      draft.id === draftID &&
      (draft.id !== compose.draftID || compose.pending === "load")
    ) {
      editor.current.setMessage(draft.message);
      dispatch({ type: "loaded", draft });
      onLoadEnd?.();
    }
  }, [
    compose.draftID,
    compose.pending,
    dispatch,
    draft,
    draftID,
    draftLoading,
    editor,
    onLoadEnd,
    onLoadStart,
  ]);
};

export default useRestoreDraft;

import {
  routePath,
  routeToGroup,
  routeToThread,
  routeToUser,
  useRouteParams,
} from "components/routing/utils";
import useMenuKeyboardShortcuts from "hooks/useMenuKeyboardShortcuts";
import { ComponentProps, useCallback, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import type { SearchResults as SearchResultsType } from "../types";

import { useFlattenedResults } from "../hooks";
import SearchRecents from "./SearchRecents";
import SearchResults, { SelectedSearchResult } from "./SearchResults";

type Props = {
  floatingProps: Record<string, unknown>;
  onSelectResult: () => void;
  searchResults: SearchResultsType;
  searchTerm: string;
};

type DeepSearchShortcut = {
  id: "deep-search-shortcut";
  __typename: "DeepSearchShortcut";
};

const QuickSearchResults = ({
  floatingProps,
  onSelectResult,
  searchResults,
  searchTerm,
  ...props
}: Props & ComponentProps<typeof SearchRecents>) => {
  const selectedResultRef = useRef<HTMLLIElement>(null);
  const { superTab } = useRouteParams();
  const isDisabled = superTab === "search";

  const history = useHistory();

  const navigateResult = useCallback(
    (
      item: SelectedSearchResult | DeepSearchShortcut,
      _i: number,
      e: KeyboardEvent | React.MouseEvent<HTMLDivElement | HTMLLIElement>
    ): void => {
      if (isDisabled) return;

      onSelectResult();

      const to = e.ctrlKey || e.metaKey ? "secondary" : "primary";

      switch (item.__typename) {
        case "DeepSearchShortcut":
          history.push(routePath({ superTab: "search" }));
          break;
        case "Message": {
          history.push(
            routeToThread({
              threadID: item.threadID,
              messageID: item.streamID ?? item.id,
              to,
            })
          );
          break;
        }
        case "Thread":
          history.push(
            routeToThread({
              threadID: item.id,
              to,
            })
          );
          break;
        case "Group":
        case "Workspace":
          history.push(routeToGroup({ groupID: item.id }));
          break;
        case "User":
          history.push(
            routeToUser({
              to: to === "secondary" ? to : "canonical",
              userID: item.id,
            })
          );
          break;
      }
    },
    [history, isDisabled, onSelectResult]
  );

  const orderedResults = useFlattenedResults({ searchResults });
  const combinedResults = useMemo(() => {
    const deepSearchShortcut: DeepSearchShortcut[] = searchTerm
      ? [
          {
            id: "deep-search-shortcut",
            __typename: "DeepSearchShortcut",
          },
        ]
      : [];
    return [...deepSearchShortcut, ...orderedResults];
  }, [searchTerm, orderedResults]);

  const { selectedItem, setSelectedIndex } = useMenuKeyboardShortcuts({
    data: combinedResults,
    onSelectItem: navigateResult,
    preventReset: true,
    selectedItemRef: selectedResultRef,
  });

  const selectedResultID = selectedItem?.id;

  const setSelectedResultID = useCallback(
    (id: string) => {
      const index = combinedResults.findIndex(result => result.id === id);

      setSelectedIndex(index);
    },
    [combinedResults, setSelectedIndex]
  );

  return (
    <div
      className="w-[685px] min-w-[200px] z-1 rounded-lg bg-background-body shadow-level2 mt-4"
      {...floatingProps}
    >
      {searchTerm ? (
        isDisabled ? null : (
          <SearchResults
            className="my-4 rounded-lg"
            onClickResult={navigateResult}
            searchResults={searchResults}
            searchTerm={searchTerm}
            selectedResultID={selectedResultID}
            setSelectedResultID={setSelectedResultID}
            selectedResultRef={selectedResultRef}
            openDeepSearch
          />
        )
      ) : (
        <SearchRecents {...props} />
      )}
    </div>
  );
};

export default QuickSearchResults;

import SidebarHeader from "components/SideBar/SidebarHeader/SidebarHeader";
import { Tabs } from "components/Tabs";
import { usePartitionState } from "components/routing/RoutingPartition";
import {
  ActivityTabs,
  routeParams as getRouteParams,
  locationFromRoute,
  tabPath,
} from "components/routing/utils";
import { useHistory } from "react-router-dom";
import ActivityList from "./ActivityList";

const ActivityMainTabs = ({
  desktopHeader,
  scrollRef,
  title,
}: {
  desktopHeader?: boolean;
  title?: string;
  scrollRef?: (el: HTMLUListElement | null) => void;
}) => {
  const history = useHistory();

  const { route } = usePartitionState(({ route }) => ({ route }));
  const routeParams = getRouteParams(locationFromRoute(route));

  const currentTab = (() => {
    switch (routeParams.t) {
      case ActivityTabs.All:
      case ActivityTabs.Mentions:
      case ActivityTabs.Reactions:
      case ActivityTabs.Groups:
      case ActivityTabs.Requests:
        return routeParams.t;
      default:
        return ActivityTabs.All;
    }
  })();

  const tabs = [
    {
      tab: ActivityTabs.All,
      tabTitle: ActivityTabs.All,
    },
    {
      tab: ActivityTabs.Mentions,
      tabTitle: ActivityTabs.Mentions,
    },
    {
      tab: ActivityTabs.Reactions,
      tabTitle: ActivityTabs.Reactions,
    },
    {
      tab: ActivityTabs.Groups,
      tabTitle: ActivityTabs.Groups,
    },
    /* {
      tab: ActivityTabs.Requests,
      tabTitle: ActivityTabs.Requests,
    }, */
  ];

  return (
    <>
      {desktopHeader ? (
        <SidebarHeader title={title}>
          <Tabs<ActivityTabs>
            buttonClassName="border-b-2 capitalize h-[23px] mr-24 pb-7 px-0 text-sm"
            buttonSelectedClassName="border-accent-primary text-text-primary"
            buttonUnselectedClassName="border-transparent text-text-secondary cursor-pointer"
            className="pl-20"
            onClickTab={tab => history.push(tabPath(tab))}
            selectedTab={currentTab}
            tabs={tabs}
          />
        </SidebarHeader>
      ) : (
        <div className="">
          <Tabs<ActivityTabs>
            buttonClassName="flex items-center capitalize justify-between h-34 mr-8 px-16 rounded-[100px] text-footnote"
            buttonSelectedClassName="bg-background-action border-1 border-transparent text-text-action-inverse"
            buttonUnselectedClassName="border-1 border-border-container cursor-pointer text-text-secondary"
            className="mt-16 mb-8 pl-20"
            onClickTab={tab => history.push(tabPath(tab))}
            selectedTab={currentTab}
            tabs={tabs}
          />
        </div>
      )}
      <ActivityList scrollRef={scrollRef} feedType={currentTab} />
    </>
  );
};

export default ActivityMainTabs;

import BlockComponent, { BlockComponentProps } from "./BlockComponent";
import BlockMarkdown from "./BlockMarkdown";
import BlockPlainText from "./BlockPlainText";
import { ContextBlock } from "./blockTypes";

type BlockContextProps = BlockComponentProps & {
  block: ContextBlock;
};

const BlockContext = ({ block, handlers }: BlockContextProps) => {
  return (
    <div className="flex items-center py-8 rounded-md text-sm text-text-subtle">
      {block.elements?.map((child, i) => {
        if (child.type === "image") {
          return (
            <BlockComponent
              key={child.key}
              block={child}
              handlers={handlers}
              parentType={block.type}
            />
          );
        }
        if (child.type === "markdown") {
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          return <BlockMarkdown key={i} text={child} handlers={handlers} />;
        }
        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
        return <BlockPlainText key={i} text={child} />;
      })}
    </div>
  );
};

export default BlockContext;

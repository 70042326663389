import { FormEvent, useMemo, useRef, useState } from "react";

import { Recipient, ThreadSimple, nodeAs } from "@utility-types";
import { Icon } from "components/design-system/icons";
import {
  ThreadsMailbox,
  ThreadsOrder,
  useFetchGroupsQuery,
  useFetchUsersQuery,
  useThreadListQuery,
} from "generated/graphql";
import { formatGroupName } from "utils/group/formatGroupName";
import env from "utils/processEnv";
import tw from "utils/tw";
import { Suggestion, pickSuggestions } from "./PromptSuggestions";

type ChosenPrompt = {
  suggestion: Suggestion;
  text: string;
  files: File[];
};

const supportedImageExt = ["png", "webp", "jpg", "jpeg", "tiff", "bmp", "heic"];

const supportedFileExt = [
  "eml",
  "html",
  "json",
  "md",
  "msg",
  "rst",
  "rtf",
  "txt",
  "xml",
  "csv",
  "doc",
  "docx",
  "epub",
  "odt",
  "pdf",
  "ppt",
  "pptx",
  "tsv",
  "xlsx",
];

const fileAccept = supportedFileExt.map(ext => `.${ext}`).join(",");
const imageAccept = supportedImageExt.map(ext => `.${ext}`).join(",");

const formattedName = (recipient: Recipient | ThreadSimple) =>
  recipient?.__typename === "Thread"
    ? recipient.subject
    : formatGroupName(recipient).nameWithEmoji;

const promptText = (
  { title, subhead }: Suggestion,
  recipient?: Recipient | ThreadSimple
) =>
  `${title} ${
    recipient
      ? subhead.replace(
          "@mention",
          `[${formattedName(recipient)}](glue:${recipient.id})`
        )
      : subhead
  }`;

const Prompt = ({
  onChoose,
  upload,
  group,
  user,
  thread,
  ...suggestion
}: Suggestion & {
  group: Recipient | undefined;
  user: Recipient | undefined;
  thread: ThreadSimple | undefined;
  onChoose: (prompt: ChosenPrompt) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { title, subhead, icon, mention } = suggestion;

  const recipient = mention
    ? mention === "group"
      ? group
      : mention === "user"
        ? user
        : thread
    : undefined;

  const formattedSubhead = recipient
    ? subhead.replace("@mention", `${formattedName(recipient)}`)
    : subhead;

  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    if (!event.currentTarget.files?.length) return;
    const files = [...event.currentTarget.files];
    onChoose({ suggestion, text: title, files });
    event.currentTarget.value = "";
  };

  const onClickPrompt = () => {
    upload
      ? inputRef.current?.click()
      : onChoose({
          suggestion,
          text: promptText(suggestion, recipient),
          files: [],
        });
  };

  if (mention && !recipient) return null;

  return (
    <button
      className={tw(
        "flex min-w-[208px] max-w-300 h-56 shrink-0 px-12 py-8 mr-12 bg-background-subtle hover:bg-background-subtle-hover rounded-md"
      )}
      onClick={onClickPrompt}
    >
      {upload && (
        <input
          ref={inputRef}
          accept={upload === "image" ? imageAccept : fileAccept}
          className="overflow-hidden absolute -z-1 w-0 h-0 opacity-0"
          multiple={true}
          onChange={onInputChange}
          tabIndex={-1}
          type="file"
        />
      )}
      <div className="flex flex-col justify-center max-w-full">
        <div className="flex text-subhead-bold">
          <span className="truncate">{title}</span>
        </div>
        <div className="flex items-center text-subhead text-text-subtle">
          {icon && <Icon className="mr-4" icon={icon} size={20} />}

          <span className="truncate">{formattedSubhead}</span>
        </div>
      </div>
    </button>
  );
};

const ThreadPromptSuggestions = ({
  onChoose,
}: {
  onChoose: (chosen: ChosenPrompt) => void;
}) => {
  const [chosen, setChosen] = useState<ChosenPrompt>();
  const suggestions = useMemo(() => pickSuggestions(), []);

  const { data: groupsData } = useFetchGroupsQuery({
    fetchPolicy: "cache-first",
  });
  const { data: usersData } = useFetchUsersQuery({
    fetchPolicy: "cache-first",
  });
  const { data: threadsData } = useThreadListQuery({
    fetchPolicy: "cache-first",
    variables: {
      mailbox: ThreadsMailbox.All,
      order: ThreadsOrder.LastMessage,
    },
  });

  const group = nodeAs(groupsData?.groups.edges[0]?.node, ["Group"]);
  const user = nodeAs(
    usersData?.users.edges.filter(e => e.node.id !== env.glueAIBotID)[0]?.node,
    ["User"]
  );
  const thread = nodeAs(threadsData?.threads.edges[0]?.node, ["Thread"]);

  const handleChoose = (chosen: ChosenPrompt) => {
    setChosen(chosen);
    onChoose(chosen);
  };

  if (chosen) {
    return null;
  }

  return (
    <div
      className={tw(
        "flex mx-10 pb-20 animate-fadeIn overflow-x-auto",
        "after:content-[''] after:sticky after:z-1 after:right-0 after:h-56 after:px-16",
        "after:bg-[linear-gradient(to_left,rgb(var(--color-background-body)),transparent_90%)] after:pointer-events-none"
      )}
    >
      {suggestions.map(suggestion => (
        <Prompt
          key={suggestion.title}
          {...suggestion}
          group={group}
          user={user}
          thread={thread}
          onChoose={handleChoose}
        />
      ))}
    </div>
  );
};

export default ThreadPromptSuggestions;

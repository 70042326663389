import { ComponentProps, useEffect, useState } from "react";

import { useApolloClient } from "@apollo/client";

import { WorkspaceAppEdge } from "@utility-types";
import { useThreadViewState } from "components/thread/ThreadView/provider/ThreadViewProvider";
import { WorkspacesAppsDocument, WorkspacesAppsQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useComponentMounted from "hooks/useComponentMounted";
import env from "utils/processEnv";

import ThreadActionsList from "../ThreadActionsList";

type WorkspaceActions = {
  actions: WorkspaceAction[];
  title: string;
};

type WorkspaceAction = {
  actionName: string;
  appID: string;
  avatarUrl: string;
  description: string;
  label: string;
  path: string | null;
  threadID: string;
  workspaceID: string;
};

const useAppsThreadActions = () => {
  const [appsThreadActions, setAppsThreadActions] = useState<
    ComponentProps<typeof ThreadActionsList>["threadActions"] | null
  >(null);
  const client = useApolloClient();
  const { authData } = useAuthData();
  const isMounted = useComponentMounted();

  const { threadID } = useThreadViewState(({ threadID }) => ({ threadID }));

  useEffect(() => {
    const parseActions = (app: WorkspaceAppEdge) =>
      app.node.threadActions?.map(action => ({
        actionName: action.name,
        appID: app.node.id,
        avatarUrl: app.node.avatarURL,
        description: action.description,
        label: action.label,
        path: action.path,
        threadID: threadID,
        workspaceID: app.workspaceID,
      })) ?? [];

    const groupActionsByWorkspace = (
      apps: WorkspaceAppEdge[],
      getWorkspaceName: (id: string) => string | undefined
    ): WorkspaceActions[] => {
      let glueAIApp: WorkspaceAppEdge | undefined;
      const appsByWorkspace: Record<string, WorkspaceAppEdge[]> = {};
      apps.forEach(app => {
        if (app.node.id === env.glueAIAppID) {
          glueAIApp = app;
        } else {
          appsByWorkspace[app.workspaceID] = [
            ...(appsByWorkspace[app.workspaceID] ?? []),
            app,
          ];
        }
      });
      const workspaceActions: WorkspaceActions[] = [];
      // Glue AI should always be in the global thread actions
      if (glueAIApp) {
        workspaceActions.push({
          actions: parseActions(glueAIApp),
          title: "Thread actions",
        });
      }
      const workspaceIds: string[] = Object.keys(appsByWorkspace);
      if (workspaceIds.length === 1) {
        // If there is only one workspace, put all actions in the global section
        const actions = (appsByWorkspace[workspaceIds[0] as string] || []).map(
          parseActions
        );
        // If the global section isn't already added, add it
        let globalSection = workspaceActions[0];
        if (!globalSection) {
          globalSection = {
            actions: [],
            title: "Thread actions",
          };
          workspaceActions.push(globalSection);
        }
        globalSection.actions.push(...actions.flat());
      } else if (workspaceIds.length > 1) {
        // If there are multiple workspaces, put each workspace's actions in its own section
        workspaceIds.forEach(workspaceId => {
          const actions = (appsByWorkspace[workspaceId] || []).map(
            parseActions
          );
          workspaceActions.push({
            actions: actions.flat(),
            title: getWorkspaceName(workspaceId) ?? "Unknown workspace",
          });
        });
      }
      return workspaceActions;
    };

    const getAppsThreadActions = async () => {
      const appsWorkspaces = await client
        .query<WorkspacesAppsQuery>({
          query: WorkspacesAppsDocument,
        })
        .then(({ data }) => data);
      const apps = appsWorkspaces?.workspaces.edges.flatMap(workspaces =>
        workspaces.node.apps.edges.filter(app => app.node.threadActions)
      );
      if (!apps?.length) return;

      const getWorkspaceName = (id: string) =>
        appsWorkspaces.workspaces.edges.filter(
          workspace => workspace.node.id === id
        )[0]?.node.name;

      const workspaceActions = groupActionsByWorkspace(apps, getWorkspaceName);
      isMounted.current && setAppsThreadActions(workspaceActions);
    };

    getAppsThreadActions();
  }, [authData, client, isMounted, threadID]);

  return appsThreadActions;
};

export default useAppsThreadActions;

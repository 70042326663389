import { RecipientType, ThreadSimple, nodeAs } from "@utility-types";
import useAuthData from "hooks/useAuthData";

type ChatRecipientType = Extract<
  RecipientType,
  "User" | "GroupPreview" | "WorkspacePreview"
>;

const useChatRecipient = (
  types: ChatRecipientType[] = ["User", "GroupPreview", "WorkspacePreview"]
) => {
  const { authData } = useAuthData();
  return (thread?: ThreadSimple) => {
    const recipients = thread?.recipients.edges || [];
    const recipient = thread?.isPersistentChat
      ? nodeAs(
          (
            recipients.filter(r => r.node.id !== authData?.me.id)[0] ||
            recipients[0]
          )?.node,
          types
        )
      : undefined;

    return recipient;
  };
};

export default useChatRecipient;

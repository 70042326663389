import { useMemo } from "react";
import { useHistory } from "react-router";

import { SectionItem } from "components/design-system/ui/sections-sidebar";
import { RouteToApp, useRouteParams } from "components/routing/utils";
import useAuthData from "hooks/useAuthData";
import useLocalSettingsStore from "store/useLocalSettingsStore";

import InboxListItem from "./InboxListItem";
import MailboxSection from "./MailboxSection";
import useWorkspaceApps from "./hooks/useWorkspaceApps";

import Avatar from "components/design-system/Avatar/Avatar";
import { DropdownActionButton } from "components/design-system/FloatingUi/DropdownActionButton";
import { DropdownActionButtonGroup } from "components/design-system/FloatingUi/DropdownActionButtonGroup";
import { DropdownActions } from "components/design-system/FloatingUi/DropdownActions";
import {
  WorkspaceModal,
  WorkspaceModalTabs,
} from "components/workspace/WorkspaceModal";
import useAppStateStore from "store/useAppStateStore";
import useModalStore from "store/useModalStore";

const sectionKey = "Apps";

const Apps = () => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const { authData } = useAuthData();
  const { apps } = useWorkspaceApps();
  const { appID } = useRouteParams();
  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));
  const workspaces = (authData?.workspaces.edges ?? []).map(({ node }) => node);

  const { collapsedSidebarSections } = useLocalSettingsStore(
    ({ collapsedSidebarSections }) => ({ collapsedSidebarSections })
  );
  const collapsed = collapsedSidebarSections.includes(sectionKey);

  const history = useHistory();

  const sectionItems = useMemo(() => {
    let items = Array.from(apps.values());

    if (collapsed) {
      items = Array.from(apps.values()).filter(app => app.id === appID);
    }

    return items.map(app => (
      <SectionItem
        key={app.id}
        flipId={app.id}
        onClick={(
          e:
            | React.KeyboardEvent<HTMLDivElement>
            | React.MouseEvent<HTMLDivElement>
        ) => {
          const to = e.ctrlKey || e.metaKey ? "secondary" : "primary";
          history.push(RouteToApp({ appID: app.id, to }));
        }}
      >
        <InboxListItem
          avatarName={app.name}
          avatarURL={app.avatarURL}
          bulkMode={false}
          isSelected={app.id === appID}
          subject={app.name}
        />
      </SectionItem>
    ));
  }, [appID, apps, collapsed, history]);

  if (authData?.workspaces.edges.length === 0) return null;

  return (
    <MailboxSection
      flipKey={""}
      moreItems={[]}
      onClickMore={() => {}}
      sectionItems={sectionItems}
      sectionKey={sectionKey}
      sectionTitle="Apps"
      sectionTitleDropdown={
        <DropdownActions>
          <DropdownActionButtonGroup>
            {workspaces.map(workspace => (
              <DropdownActionButton
                key={`dropdown-action-button-${workspace.id}`}
                onClick={() =>
                  openModal(
                    <WorkspaceModal
                      defaultTab={WorkspaceModalTabs.Apps}
                      workspaceID={workspace.id}
                    />,
                    { id: workspace.id }
                  )
                }
              >
                <Avatar
                  avatarURL={workspace.avatarURL}
                  name={workspace.name}
                  rounded="rounded-sm"
                  size={breakpointMD ? "x-small" : "small"}
                />
                Add apps in {workspace.name}
              </DropdownActionButton>
            ))}
          </DropdownActionButtonGroup>
        </DropdownActions>
      }
    />
  );
};

export default Apps;

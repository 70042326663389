const NoAuthError = () => {
  return (
    <span>
      Sorry, your access to Glue has been temporarily suspended. Please contact
      your admin or{" "}
      <a className="underline" href="mailto:support@gluegroups.com">
        support@gluegroups.com
      </a>
    </span>
  );
};

export default NoAuthError;

import { TextInput } from "components/design-system/Forms";
import useElementTextWidth from "hooks/useElementTextWidth";
import { ComponentProps, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import tw from "utils/tw";

type Props = ComponentProps<typeof TextInput>;
const EmojiShortcutInput = ({
  className,
  config,
  placeholder,
  ...props
}: Props) => {
  const { watch } = useFormContext();
  const emojiShortcut: string = watch("emojiShortcut") ?? "";
  const [focus, setFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { measureText } = useElementTextWidth(inputRef.current);
  return (
    <div className="relative">
      <span
        className={tw("absolute top-[21px] left-11 opacity-0", {
          "opacity-100": focus || emojiShortcut.length,
        })}
      >
        :
      </span>
      <TextInput
        config={{ ...config, onBlur: () => setFocus(false) }}
        {...props}
        inputRef={inputRef}
        className={tw(className, { "pl-14": focus || emojiShortcut.length })}
        onFocus={() => setFocus(true)}
        placeholder={focus ? undefined : placeholder}
      />
      <span
        className={tw("absolute top-[21px] opacity-0", {
          "opacity-100": focus || emojiShortcut.length,
        })}
        style={{ left: measureText(emojiShortcut) + 16 }}
      >
        :
      </span>
    </div>
  );
};

export default EmojiShortcutInput;

import { useCallback } from "react";

import { InvalidContentHandler } from "remirror";

import { useSnackbar } from "providers/SnackbarProvider";

const CONTENT_ERROR =
  "The message editor encountered an error. Some of your changes may have been lost.";

function useOnError() {
  const { openSnackbar } = useSnackbar();

  const onContentError: InvalidContentHandler = useCallback(
    ({ invalidContent, json, transformers }) => {
      openSnackbar("error", CONTENT_ERROR);
      // Automatically remove all invalid nodes and marks.
      return transformers.remove(json, invalidContent);
    },
    [openSnackbar]
  );

  return {
    onContentError,
  };
}

export default useOnError;

import { useEffect, useRef, useState } from "react";

const TAB_KEY = "Tab";

const useElementHasTabKeyFocus = () => {
  const [focused, setFocused] = useState(false);
  const lastKeyRef = useRef<string | undefined>();

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      lastKeyRef.current = e.code;
    };

    window.addEventListener("keydown", handler);

    return () => window.removeEventListener("keydown", handler);
  }, []);

  return {
    focused,
    onBlur: () => setFocused(false),
    onFocus: () => {
      if (lastKeyRef.current !== TAB_KEY) return;
      lastKeyRef.current = undefined;
      setFocused(true);
    },
  };
};

export default useElementHasTabKeyFocus;

import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";

import {
  FetchThreadEdgeDocument,
  FetchThreadEdgeQuery,
  NotifyMessagesSetting,
  ThreadSubscription,
  useUserSettingsQuery,
} from "generated/graphql";

import { Message, StreamGlueEvent, ThreadEdge, nodeAs } from "@utility-types";
import useThreadCacheUpdate from "hooks/thread/useThreadCacheUpdate";
import useAuthData from "hooks/useAuthData";
import useAppStateStore from "store/useAppStateStore";
import { streamMessageToGlueMessage } from "utils/stream/message";

import useCacheEvict from "./useCacheEvict";

type NewMessage = {
  meID: string;
  message: Message;
  threadEdge: ThreadEdge;
};

// Since we don't check if the user is a member of the
// mentioned group, for now we're just refetching on any mention.
// The likelihood is high that the user is in that group.
const isLikelyDirect = (message: Message, userID: string) =>
  message.quotedMessage?.user?.id === userID ||
  !!message.text.match(/^glue:(grp|usr|wks)_/g);

// Once we have the thread edge, we can handle DMs as well
const isDirectMessage = ({ meID, message, threadEdge }: NewMessage) => {
  const userMention = `(glue:${meID})`;
  return !!(
    message.text.includes(userMention) ||
    message.quotedMessage?.user?.id === meID ||
    (threadEdge.node.isPersistentChat &&
      threadEdge.node.recipients.edges.length === 2)
  );
};

const shouldNotify = (
  props: NewMessage & {
    appStatus: "active" | "inactive" | "unknown";
    notifyMessages?: NotifyMessagesSetting;
  }
) => {
  if (props.meID === props.message.user.id) return false;

  switch (props.notifyMessages) {
    case NotifyMessagesSetting.Inbox:
      return (
        props.threadEdge.subscription === ThreadSubscription.Inbox ||
        isDirectMessage(props)
      );
    case NotifyMessagesSetting.Direct:
      return isDirectMessage(props);
    default:
      return false;
  }
};

const useStateUpdateFromStream: () => (event: StreamGlueEvent) => void = () => {
  const { authData } = useAuthData();
  const apolloClient = useApolloClient();

  const { evictNode } = useCacheEvict();
  const { onMessageNew, onThreadRead } = useThreadCacheUpdate();

  const { data: userSettings } = useUserSettingsQuery({
    fetchPolicy: "cache-first",
    skip: !authData?.me.id,
  });

  const notifyMessages = userSettings?.settings.notifyMessages;

  const getThreadEdge = useCallback(
    async (
      threadID: string,
      isDirect: boolean,
      fetchPolicy: "cache-only" | "network-only" = "cache-only"
    ): Promise<ThreadEdge | undefined> => {
      if (!authData?.me.id) return;

      // We start out with a cache-only fetch to avoid unnecessary delays
      // but if the edge is not in the cache, we do a network-only fetch

      if (fetchPolicy !== "cache-only") {
        // add some random jitter to give backend time to sync and avoid stampeding
        await new Promise(resolve =>
          setTimeout(resolve, Math.floor(Math.random() * 1000) + 500)
        );
      }

      return apolloClient
        .query<FetchThreadEdgeQuery>({
          query: FetchThreadEdgeDocument,
          fetchPolicy,
          variables: { id: `${threadID}-${authData.me.id}` },
        })
        .then(({ data }) => {
          const edge = nodeAs(data?.node, ["ThreadEdge"]);
          if (!edge) {
            if (fetchPolicy === "cache-only") {
              return getThreadEdge(threadID, isDirect, "network-only");
            }
            return undefined;
          }

          // If we haven't fetched from the network and it's
          // possible that we've followed the thread, we refetch
          if (
            isDirect &&
            edge.subscription !== ThreadSubscription.Inbox &&
            fetchPolicy !== "network-only"
          ) {
            return getThreadEdge(threadID, isDirect, "network-only");
          }

          return edge;
        });
    },
    [apolloClient, authData?.me.id]
  );

  const handleNewMessage = useCallback(
    async (event: StreamGlueEvent) => {
      if (!authData?.me.id || !event.message?.user) return;

      if (event.message.silent) return; // ignore silent system messages

      const threadID = (event.cid || event.message?.cid)?.split(":")?.pop();
      if (!threadID) return;

      const meID = authData.me.id;
      const message = streamMessageToGlueMessage(event.message);
      const isDirect = isLikelyDirect(message, meID);

      const threadEdge = await getThreadEdge(threadID, isDirect);
      if (!threadEdge) return;

      const { activeThreadId, appStatus } = useAppStateStore.getState();
      const isActive = activeThreadId === threadID;
      const isMe = event.message.user.id === meID;
      const isRead = isMe || (appStatus === "active" && isActive);

      onMessageNew(message, isMe, isRead);

      const notify = shouldNotify({
        appStatus,
        meID,
        message,
        notifyMessages,
        threadEdge,
      });

      if (notify) {
        useAppStateStore.setState({
          activeNotification: { message, threadEdge, threadID },
        });
      }
    },
    [authData?.me.id, getThreadEdge, notifyMessages, onMessageNew]
  );

  return useCallback(
    (event: StreamGlueEvent) => {
      if (!authData?.me.id) return;

      switch (event.type) {
        case "channel.deleted":
        case "notification.channel_deleted":
        case "notification.removed_from_channel":
          if (!event.channel_id) return;
          evictNode({ id: event.channel_id });
          break;
        case "message.read":
        case "notification.mark_read":
          if (!event.channel_id || event.user?.id !== authData.me.id) return;
          onThreadRead(event.channel_id);
          break;
        case "message.new":
        case "notification.message_new":
          handleNewMessage(event);
          break;
        // TODO: add events
        // case "message.updated":
        // case "message.deleted":
      }
    },
    [authData?.me.id, evictNode, handleNewMessage, onThreadRead]
  );
};

export default useStateUpdateFromStream;

import { useEffect } from "react";

import { ObservableQuery } from "@apollo/client";

import {
  FetchGroupOrPreviewEdgeQuery,
  FetchWorkspaceOrPreviewEdgeQuery,
  GroupFieldsFragment,
  WorkspaceFieldsFragment,
} from "generated/graphql";

const useFetchMorePendingApprovals = ({
  fetchMore,
  pendingApprovals,
}: {
  fetchMore:
    | ObservableQuery<FetchWorkspaceOrPreviewEdgeQuery>["fetchMore"]
    | ObservableQuery<FetchGroupOrPreviewEdgeQuery>["fetchMore"];
  pendingApprovals?:
    | WorkspaceFieldsFragment["pendingApprovals"]
    | GroupFieldsFragment["pendingApprovals"];
}) => {
  useEffect(() => {
    if (!pendingApprovals?.pageInfo?.hasNextPage) return;
    const { endCursor } = pendingApprovals.pageInfo;
    endCursor && fetchMore({ variables: { pendingApprovalsAfter: endCursor } });
  }, [fetchMore, pendingApprovals?.pageInfo]);
};

export default useFetchMorePendingApprovals;

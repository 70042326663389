import { useState } from "react";

import useResizeObserver from "./useResizeObserver";

const useElementBreakpoint = (
  element: HTMLElement | null,
  widthProp: number | "wide"
) => {
  const width = widthProp === "wide" ? 832 : widthProp;
  const [breakpoint, setBreakpoint] = useState<boolean | null>(() =>
    element ? element.offsetWidth >= width : null
  );

  useResizeObserver(element, rect => {
    const isBreakpoint = rect.inlineSize >= width;
    if (isBreakpoint === breakpoint) return;
    setBreakpoint(isBreakpoint);
  });

  return breakpoint;
};

export default useElementBreakpoint;

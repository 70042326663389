import { AnalyticsEvent } from "analytics/analyticsEvent";

type ApplicationVersionAndBuild = {
  build: string;
  version: string;
};

export class ApplicationOpened extends AnalyticsEvent<
  ApplicationVersionAndBuild & { fromBackground: boolean }
> {
  event = "Application Opened";
}

export class ApplicationUpdated extends AnalyticsEvent<
  ApplicationVersionAndBuild & {
    previousBuild: string;
    previousVersion: string;
  }
> {
  event = "Application Updated";
}

export class ApplicationBackgrounded extends AnalyticsEvent<void> {
  event = "Application Backgrounded";
}

export class ApplicationInstalled extends AnalyticsEvent<ApplicationVersionAndBuild> {
  event = "Application Installed";
}

import { ComponentProps } from "react";

import Avatar from "components/design-system/Avatar/Avatar";
import { AvatarUploader } from "./AvatarUploader";

type Props = Pick<
  ComponentProps<typeof Avatar>,
  "avatarURL" | "name" | "size" | "rounded"
> &
  ComponentProps<typeof AvatarUploader>;

const EditableAvatar = ({
  onAvatarUpdate,
  size = "x-large",
  ...props
}: Props): JSX.Element => (
  <div className="avatar-editable relative">
    <div>
      <label className="cursor-pointer" htmlFor="picture-input">
        <Avatar {...props} size={size} />
        <AvatarUploader onAvatarUpdate={onAvatarUpdate} />
      </label>
    </div>
  </div>
);

export default EditableAvatar;

import {
  ChatType,
  ThreadsOrder,
  useMailboxCountsQuery,
  useNotificationCountsQuery,
  usePersistentChatsQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";

type UnreadCounts = {
  draftsCount: number;
  joinApprovalsCount: number;
  starredThreadCount: number;
  unreadInboxCount: number;
  unreadDirectMessages: number;
  unreadMentionCount: number;
  unreadNotificationCount: number;
  unreadThreadCount: number;
  unseenFeedCount: number;
};

export const useSidebarCounts = ({
  refresh,
}: { refresh?: boolean } = {}): UnreadCounts => {
  const { authReady } = useAuthData();

  const fetchOptions = {
    fetchPolicy: authReady
      ? refresh
        ? ("cache-and-network" as const)
        : ("cache-first" as const)
      : ("cache-only" as const),
    nextFetchPolicy: "cache-first" as const,
  };

  const { data: notificationCountsData } =
    useNotificationCountsQuery(fetchOptions);

  const { data: mailboxCountsData } = useMailboxCountsQuery(fetchOptions);

  const { data: userChatsData } = usePersistentChatsQuery({
    variables: { chatType: ChatType.User, order: ThreadsOrder.LastMessage },
    ...fetchOptions,
  });

  return {
    draftsCount: mailboxCountsData?.drafts.totalCount ?? 0,
    joinApprovalsCount: notificationCountsData?.joinApprovals?.totalCount ?? 0,
    starredThreadCount: mailboxCountsData?.starredThreads.totalCount ?? 0,
    unreadInboxCount: mailboxCountsData?.unreadInbox.totalCount ?? 0,
    unreadMentionCount: mailboxCountsData?.mentionedThreads.totalCount ?? 0,
    unreadDirectMessages:
      userChatsData?.persistentChats.unreadCounts.total ?? 0,
    unreadNotificationCount:
      notificationCountsData?.notifications?.unseenCount ?? 0,
    unreadThreadCount: mailboxCountsData?.unreadThreads.totalCount ?? 0,
    unseenFeedCount: mailboxCountsData?.unseenFeed.totalCount ?? 0,
  };
};

export default useSidebarCounts;

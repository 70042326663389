import { useCallback, useLayoutEffect, useState } from "react";

import useIntersectionObserver from "hooks/useIntersectionObserver";

import { Position } from "store/useUnreadSidebarItemStore";

const topMargin = 60;

const useElementRelativePosition = (
  element: HTMLElement | null,
  parentId: string,
  skip = false
) => {
  const parent = document.getElementById(parentId);

  const [relativePosition, setRelativePosition] = useState<Position>();

  const getRelativePosition = useCallback(
    (rect: DOMRectReadOnly): Position => {
      if (skip || !parent || (!rect.width && !rect.height)) return undefined;
      const bounds = parent.getBoundingClientRect();
      if (rect.bottom > bounds.bottom) return "beneath";
      if (rect.top < bounds.top + topMargin) return "above";
    },
    [parent, skip]
  );

  // Update the first time since the intersection observer won't fire immediately
  useLayoutEffect(() => {
    if (!element) return;
    setRelativePosition(getRelativePosition(element.getBoundingClientRect()));
  }, [element, getRelativePosition]);

  useIntersectionObserver(
    skip ? null : element,
    (e: IntersectionObserverEntry) => {
      if (e.isIntersecting) {
        setRelativePosition(undefined);
        return;
      }
      setRelativePosition(getRelativePosition(e.boundingClientRect));
    },
    {
      root: parent,
      rootMargin: `${topMargin}px 0px 0px 0px`,
      threshold: 0.5,
    }
  );

  return { relativePosition };
};

export default useElementRelativePosition;

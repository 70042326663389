import { Icon } from "components/design-system/icons";

type Props = {
  thread?: { isPersistentChat?: boolean; subject: string };
};

const ThreadName = ({ thread }: Props) => (
  <>
    {thread?.isPersistentChat === false && "🧵 "}
    {thread?.subject}
    {thread?.isPersistentChat && (
      <Icon
        className="inline-block ml-8 -mt-2 text-interactive-subtle"
        icon="ChatSquare"
        size={16}
      />
    )}
  </>
);

export default ThreadName;

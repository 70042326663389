import { ComponentProps } from "react";

import { Icon } from "components/design-system/icons";

export type Suggestion = {
  title: string;
  icon?: ComponentProps<typeof Icon>["icon"];
  subhead: string;
  mention?: "group" | "thread" | "user";
  upload?: "image" | "file";
};

// search suggestions
const searchSuggestions: Suggestion[] = [
  {
    title: "Give me a project update",
    subhead: "from threads in @mention",
    mention: "group",
  },
  {
    title: "Have any users reported errors",
    subhead: "in the last 2 weeks?",
  },
  {
    title: "Identify some key questions",
    subhead: "asked by @mention this week",
    mention: "user",
  },
  {
    title: "Search for conversations",
    subhead: "discussing deadlines or milestones",
  },
];
// analyze suggestions
const analyzeSuggestions: Suggestion[] = [
  {
    title: "Suggest edits to this document",
    icon: "Paperclip",
    subhead: "Attach any file",
    upload: "file",
  },
  {
    title: "Give me feedback on this design",
    icon: "Image",
    subhead: "Attach a screenshot",
    upload: "image",
  },
];
// documentation suggestions

const documentationSuggestions: Suggestion[] = [
  {
    title: "Teach me how to",
    subhead: "create a thread in Glue",
  },
  {
    title: "Can I add external members",
    subhead: "to a group in Glue?",
  },
  {
    title: "What keyboard shortcuts",
    subhead: "can I use in Glue?",
  },
  {
    title: "How can I manage my inbox",
    subhead: "to stay organized in Glue?",
  },
];

// generative suggestions

export const generativeSuggestions: Suggestion[] = [
  {
    title: "Create a checklist",
    subhead: "for onboarding a new hire",
  },
  {
    title: "Suggest creative names",
    subhead: "for a low-code website design tool",
  },
  {
    title: "Provide some example code",
    subhead: "for using Tailwind in React",
  },
  {
    title: "Write an example Python script",
    subhead: "to download an entire website",
  },
  {
    title: "Write a sample SQL query",
    subhead: "showing active users per week",
  },
];

// returns random suggestions from the given source

const pickSuggestion = (source: Suggestion[], count: number) => {
  const suggestions = [...source];
  const picked: Suggestion[] = [];
  for (let i = 0; i < count; i++) {
    const randomIndex = Math.floor(Math.random() * suggestions.length);
    const suggestion = suggestions[randomIndex];
    if (!suggestion) continue;
    picked.push(suggestion);
    suggestions.splice(randomIndex, 1);
  }
  return picked;
};

// returns 6 random suggestions, 2 from each category

export const pickSuggestions = () => {
  return [
    ...pickSuggestion(searchSuggestions, 2),
    ...pickSuggestion(analyzeSuggestions, 2),
    ...pickSuggestion(documentationSuggestions, 2),
  ];
};

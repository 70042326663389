import { toLower } from "lodash-es";
import { FiltersKeys, SharedFilters } from "./types";

const EmptyState = ({ filter }: { filter: FiltersKeys }) => (
  <div className="flex justify-center">
    <div className="flex flex-col items-center px-24 py-16 w-[375px]">
      <span className="text-body-bold">
        {filter === SharedFilters.Recent
          ? "Nothing... yet!"
          : `No ${toLower(filter)}... yet!`}
      </span>
      <span className="text-body text-text-secondary text-center">
        {filter === SharedFilters.Recent
          ? "Recently shared docs, media and links from threads in this group will appear here."
          : `Recently shared ${toLower(filter)} from threads in this group will appear here.`}
      </span>
    </div>
  </div>
);

export default EmptyState;

import { FieldValues, useFormContext } from "react-hook-form";

import { Icon } from "components/design-system/icons";
import tw from "utils/tw";
import Avatar from "../Avatar/Avatar";
import { CheckboxProps } from "./types";

type Props = {
  imageSrc?: string;
  text: string;
};
const Selector = <TFieldValues extends FieldValues>({
  config,
  name,
  imageSrc,
  text,
  ...props
}: CheckboxProps<TFieldValues> & Props) => {
  const {
    formState: { isSubmitting },
    register,
    watch,
  } = useFormContext<TFieldValues>();

  const isChecked = watch(name);
  const disabled = props.disabled || isSubmitting;

  return (
    <>
      <input
        className="hidden"
        id={name}
        type="checkbox"
        {...props}
        disabled={disabled}
        {...register(name, config)}
      />
      <label
        className={tw(
          "h-50 w-full p-8 pr-16 flex items-center rounded-lg",
          "border border-border-container hover:border-border-strong hover:cursor-pointer"
        )}
        htmlFor={name}
      >
        {imageSrc && (
          <Avatar
            className="mr-10"
            avatarURL={imageSrc}
            name={text}
            size="medium"
            rounded="rounded-md"
            background="transparent"
          />
        )}
        <span className="text-body-bold grow truncate">{text}</span>
        <Icon
          icon={isChecked ? "IconTapSelected" : "IconTapUnselected"}
          className={
            isChecked ? "text-icon-primary-selected" : "text-icon-subtle"
          }
        />
      </label>
    </>
  );
};

export default Selector;

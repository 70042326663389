import { memo, useMemo } from "react";

import { useApolloClient } from "@apollo/client";
import { isEqual } from "lodash-es";

import analytics from "analytics";
import {
  ThreadActionOrigin,
  ThreadArchivedUnarchived,
  ThreadBulkArchivedUnarchived,
  ThreadBulkMarkedReadUnread,
  ThreadBulkStarredUnstarred,
  ThreadBulkSubscribedUnsubscribed,
  ThreadMarkedReadUnread,
  ThreadStarredUnstarred,
  ThreadSubscribedUnsubscribed,
} from "analytics/events/thread";
import { readThreadList } from "apollo/cache/threadHelpers";
import { Button } from "components/design-system/Button";
import { ThreadSubscription } from "generated/graphql";
import useInboxThreadActions, {
  ThreadSelection,
} from "hooks/thread/useInboxThreadActions";
import tw from "utils/tw";

import { isSubscribed } from "../ThreadItem/utils";

type Props = {
  archiveSelection?: ThreadSelection;
  bulkMode?: boolean;
  bulkModeExit?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  canArchive?: boolean;
  canFollow?: boolean;
  inboxSelection?: ThreadSelection;
  selection?: ThreadSelection;
};

type PropsMemo = Omit<Props, "canArchive" | "bulkMode"> & {
  bulkMode: boolean;
  canArchive: boolean;
  inboxThreadActions: ReturnType<typeof useInboxThreadActions>;
};

const buttonClassName = tw(
  "btn-hover-grow",
  "flex items-center justify-center",
  "h-28 w-28 mr-2 last:mr-0", // icon 20px, inside a 28px button w/ mr-2 equates to 10px space between icons
  "relative text-icon-secondary hover:text-icon-secondary-hover"
);

const InboxThreadActions = ({
  bulkMode = false,
  canArchive = true,
  ...props
}: Props): JSX.Element | null => {
  const inboxThreadActions = useInboxThreadActions();

  return (
    <MemoizedInboxThreadActions
      bulkMode={bulkMode}
      canArchive={canArchive}
      inboxThreadActions={inboxThreadActions}
      {...props}
    />
  );
};

const MemoizedInboxThreadActions = memo(
  ({
    archiveSelection,
    bulkMode,
    bulkModeExit,
    canArchive,
    canFollow = true,
    inboxSelection,
    inboxThreadActions,
    selection,
  }: PropsMemo): JSX.Element | null => {
    const cache = useApolloClient().cache;
    const order = [
      ...(canFollow ? ["follow"] : []),
      "unread",
      "star",
      ...(canArchive ? ["archive"] : []),
    ];

    const archiveThreadEdges =
      archiveSelection?.selectMode === "all"
        ? readThreadList(archiveSelection.filter, cache)?.threads.edges.filter(
            e => !archiveSelection?.excludedEdgeIDs.has(e.id)
          )
        : archiveSelection?.threadEdges;

    const inboxThreadEdges =
      inboxSelection?.selectMode === "all"
        ? readThreadList(inboxSelection.filter, cache)?.threads.edges.filter(
            e => !inboxSelection?.excludedEdgeIDs.has(e.id)
          )
        : inboxSelection?.threadEdges;

    const selectionThreadEdges =
      selection?.selectMode === "all"
        ? readThreadList(selection.filter, cache)?.threads.edges.filter(
            e => !selection?.excludedEdgeIDs.has(e.id)
          )
        : selection?.threadEdges;

    const threadEdges = useMemo(
      () => [
        ...(inboxThreadEdges ?? []),
        ...(archiveThreadEdges ?? []),
        ...(selectionThreadEdges ?? []),
      ],
      [archiveThreadEdges, inboxThreadEdges, selectionThreadEdges]
    );

    if (!threadEdges.length) return null;

    const archived =
      threadEdges.filter(edge => edge.isArchived).length !== threadEdges.length;
    const read =
      threadEdges.filter(edge => edge.isRead).length !== threadEdges.length;
    const starred =
      threadEdges.filter(edge => edge.isStarred).length !== threadEdges.length;
    const subscribed =
      threadEdges.filter(edge => isSubscribed(edge)).length === 0;

    const wordThread = threadEdges.length > 1 ? "threads" : "thread";

    const threadId = threadEdges[0]?.id.split("-")[0] ?? "";

    const FollowButton = () => (
      <Button
        buttonStyle="subtle"
        buttonType="none"
        className={buttonClassName}
        icon={subscribed ? "Bell" : "BellSmallFilled"}
        iconSize={20}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          if (!threadEdges[0]) return;

          if (bulkMode) {
            const subscription = subscribed
              ? ThreadSubscription.Inbox
              : ThreadSubscription.Archive;

            if (archiveSelection) {
              inboxThreadActions.markThreadEdges({
                selection: archiveSelection,
                subscription,
              });
            }

            if (inboxSelection) {
              inboxThreadActions.markThreadEdges({
                selection: inboxSelection,
                subscription,
              });
            }

            if (selection) {
              inboxThreadActions.markThreadEdges({
                selection,
                subscription,
              });
            }

            if (inboxSelection || selection?.filter?.mailbox === "inbox")
              bulkModeExit?.();

            analytics.track(ThreadBulkSubscribedUnsubscribed, {
              count: threadEdges.length,
              subscribed: !!inboxSelection || subscribed,
            });
            return;
          }

          inboxThreadActions.toggleThreadSubscribed(threadEdges[0]);
          analytics.track(ThreadSubscribedUnsubscribed, {
            subscribed,
            threadId,
            uiOrigin: ThreadActionOrigin.ListItem,
          });
        }}
        onPointerDown={e => e.stopPropagation()}
        tooltip={subscribed ? `Follow ${wordThread}` : `Unfollow ${wordThread}`}
        type="button"
      />
    );

    const StarButton = () => (
      <Button
        buttonStyle="subtle"
        buttonType="none"
        className={buttonClassName}
        icon={"Star"}
        iconClassName={tw({
          "text-icon-action-highlight fill-current": !starred,
        })}
        iconSize={20}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          if (!threadEdges[0]) return;

          if (bulkMode) {
            if (archiveSelection) {
              inboxThreadActions.markThreadEdges({
                selection: archiveSelection,
                starred,
              });
            }

            if (inboxSelection) {
              inboxThreadActions.markThreadEdges({
                selection: inboxSelection,
                starred,
              });
            }

            if (selection) {
              inboxThreadActions.markThreadEdges({
                selection,
                starred,
              });
            }

            analytics.track(ThreadBulkStarredUnstarred, {
              count: threadEdges.length,
              starred,
            });
            return;
          }

          const edge = threadEdges[0];
          inboxThreadActions.toggleThreadStarred(edge, undefined, {});

          analytics.track(ThreadStarredUnstarred, {
            starred,
            threadId,
            uiOrigin: ThreadActionOrigin.ListItem,
          });
        }}
        onPointerDown={e => e.stopPropagation()}
        tooltip={`${!starred ? "Unstar" : "Star"} ${wordThread}`}
        type="button"
      />
    );

    const UnreadButton = () => (
      <Button
        buttonStyle="subtle"
        buttonType="none"
        className={buttonClassName}
        icon={!read ? "Unread" : "Mail"}
        iconSize={20}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          if (!threadEdges[0]) return;

          if (bulkMode) {
            if (archiveSelection) {
              inboxThreadActions.markThreadEdges({
                read,
                selection: archiveSelection,
              });
            }

            if (inboxSelection) {
              inboxThreadActions.markThreadEdges({
                read,
                selection: inboxSelection,
              });
            }

            if (selection) {
              inboxThreadActions.markThreadEdges({
                read,
                selection,
              });
            }

            analytics.track(ThreadBulkMarkedReadUnread, {
              count: threadEdges.length,
              read,
            });
            return;
          }

          inboxThreadActions.toggleThreadRead(threadEdges[0]);
          analytics.track(ThreadMarkedReadUnread, {
            read,
            threadId,
            uiOrigin: ThreadActionOrigin.ListItem,
          });
        }}
        onPointerDown={e => e.stopPropagation()}
        tooltip={`${!read ? "Mark unread" : "Mark read"}`}
        type="button"
      />
    );

    const ArchiveButton = () => (
      <Button
        buttonStyle="subtle"
        buttonType="none"
        className={buttonClassName}
        icon={!archived ? "Inbox" : "Check"}
        iconSize={20}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          if (!threadEdges[0]) return;

          if (bulkMode) {
            if (archiveSelection) {
              inboxThreadActions.markThreadEdges({
                archived,
                selection: archiveSelection,
              });
            }

            if (inboxSelection) {
              inboxThreadActions.markThreadEdges({
                archived,
                selection: inboxSelection,
              });
            }

            if (selection) {
              inboxThreadActions.markThreadEdges({
                archived,
                selection,
              });
            }

            if (inboxSelection || selection?.filter?.mailbox === "inbox")
              bulkModeExit?.();

            analytics.track(ThreadBulkArchivedUnarchived, {
              archived,
              count: threadEdges.length,
            });

            return;
          }

          const edge = threadEdges[0];
          inboxThreadActions.toggleThreadArchived(edge, undefined, {});

          analytics.track(ThreadArchivedUnarchived, {
            archived,
            threadId,
            uiOrigin: ThreadActionOrigin.ListItem,
          });
        }}
        onPointerDown={e => e.stopPropagation()}
        tooltip={!archived ? "Unarchive" : `Archive ${wordThread}`}
        type="button"
      />
    );

    return (
      <div className="flex items-center">
        {order.map(action => {
          switch (action) {
            case "follow":
              return <FollowButton key={action} />;
            case "star":
              return <StarButton key={action} />;
            case "unread":
              return <UnreadButton key={action} />;
            case "archive":
              return <ArchiveButton key={action} />;
          }
        })}
      </div>
    );
  },
  (prev, next): boolean =>
    prev.canArchive === next.canArchive &&
    isEqual(prev.archiveSelection, next.archiveSelection) &&
    isEqual(prev.inboxSelection, next.inboxSelection) &&
    isEqual(prev.selection, next.selection)
);

export default InboxThreadActions;

import { useEffect, useState } from "react";

type Props<T> = {
  firstPageLimit?: number;
  loadMore: boolean;
  loadNextPage: () => Promise<T>;
  pageSize: number;
};

const useLoadMoreOnFirstPage = <T,>({
  firstPageLimit = 30,
  loadMore,
  loadNextPage,
  pageSize,
}: Props<T>) => {
  const [firstPageSize, setFirstPageSize] = useState(pageSize);

  useEffect(() => {
    (async () => {
      if (!loadMore || firstPageSize >= firstPageLimit) return;
      await loadNextPage();
      setFirstPageSize(size => size + pageSize + 1);
    })();
  }, [firstPageLimit, firstPageSize, loadMore, loadNextPage, pageSize]);

  return { firstPageSize };
};

export default useLoadMoreOnFirstPage;

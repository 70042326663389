import { RecipientsSelect } from "components/design-system/Forms/RecipientsSelect";
import SelectDropdown from "components/design-system/Forms/SelectDropdown";
import useAuthData from "hooks/useAuthData";
import NameAndEmojiInputs from "../NameAndEmojiInputs";

const GroupSetup = () => {
  const { authData } = useAuthData();
  const userHasMultipleWorkspaces =
    (authData?.me?.workspaceIDs.length ?? 0) > 1;
  return (
    <div className="flex flex-col">
      <NameAndEmojiInputs formSubmitting={false} />
      <div className="flex flex-col gap-4 mt-24">
        <span className="text-subhead-bold text-text-secondary">Members</span>
        <RecipientsSelect
          name="members"
          containerHeight="90px"
          placeholder="Add users or emails..."
          wrapperClassName="!my-0"
          containerPadding="8px 4px 8px 8px"
          placeholderMargin="0px 0px 0px 4px"
          selectGroups={false}
        />
      </div>
      <div className="flex flex-col gap-4 mt-24">
        <span className="text-subhead-bold text-text-secondary">Admins</span>
        <RecipientsSelect
          name="admins"
          placeholder="Admins"
          wrapperClassName="!my-0"
          selectGroups={false}
        />
      </div>
      {userHasMultipleWorkspaces && authData?.workspaces.edges && (
        <div className="flex flex-col gap-4 mt-24">
          <span className="text-subhead-bold text-text-secondary">
            Workspace
          </span>
          <SelectDropdown
            name="workspaceID"
            options={authData.workspaces.edges.map(w => ({
              iconSrc: w.node.avatarURL ?? "",
              label: w.node.name,
              value: w.node.id,
            }))}
          />
        </div>
      )}
    </div>
  );
};

export default GroupSetup;

export type FlagEnv = "development" | "staging" | "production";

export type FlagConfig = {
  /** Environments where flag can be used. Value determines default setting. */
  environments: { [K in FlagEnv]?: boolean };
  /** Whether the flag can be toggled. */
  toggleable: boolean;
};

export const flagsConfig = <T>(obj: { [K in keyof T]: FlagConfig }) => obj;

import { useRef } from "react";

import { FileOrImageUpload } from "@utility-types";
import { fileToFileUpload } from "components/MessageEditor/stream-helpers";
import { Button } from "components/design-system/Button";
import useFileUploader from "hooks/useFileUploader";
import useForceUpdate from "hooks/useForceUpdate";
import { isNativeAndroid } from "utils/platform";
import tw from "utils/tw";

import FileUploadMenu from "components/FileUploadMenu";
import { Skeleton } from "components/Skeleton";
import { Icon } from "components/design-system/icons";

type CallBack = Parameters<typeof useFileUploader>[0]["onChange"];

type State = Parameters<CallBack>[0];

type Props = {
  onAvatarUpdate: (src: string) => void;
};

export const AvatarUploader = ({ onAvatarUpdate }: Props): JSX.Element => {
  const forceUpdate = useForceUpdate();
  const profilePictureUploads = useRef<State>(new Map());

  const uploadChange = (state: State) => {
    const uploads = [...state.values()];

    if (!uploads) return;

    const file = uploads[0];

    if (!file) return;

    onAvatarUpdate(file.url || file.uploadInfo?.previewUri || "");

    forceUpdate();
  };

  const handleRetry: (upload: FileOrImageUpload) => void = upload => {
    const { uploadInfo } = upload;
    uploadInfo.queued = true;
    uploadInfo.state = "uploading";

    profilePictureUploads.current.set(upload.id, upload);

    forceUpdate();
  };

  const handleFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.[0]) return;

    const fileUpload = fileToFileUpload(e.target.files[0]);

    if (fileUpload.contentType.startsWith("image")) {
      [
        ...profilePictureUploads.current.values(),
      ][0]?.uploadInfo?.axiosCancelToken?.cancel();

      profilePictureUploads.current = new Map([[fileUpload.id, fileUpload]]);
    }

    forceUpdate();
  };

  useFileUploader({
    onChange: uploadChange,
    orderedUploads: profilePictureUploads,
  });

  const loading = [...profilePictureUploads.current.values()].find(
    ({ uploadInfo: { state } }) => state === "uploading"
  );

  const failed = [...profilePictureUploads.current.values()].find(
    ({ uploadInfo: { state } }) => state === "failed"
  );

  const inputStyle = tw(
    "flex justify-center items-center",
    "absolute bottom-0 left-1/2 translate-x-[-50%] translate-y-[50%]",
    "text-background bg-accent-primary cursor-pointer rounded-full border-2 border-background",
    "w-24 h-24"
  );

  const cameraIcon = <Icon className="editable-icon" icon="Camera" size={12} />;

  return (
    <>
      {loading && (
        <Skeleton
          className={
            "absolute top-0 left-0 m-0 rounded-full opacity-80 h-80 w-80"
          }
        />
      )}
      {failed && (
        <div
          className={
            "flex absolute top-0 left-0 justify-center items-center m-0 bg-accent-error/60 rounded-full h-80 w-80"
          }
          onClick={e => {
            e.preventDefault();
            handleRetry(failed);
          }}
        >
          <Icon
            className="text-background opacity-100 cursor-pointer"
            icon="RefreshCCW"
          />
        </div>
      )}
      <div className="profile-picture-input">
        {!isNativeAndroid() ? (
          <>
            <span className={inputStyle}>{cameraIcon}</span>
            <input
              accept="image/*"
              className="hidden"
              id="picture-input"
              onChange={handleFileSelected}
              type="file"
            />
          </>
        ) : (
          <FileUploadMenu
            onChange={handleFileSelected}
            target={
              <Button
                buttonStyle="primary"
                buttonType="none"
                className={inputStyle}
              >
                {cameraIcon}
              </Button>
            }
            onlyImages
          />
        )}
      </div>
    </>
  );
};

export default AvatarUploader;

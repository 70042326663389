import { WorkspaceAppEdge, WorkspaceEdge } from "@utility-types";
import { isWebhook } from "components/apps/utils/isWebhook";
import {
  FetchWorkspaceOrPreviewEdgeDocument,
  useAppsQuery,
} from "generated/graphql";
import useRefetchOnAppActive from "hooks/useRefetchOnAppActive";
import useModalStore from "store/useModalStore";

import { AppConfigurationModal, AppsSection } from "components/apps";

import EditWebhookModal from "./apps/EditWebhookModal";

import { AddButton, newWebhookItem } from "./apps/StaticAppItems";

const sortAddedApps = (apps: WorkspaceAppEdge[]) =>
  apps.sort((a, b) => {
    if (a.node.publishedAt === null && b.node.publishedAt !== null) {
      return 1;
    }
    if (a.node.publishedAt !== null && b.node.publishedAt === null) {
      return -1;
    }
    return 0;
  });

const WorkspaceApps = ({
  workspaceEdge,
}: {
  workspaceEdge?: WorkspaceEdge;
}): JSX.Element | null => {
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const { data: availableApps } = useAppsQuery({
    fetchPolicy: "cache-and-network",
  });

  useRefetchOnAppActive([FetchWorkspaceOrPreviewEdgeDocument]);

  if (!workspaceEdge) return null;

  const appsAdded = workspaceEdge.node.apps.edges.slice();
  const workspaceID = workspaceEdge.node.id;

  const handleOpenWebhookModal = (app?: WorkspaceAppEdge) =>
    openModal(
      <EditWebhookModal workspaceApp={app} workspaceID={workspaceID} />
    );

  const handleOpenAppConfigurationModal = (appID: string) =>
    openModal(
      <AppConfigurationModal
        appID={appID}
        workspaceID={workspaceEdge.node.id}
      />
    );

  return (
    <div className="flex flex-col w-full overflow-y-hidden">
      <div className="native:pb-safe-area grow shrink w-full h-full">
        <div className="overflow-hidden h-fit">
          <AppsSection
            items={sortAddedApps(appsAdded).map(app => {
              const { node } = app;
              return {
                description: node.description,
                icon: { alt: node.name, src: node.avatarURL },
                id: node.id,
                onClick: () =>
                  isWebhook(node)
                    ? handleOpenWebhookModal(app)
                    : handleOpenAppConfigurationModal(app.node.id),
                title: `${isWebhook(node) ? "Webhook -" : ""} ${node.name}`,
              };
            })}
            title="Added"
          />
          <AppsSection
            items={[
              ...(availableApps?.apps.edges
                .filter(app => !appsAdded.find(a => app.node.id === a.node.id))
                .map(app => ({
                  actions: <AddButton />,
                  description: app.node.description,
                  icon: { alt: app.node.name, src: app.node.avatarURL },
                  id: app.node.id,
                  onClick: () => handleOpenAppConfigurationModal(app.node.id),
                  title: app.node.name,
                })) || []),
              ...newWebhookItem({ onClick: () => handleOpenWebhookModal() }),
            ]}
            title="Available"
          />
        </div>
      </div>
    </div>
  );
};

export default WorkspaceApps;

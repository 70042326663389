import formatRecipientNames, {
  RecipientEnvelope,
} from "utils/thread/formatRecipientNames";

const RecipientsLineSimple = (recipientEnvelope: RecipientEnvelope) => {
  /**
   * When persisting the Draft object to cache, we're saving full Group and Workspace type objects
   * rather than the expected GroupPreview and WorkspacePreview objects. Because the query for Draft.recipients
   * only handles the preview types and doesn't select any props for the non-preview types, the result of the
   * query are empty objects such as { __typename: 'Group' }. The better long term solution is to somehow stop
   * persisting the full types and instead save the preview types.
   * For more info, see conversion in Glue: https://app-staging.gluegroups.com/thr_2m1w1aR2gd70eo0XQFUwGLVUhzL
   */
  const filteredRecipientEnvelope: RecipientEnvelope = {
    ...recipientEnvelope,
    recipients: recipientEnvelope.recipients?.filter(
      recipient => recipient.id !== undefined
    ),
  };

  const recipientsNames = formatRecipientNames(filteredRecipientEnvelope)?.map(
    r => {
      const name = r.name.replace(/ /g, "\u00a0");
      if (r.isGroup) {
        return r.emoji ? `${r.emoji}\u00a0${name}` : name;
      }
      return name;
    }
  );

  return (
    <>
      <span className="truncate">{recipientsNames[0]}</span>
      {recipientsNames.length > 1 && (
        <span className="shrink-0">{`, +${recipientsNames.length - 1}`}</span>
      )}
    </>
  );
};

export default RecipientsLineSimple;

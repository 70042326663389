const PUNCTUATION_REGEX = /[^a-zA-Z0-9]+$/;
const ELLIPSIS = "...";
const CLOSE_CODE_BLOCK = "\n```\n\n";
const CODE_BLOCK_REGEX =
  /^([A-Za-z \t]*)```([A-Za-z]*)?\n([\s\S]*?)```([A-Za-z \t]*)*$/gm;
/**
 * Truncates a given string to a specified length while ensuring that the last word remains intact.
 * @param input - The string to be truncated
 * @param length - The maximum length of the truncated string
 * @param truncateTail - The string to append when the text is truncated (default is '...')
 * @returns An object containing the truncated text
 */
export const truncateMarkdown = (
  input: string,
  length: number,
  truncateTail = ""
) => {
  if (input.length <= length) {
    return input.trim();
  }
  const trimmedInput = input.trim();
  const codeBlocks = trimmedInput.match(CODE_BLOCK_REGEX);
  let blocks: string[] = [];
  if (codeBlocks) {
    blocks = trimmedInput
      .replaceAll(CODE_BLOCK_REGEX, "__CODE_BLOCK_PLACEHOLDER__")
      .split("\n\n")
      .map(block =>
        block === "__CODE_BLOCK_PLACEHOLDER__"
          ? codeBlocks.shift() || block
          : block
      );
  } else {
    blocks = trimmedInput.split("\n\n");
  }

  return appendTruncateTail(getTruncatedBlocks(blocks, length), truncateTail);
};

/**
 * Splits blocks, truncates each block individually, and reassembles the blocks.
 * @param input - The array of blocks to be truncated
 * @param length - The maximum length of the truncated blocks
 * @returns The concatenated string of truncated blocks
 */
function getTruncatedBlocks(input: string[], length: number) {
  return input
    .reduce((prevBlock, current, i, blocks) => {
      const nextBlock = prevBlock.concat(`\n\n${current}`);

      const isCodeBlock = CODE_BLOCK_REGEX.test(nextBlock);
      if (i === 0) {
        const { block, exit } = truncateBlock(nextBlock, length);
        let firstBlock = block;

        if (exit) {
          blocks.splice(1);
          const punctuationMatch =
            firstBlock.length > length
              ? checkForPunctuationMatch(firstBlock)
              : undefined;

          if (punctuationMatch?.index) {
            firstBlock = `${firstBlock.slice(
              0,
              punctuationMatch.index
            )}TRUNCATE_TEXT_BY_BLOCK_PLACEHOLDER`;
          } else if (isCodeBlock) {
            firstBlock = `${firstBlock}${ELLIPSIS}${CLOSE_CODE_BLOCK}TRUNCATE_TEXT_BY_BLOCK_PLACEHOLDER_CODE_BLOCK`;
          }
        }

        return firstBlock;
      }

      if (nextBlock.length > length) {
        blocks.splice(1);
        let { block } = truncateBlock(current, length - prevBlock.length);

        const punctuationMatch = checkForPunctuationMatch(block);
        if (punctuationMatch?.index) {
          block = block.slice(0, punctuationMatch.index);
        }

        if (isCodeBlock) {
          return prevBlock.concat(
            `\n\n${block}${ELLIPSIS}${CLOSE_CODE_BLOCK}TRUNCATE_TEXT_BY_BLOCK_PLACEHOLDER_CODE_BLOCK`
          );
        }

        return prevBlock.concat(
          `\n\n${block}${
            block.includes("\n\n") ? "" : "TRUNCATE_TEXT_BY_BLOCK_PLACEHOLDER"
          }`
        );
      }

      return nextBlock;
    }, "")
    .trim();
}

/**
 * Appends a string to the truncated text to signal where it was truncated.
 * @param input - The truncated text
 * @param truncateTail - The string to append for truncation
 * @returns The truncated text with the append string
 */
function appendTruncateTail(input: string, truncateTail: string) {
  const lastBlockIndex = input.lastIndexOf("\n\n");
  const lastBreakIndex = input.lastIndexOf("<br>");
  const hasEmptyLine = !input
    .slice(lastBreakIndex)
    .replace("<br>", "")
    .replace(/\s/g, "").length;

  if (input.includes("TRUNCATE_TEXT_BY_BLOCK_PLACEHOLDER_CODE_BLOCK")) {
    return input
      .replace("TRUNCATE_TEXT_BY_BLOCK_PLACEHOLDER_CODE_BLOCK", truncateTail)
      .trim();
  }

  if (lastBlockIndex !== -1 && input.slice(lastBlockIndex).includes("\n\n")) {
    return (hasEmptyLine ? input.slice(0, lastBreakIndex - 1) : input)
      .replace(
        "TRUNCATE_TEXT_BY_BLOCK_PLACEHOLDER",
        `${ELLIPSIS} ${truncateTail}`
      )
      .trim();
  }

  return (
    checkForPunctuationMatch(input)?.index
      ? `${input.slice(
          0,
          checkForPunctuationMatch(input)?.index
        )}TRUNCATE_TEXT_BY_BLOCK_PLACEHOLDER`
      : `${input}TRUNCATE_TEXT_BY_BLOCK_PLACEHOLDER`
  )
    .replace(
      "TRUNCATE_TEXT_BY_BLOCK_PLACEHOLDER",
      `${ELLIPSIS} ${truncateTail}`
    )
    .trim();
}

/**
 * Checks for punctuation match in a given string.
 * @param input - The string to be checked
 * @returns A match object if punctuation is found, otherwise null
 */
function checkForPunctuationMatch(input: string) {
  return input.match(PUNCTUATION_REGEX);
}

/**
 * Truncates a given string to a specified length while ensuring that the last word remains intact.
 * @param input - The string to be truncated
 * @param length - The maximum length of the truncated string
 * @returns An object containing the truncated string and a boolean indicating if the string was truncated
 */
function truncateBlock(
  input: string,
  length: number
): { block: string; exit: boolean } {
  if (input.length <= length) {
    return { block: input, exit: false };
  }
  const truncatedString = input.slice(0, length);
  const lastSpaceIndex = truncatedString.lastIndexOf(" ");

  if (lastSpaceIndex !== -1) {
    return {
      block: truncatedString.slice(0, lastSpaceIndex),
      exit: true,
    };
  }

  return { block: truncatedString, exit: true };
}

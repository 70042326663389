import { ExternalObjectMedia } from "generated/graphql";

type Props = {
  audio: Pick<ExternalObjectMedia, "url">;
  className?: string;
};

const AudioPreview = ({ audio, className }: Props): JSX.Element => (
  <div className={className} onClick={e => e.preventDefault()}>
    <audio
      className="w-11/12 min-w-0 opacity-95"
      src={audio.url}
      style={{ maxWidth: "300px" }}
      controls
    >
      Your browser does not support the
      <code>audio</code> element.
    </audio>
  </div>
);

export default AudioPreview;

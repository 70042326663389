import { GlueLink, Message, ThreadPreview, User, nodeAs } from "@utility-types";
import { routeToThread } from "components/routing/utils";
import { FeedType, useFetchMessageQuery } from "generated/graphql";

import { Skeleton } from "components/Skeleton";
import useAuthData from "hooks/useAuthData";
import ActivityItemLayout from "./ActivityItemLayout";
import { MessagePreview } from "./MessagePreview";

type NotificationCreatedMessageLinkProps = {
  actor: User;
  isRead: boolean;
  object: GlueLink;
  onClick: (e: React.MouseEvent<HTMLLIElement>) => void;
  selected: boolean;
  target: ThreadPreview;
  timestamp: string;
};

const ActivityItemCreatedMessageLink = ({
  actor,
  object,
  target,
  ...props
}: NotificationCreatedMessageLinkProps) => {
  let messageType: "Message" | "MessagePreview" | undefined = undefined;
  let streamID: Message["streamID"] = "";

  // re-fetch from API if we haven't cached the full message yet
  const { data } = useFetchMessageQuery({
    fetchPolicy:
      messageType === "MessagePreview" ? "cache-and-network" : "cache-first",
    variables: { id: object.message.id },
  });

  const message = nodeAs(data?.node, ["Message", "MessagePreview"]);
  messageType = message?.__typename;

  if (message?.__typename === "Message") {
    streamID = message.streamID;
  }

  const user = useAuthData();

  if (!user?.authData) return null;

  return (
    <ActivityItemLayout
      avatarProps={{ ...actor }}
      linkTo={toSecondary =>
        routeToThread({
          superTab: "activity",
          messageID: streamID ?? undefined,
          threadID: target.id,
          to: toSecondary ? "secondary" : "primary",
        })
      }
      title={`Mention in ${target.name}`}
      type={FeedType.Mentions}
      {...props}
    >
      {message?.__typename === "Message" ? (
        <MessagePreview
          message={message}
          authData={user.authData}
          title={actor.name}
        />
      ) : (
        <Skeleton height="22" width="100" />
      )}
    </ActivityItemLayout>
  );
};

export default ActivityItemCreatedMessageLink;

import {
  CSSProperties,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { useAbortController } from "use-abort-controller-hook";

import { GlueFile } from "@utility-types";

import ProgressModal from "components/Modals/ProgressModal";
import { Portal } from "components/Portal";
import { Button } from "components/design-system/Button";
import { Icon } from "components/design-system/icons";
import { IFrame } from "components/helper";
import {
  LayerProvider,
  LayerStackPriority,
  useLayerState,
} from "providers/LayerProvider/LayerProvider";
import useModalStore from "store/useModalStore";
import useProgressStore from "store/useProgressStore";
import { isNativeMobile } from "utils/platform";
import shareFileNativeMobile from "utils/shareFileNativeMobile";
import tw from "utils/tw";
import useGetBoxData from "./hooks/useGetBoxData";
import { CurrentFile } from "./types";

const EscHandler = ({ reset }: { reset: () => void }) => {
  const { layerIsActive } = useLayerState(({ layerIsActive }) => ({
    layerIsActive,
  }));

  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (!layerIsActive) return;
      if (e.key === "Escape") {
        e.preventDefault();
        e.stopPropagation();

        reset();
      }
    };

    window.addEventListener("keydown", handleEsc);
    return () => window.removeEventListener("keydown", handleEsc);
  }, [reset, layerIsActive]);

  return null;
};

const headElements = [
  {
    type: "link" as const,
    values: {
      url: "https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=block",
    },
  },
  {
    type: "link" as const,
    values: {
      url: "https://cdn01.boxcdn.net/platform/preview/2.78.0/en-US/preview.css",
    },
  },
  {
    type: "script" as const,
    values: {
      url: "https://cdn01.boxcdn.net/platform/preview/2.78.0/en-US/preview.js",
    },
  },
  {
    type: "inlineStyle" as const,
    values: {
      text: `html,
      body, 
      body > div, 
      .box-modal {
        font-family: 'Lato', sans-serif;
        width: 100vw;
        height: 100vh;
        margin: 0;
      }
      button.bp-navigate {
        display: none!important;
      }
      .loading {
        display:flex;
        justify-content: center;
        font-size: 15px;
        line-height: 18px;
        align-items: center;
        width: 100%;
        height: 100%;
        position: fixed;
        top:0; 
        left:-0.5px;
        color: rgba(227, 229, 232, 1);
      }`,
    },
  },
];

type BoxData = {
  boxAccessToken: string;
  boxFileID: string;
};

export type FileSimple = Pick<GlueFile, "id" | "name" | "url">;

type FilePreviewProps = {
  files: FileSimple[];
};

export type FilePreviewRef = {
  openPreview: (file: FileSimple) => void;
};

const FilePreview = forwardRef<FilePreviewRef, FilePreviewProps>(
  ({ files }: FilePreviewProps, ref) => {
    const [boxData, setBoxData] = useState<BoxData[]>();
    const [currentFile, setCurrentFile] = useState<CurrentFile>();

    const boxModalHeaderRef = useRef<HTMLDivElement>(null);
    const boxModalRef = useRef<HTMLDivElement>(null);
    const iFrameRef = useRef<HTMLIFrameElement>(null);
    const refCurrentIndex = useRef<number>();
    const previewInstanceRef = useRef<Preview>();

    const { boxIDs, getBoxData } = useGetBoxData({ files });

    const reset = useCallback(() => {
      refCurrentIndex.current = undefined;
      setCurrentFile(undefined);
      setBoxData(undefined);
      previewInstanceRef.current?.hide();
      previewInstanceRef.current = undefined;
    }, []);

    const abortController = useAbortController();
    const { openModal } = useModalStore(({ openModal }) => ({
      openModal,
    }));

    const showProgress = () =>
      openModal(
        <ProgressModal
          autoCloseDelay={0}
          header={"Downloading File"}
          onCancel={abortController.abort}
        />
      );

    useEffect(() => {
      if (!boxData) return;

      const Box = iFrameRef.current?.contentWindow?.Box;
      if (Box && !previewInstanceRef.current) {
        previewInstanceRef.current = new Box.Preview();
      }
      const { current: container } = boxModalRef;
      const { current: preview } = previewInstanceRef;

      if (!preview || !container || !boxData || boxData.length === 0) return;
      const collection = boxData.map(file => file.boxFileID);

      const update = (boxId: string) => {
        const glueId = [...boxIDs.current.values()]
          .filter(file => file.boxId && file.boxId === boxId)
          .map(file => file.glueId)[0];
        const file = files.find(({ id }) => id === glueId);
        setCurrentFile({
          boxId,
          downloadUrl: file?.url,
          glueId: file?.id,
          isFirst: preview.collection?.[0] === boxId,
          isLast: preview.collection?.pop() === boxId,
          title: file?.name || "",
        });
        preview.updateCollection(collection);
      };

      const loadHandler = ({
        file: { id: boxId },
      }: {
        file: { id: string };
      }) => {
        update(boxId);
      };

      const navigateHandler = (boxId: string) => {
        refCurrentIndex.current = collection.findIndex(file => file === boxId);
        update(boxId);
      };

      preview.addListener("load", loadHandler);
      preview.addListener("navigate", navigateHandler);

      if (refCurrentIndex.current) return;

      preview.show(
        boxData[0]?.boxFileID ?? "",
        () =>
          Promise.resolve(
            Object.fromEntries(
              boxData.map(({ boxAccessToken, boxFileID }) => [
                boxFileID,
                boxAccessToken,
              ])
            )
          ),
        {
          collection,
          container,
          header: "none",
          showDownload: true,
        }
      );
    }, [boxData, boxIDs, files]);

    const openPreview = useCallback(
      async (file: FileSimple) => {
        setCurrentFile({
          isFirst: true,
          title: file.name,
          downloadUrl: file.url,
          glueId: file.id,
        });

        const boxData: (BoxData | undefined)[] = await getBoxData(file.id);
        setBoxData(boxData.filter((item): item is BoxData => !!item));
      },
      [getBoxData]
    );

    useImperativeHandle(ref, () => ({
      openPreview,
    }));

    return (
      <Portal>
        <LayerProvider
          id={LayerStackPriority[LayerStackPriority.Topmost]}
          isActive={!!currentFile}
          zIndex={LayerStackPriority.Topmost}
        >
          {currentFile && (
            <div
              className="box-file-preview fixed bg-background-black/80 top-0 left-0 w-full h-full"
              onClick={reset}
              style={
                {
                  "--header-height": `${boxModalHeaderRef.current?.offsetHeight}px`,
                } as CSSProperties
              }
            >
              <header
                ref={boxModalHeaderRef}
                className="flex justify-between w-full"
              >
                <div className="flex items-center px-15 text-sm text-interactive-ghost">
                  {refCurrentIndex.current !== undefined &&
                    `${refCurrentIndex.current + 1} / ${boxIDs.current.size}`}
                </div>
                <div className="flex" onClick={e => e.stopPropagation()}>
                  <Button
                    buttonStyle="none"
                    // TODO: fix these colors waiting for design - semantic names are off
                    className="hover:text-ghost-accent text-interactive-subtle-disabled disabled:text-interactive-subtle-hover p-10 h-44 w-44"
                    disabled={!!currentFile.isFirst}
                    icon="ArrowLeft"
                    iconSize={24}
                    onClick={previewInstanceRef.current?.navigateLeft}
                    type="button"
                  />

                  <Button
                    buttonStyle="none"
                    // TODO: fix these colors waiting for design - semantic names are off
                    className="hover:text-ghost-accent text-interactive-subtle-disabled disabled:text-interactive-subtle-hover p-10 h-44 w-44"
                    disabled={!!currentFile.isLast}
                    icon="ArrowRight"
                    iconSize={24}
                    onClick={previewInstanceRef.current?.navigateRight}
                    type="button"
                  />

                  {isNativeMobile() ? (
                    <Button
                      buttonStyle="none"
                      // TODO: fix these colors waiting for design - semantic names are off
                      className="hover:text-ghost-accent text-interactive-subtle-disabled p-10 h-44 w-44"
                      icon="Download"
                      iconSize={22}
                      onClick={() => {
                        if (!currentFile.downloadUrl) return;

                        shareFileNativeMobile({
                          abortSignal: abortController.signal,
                          dialogTitle: "Share file",
                          fileName: currentFile.title,
                          onError: () => {
                            useProgressStore.setState({
                              message: {
                                text: "Unknown error while sharing file.",
                                type: "error",
                              },
                            });
                          },
                          showProgress,
                          title: currentFile.title,
                          url: currentFile.downloadUrl,
                        });
                      }}
                      type="button"
                    />
                  ) : (
                    <a
                      // TODO: fix these colors waiting for design - semantic names are off
                      className="hover:text-ghost-accent text-interactive-subtle-disabled p-10 h-44 w-44"
                      href={currentFile.downloadUrl}
                      rel="noreferrer"
                      target="_blank"
                      download
                    >
                      <Icon icon="Download" size={22} />
                    </a>
                  )}
                  {!isNativeMobile() && (
                    <Button
                      buttonStyle="none"
                      className="hover:text-ghost-accent text-interactive-subtle-disabled p-10 h-44 w-44"
                      icon="Printer"
                      // TODO: fix these colors waiting for design - semantic names are off
                      iconSize={22}
                      onClick={previewInstanceRef.current?.print}
                      type="button"
                    />
                  )}
                  <Button
                    buttonStyle="none"
                    className="hover:text-ghost-accent text-interactive-subtle-disabled p-10 h-44 w-44"
                    // TODO: fix these colors waiting for design - semantic names are off
                    icon="Close"
                    iconSize={24}
                    onClick={reset}
                    type="button"
                  />
                  <EscHandler reset={reset} />
                </div>
              </header>
              {!boxModalHeaderRef.current ? (
                <div className="flex fixed top-0 left-0 justify-center items-center w-full h-full text-base font-normal text-interactive-ghost">
                  <p>Loading...</p>
                </div>
              ) : null}
              <IFrame
                ref={iFrameRef}
                className={tw("w-full h-full md:max-w-4xl mx-auto md:px-20", {
                  "opacity-0": !boxModalHeaderRef.current,
                })}
                headElements={headElements}
                title="file-preview"
              >
                <div
                  ref={boxModalRef}
                  className="box-modal"
                  onClick={e => e.stopPropagation()}
                >
                  <div className="loading" onClick={reset}>
                    <p>Loading...</p>
                  </div>
                </div>
              </IFrame>
              <footer className="flex justify-center items-center w-full">
                <div className="file-title text-center text-interactive-ghost truncate">
                  {currentFile.title}
                </div>
              </footer>
            </div>
          )}
        </LayerProvider>
      </Portal>
    );
  }
);

export default FilePreview;

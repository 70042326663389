import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import { nodeAs } from "@utility-types";
import {
  FetchGroupDocument,
  FetchGroupQuery,
  FetchWorkspaceDocument,
  FetchWorkspaceQuery,
} from "generated/graphql";
import useComponentMounted from "hooks/useComponentMounted";

export const useIsGroupMember = () => {
  const isMounted = useComponentMounted();
  const apolloClient = useApolloClient();

  const queryGrp = useCallback(
    (variables: { id: string }) =>
      apolloClient.query<FetchGroupQuery>({
        fetchPolicy: "cache-first",
        query: FetchGroupDocument,
        variables,
      }),
    [apolloClient]
  );

  const queryWks = useCallback(
    (variables: { id: string }) =>
      apolloClient.query<FetchWorkspaceQuery>({
        fetchPolicy: "cache-first",
        query: FetchWorkspaceDocument,
        variables,
      }),
    [apolloClient]
  );

  return useCallback(
    async (group: { id: string }, user: { id: string }) => {
      const groupNode = group.id.startsWith("wks_")
        ? nodeAs((await queryWks(group)).data?.node, ["Workspace"])
        : nodeAs((await queryGrp(group)).data?.node, ["Group"]);

      if (!isMounted.current) return;

      return !!groupNode?.members.edges
        ?.map(m => m.node)
        .find(m => m.id === user.id);
    },
    [isMounted, queryGrp, queryWks]
  );
};

import { Button } from "components/design-system/Button";
import useAppStateStore from "store/useAppStateStore";
import tw from "utils/tw";

const LessButton = ({
  className,
  hide,
  onClick,
}: {
  className?: string;
  hide?: boolean;
  onClick?: () => void;
}) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  return (
    <li className={tw("mx-12 !px-0 py-6", { "px-8": !hide }, className)}>
      <Button
        buttonFont="small"
        buttonStyle="subtle"
        className={tw("!p-0 border-none w-full", {
          "flex justify-center": hide,
        })}
        icon="ChevronUp"
        iconClassName={tw({ "mr-8": !hide })}
        iconSize={breakpointMD ? 20 : 24}
        onClick={onClick}
      >
        {!hide && "Less"}
      </Button>
    </li>
  );
};

export default LessButton;

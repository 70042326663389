import { Recipient, ThreadEdgeSimple, nodeAs } from "@utility-types/graphql";
import { useFetchThreadEdgeSimpleQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useAppStateStore from "store/useAppStateStore";

type ReturnType = {
  threadEdge: ThreadEdgeSimple | null;
  recipients: Recipient[];
};

const useThreadRecipients = ({
  threadID,
}: { threadID?: string }): ReturnType => {
  const { authData, authReady } = useAuthData();

  const { activeThreadId } = useAppStateStore(({ activeThreadId }) => ({
    activeThreadId,
  }));

  const id = threadID ?? activeThreadId;

  const { data: thrData } = useFetchThreadEdgeSimpleQuery({
    fetchPolicy: authReady ? "cache-first" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !id?.startsWith("thr_") || !authData?.me.id,
    variables: { id: `${id}-${authData?.me.id}` },
  });

  const memberEdge = nodeAs(thrData?.node, ["ThreadEdge"]);

  return {
    threadEdge: memberEdge ?? null,
    recipients:
      memberEdge?.node.recipients.edges
        .filter(r => r.node.id !== authData?.me.id)
        .map(r => r.node) ?? [],
  };
};

export default useThreadRecipients;

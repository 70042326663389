import useLocalSettingsStore from "store/useLocalSettingsStore";

const useEnterKeyBehavior = (editorInModal = false) => {
  const { enterKeyBehavior } = useLocalSettingsStore(
    ({ enterKeyBehavior }) => ({ enterKeyBehavior })
  );

  // always use "new-line" behavior when in modal
  return enterKeyBehavior === "send-message" && !editorInModal
    ? "send-message"
    : "new-line";
};

export default useEnterKeyBehavior;

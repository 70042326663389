import { useCallback } from "react";

import { UserEdge } from "@utility-types";
import {
  FetchUsersDocument,
  UserEdgeStatus,
  useUpdateUserEdgeMutation,
} from "generated/graphql";

type Result = {
  status?: UserEdgeStatus;
  toggleUserStatus: (status: UserEdgeStatus) => void;
};

const useUserProfileAction = (userEdge?: UserEdge): Result => {
  const [updateUserEdge] = useUpdateUserEdgeMutation({
    errorPolicy: "all",
  });

  const toggleUserStatus = useCallback(
    (status: UserEdgeStatus) => {
      if (!userEdge) return;

      const newStatus =
        userEdge.status !== status ? status : UserEdgeStatus.None;

      updateUserEdge({
        optimisticResponse: {
          updateUserEdge: {
            ...userEdge,
            status: newStatus,
          },
        },
        refetchQueries: [FetchUsersDocument],
        variables: {
          id: userEdge.id,
          input: { status: newStatus },
        },
      });
    },
    [userEdge, updateUserEdge]
  );

  return {
    status: userEdge?.status,
    toggleUserStatus,
  };
};

export default useUserProfileAction;

// cspell:disable
const trackingPrefixes = [
  "ad_",
  "_bta_",
  "campaign_",
  "dclid",
  "epik",
  "ef_id",
  "fbclid",
  "gad_",
  "gclid",
  "hsa_",
  "igshid",
  "mc_",
  "msclkid",
  "mtm_",
  "piwik_",
  "pk_",
  "s_kwicid",
  "twelkd",
  "utm_",
];
// cspell:enable

const normalizeSharedURL = (url: string) => {
  let urlObject: URL;
  try {
    urlObject = new URL(url);
  } catch {
    return url;
  }

  // Remove tracking parameters
  const searchParams = urlObject.searchParams;
  for (const key of Array.from(searchParams.keys())) {
    if (trackingPrefixes.some(prefix => key.startsWith(prefix))) {
      searchParams.delete(key);
    }
  }

  return urlObject.toString();
};

export default normalizeSharedURL;

import { useEffect, useRef } from "react";
import { useEditorContentState } from "../providers/EditorContentProvider";

/**
 * Used to prevent editor submission, pressing `Enter`, while processing;
 * for example, while the emoji or mention suggest popover is open.
 */
const useEditorIsProcessing = (isProcessing?: boolean) => {
  const { setState: editorContentUpdater } = useEditorContentState();
  const editorContentUpdaterRef = useRef(editorContentUpdater);
  editorContentUpdaterRef.current = editorContentUpdater;

  useEffect(() => {
    editorContentUpdaterRef.current({ isProcessing });
  }, [isProcessing]);
};

export default useEditorIsProcessing;

import { useMemo } from "react";

import { Emoji } from "@emoji-mart/data";
import { CustomEmoji } from "@utility-types/graphql";
import { MemberRole, useCustomEmojisQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import glueImageURL from "utils/glueImageURL";

type WorkspaceEmojis = {
  isAdmin: boolean;
  workspaceName: string;
  emojis: CustomEmoji[];
};

const useCustomEmojisList = (refreshData = false) => {
  const { authData } = useAuthData();

  // Initial fetch for custom emojis. Future fetches
  // will happen via the useSyncAppState hook
  const { data, loading } = useCustomEmojisQuery({
    fetchPolicy: refreshData ? "cache-and-network" : "cache-first",
    // TODO: handle pagination of more custom emojis
    variables: { first: 200 },
  });

  const emojisPerWorkspace: WorkspaceEmojis[] = useMemo(
    () =>
      authData?.workspaces.edges.map(w => ({
        isAdmin: w.memberRole === MemberRole.Admin,
        workspaceName: w.node.name,
        emojis:
          data?.customEmojis.edges
            .filter(e => e.node.workspaceID === w.node.id)
            .map(e => e.node)
            .reverse() ?? [],
      })) ?? [],
    [authData?.workspaces.edges, data?.customEmojis.edges]
  );

  const pickerCustomEmojis: {
    id: string;
    name: string;
    emojis: Emoji[];
  }[] = useMemo(
    () =>
      emojisPerWorkspace
        .filter(e => e.emojis.length)
        .map((w, _, arr) => ({
          id: arr.length > 1 ? w.workspaceName : "Custom",
          name: arr.length > 1 ? w.workspaceName : "Custom",
          emojis: w.emojis.map(e => ({
            id: e.id.replace("emj_", ""),
            name: e.name,
            keywords: [e.name],
            skins: [
              {
                src: glueImageURL(e.imageURL, {
                  fit: "max",
                  w: 48,
                  h: 48,
                }),
              },
            ],
            version: 1,
          })),
        })),
    [emojisPerWorkspace]
  );

  return {
    emojisPerWorkspace,
    emojiDataLoading: loading,
    pickerCustomEmojis,
  };
};

export default useCustomEmojisList;

import type { EditorView } from "@remirror/core-types";
import { Selection, getMarkRange } from "remirror";

export const getLinkData = (selection: Selection, view: EditorView) => {
  const { $from, $to, from, to } = selection;
  const selectedText = view.state.doc.textBetween(from, to);
  const selectedMark = getMarkRange($from, "link", $to);

  return {
    href: /* istanbul ignore next */ selectedMark?.mark.attrs.href || "",
    range: /* istanbul ignore next */ selectedMark ?? { from, to },
    text: /* istanbul ignore next */ selectedMark?.text || selectedText,
  };
};

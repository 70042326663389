import { Form, OnChangeSubmit, Toggle } from "components/design-system/Forms";
import { Icon } from "components/design-system/icons";
import {
  Addable,
  ThreadFieldsFragment,
  ThreadPreviewFieldsFragment,
  useUpdateThreadMutation,
} from "generated/graphql";
import type { FormData } from "./types";

const RecipientsAddable = ({
  isAdmin,
  thread,
}: {
  isAdmin: boolean;
  thread?: ThreadFieldsFragment | ThreadPreviewFieldsFragment;
}) => {
  const [updateThread] = useUpdateThreadMutation({
    errorPolicy: "all",
  });

  if (!isAdmin || thread?.__typename !== "Thread") return null;

  const isLocked = thread.recipientsAddable === Addable.None;

  const handleOnSubmit = (data: { recipientsAddable: boolean }) => {
    const recipientsAddable = data.recipientsAddable
      ? Addable.None
      : Addable.Anyone;

    return updateThread({
      optimisticResponse: {
        updateThread: {
          ...thread,
          recipientsAddable,
        },
      },
      variables: {
        id: thread.id,
        input: {
          recipientsAddable,
        },
      },
    });
  };

  return (
    <Form
      onSubmit={handleOnSubmit}
      useFormProps={{
        defaultValues: {
          recipientsAddable: !!isLocked,
        },
      }}
    >
      <OnChangeSubmit<{ recipientsAddable: boolean }>
        name="recipientsAddable"
        submitHandler={handleOnSubmit}
      >
        <Toggle<FormData>
          disabled={!isAdmin}
          label={
            <div className="flex items-center">
              {isLocked ? (
                <Icon
                  className="text-accent-badge mr-10"
                  icon="Lock"
                  size={18}
                />
              ) : (
                <Icon
                  className="text-interactive-subtle mr-10"
                  icon="Unlock"
                  size={18}
                />
              )}{" "}
              Locked recipient list (require approval)?
            </div>
          }
          labelClassName="w-full"
          name="recipientsAddable"
          wrapperClassName="mb-16"
        />
      </OnChangeSubmit>
    </Form>
  );
};

export default RecipientsAddable;

import {
  ComponentProps,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { useFormContext } from "react-hook-form";

import { Button } from "components/design-system/Button";
import { EmojiListWrapper } from "components/design-system/EmojiList";
import {
  Form,
  Label,
  SubmitButton,
  TextInput,
} from "components/design-system/Forms";
import {
  ThreadFieldsFragment,
  ThreadPreviewFieldsFragment,
  useUpdateThreadMutation,
} from "generated/graphql";
import useModalStore from "store/useModalStore";
import { formatNameEmoji } from "utils/formatNameEmoji";
import tw from "utils/tw";

import type { FormData } from "./types";

const FormFields = ({
  canEdit,
  editing,
  focusSubject,
  toggleEditing,
}: Omit<ComponentProps<typeof SubjectForm>, "thread"> & {
  editing: boolean;
  toggleEditing: () => void;
}) => {
  const { setFocus } = useFormContext<Pick<FormData, "subject">>();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { topModalState } = useModalStore(({ topModalState }) => ({
    topModalState,
  }));

  useEffect(() => {
    if (canEdit && focusSubject && topModalState === "open") {
      toggleEditing();
    }
  }, [canEdit, focusSubject, toggleEditing, topModalState]);

  useEffect(() => {
    if (!editing) return;
    setFocus("subject");
  }, [editing, setFocus]);

  return (
    <>
      <Label className="!font-semibold !text-text-strong">Subject</Label>
      <div className="flex items-center mb-16">
        <div className="grow" onClick={editing ? undefined : toggleEditing}>
          <EmojiListWrapper>
            <TextInput
              className={tw(
                !canEdit || editing ? undefined : "!cursor-pointer",
                "text-lg font-semibold"
              )}
              config={{ required: true }}
              disabled={!canEdit || !editing}
              inputRef={inputRef}
              name="subject"
              wrapperClassName="!my-0"
            />
          </EmojiListWrapper>
        </div>
        {canEdit &&
          (editing ? (
            <SubmitButton
              buttonStyle="simplePrimary"
              className="justify-center pl-16 h-[42px]"
              icon="Check"
              iconSize={20}
              iconStroke={2}
            />
          ) : (
            <Button
              buttonStyle="subtle"
              className="justify-center pl-16 h-[42px]"
              icon="Compose"
              onClick={toggleEditing}
              type="button"
            />
          ))}
      </div>
    </>
  );
};

const SubjectForm = ({
  canEdit,
  focusSubject,
  thread,
}: {
  canEdit: boolean;
  focusSubject?: boolean;
  thread?: ThreadFieldsFragment | ThreadPreviewFieldsFragment;
}) => {
  const [editing, setEditing] = useState(false);
  const [updateThread] = useUpdateThreadMutation({
    errorPolicy: "all",
  });

  const toggleEditing = useCallback(
    (e?: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
      e?.preventDefault();
      setEditing(prev => !prev);
    },
    []
  );

  const handleOnSubmit = (input: { subject: string }) => {
    if (thread?.__typename !== "Thread") {
      return Promise.resolve();
    }

    input.subject = formatNameEmoji({ name: input.subject }).nameWithEmoji;

    if (input.subject === thread.subject) {
      toggleEditing();
      return Promise.resolve();
    }

    return updateThread({
      optimisticResponse: {
        updateThread: {
          ...thread,
          ...input,
        },
      },
      variables: {
        id: thread.id,
        input,
      },
    }).then(() => toggleEditing());
  };

  return (
    <Form
      onSubmit={handleOnSubmit}
      useFormProps={{
        defaultValues: {
          subject: thread?.subject || "",
        },
      }}
    >
      <FormFields
        canEdit={canEdit}
        editing={editing}
        focusSubject={focusSubject}
        toggleEditing={toggleEditing}
      />
    </Form>
  );
};

export default SubjectForm;

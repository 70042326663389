type Props = {
  children: React.ReactNode;
};

export function Badge({ children }: Props) {
  return (
    <div className="bg-background-warning rounded-sm py-2 px-4 text-text-warning text-caption-bold">
      {children}
    </div>
  );
}

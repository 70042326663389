import { memo } from "react";

import { GlueDefaultStreamChatGenerics } from "@utility-types";
import EditMessageForm from "components/threads-list/EditMessageForm";

import type { MessageInputProps } from "./types";

const UnMemoizedMessageInput = <T extends GlueDefaultStreamChatGenerics>({
  disabled = false,
  message,
  messageWrapperRef,
  ...props
}: MessageInputProps<T>) => {
  return (
    <EditMessageForm
      {...props}
      disabled={disabled}
      message={message}
      messageWrapperRef={messageWrapperRef}
    />
  );
};

/**
 * MessageInput - a high level component that has provides all functionality to the Input it renders.
 *
 * It exposes the [useMessageInput](https://getstream.github.io/stream-chat-react/#section-usemessageinput) hook, which accepts the MessageInput props and returns
 * all functions needed to customize and build your custom Input components.
 * @example ./MessageInput.md
 */
export const MessageInput = memo(
  UnMemoizedMessageInput
) as typeof UnMemoizedMessageInput;

import { cloneElement, useEffect, useRef, useState } from "react";

import { getEmojisByName } from "components/design-system/Emoji/data";
import type { FlatEmoji } from "svgmoji";

import useMenuKeyboardShortcuts from "hooks/useMenuKeyboardShortcuts";

import useFloatingElement from "../FloatingUi/useFloatingElement";
import { useCaretPosition } from "../Forms";

import EmojiList from "./EmojiList";

const EmojiListWrapper = ({
  children,
  className,
}: {
  children: JSX.Element;
  className?: string;
}) => {
  const [emojis, setEmojis] = useState<FlatEmoji[] | null>(null);
  const [selectedItemRef, setSelectedItemRef] = useState<HTMLDivElement | null>(
    null
  );
  const emojiListRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { anchorProps, floatingProps, isOpen, setIsOpen } = useFloatingElement({
    strategy: "fixed",
    placement: "bottom-start",
  });

  const { selectedItem } = useMenuKeyboardShortcuts({
    closeMenu: () => setEmojis(null),
    data: emojis ?? [],
    onSelectItem: item => onSelectEmoji(0, item),
    scrollProps: { block: "center" },
    selectedItemRef: selectedItemRef,
  });

  const { left, position, resetPosition, ...caretEvents } = useCaretPosition(
    inputRef.current
  );

  useEffect(() => {
    const selectedText = position?.selectedText.text ?? "";
    // Two letters after the ":" character
    if (selectedText.startsWith(":") && selectedText.length > 2) {
      getEmojisByName(selectedText.replace(":", ""), 20).then(emojis =>
        setEmojis(emojis)
      );
      return;
    }
    setEmojis(null);
  }, [position]);

  useEffect(() => {
    setIsOpen(!!emojis);
  }, [emojis, setIsOpen]);

  const onSelectEmoji = (_index: number, emoji: FlatEmoji) => {
    if (!position || !inputRef.current) return;
    const { selectedText, value } = position;

    const before = value.substring(0, selectedText.start);
    let after = value.substring(selectedText.end);
    if (after.at(0) !== " ") {
      after += ` ${after}`;
    }

    const input = inputRef.current;
    input.focus();
    input.value = `${before}${emoji.emoji}${after}`;
    input.dispatchEvent(
      new Event("input", { bubbles: true, cancelable: true })
    );

    const caretAt = selectedText.start + emoji.emoji.length + 1;
    input.setSelectionRange(caretAt, caretAt);

    resetPosition();
    setEmojis(null);
  };

  const selectedIndex =
    emojis?.findIndex(e => e.emoji === selectedItem?.emoji) ?? 0;

  return (
    <>
      <div className={className} {...anchorProps}>
        {cloneElement(children, {
          disableArrowShortcuts: isOpen,
          inputRef: inputRef,
          onKeyDown: (e: KeyboardEvent) =>
            e.key === "Escape" && resetPosition(),
          ...caretEvents,
        })}
      </div>
      {isOpen && (
        <div className="z-1" {...floatingProps}>
          <EmojiList
            containerProps={{
              className:
                "relative bg-background-modal rounded-lg border-thin border-interactive-strong/30 shadow-strong-ui",
              ref: emojiListRef,
              style: { left },
            }}
            emojis={emojis ?? []}
            itemProps={(index, emoji) => ({
              onClick: () => onSelectEmoji(index, emoji),
              ref:
                selectedIndex === index
                  ? (e: HTMLDivElement | null) =>
                      e !== null && setSelectedItemRef(e)
                  : undefined,
            })}
            selectedIndex={selectedIndex}
          />
        </div>
      )}
    </>
  );
};

export default EmojiListWrapper;

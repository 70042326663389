import { devtools, subscribeWithSelector } from "zustand/middleware";

import { glueCreateZustand } from "./helper/glueCreate";

type AnimationStates = "complete" | "paused" | "running";

const STORE_NAME = "NativeKeyboardStore";

type KeyboardStore = {
  animationState: AnimationStates;
  isAccessoryBarVisible: boolean;
  keyboardHeight: number;
  setState: (values: Partial<KeyboardStore>) => void;
  state: "open" | "close" | "opening" | "closing";
};

const useNativeKeyboardStore = glueCreateZustand<KeyboardStore>({
  name: STORE_NAME,
})(
  devtools(
    subscribeWithSelector(set => ({
      animationState: "paused",
      isAccessoryBarVisible: true,
      keyboardHeight: 0,
      setState: values => set(state => ({ ...state, ...values })),
      state: "close",
    })),
    { name: STORE_NAME }
  )
);

export default useNativeKeyboardStore;

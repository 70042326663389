import { devtools, subscribeWithSelector } from "zustand/middleware";

import generateRandomId from "utils/generateRandomId";

import {
  MasonryRequestInput,
  MasonrySessionOriginationInput,
} from "generated/graphql";
import { glueCreateZustand } from "store/helper/glueCreate";

export type MasonryInitialRequestInput = Omit<
  MasonryRequestInput,
  "session" | "timestamp" | "surface"
>;

export type MasonrySessionElement = {
  id: string;
  origination: MasonrySessionOriginationInput;
  input: MasonryInitialRequestInput;
};

const STORE_NAME = "MasonrySessionStore";

type SessionStore = {
  sessions: MasonrySessionElement[];
  startSession: (
    origination: MasonrySessionOriginationInput,
    input: MasonryInitialRequestInput
  ) => string;
  sessionIsActive: (sessionID: MasonrySessionElement["id"]) => boolean;
  removeSession: (id: MasonrySessionElement["id"]) => void;
};

const useMasonrySessionStore = glueCreateZustand<SessionStore>({
  name: STORE_NAME,
})(
  subscribeWithSelector(
    devtools<SessionStore>(
      (set, get) => ({
        backdropId: undefined,
        enqueuedToClose: [],

        sessionIsActive: sessionID =>
          !!get().sessions.find(session => sessionID === session.id),

        sessions: [],

        startSession: (origination, input) => {
          const sessionID = generateRandomId();
          set(state => {
            return {
              sessions: [
                {
                  id: sessionID,
                  origination,
                  input,
                },
                ...state.sessions,
              ],
            };
          });
          return sessionID;
        },

        removeSession: id =>
          set(state => {
            return {
              sessions: state.sessions.filter(session => session.id !== id),
            };
          }),
      }),
      {
        name: STORE_NAME,
        serialize: true,
      }
    )
  )
);

export default useMasonrySessionStore;

import { RenderMarkdown } from "md";

import { BlockInteractionHandlers } from "./BlockComponent";
import { Text } from "./blockTypes";

type BlockMarkdownProps = {
  handlers: BlockInteractionHandlers;
  text: Text;
};

const BlockMarkdown = ({ text, handlers }: BlockMarkdownProps) => {
  const handleMasonryLink = (url: string) => {
    handlers.onLink(url.replace("masonry:", ""));
  };
  return <div>{RenderMarkdown(text.text, { handleMasonryLink })}</div>;
};

export default BlockMarkdown;

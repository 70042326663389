import { differenceInSeconds } from "date-fns";
import { StreamMessage } from "stream-chat-react";

import { GlueDefaultStreamChatGenerics, Message } from "@utility-types";
import { glueMessageToStreamMessage } from "utils/stream/message";

type Props<T extends GlueDefaultStreamChatGenerics> = {
  messages: StreamMessage<T>[];
  replyToMessage?: Message;
  threadStartedAt?: string;
};

const useReplyToMessage = <T extends GlueDefaultStreamChatGenerics>({
  messages,
  replyToMessage,
  threadStartedAt,
}: Props<T>): StreamMessage<T>[] => {
  if (!replyToMessage) return messages;

  const firstMessage = messages[0];
  if (firstMessage?.id === replyToMessage.id) return messages; // already added

  const allMessagesLoaded =
    !firstMessage ||
    differenceInSeconds(
      new Date(firstMessage.created_at || 0),
      new Date(threadStartedAt || 0)
    ) < 2;

  if (!allMessagesLoaded) return messages;

  return [glueMessageToStreamMessage(replyToMessage), ...messages];
};

export default useReplyToMessage;

import { nodeAs } from "@utility-types/graphql";
import { useFetchUserEdgeQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import env from "utils/processEnv";

export const useGlueAIBot = () => {
  const { authData } = useAuthData();

  const { data } = useFetchUserEdgeQuery({
    fetchPolicy: "cache-first",
    skip: !authData?.me.id || !env.glueAIBotID,
    variables: {
      id: `${env.glueAIBotID}-${authData?.me.id}`,
    },
  });

  const glueAIBot = nodeAs(data?.node, ["UserEdge"])?.node;

  return glueAIBot;
};

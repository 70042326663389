import { nodeAs } from "@utility-types";
import {
  AppConfigureMethod,
  AppFieldsFragment,
  FetchThreadEdgeDocument,
  MemberRole,
  ThreadFieldsFragment,
  ThreadPreviewFieldsFragment,
  useAddThreadRecipientsMutation,
  useRemoveThreadRecipientsMutation,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useRefetchOnAppActive from "hooks/useRefetchOnAppActive";

import { useConfigureApps } from "components/apps";

const useThreadApps = (
  thread?: ThreadFieldsFragment | ThreadPreviewFieldsFragment
) => {
  const { configureApp, editApp, getAppsAvailable } = useConfigureApps();
  const { authData } = useAuthData();
  useRefetchOnAppActive([FetchThreadEdgeDocument]);

  const [addThreadRecipients] = useAddThreadRecipientsMutation();
  const [removeThreadRecipients] = useRemoveThreadRecipientsMutation();

  const workspaceID = authData?.me.workspaceIDs[0];

  const appsAvailable = getAppsAvailable(
    nodeAs(thread, ["Thread"])?.apps.edges ?? []
  )?.filter(app => app.node.configureThread);

  const addApp = (app: AppFieldsFragment) => {
    if (!thread?.id || !workspaceID) return;
    const workspaceApp = appsAvailable?.find(a => a.node.id === app.id);
    if (!workspaceApp) return;

    if (app.configureThread === AppConfigureMethod.Add) {
      addThreadRecipients({
        variables: {
          id: thread.id,
          recipients: [
            { recipient: workspaceApp.appUser.id, role: MemberRole.Default },
          ],
        },
      });
      return;
    }
    configureApp({
      app,
      closeModalProp: false,
      configurableID: thread.id,
      workspaceID,
    });
  };

  const removeApp = (appID: string) => {
    if (!thread?.id) return Promise.resolve();
    const workspaceApp = nodeAs(thread, ["Thread"])?.apps.edges.find(
      a => a.node.id === appID
    );
    if (!workspaceApp) return Promise.resolve();
    return removeThreadRecipients({
      variables: { id: thread.id, recipientIDs: workspaceApp.appUser.id },
    });
  };

  return {
    addApp,
    appsAvailable,
    editApp,
    removeApp,
    workspaceID,
  };
};

export default useThreadApps;

import { UIEventHandler, useRef } from "react";
import { useHistory } from "react-router-dom";
import { MultiValueProps } from "react-select";

import { Recipient } from "@utility-types";
import { formatGroupName } from "utils/group/formatGroupName";
import tw from "utils/tw";

import { ProfileAvatar } from "components/ProfileItem";
import { Icon } from "components/design-system/icons";
import IdentityBadge from "components/design-system/ui/IdentityBadge/IdentityBadge";
import { routeToGroup, routeToUser } from "components/routing/utils";

const GroupTypes = ["Workspace", "WorkspacePreview", "Group", "GroupPreview"];

const isGroup = (recipient: Recipient) =>
  GroupTypes.includes(recipient.__typename);

const RecipientsMultiValue = (
  props: MultiValueProps<Recipient, true>
): JSX.Element => {
  const { data: recipient, isDisabled, removeProps, selectProps } = props;
  const { valueFixed } = selectProps;

  const { __typename: type, avatarURL, id } = recipient;
  const { emoji, name } = formatGroupName(recipient);

  const history = useHistory();

  const refRecipient = useRef<HTMLDivElement>(null);

  const onClickItem: UIEventHandler<HTMLDivElement> = e => {
    if (!isDisabled || !id || type === "Address") return;
    e.preventDefault();
    history.push(
      type === "User"
        ? routeToUser({ userID: id, to: "canonical" })
        : routeToGroup({ groupID: id })
    );
  };

  const recipientIsGroup = isGroup(recipient);

  return (
    <div
      className={tw(
        "flex items-center h-32 rounded-lg overflow-hidden text-subhead select-none",
        "bg-background-ghost border-thin border-background-ghost"
      )}
    >
      <div
        ref={refRecipient}
        className="flex justify-between items-center min-w-0 pr-6"
        onClick={onClickItem}
        onTouchEnd={onClickItem} // fix for onClick not working on iOS
      >
        <ProfileAvatar
          avatarURL={avatarURL}
          className={tw("h-32 w-32", recipientIsGroup ? "mr-2" : "mr-8")}
          emoji={emoji}
          id={id}
          name={name}
          rounded={false}
          size="small"
          type={type}
        />
        <span className="inline-block mb-1 truncate mr-6">{name}</span>
        <IdentityBadge recipient={recipient} showLabel={false} />
      </div>
      {!valueFixed(recipient) && (
        <div
          className="shrink-0 pr-4 py-8 text-interactive-subtle hover:text-interactive-subtle-hover"
          role="button"
          {...removeProps}
        >
          <Icon icon={"Close"} size={16} />
        </div>
      )}
    </div>
  );
};

export default RecipientsMultiValue;

import ThreadComposeModal from "components/threads/ThreadComposeModal";
import useModalStore from "store/useModalStore";
import { useGlueAIBot } from "./useGlueAIBot";

export const useComposeToGlueAI = () => {
  const glueAIBot = useGlueAIBot();
  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));

  return () => {
    openModal(
      <ThreadComposeModal
        initialDraft={{
          recipients: glueAIBot ? [glueAIBot] : undefined,
          message: { attachments: [], text: "" },
        }}
      />
    );
  };
};

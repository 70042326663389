import { ModalProps } from "components/ModalKit/Modal";
import { Header } from "components/ModalKit/Parts";
import { StandardModal } from "components/Modals";
import { StepCounter } from "components/StepCounter";
import { Button } from "components/design-system/Button";
import { useMemo, useReducer } from "react";

import useModalStore from "store/useModalStore";
import ChooseFile from "./ChooseFile";
import ImportInformation from "./ImportInformation";
import MakeSelections from "./MakeSelections";
import Reducer from "./Reducer";
import Review from "./Review";
import SelectionInfo from "./SelectionInfo";

type Props = ModalProps & {
  workspaceID: string;
};

const SlackImportModal = ({ workspaceID, ...props }: Props) => {
  const { closeModal, closeAllModals } = useModalStore(
    ({ closeModal, closeAllModals }) => ({ closeModal, closeAllModals })
  );
  const [state, dispatch] = useReducer(Reducer, { step: 1 });

  const updateSelections = (
    includeChannels: string[],
    skipChannels: string[],
    groups: string[]
  ) => {
    dispatch({
      type: "updateSelections",
      includeChannels,
      skipChannels,
      groups,
    });
  };

  const view = useMemo(() => {
    const commonProps = {
      cancelButton: (
        <Button
          buttonStyle="subtle"
          onClick={() => closeModal(props.modalId)}
          type="button"
        >
          Cancel
        </Button>
      ),
      onClickNext: () => dispatch({ type: "nextView" }),
    };
    const BackButton = (
      <Button
        buttonStyle="secondary"
        icon="ArrowLeft"
        onClick={() => dispatch({ type: "prevView" })}
      >
        Back
      </Button>
    );
    switch (state.step) {
      case 1:
        return <ImportInformation {...commonProps} workspaceID={workspaceID} />;
      case 2:
        return (
          <ChooseFile
            {...commonProps}
            workspaceID={workspaceID}
            backButton={BackButton}
          />
        );
      case 3:
        return <SelectionInfo {...commonProps} />;
      case 4:
        return (
          <MakeSelections
            {...commonProps}
            workspaceID={workspaceID}
            updateSelections={updateSelections}
            includeChannels={state.includeChannels}
            skipChannels={state.skipChannels}
            groups={state.groups}
          />
        );
      case 5:
        return (
          <Review
            includeChannels={state.includeChannels ?? []}
            skipChannels={state.skipChannels ?? []}
            groups={state.groups ?? []}
            workspaceID={workspaceID}
            backButton={BackButton}
            cancelButton={commonProps.cancelButton}
            onClickNext={() => closeAllModals()}
          />
        );
    }
  }, [
    closeAllModals,
    closeModal,
    props.modalId,
    state.groups,
    state.includeChannels,
    state.skipChannels,
    state.step,
    workspaceID,
  ]);

  return (
    <StandardModal {...props} height="half">
      <Header variant="bordered">
        <div className="w-full flex justify-between items-center pr-8">
          Slack import
          <StepCounter step={state.step} total={5} />
        </div>
      </Header>
      {view}
    </StandardModal>
  );
};

export default SlackImportModal;

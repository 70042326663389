import {
  PointerEvent,
  PointerEventHandler,
  useCallback,
  useEffect,
} from "react";

let pointerTarget: EventTarget | null = null;

const bodyPointerUpHandler = () => (pointerTarget = null);

export default function usePointerClick<El extends HTMLElement>(
  handler?: PointerEventHandler<El>
) {
  useEffect(() => {
    document.body.addEventListener("pointerup", bodyPointerUpHandler);

    return () =>
      document.body.removeEventListener("pointerup", bodyPointerUpHandler);
  }, []);

  return {
    onPointerDown: useCallback(
      (event: PointerEvent<El>) => (pointerTarget = event.currentTarget),
      []
    ),
    onPointerUp: useCallback(
      (event: PointerEvent<El>) =>
        event.currentTarget === pointerTarget && handler?.(event),
      [handler]
    ),
  };
}

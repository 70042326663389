import { useCallback, useReducer } from "react";

export default function useForceUpdate(): () => boolean {
  const [, dispatch] = useReducer(x => x + 1, 0);
  const forceUpdate = useCallback(() => {
    dispatch();
    return true;
  }, []);
  return forceUpdate;
}

import { ComponentProps, forwardRef } from "react";

import type { TWMargin } from "components/design-system/types/margin";
import {
  VariantPropsOf,
  variantProps,
} from "components/helper/classNameVariants";
import { isNative, isSafari } from "utils/platform";
import tw from "utils/tw";
import AvatarPrimitive from "./AvatarPrimitive";

export const AVATAR_DEFAULT_SIZE = "large";

const className = {
  base: "flex items-center justify-center shrink-0 relative",
  defaultVariants: {
    background: "subtle" as const,
    border: "none" as const,
    rounded: "none" as const,
    size: `${AVATAR_DEFAULT_SIZE}` as const,
    textColor: "primary" as const,
  },
  variants: {
    background: {
      subtle: "bg-background-subtle",
      transparent: "bg-transparent",
    },
    border: {
      "background-body-1": "border-background-body border-1",
      "background-body-2": "border-background-body border-2",
      "border-container-1": "border-border-container border-1",
      none: "",
    },
    size: {
      large: "text-xl w-36 h-36",
      medium: "text-base w-32 h-32",
      small: "text-xs w-24 h-24",
      tiny: "text-xxs w-16 h-16",
      "x-large": "text-xl w-80 h-80",
      "x-small": "text-xs w-20 h-20",
      "x-tiny": "text-xxs w-12 h-12",
      "xx-large": "text-xl w-100 h-100",
    },
  },
};

const avatarProps = variantProps(className);

type AvatarVariantProps = VariantPropsOf<typeof avatarProps>;
type AvatarSize = Exclude<AvatarVariantProps["size"], undefined>;

const emojiSizes: Record<AvatarSize, number> = {
  large: 28,
  medium: 24,
  small: 20,
  tiny: 15,
  "x-large": 54,
  "x-small": 17,
  "x-tiny": 12,
  "xx-large": 100,
};

const iconSizes: Record<AvatarSize, number> = {
  large: 28,
  medium: 26,
  small: 24,
  tiny: 16,
  "x-large": 54,
  "x-small": 20,
  "x-tiny": 12,
  "xx-large": 100,
};

const roundedClassNames: Record<AvatarSize, string> = {
  large: "rounded-full",
  medium: "rounded-full",
  small: "rounded-md",
  tiny: "rounded-sm",
  "x-large": "rounded-full",
  "x-small": "rounded-sm",
  "x-tiny": "rounded-sm",
  "xx-large": "rounded-full",
};

const sizeInPx: Record<AvatarSize, number> = {
  large: 36,
  medium: 32,
  small: 24,
  tiny: 16,
  "x-large": 80,
  "x-small": 20,
  "x-tiny": 12,
  "xx-large": 100,
};

const scaleForSafari = isNative() || isSafari();

type Props = Omit<ComponentProps<typeof AvatarPrimitive>, "size"> & {
  margin?: TWMargin;
  rounded?: boolean | `rounded-${string}`;
  loading?: boolean;
};

const Avatar = forwardRef<HTMLDivElement, Props & AvatarVariantProps>(
  (
    {
      background,
      border,
      className,
      emojiProps,
      iconProps,
      margin,
      loading,
      rounded = true,
      size = AVATAR_DEFAULT_SIZE,
      ...props
    }: Props & AvatarVariantProps,
    ref
  ) => {
    const emojiSize = emojiProps?.size ?? emojiSizes[size];
    const roundedClassName =
      typeof rounded === "string" ? rounded : roundedClassNames[size];

    return (
      <div
        ref={ref}
        data-testid="avatar-image"
        {...avatarProps({
          background,
          border,
          className: tw(
            "select-none",
            margin,
            roundedClassName,
            className,
            {
              "overflow-hidden":
                rounded && (background !== "transparent" || props.avatarURL),
            },
            { "glue-skeleton": loading }
          ),
          size,
        })}
      >
        <AvatarPrimitive
          emojiProps={{
            ...emojiProps,
            size: scaleForSafari ? Math.ceil(emojiSize * 0.75) : emojiSize,
          }}
          iconProps={{
            ...iconProps,
            size: iconProps?.size ?? iconSizes[size],
          }}
          size={sizeInPx[size]}
          {...props}
        />
      </div>
    );
  }
);

export default Avatar;

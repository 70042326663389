import { memo } from "react";

import { StreamFileAttachment } from "@utility-types";

type Props = {
  files: StreamFileAttachment[];
};

function Audio({ files }: Props): JSX.Element {
  return (
    <div>
      {files.map(file => (
        <figure key={file.file_id} className="my-6">
          <audio src={file.asset_url} controls>
            Your browser does not support the
            <code>audio</code> element.
          </audio>
          {/* TODO: adjust/add styles for captions  */}
          {/* <figcaption>{file.title}</figcaption> */}
        </figure>
      ))}
    </div>
  );
}

export default memo(
  Audio,
  (prev, next) =>
    prev.files.map(({ file_id }) => file_id).toString() ===
    next.files.map(({ file_id }) => file_id).toString()
);

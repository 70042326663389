import { Suggester } from "@remirror/pm/suggest";
import escapeStringRegex from "escape-string-regexp";
import { EmojiExtension } from "remirror/extensions";

class GlueEmojiExtension extends EmojiExtension {
  createSuggesters(): Suggester {
    return {
      char: this.options.suggestionCharacter,
      disableDecorations: true,
      invalidPrefixCharacters: `${escapeStringRegex(
        this.options.suggestionCharacter
      )}|\\w`,
      name: this.name,
      onChange: props => {
        // When the change handler is called call the extension handler
        // `suggestEmoji` with props that can be used to trigger the emoji.
        this.options.suggestEmoji({
          apply: (code: string) => {
            this.store.commands.replaceText({
              appendText: " ",
              content: code,
              selection: props.range,
            });
          },
          change: !!props.changeReason,
          exit: !!props.exitReason,
          moji: this.moji,
          query: props.query.full.toLowerCase(),
          range: props.range,
          text: props.text.full,
        });
      },
      suggestTag: "span",
      supportedCharacters: /[\w\d_+-]+/,
    };
  }
}

export default GlueEmojiExtension;

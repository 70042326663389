import { devtools } from "zustand/middleware";

import { UseBoundStoreOmitSetState } from "@utility-types/zustand";

import { glueCreateZustand } from "./helper/glueCreate";

const STORE_NAME = "ActiveThreadStore";

type ActiveThread = {
  stackPriority: StackPriority;
  threadID: string;
};

export type StackPriority =
  | 0 // page
  | 1 // drawer
  | 2; // modal

type ActiveThreadStore = {
  activeThreadId: string;
  addThread: ({ stackPriority, threadID }: ActiveThread) => void;
  removeThread: (threadID: string) => void;
  threads: Map<string, ActiveThread>;
  updateActiveThreadId: (
    input: ActiveThreadStore["threads"] | "reset" | "restore"
  ) => string;
};

const useActiveThreadStore: UseBoundStoreOmitSetState<ActiveThreadStore> =
  glueCreateZustand<ActiveThreadStore>({ name: STORE_NAME })(
    devtools(
      (set, get) => ({
        activeThreadId: "",

        addThread: ({ stackPriority, threadID }) =>
          set(state => {
            const nextThreads = new Map(state.threads);

            nextThreads.set(threadID, { stackPriority, threadID });

            return {
              activeThreadId: state.updateActiveThreadId(nextThreads),
              threads: nextThreads,
            };
          }),

        removeThread: threadID =>
          set(state => {
            const nextThreads = new Map(state.threads);

            nextThreads.delete(threadID);

            return {
              activeThreadId: state.updateActiveThreadId(nextThreads),
              threads: nextThreads,
            };
          }),

        threads: new Map(),

        updateActiveThreadId: input => {
          const isThreads = typeof input !== "string";
          let activeThreadId = "";
          let currentPriority = 0;

          if (!isThreads && input === "reset") {
            set({ activeThreadId: "" });
            return "";
          }

          const temp = [...(!isThreads ? get().threads : input).values()];

          activeThreadId = temp[0]?.threadID || "";

          temp.forEach(({ stackPriority, threadID }) => {
            if (stackPriority <= currentPriority) return;

            activeThreadId = threadID;
            currentPriority = stackPriority;
          });

          if (isThreads) return activeThreadId;

          set({ activeThreadId });

          return "";
        },
      }),
      {
        name: STORE_NAME,
        serialize: true,
      }
    )
  );

export default useActiveThreadStore;

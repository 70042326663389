import { Mailbox } from "@utility-types";
import { AnalyticsEvent } from "analytics/analyticsEvent";
import { ThreadsOrder } from "generated/graphql";

export class ThreadListItemSelected extends AnalyticsEvent<{
  /**
   * The offset of the item that was clicked in the list.
   */
  listIndex: number;
  threadId: string;
}> {
  event = "ThreadListItemSelected";
}

export class ThreadListEnteredBulkEditMode extends AnalyticsEvent<{
  selectMode: "some" | "all";
}> {
  event = "ThreadListEnteredBulkEditMode";
}

export enum ThreadActionOrigin {
  SwipedListItem = "swipedListItem",
  ListItem = "listItem",
  ThreadHeader = "threadHeader",
}

export class ThreadStarredUnstarred extends AnalyticsEvent<{
  starred: boolean;
  threadId: string;
  uiOrigin: ThreadActionOrigin;
}> {
  event = "ThreadStarredUnstarred";
}

export class ThreadArchivedUnarchived extends AnalyticsEvent<{
  archived: boolean;
  threadId: string;
  uiOrigin: ThreadActionOrigin;
}> {
  event = "ThreadArchivedUnarchived";
}

export class ThreadSubscribedUnsubscribed extends AnalyticsEvent<{
  subscribed: boolean;
  threadId: string;
  uiOrigin: ThreadActionOrigin;
}> {
  event = "ThreadSubscribedUnsubscribed";
}

export class ThreadMarkedReadUnread extends AnalyticsEvent<{
  read: boolean;
  threadId: string;
  uiOrigin: ThreadActionOrigin;
}> {
  event = "ThreadMarkedReadUnread";
}

export class ThreadDeleted extends AnalyticsEvent<{
  threadId: string;
  uiOrigin: ThreadActionOrigin;
}> {
  event = "ThreadDeleted";
}

export class ThreadLeft extends AnalyticsEvent<{
  threadId: string;
  uiOrigin: ThreadActionOrigin;
}> {
  event = "ThreadLeft";
}

export class ThreadLockedUnlocked extends AnalyticsEvent<{
  locked: boolean;
  threadId: string;
  uiOrigin: ThreadActionOrigin;
}> {
  event = "ThreadLockedUnlocked";
}

export class ThreadCopyShareUrl extends AnalyticsEvent<{
  threadId: string;
  uiOrigin: ThreadActionOrigin;
}> {
  event = "ThreadCopyShareUrl";
}

export class ThreadBulkStarredUnstarred extends AnalyticsEvent<{
  count: number;
  starred: boolean;
}> {
  event = "ThreadBulkStarredUnstarred";
}

export class ThreadBulkArchivedUnarchived extends AnalyticsEvent<{
  archived: boolean;
  count: number;
}> {
  event = "ThreadBulkArchivedUnarchived";
}

export class ThreadBulkSubscribedUnsubscribed extends AnalyticsEvent<{
  count: number;
  subscribed: boolean;
}> {
  event = "ThreadBulkSubscribedUnsubscribed";
}

export class ThreadBulkMarkedReadUnread extends AnalyticsEvent<{
  count: number;
  read: boolean;
}> {
  event = "ThreadBulkMarkedReadUnread";
}

export class ThreadListSorted extends AnalyticsEvent<{
  mailbox: Mailbox;
  order: ThreadsOrder;
  recipientID?: string;
}> {
  event = "ThreadListSorted";
}

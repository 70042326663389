import { Icon } from "components/design-system/icons";
import { PanelResizeHandle } from "react-resizable-panels";
import tw from "utils/tw";

const AppPanelResizeHandle = ({ className }: { className?: string }) => (
  <PanelResizeHandle
    className={tw("relative h-full w-0 z-1", className)}
    style={{ cursor: "col-resize" }}
  >
    <div className="group absolute flex justify-center h-full w-16 -ml-8">
      <div className="hidden group-hover:block h-full w-1 bg-border-strong" />
      <Icon
        icon="GrabberVertical"
        className={tw(
          "absolute hidden group-hover:block group-active:block",
          "fill-background text-icon-subtle",
          "left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
        )}
        style={{
          filter: "drop-shadow(0px 1px 3px rgba(24, 39, 75, 0.12))",
        }}
      />
    </div>
  </PanelResizeHandle>
);

export default AppPanelResizeHandle;

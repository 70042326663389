import { Recipient } from "@utility-types";
import BadgedAvatar from "components/design-system/Avatar/BadgedAvatar";
import { IconName } from "components/design-system/icons/Icon";
import useAppStateStore from "store/useAppStateStore";
import tw from "utils/tw";

const GroupAvatar = ({
  badgeUrl,
  emoji,
  icon,
  isRead,
  recipient,
}: {
  badgeUrl?: string;
  emoji?: string;
  icon?: IconName;
  isRead?: boolean;
  recipient?: Recipient;
}) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  if (!recipient && !icon && !emoji) return null;

  return (
    <div
      className={tw(
        "group",
        "flex items-center justify-center min-w-0 shrink-0",
        isRead ? "text-icon-secondary" : "text-icon-primary"
      )}
    >
      <BadgedAvatar
        avatarURL={recipient?.avatarURL}
        background="transparent"
        badgeProps={{
          avatarURL: badgeUrl,
          background: "transparent",
          rounded: "rounded-sm",
          size: "x-tiny",
        }}
        badgeVisible={!!badgeUrl}
        emojiProps={{ emoji }}
        iconProps={{ icon }}
        name={recipient?.name}
        rounded={!!recipient?.avatarURL}
        size={breakpointMD ? "x-small" : "small"}
      />
    </div>
  );
};

export default GroupAvatar;

import { Tooltip } from "components/design-system/FloatingUi";
import Icon from "components/design-system/icons/Icon";
import usePointerClick from "hooks/usePointerClick";
import tw from "utils/tw";

type Props = {
  disabled?: boolean;
  handleClose: (event: React.PointerEvent) => void;
  size?: number;
  title?: string;
};

export const CloseButton = ({
  className,
  disabled,
  handleClose,
  size = 24,
  title,
}: Props & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  const pointerClick = usePointerClick<HTMLButtonElement>(handleClose);

  return (
    <Tooltip content={title} placement="top">
      <button
        aria-label="Close"
        className={tw(
          "flex items-center justify-center",
          "border-none m-0 outline-none select-none",
          "bg-transparent text-interactive-subtle hover:text-interactive-subtle-hover disabled:text-interactive-subtle-disabled",
          {
            "cursor-pointer": !disabled,
            opacity: disabled ? 0.5 : 1,
          },
          className
        )}
        disabled={disabled}
        title={title}
        type="button"
        {...pointerClick}
      >
        <Icon icon="Close" size={size} />
      </button>
    </Tooltip>
  );
};

import { useMemo } from "react";
import useOnboardingStore, { Views } from "store/useOnboardingStore";

import Start from "components/App/Start";
import ChooseApps from "./ChooseApps";
import CompleteYourProfile from "./CompleteYourProfile";
import CreateGroup from "./CreateGroup";
import CreateWorkspace from "./CreateWorkspace";
import InviteMembers from "./InviteMembers";
import JoinGroups from "./JoinGroups";
import JoinWorkspace from "./JoinWorkspace";
import NoWorkspaceWall from "./NoWorkspaceWall";
import PlanAndPayment from "./PlanAndPayment";
import Review from "./Review";

const noWrapperRequired: Views[] = ["PlanAndPayment"];

const OnboardingMain = () => {
  const { view } = useOnboardingStore(({ view }) => ({
    view,
  }));

  const currentView = useMemo(() => {
    switch (view) {
      case "CompleteYourProfile":
        return <CompleteYourProfile />;
      case "PlanAndPayment":
        return <PlanAndPayment />;
      case "JoinWorkspace":
        return <JoinWorkspace />;
      case "JoinGroups":
        return <JoinGroups />;
      case "InviteMembers":
        return <InviteMembers />;
      case "CreateWorkspace":
        return <CreateWorkspace />;
      case "NoWorkspaceWall":
        return <NoWorkspaceWall />;
      case "ChooseApps":
        return <ChooseApps />;
      case "CreateGroup":
        return <CreateGroup />;
      case "Review":
        return <Review />;
    }
  }, [view]);

  return noWrapperRequired.includes(view) ? (
    currentView
  ) : (
    <Start>{currentView}</Start>
  );
};

export default OnboardingMain;

import { devtools, subscribeWithSelector } from "zustand/middleware";

import { glueCreateZustand } from "store/helper/glueCreate";

const STORE_NAME = "UnreadMessageStore";

export type Position = "above" | "beneath" | undefined;

type ItemInput = {
  hasMentions: boolean;
  id: string;
  position: Position;
  scrollToItem: () => void;
};

type UnreadMessageStore = {
  addItem: (input: ItemInput) => void;
  removeItem: (id: string) => void;
  resetUnreadItems: (position: Position) => void;
  unreadItems: {
    above: Map<string, Pick<ItemInput, "scrollToItem">>;
    beneath: Map<string, Pick<ItemInput, "scrollToItem">>;
  };
  unreadMentions: {
    above: Map<string, Pick<ItemInput, "scrollToItem">>;
    beneath: Map<string, Pick<ItemInput, "scrollToItem">>;
  };
};

const useUnreadSidebarItemStore = glueCreateZustand<UnreadMessageStore>({
  name: STORE_NAME,
})(
  subscribeWithSelector(
    devtools<UnreadMessageStore>(
      set => ({
        addItem: (input: ItemInput) => {
          const { hasMentions, id, position, scrollToItem } = input;
          set(state => {
            if (!position) return {};

            state.unreadItems[position].set(id, {
              scrollToItem,
            });
            if (hasMentions) {
              state.unreadMentions[position].set(id, {
                scrollToItem,
              });
            }
            return {
              unreadItems: state.unreadItems,
            };
          });
        },
        removeItem: (id: string) => {
          set(state => {
            state.unreadMentions.above.delete(id);
            state.unreadMentions.beneath.delete(id);
            state.unreadItems.above.delete(id);
            state.unreadItems.beneath.delete(id);
            return {
              unreadItems: state.unreadItems,
              unreadMentions: state.unreadMentions,
            };
          });
        },
        resetUnreadItems: position => {
          set(state => {
            if (!position) {
              state.unreadMentions.above.clear();
              state.unreadMentions.beneath.clear();
              state.unreadItems.above.clear();
              state.unreadItems.beneath.clear();
            } else {
              state.unreadMentions[position].clear();
              state.unreadItems[position].clear();
            }
            return {
              unreadItems: state.unreadItems,
              unreadMentions: state.unreadMentions,
            };
          });
        },
        unreadItems: { above: new Map(), beneath: new Map() },
        unreadMentions: { above: new Map(), beneath: new Map() },
      }),
      {
        name: STORE_NAME,
        serialize: true,
      }
    )
  )
);

export default useUnreadSidebarItemStore;

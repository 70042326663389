type JWT = { exp: number; iat: number };

// NOTE: we can't guarantee validity of token since we're not
// checking the signature here! Just for optimizing refreshes.
export const parseJWT = (token: string): JWT | undefined => {
  try {
    return JSON.parse(atob(token.split(".")[1] ?? "")) as JWT;
  } catch (_err) {
    console.warn("Error: [parse JWT]");
  }
};

export default parseJWT;

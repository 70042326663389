import { MouseEvent } from "react";

import { Tooltip } from "components/design-system/FloatingUi";
import { Icon } from "components/design-system/icons";
import tw from "utils/tw";

type Props = {
  count: number;
  disabled?: boolean;
  tooltip?: string;
  hasExternal?: boolean;
  onClick: (e: MouseEvent) => void;
};

const FacePileCount = ({
  count,
  disabled = false,
  tooltip,
  hasExternal,
  onClick,
}: Props) => {
  return (
    <Tooltip
      className="max-w-250 px-16"
      content={tooltip || "See all recipients"}
      placement="bottom"
      tooltipStyle="inverted"
      disabled={disabled}
    >
      <div
        className={tw(
          "flex items-center h-28 gap-4 p-4 pr-8 border-1 border-border-container rounded-md hover:cursor-pointer hover:bg-background-secondary",
          { "hover:cursor-default hover:bg-background": disabled }
        )}
        onClick={onClick}
      >
        <Icon
          icon="User"
          className={tw("text-icon-secondary", {
            "text-icon-disabled": disabled,
          })}
          size={20}
        />
        <span
          className={tw("text-footnote text-text-subtle", {
            "!text-text-disabled ": disabled,
          })}
        >
          {count}
        </span>
        {hasExternal && (
          <Icon
            className="p-2 text-icon-warning bg-background-warning rounded-sm select-none"
            icon="ExternalAccount"
            size={14}
            strokeWidth={1}
          />
        )}
      </div>
    </Tooltip>
  );
};

export default FacePileCount;

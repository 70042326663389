import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Flipper } from "react-flip-toolkit";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useHistory } from "react-router";

import {
  Recipient,
  ThreadEdgeSimple,
  ThreadPreview,
  User,
  nodeAs,
  nodeIs,
} from "@utility-types";
import { cloneElementForSkeletons } from "components/Skeleton/Skeleton";
import Avatar from "components/design-system/Avatar/Avatar";
import { useRouteParams, userPath } from "components/routing/utils";
import ThreadListItem from "components/threads-list/ThreadListItem/ThreadListItem";
import useChatRecipient from "hooks/useChatRecipient";

import { useInstantSearch } from "components/views/search/hooks";

import FindThread from "components/views/inbox/InboxMain/FindThread";

import EmptyDMs from "./EmptyDMs";
import FooterInvite from "./FooterInvite";

type Props = {
  edges?: (ThreadEdgeSimple | User)[];
  hasNextPage?: boolean;
  loadMore: () => void;
  loading: boolean;
  searchPlaceholder?: string;
};

const recipientToThreadPreview = (recipient?: Recipient): ThreadPreview => ({
  __typename: "ThreadPreview",
  admin: null,
  id: recipient?.id ?? "",
  name: recipient?.name ?? "",
  recipients: { __typename: "RecipientConnection", edges: [] },
  subject: recipient?.name ?? "",
});

const DirectMessagesList = ({
  edges,
  hasNextPage,
  loading,
  loadMore,
  searchPlaceholder = "Find a DM",
}: Props) => {
  const [match, setMatch] = useState("");
  const isSearchingUsers = !!match.length;
  const { search, searchResults } = useInstantSearch({
    resultsOrder: ["users"],
  });
  const formRef = useRef<HTMLFormElement>(null);

  const { userID, v } = useRouteParams();
  const history = useHistory();
  const [swipedOpenItemId, setSwipedOpenItemId] = useState<string>();

  useEffect(() => {
    search({ groups: false, match, threads: false });
  }, [match, search]);

  const foundUsers = useMemo(
    () =>
      searchResults.instantResults.flatMap(r =>
        r.resultType === "users" ? r.edges : []
      ),
    [searchResults]
  );

  const chatRecipient = useChatRecipient();

  const filterFoundUsers = (edges?: (ThreadEdgeSimple | User)[]) =>
    edges?.filter(e => {
      const recipient = nodeIs(e, ["ThreadEdge"]) ? chatRecipient(e.node) : e;
      return foundUsers.find(u => u.node.id === recipient?.id);
    });

  const chats = isSearchingUsers ? filterFoundUsers(edges) : edges;

  const [scrollSentryRef, { rootRef: scrollListRef }] = useInfiniteScroll({
    hasNextPage: hasNextPage ?? false,
    loading,
    onLoadMore: useCallback(() => {
      loadMore();
    }, [loadMore]),
  });

  const skeletonItems = useMemo(
    () =>
      cloneElementForSkeletons(
        <ThreadListItem
          avatarComponent={<Avatar rounded="rounded-md" size="medium" />}
          avatarSize={32}
          height="h-67"
          className="!py-12 !h-64 !px-24"
        />,
        5
      ),
    []
  );

  return (
    <div
      ref={scrollListRef}
      className="grow min-h-0 overflow-x-hidden overflow-y-auto pb-72 translate-x-0"
    >
      <FindThread
        className="mx-20"
        label={searchPlaceholder}
        onChange={setMatch}
        ref={formRef}
      />
      {chats?.length ? (
        <Flipper flipKey={chats.map(c => c.id).join()} element="ol">
          {chats.map(e => {
            const isThread = nodeIs(e, ["ThreadEdge"]);
            const user = isThread ? nodeAs(chatRecipient(e.node), ["User"]) : e;
            return (
              <ThreadListItem
                key={e.id}
                canArchive={false}
                setSwipedOpenItemId={setSwipedOpenItemId}
                swipedOpenItemId={swipedOpenItemId}
                avatarComponent={
                  <Avatar
                    avatarURL={user?.avatarURL}
                    name={user?.name}
                    rounded="rounded-md"
                    size="medium"
                  />
                }
                avatarSize={32}
                className="!rounded-none select-none md:!h-64"
                isSelected={userID === user?.id && v !== "compose-dm"}
                height="h-67"
                item={isThread ? e : recipientToThreadPreview(user)}
                onClick={() => {
                  history.push(userPath(undefined, user?.id));
                }}
              />
            );
          })}
          {hasNextPage ? <div ref={scrollSentryRef} /> : <FooterInvite />}
        </Flipper>
      ) : searchResults.searching || !chats ? (
        skeletonItems
      ) : (
        <EmptyDMs resetForm={() => formRef.current?.reset()} />
      )}
    </div>
  );
};

export default DirectMessagesList;

import { useEffect, useMemo } from "react";

import { nodeAs } from "@utility-types";
import { ThreadsMailbox, useFetchUserEdgeQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import { addToHistory, createHistoryPath } from "store/useHistoryStore";

import { useRouteParams } from "components/routing/utils";
import ThreadView from "components/threads/ThreadView";
import NoPersistentChat from "components/user/NoPersistentChat";

import EmptyView from "../EmptyView";
import ComposeDM from "./ComposeDM";

const DirectMessage = ({
  messageID,
  userID,
}: {
  messageID?: string;
  userID?: string;
}) => {
  const { authData, authReady } = useAuthData();

  const { data: userEdgeData } = useFetchUserEdgeQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-and-network",
    skip: !authData?.me.id || !userID,
    variables: { id: `${userID}-${authData?.me.id}` },
  });

  const userEdge = useMemo(
    () => nodeAs(userEdgeData?.node, ["UserEdge"]),
    [userEdgeData]
  );

  const routeParams = useRouteParams();

  const name = userEdge?.node.name;
  const path = createHistoryPath(routeParams);

  const avatarURL = userEdge?.node.avatarURL;
  useEffect(() => {
    if (!name || !path) return;
    addToHistory({
      avatarURL,
      recipient: userEdge?.node,
      name,
      path,
      primaryType: "dms",
    });
  }, [avatarURL, name, path, userEdge?.node]);

  if (routeParams?.v === "compose-dm") return <ComposeDM />;

  if (!userEdge) return <EmptyView />;

  return userEdge.persistentChatEdge ? (
    <ThreadView
      headerType="full"
      mailbox={ThreadsMailbox.All}
      messageID={messageID}
      recipientID={userEdge.node.id}
      stackPriority={0}
      threadID={userEdge.persistentChatEdge.node.id}
    />
  ) : (
    <NoPersistentChat userID={userEdge.node.id} />
  );
};

export default DirectMessage;

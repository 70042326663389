import { devtools, persist } from "zustand/middleware";

import flags from "components/FeatureFlags/flags-config";
import env from "utils/processEnv";

import { FlagName, flagNames } from "components/FeatureFlags";

import { glueCreateZustand } from "./helper/glueCreate";

const STORE_NAME = "FeatureFlagStore";

type FeatureFlagStore = {
  flags: Record<FlagName, boolean>;
  toggleFlag: (name: FlagName, enabled: boolean) => void;
};

const useFeatureFlagStore = glueCreateZustand<FeatureFlagStore>({
  name: STORE_NAME,
})(
  devtools(
    persist(
      set => ({
        flags: flagNames.reduce<Record<string, boolean>>((acc, keyName) => {
          acc[keyName] = !!flags[keyName].environments[env.glueEnv];
          return acc;
        }, {}),
        toggleFlag: (name, enabled) =>
          set(state => ({
            ...state,
            flags: { ...state.flags, [name]: !!enabled },
          })),
      }),
      {
        migrate: (s, v) => {
          const { flags } = s as FeatureFlagStore;
          if (v === 0) {
            for (const flag in flags) {
              flags[flag as FlagName] = !!flags[flag as FlagName];
            }
          }

          if (v < 4) {
            flags.SERVICE_WORKER = env.glueEnv === "staging";
          }

          if (v < 8 && "CYAN" in flags) {
            delete flags.CYAN; // CYAN flag was removed
          }

          return { flags } as FeatureFlagStore;
        },

        name: STORE_NAME,
        partialize: ({ flags }) => ({ flags }),
        version: 8,
      }
    ),
    {
      name: STORE_NAME,
      serialize: true,
    }
  )
);

export default useFeatureFlagStore;

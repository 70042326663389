import { FieldValues, useFormContext } from "react-hook-form";

import tw from "utils/tw";

import Error from "./Error";
import { Label } from "./Label";
import { SelectProps } from "./types";

export const Select = <TFieldValues extends FieldValues>({
  className,
  config,
  label,
  labelClassName,
  name,
  options,
  wrapperClassName,
  ...props
}: SelectProps<TFieldValues>) => {
  const {
    formState: { errors, isSubmitting },
    register,
  } = useFormContext<TFieldValues>();

  const disabled = props.disabled || isSubmitting;

  return (
    <div className={tw("my-20", wrapperClassName)}>
      {label && (
        <Label className={labelClassName} htmlFor={name}>
          {label}
        </Label>
      )}
      <select
        className={tw(
          "py-6 pr-10 pl-5 max-w-full bg-background disabled:bg-background-ghost rounded border border-interactive-ghost focus:outline-none",
          {
            "border-accent-alert": !!errors[name],
            "cursor-pointer": !disabled,
          },
          className
        )}
        id={name}
        {...props}
        disabled={disabled}
        {...register(name, config)}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      <Error name={name} />
    </div>
  );
};

import { useEffect, useState } from "react";

import useModalStore from "store/useModalStore";

import { Icon } from "components/design-system/icons";
import { BlockComponentProps } from "./BlockComponent";
import BlockConfirm from "./BlockConfirm";
import BlockMarkdown from "./BlockMarkdown";
import BlockPlainText from "./BlockPlainText";
import { CheckboxesControl } from "./blockTypes";

type BlockCheckboxesProps = BlockComponentProps & {
  block: CheckboxesControl;
};

const BlockCheckboxes = ({ block, handlers }: BlockCheckboxesProps) => {
  const [selected, setSelected] = useState<string[]>([]);
  const [pendingValue, setPendingValue] = useState<string | null>(null);
  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));

  useEffect(() => {
    const values = block.initialOptions?.map(option => option.value);
    if (values) {
      setSelected(values);
    }
    handlers.onValueChanged(block, values);
  }, [block, handlers]);

  const handleCheckboxChange = (value: string) => {
    if (block.confirm) {
      setPendingValue(value);
      openModal(
        <BlockConfirm confirm={block.confirm} onConfirm={handleConfirm} />
      );
    } else {
      handleNewValue(value);
    }
  };

  const handleNewValue = (value: string) => {
    let values = [...selected, value];
    if (selected.includes(value)) {
      values = selected.filter(v => v !== value);
    }

    setSelected(values);
    handlers.onValueChanged(block, values);
    handlers.onAction(block, values);
  };

  const handleConfirm = () => {
    if (pendingValue) {
      handleNewValue(pendingValue);
    }
  };

  return (
    <div className="w-full flex flex-col space-y-8">
      {block.options.map(option => (
        <div
          key={option.value}
          className="cursor-pointer flex"
          onClick={() => handleCheckboxChange(option.value)}
        >
          <div className="mt-12">
            {selected.includes(option.value) ? (
              <Icon icon="ThreadSelected" size={13} />
            ) : (
              <Icon icon="Checkbox" size={13} />
            )}
          </div>
          <div className="flex flex-col ml-8">
            <span className="text-gray-700">
              {option.text && option.text.type === "markdown" ? (
                <BlockMarkdown text={option.text} handlers={handlers} />
              ) : (
                <BlockPlainText text={option.text} />
              )}
            </span>
            {option.description ? (
              <span className="text-gray-700 my-4">
                {option.description.type === "markdown" ? (
                  <BlockMarkdown
                    text={option.description}
                    handlers={handlers}
                  />
                ) : (
                  <BlockPlainText text={option.description} />
                )}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlockCheckboxes;

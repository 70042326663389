import { useDevicesQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useLocalSettingsStore from "store/useLocalSettingsStore";
import { isNativeMobile } from "utils/platform";

type UseShowDownloadApp = {
  showDownloadBanner: boolean;
  showMobileReminder: boolean;
};

export const useShowDownloadApp = (): UseShowDownloadApp => {
  const { data, loading } = useDevicesQuery();
  const { authData } = useAuthData();

  const dismissedBanner = useLocalSettingsStore(
    ({ dismissedDownloadBanner }) => dismissedDownloadBanner
  );

  const hasRegisteredDevice = !!data?.devices.edges.find(
    d => d.node.platform === "ios" || d.node.platform === "android"
  );

  const showDownloadApp = !!(
    data &&
    authData &&
    !loading &&
    !hasRegisteredDevice &&
    !isNativeMobile()
  );

  return {
    showDownloadBanner: showDownloadApp && !dismissedBanner,
    showMobileReminder: showDownloadApp && dismissedBanner === true,
  };
};

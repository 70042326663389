import env from "./processEnv";

const allowedOrigins = [env.glueApiUrl];

const sanitizeRedirect = (urlString?: string) => {
  if (!urlString) {
    return null;
  }

  let url: URL;
  try {
    url = new URL(urlString);
  } catch (_e) {
    return null;
  }
  if (!allowedOrigins.includes(url.origin)) {
    return null;
  }
  return urlString;
};

export default sanitizeRedirect;

import { capacitorWebAuthURLScheme } from "components/Masonry/MasonrySessionManager";
import useOnce from "hooks/useOnce";
import env from "utils/processEnv";
import { getSessionStorage } from "utils/sessionStorage";

const cannonURL = new URL("/", env.glueAppUrl);

/*
 * This component handles callbacks from oauth processes for 3rd party apps.
 * The general process is that an app initiates an oauth process via Masonry that:
 * 1. Opens a new window
 * 2. Uses a redirect URL that leads back to this component at the end of the process.
 *
 * When arriving at that URL at the end of the process, this component is responsible for the following:
 * 1. For an auth process originating from the web browser, this displays a blank page.
 *    The other window that opened this window will read the URL to get the result and then close this window.
 * 2. For local development, we use ngrok domains specific to each integration that are all bound to glue-web.
 *    This allows different developers to do local development for these integrations by binding the domain as needed.
 *    This component is responsible for redirecting back to the "canonical" domain for glue-web so that the URL
 *    of the window can be read by the other window that started the process.
 * 3. For an auth process originating from the native app, this will invoke the native app
 *    using a URL that is handled by the native app.
 */
const IntegrationCallbackHandler = (): null => {
  useOnce(() => {
    if (getSessionStorage("native-app-callback")) {
      window.location.href = `${capacitorWebAuthURLScheme}://${window.location.pathname}${window.location.search}`;
    } else if (
      env.glueEnv === "development" &&
      window.location.origin !== cannonURL.origin // ie: glue-web-zoom.ngrok instead of glue-web-dev.ngrok
    ) {
      cannonURL.pathname = window.location.pathname;
      cannonURL.search = window.location.search;
      window.location.href = cannonURL.href; // add pathname and query parameters to cannon url and redirect
    }
  });
  return null;
};

export default IntegrationCallbackHandler;

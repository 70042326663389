import { uniq } from "lodash-es";

import {
  Attachment,
  Draft,
  ThreadEdgeSimple,
  ThreadPreview,
  ThreadPreviewEdge,
  ThreadSimple,
  nodeAs,
} from "@utility-types";
import {
  ExternalObjectType,
  FileType,
  ThreadEdgeFieldsFragment,
  ThreadSubscription,
} from "generated/graphql";

import { Skeleton } from "components/Skeleton";
import RecipientsLineSimple from "components/design-system/ui/RecipientsLineSimple";
import { AuthData } from "providers/AuthProvider";
import { formatDate } from "utils/formatDate";
import generateRandomId from "utils/generateRandomId";
import getRandomInt from "utils/getRandomInt";
import { formatGroupName } from "utils/group/formatGroupName";
import getRecipients from "utils/thread/getRecipients";

// TODO: replace feather icons, requires a new YouTube icon
export function getAttachmentIcon(types: AttachmentType[]) {
  const onlyImage = types?.length === 1 && types[0] === "image";
  const onlyVideo = types?.length === 1 && types[0] === "video";
  const onlyFiles =
    types?.filter(a => a !== "image" && a !== "video").length === types?.length;

  const Icon = onlyImage
    ? "Image"
    : onlyVideo
      ? "YouTube"
      : onlyFiles
        ? "File"
        : "Paperclip";
  return Icon;
}

export const isSubscribed = (thread: ThreadEdgeFieldsFragment): boolean =>
  thread.subscription === ThreadSubscription.Inbox;

export type AttachmentType = ExternalObjectType | FileType;
export type ItemType =
  | Draft
  | ThreadSimple
  | ThreadPreview
  | ThreadEdgeSimple
  | ThreadPreviewEdge;

export interface ItemData {
  attachmentTypes: AttachmentType[];
  chatType: "user" | "group" | null;
  date: string | JSX.Element | null;
  id: string;
  message: string | JSX.Element;
  messageSender: string | JSX.Element | null;
  recipients: string | JSX.Element | null;
  showSkeleton: boolean;
  subject?: string | JSX.Element | null;
  unreadCount: number;
  unreadMentionCount?: number;
}

type Props = {
  authData: AuthData | undefined;
  item: ItemType | undefined;
  recipientID?: string;
};

export const mapAttachmentTypes = (
  attachments: Partial<Attachment>[]
): AttachmentType[] =>
  uniq(
    attachments
      .map(attachment => {
        if (attachment.__typename === "ExternalObject") {
          return attachment.objectType;
        }
        if (attachment.__typename === "File") {
          return attachment.fileType;
        }
        return undefined;
      })
      .filter(
        (attachmentType): attachmentType is AttachmentType =>
          attachmentType !== undefined
      )
  );

export function parseData({ authData, item, recipientID }: Props): ItemData {
  const draft = nodeAs(item, ["Draft"]);
  const threadEdge = nodeAs(item, ["ThreadEdge", "ThreadPreviewEdge"]);
  const thread = threadEdge?.node || nodeAs(item, ["Thread", "ThreadPreview"]);
  const isMember = thread?.__typename === "Thread";

  const isOwnChat =
    isMember &&
    thread.recipients.edges.length === 1 &&
    thread.recipients.edges[0]?.node.id === authData?.me.id;

  const chatType =
    isMember && thread.isPersistentChat
      ? (thread.recipients.edges?.length || 0) > 1 || isOwnChat
        ? "user"
        : "group"
      : null;

  const recipients = getRecipients(item);

  const threadStarter = recipients[0];

  const messageData = {
    attachmentTypes: draft
      ? mapAttachmentTypes(draft.message?.attachments ?? [])
      : isMember
        ? mapAttachmentTypes(thread.lastMessage?.attachments ?? [])
        : [],
    chatRecipient: chatType
      ? chatType === "user"
        ? isOwnChat
          ? recipients[0]
          : recipients.find(r => r.id !== authData?.me.id)
        : threadStarter // group chat; should display the group avatar
      : undefined,
    date: draft
      ? draft.updatedAt
      : isMember
        ? thread.lastMessage?.createdAt || thread?.createdAt
        : undefined,

    id: item?.id || generateRandomId("thr_"),
    message: (draft
      ? draft.message
      : (isMember && thread.lastMessage) || undefined
    )?.textPreview,

    messageSender: draft
      ? draft.message.text || draft.message.attachments.length
        ? authData?.me
        : undefined
      : (isMember && thread.lastMessage?.user) || undefined,

    recipients,

    subject: draft ? draft.subject : thread?.subject,

    threadStarter,
  };

  const isThreadEdge = threadEdge?.__typename === "ThreadEdge";
  const unreadCount = isThreadEdge ? threadEdge.unreadMessageCounts.total : 0;
  const unreadMentionCount = isThreadEdge
    ? threadEdge.unreadMessageCounts.mentioned
    : 0;

  const showSkeleton = !authData || item?.id === undefined;

  return {
    attachmentTypes: messageData.attachmentTypes,
    chatType: chatType,
    date: showSkeleton ? (
      <Skeleton width="38px" />
    ) : messageData.date ? (
      formatDate(new Date(messageData.date))
    ) : (
      ""
    ),
    id: messageData.id,
    message: showSkeleton ? (
      <Skeleton flexGrow={false} width={`${getRandomInt(40, 200)}px`} />
    ) : (
      messageData.message || ""
    ),
    messageSender: showSkeleton ? (
      <Skeleton width={`${getRandomInt(30, 60)}px`} />
    ) : messageData.messageSender?.id === authData?.me.id ? (
      "You"
    ) : (
      messageData.messageSender?.name?.split(" ")[0] || ""
    ),
    recipients: showSkeleton ? (
      <Skeleton width={`${getRandomInt(80, 180)}px`} />
    ) : (
      <RecipientsLineSimple
        me={authData?.me}
        recipients={recipients}
        excludeID={recipientID}
      />
    ),
    showSkeleton: showSkeleton,
    subject: showSkeleton ? (
      <Skeleton height="14px" width={`${getRandomInt(80, 220)}px`} />
    ) : chatType ? (
      chatType === "group" ? (
        `${formatGroupName(messageData.chatRecipient).name} Chat`
      ) : (
        messageData.chatRecipient?.name
      )
    ) : (
      messageData.subject
    ),
    unreadCount: unreadCount || 0,
    unreadMentionCount: unreadMentionCount || 0,
  };
}

import { Workspace } from "@utility-types";
import Start from "components/App/Start";
import ChoosePlan from "components/Payment/ChoosePlan";
import Payment from "components/Payment/Payment";
import { Button } from "components/design-system/Button";
import { Form } from "components/design-system/Forms";
import { Icon } from "components/design-system/icons";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import useOnboardingStore from "store/useOnboardingStore";
import Footer from "./Footer";

const FormContent = ({
  onComplete,
  workspace,
  view,
}: {
  onComplete: () => void;
  workspace?: Pick<Workspace, "id" | "name" | "avatarURL">;
  view: "choosePlan" | "payment";
}) => {
  const { watch } = useFormContext();
  const planID = watch("plan");
  return view === "choosePlan" ? (
    <ChoosePlan workspace={workspace} />
  ) : (
    <Payment
      onComplete={onComplete}
      workspaceID={workspace?.id}
      planID={planID}
    />
  );
};

const PlanAndPaymentMobile = ({
  onComplete,
  workspace,
}: {
  onComplete: () => void;
  workspace?: Pick<Workspace, "id" | "name" | "avatarURL">;
}) => {
  const { setState, currentStep } = useOnboardingStore(
    ({ setState, currentStep }) => ({ setState, currentStep })
  );
  const [view, setView] = useState<"choosePlan" | "payment">("choosePlan");

  return (
    <Start fullHeight={view === "payment"}>
      <div className="flex flex-col h-full w-full">
        {view === "payment" && (
          <div className="absolute top-0 left-0 w-full h-full bg-background-white z-0" />
        )}
        <Form className="flex flex-col grow w-full gap-16 text-start overflow-y-auto z-1 pb-16">
          <FormContent
            onComplete={onComplete}
            workspace={workspace}
            view={view}
          />
        </Form>
        <Footer
          className="z-1 mt-8"
          onClickBack={() => {
            if (view === "payment") {
              setView("choosePlan");
              return;
            }
            setState({
              view: "CreateWorkspace",
              currentStep: currentStep - 1,
            });
          }}
          fullHeight={false}
          isDayMode={view === "payment"}
          hideProgress={view === "payment"}
          hideSkip
        >
          {view === "choosePlan" ? (
            <Button onClick={() => setView("payment")}>
              {view === "choosePlan" ? "Next" : "Start trial"}{" "}
              <Icon className="ml-6" icon="ArrowRight" size={20} />
            </Button>
          ) : null}
        </Footer>
      </div>
    </Start>
  );
};

export default PlanAndPaymentMobile;

import { useRemirrorContext } from "@remirror/react";

import { nodeAs } from "@utility-types";
import { drawerParams, routeParams } from "components/routing/utils";
import { useFetchRecipientLazyQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";

import { getAppURLObject } from "components/routing/utils";

import { MentionSearchName } from "../types";

const mentionedID = (urlProp: string): string | undefined => {
  const url = getAppURLObject(urlProp);
  if (!url) return;

  const { d, messageID, recipientID, threadID } = routeParams(url);
  const drawer = d ? drawerParams(d) : { threadID: undefined };

  if (messageID) return; // can't mention messages yet

  const mentionID = threadID ?? drawer?.threadID ?? recipientID;
  if (!mentionID?.match(/^(thr|grp|usr|wks)_/)) return;

  return mentionID;
};

const useDetectMention = () => {
  const { commands, view } = useRemirrorContext();
  const { authData } = useAuthData();
  const [fetchRecipient] = useFetchRecipientLazyQuery({
    fetchPolicy: "cache-first",
  });

  const detectMention = async ({
    label: labelProp,
    selection,
    url: urlProp,
  }: {
    label: string;
    selection?: { from: number; to: number };
    url: string;
  }) => {
    const id = mentionedID(urlProp);
    if (!id) return;

    const data = await fetchRecipient({
      variables: {
        id: id.startsWith("thr") ? `${id}-${authData?.me.id}` : id,
      },
    });

    if (!data.data) return;

    const parsedData = nodeAs(data.data.node, [
      "ThreadEdge",
      "ThreadPreviewEdge",
      "Group",
      "User",
    ]);

    if (!parsedData || !authData) return;

    const isThreadEdge =
      parsedData.__typename === "ThreadEdge" ||
      parsedData.__typename === "ThreadPreviewEdge";

    const label = isThreadEdge ? parsedData.node.subject : parsedData.name;

    const { from, to } = selection || view.state.selection;

    const mentionFrom = from - (!selection ? labelProp.length : 0);
    const mentionTo = to;

    window.requestAnimationFrame(() => {
      commands.delete({ from: mentionFrom, to: mentionTo });
      commands.createMentionAtom(
        {
          name: isThreadEdge
            ? MentionSearchName.Threads
            : MentionSearchName.All,
          range: { cursor: mentionFrom, from: mentionFrom, to: mentionFrom },
        },
        {
          id,
          label,
        }
      );
    });
  };

  return { detectMention, mentionedID };
};

export default useDetectMention;

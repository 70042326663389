import { glueCreateZustand } from "./helper/glueCreate";

const STORE_NAME = "RefreshChannelStore";

type RefreshChannelStore = {
  channelID: string | null;
};

const initialState: RefreshChannelStore = {
  channelID: null,
};

const useRefreshChannelStore = glueCreateZustand<RefreshChannelStore>({
  name: STORE_NAME,
})(() => initialState);

export default useRefreshChannelStore;

import tw from "utils/tw";

type Props = {
  className?: string;
  important?: boolean;
};
const UnreadBadge = ({
  children,
  className: classNameOverrides = "",
  important,
}: WithChildren<Props>) => {
  const className = tw(
    "inline-block shrink-0 rounded-[1.125rem] font-semibold",
    {
      "py-2 px-10 ml-10 text-sm": !!children,
    },
    {
      "mr-7 w-8 h-8": !children,
    },
    {
      "bg-accent-highlight/50 text-accent-primary": !!children && !important,
    },
    { "bg-accent-primary/30": !children && !important },
    {
      "bg-accent-alert/20 text-accent-alert": important,
    },
    classNameOverrides
  );
  return <span className={className}>{children}</span>;
};

export default UnreadBadge;

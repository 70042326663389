import { ReactComponentExtension } from "@remirror/react";
import {
  CommandsExtension,
  GetStaticAndDynamic,
  HelpersExtension,
  SuggestExtension,
} from "remirror";
import {
  BlockquoteExtension,
  BoldExtension,
  BoldOptions,
  CodeBlockExtension,
  CodeBlockOptions,
  CodeExtension,
  CorePreset,
  FlatEmoji,
  HeadingExtension,
  HistoryExtension,
  HorizontalRuleExtension,
  LinkExtension,
  PlaceholderExtension,
  TrailingNodeExtension,
  TrailingNodeOptions,
} from "remirror/extensions";

import * as syntaxLang from "components/helper/syntax-highlighter";
import addProtocol from "utils/addProtocol";
import { linkifier, matchURL } from "utils/matchURL";
import tw from "utils/tw";

import { Styles } from "components/MessageElements";

import { MentionSearchName, MentionTriggers } from "../types";

import EditorClassListExtension from "./EditorClassListExtension";
import GlueBulletListExtension from "./GlueBulletListExtension";
import GlueEmojiExtension from "./GlueEmojiExtension";
import GlueHardBreakExtension from "./GlueHardBreakExtension";
import GlueItalicExtension from "./GlueItalicExtension";
import GlueKeymapExtension from "./GlueKeymapExtension";
import GlueMarkdownExtension from "./GlueMarkdownExtension";
import GlueMentionAtomExtension from "./GlueMentionAtomExtension";
import GlueOrderedListExtension from "./GlueOrderedListExtension";
import GlueStrikeExtension from "./GlueStrikeExtension";

// Valid mention starts with non-space, one optional space, followed by non-space
const SUPPORTED_CHARACTERS_REGEX = /\S+\s?\S*/;
const VALID_PREFIX_CHARACTERS_REGEX = /^[\s\0\(]?$/; // adds open parenthesis

/**
 * The union of types for all the extensions provided by the `Preset` function call.
 */
export type GlueWysiwygPreset =
  | ReturnType<typeof GlueWysiwyg>[0]
  | CommandsExtension
  | HelpersExtension
  | HistoryExtension
  | SuggestExtension
  | CorePreset;

interface WysiwygOptions
  extends BoldOptions,
    CodeBlockOptions,
    TrailingNodeOptions {}

const DEFAULT_OPTIONS = {
  ...BoldExtension.defaultOptions,
  ...CodeBlockExtension.defaultOptions,
  ...HeadingExtension.defaultOptions,
  ...TrailingNodeExtension.defaultOptions,
};

/**
 * Glue wysiwyg preset which includes extensions from `remirror` core library.
 */
export default function GlueWysiwyg(
  options: GetStaticAndDynamic<WysiwygOptions> & {
    className: {
      editor: string;
      placeholder: string;
    };
    emojiData?: FlatEmoji[];
    enterKeyBehavior?: "new-line" | "send-message";
    placeholder?: string;
  }
) {
  options = { ...DEFAULT_OPTIONS, ...options };

  const boldExtension = new BoldExtension({
    weight: options.weight,
  });

  const codeExtension = new CodeExtension();
  const emojiExtension = new GlueEmojiExtension({
    data: options.emojiData ?? [],
    fallback: "question",
    plainText: true,
  });

  const headerLevels: (1 | 2 | 3 | 4)[] = [1, 2, 3, 4];
  const headingExtension = new HeadingExtension({
    defaultLevel: 1,
    extraAttributes: {
      custom: {
        default: Styles.Header,
        toDOM: (attrs: Record<string, unknown>) => {
          let levelUtilClass = "";

          switch (attrs.level) {
            case 1:
              levelUtilClass = Styles.HeaderOne;
              break;
            case 2:
              levelUtilClass = Styles.HeaderTwo;
              break;
            case 3:
              levelUtilClass = Styles.HeaderThree;
              break;
            case 4:
              levelUtilClass = Styles.HeaderFour;
              break;
            /* istanbul ignore next */
            default:
              levelUtilClass = "";
              break;
          }
          return ["class", tw(Styles.Header, levelUtilClass)];
        },
      },
    },
    levels: headerLevels,
  });

  const { className, placeholder } = options;

  const reactComponentExtension = new ReactComponentExtension({});

  const glueMentionAtomExtension = new GlueMentionAtomExtension({
    invalidNodes: ["codeBlock"],
    invalidMarks: ["code", "link"],
    matchers: [
      {
        char: MentionTriggers.ALL,
        matchOffset: 0,
        name: MentionSearchName.All,
        supportedCharacters: SUPPORTED_CHARACTERS_REGEX,
        validPrefixCharacters: VALID_PREFIX_CHARACTERS_REGEX,
      },
      {
        char: MentionTriggers.THREADS,
        matchOffset: 0,
        name: MentionSearchName.Threads,
        supportedCharacters: SUPPORTED_CHARACTERS_REGEX,
        validPrefixCharacters: VALID_PREFIX_CHARACTERS_REGEX,
      },
    ],
  });

  const editorClassListExtension = new EditorClassListExtension({
    className: className.editor,
  });
  const placeholderExtension = new PlaceholderExtension({
    emptyNodeClass: className.placeholder,
    placeholder,
  });

  const glueMarkdownExtension = new GlueMarkdownExtension({});

  const horizontalRuleExtension = new HorizontalRuleExtension({});
  const italicExtension = new GlueItalicExtension();

  const linkExtension = new LinkExtension({
    autoLink: true,
    defaultProtocol: "https:",
    findAutoLinks: (str: string) =>
      linkifier.match(str)?.map(link => ({
        end: link.lastIndex,
        href: addProtocol(link.raw, "https:"),
        start: link.index,
        text: link.text,
      })) || [],
    isValidUrl: url => !!matchURL(url),
    markOverride: { excludes: undefined },
    selectTextOnClick: false,
  });

  const strikeExtension = new GlueStrikeExtension();

  const trailingNodeExtension = new TrailingNodeExtension({});

  const excludeOptions = {
    keymap: options.enterKeyBehavior === "send-message",
  };

  const bulletListExtension = new GlueBulletListExtension({
    enterKeyBehavior: options.enterKeyBehavior,
    exclude: excludeOptions,
  });
  const orderedListExtension = new GlueOrderedListExtension({
    enterKeyBehavior: options.enterKeyBehavior,
    exclude: excludeOptions,
  });

  const blockquoteExtension = new BlockquoteExtension({
    exclude: excludeOptions,
  });

  const { formatter, toggleName } = options;
  const codeBlockExtension = new CodeBlockExtension({
    defaultLanguage: "text",
    exclude: excludeOptions,
    formatter,
    supportedLanguages: Object.values(syntaxLang),
    toggleName,
  });

  const hardBreakExtension = new GlueHardBreakExtension({
    exclude: excludeOptions,
  });

  const glueKeymapExtension = new GlueKeymapExtension();
  const keymapExtension =
    options.enterKeyBehavior === "send-message" ? [glueKeymapExtension] : [];

  return [
    // Plain
    editorClassListExtension,
    trailingNodeExtension,
    ...keymapExtension,
    glueMarkdownExtension,
    glueMentionAtomExtension,
    placeholderExtension,
    reactComponentExtension,

    // Nodes
    emojiExtension,
    hardBreakExtension,
    horizontalRuleExtension,
    blockquoteExtension,
    codeBlockExtension,
    headingExtension,
    bulletListExtension,
    orderedListExtension,

    // Marks
    italicExtension,
    boldExtension,
    codeExtension,
    strikeExtension,
    linkExtension,
  ];
}

declare module "remirror" {
  interface BaseExtensionOptions {
    enterKeyBehavior?: "new-line" | "send-message";
  }
}

function S4() {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}

export default function generateRandomId(prefix = ""): string {
  let uuid: string | undefined;

  try {
    uuid = crypto.randomUUID();
  } catch (_error) {
    // https://stackoverflow.com/a/6860916/2570866
    uuid = `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
  }

  return `${prefix !== "" ? `${prefix}_` : ""}${uuid}`;
}

import WorkspacesList from "components/SideBar/Groups/WorkspacesList";
import Sidebar from "components/SideBar/Sidebar";
import DirectoryButton from "components/SideBar/SidebarHeader/DirectoryButton";
import SidebarHeader from "components/SideBar/SidebarHeader/SidebarHeader";
import ViewScrollContainer from "components/design-system/ui/ViewScrollContainer";

export const SIDEBAR_ID = "mega-tab-sidebar--groups";

const GroupsSidebarDesktop = () => {
  return (
    <Sidebar id={SIDEBAR_ID}>
      <SidebarHeader
        buttons={<DirectoryButton label="Directory" />}
        title="Groups"
      />

      <ViewScrollContainer className="pt-8 px-8">
        <WorkspacesList />
      </ViewScrollContainer>
    </Sidebar>
  );
};

export default GroupsSidebarDesktop;

const SharedListHeader = ({
  name,
  onShowAll,
}: { name: string; onShowAll?: () => void }) => (
  <div className="flex justify-between h-32 pt-12 text-footnote-bold text-text-secondary">
    {name}
    {onShowAll && (
      <span
        className="text-footnote text-text-action hover:text-text-action-hover cursor-pointer"
        onClick={onShowAll}
      >
        See all
      </span>
    )}
  </div>
);

export default SharedListHeader;

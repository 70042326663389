import { ModalProps } from "components/ModalKit/Modal";
import { Header, Main } from "components/ModalKit/Parts";
import { Form } from "components/design-system/Forms";

import { StandardModal } from "components/Modals";

import useFeatureFlagStore from "../../store/useFeatureFlagStore";

import FlagToggleSwitch from "./FlagToggleSwitch";
import { FlagName } from "./flags-config";

type Props = {
  environmentFlags: FlagName[];
} & ModalProps;

export const FeatureFlagModal = ({
  environmentFlags,
  ...props
}: Props): JSX.Element => {
  const { flags: defaultValues } = useFeatureFlagStore(({ flags }) => ({
    flags,
  }));

  return (
    <Form
      useFormProps={{
        defaultValues,
      }}
    >
      <StandardModal
        header={
          <Header variant="bordered">
            <h3 className="m-0">Feature Flags</h3>
          </Header>
        }
        {...props}
      >
        <Main>
          <div className="p-16 md:px-32">
            <div className="flex overflow-y-scroll flex-col items-center">
              {environmentFlags.map(keyName => (
                <FlagToggleSwitch key={keyName} keyName={keyName} />
              ))}
            </div>
          </div>
        </Main>
      </StandardModal>
    </Form>
  );
};

import { FeedbackType } from "components/GlueAIFeedback/types";
import { Button } from "components/design-system/Button";
import { LoadingSpinner } from "components/design-system/LoadingSpinner";
import MessageFeedback from "components/thread/ThreadView/stream-components/MessageList/MessageFeedback";
import { ThreadSummaryPointFieldsFragment } from "generated/graphql";
import tw from "utils/tw";

type Props = {
  onClose: () => void;
  points: ThreadSummaryPointFieldsFragment[];
  summarizing: boolean;
};

const Summary = ({ onClose, points, summarizing }: Props) => {
  const messageText = points.map(point => `*\t${point.point}`).join("\n");
  const message = {
    feedback_type: FeedbackType.THREAD_SUMMARY,
    text: messageText,
    thread_id: points[0]?.threadID,
  };

  return (
    <div
      className={tw("pt-16 px-16 bg-background-card rounded-lg", {
        "pb-16": summarizing || points.length === 0,
      })}
    >
      <div className="flex justify-between items-start">
        {summarizing ? (
          <div className="flex flex-row items-center">
            <LoadingSpinner className="w-18 h-18 animate-spin" />
            <span className="text-text-subtle text-subhead ml-12">
              Summarizing...
            </span>
          </div>
        ) : points.length === 0 ? (
          <span>Summary not available.</span>
        ) : (
          <ul className="list-nested">
            {points.map(point => {
              return (
                <li key={point.id} className="ml-20 mb-4">
                  {point.point}
                </li>
              );
            })}
          </ul>
        )}

        <Button
          buttonStyle="icon-secondary"
          buttonType="icon"
          icon="Close"
          iconSize={20}
          onClick={onClose}
        />
      </div>
      {!summarizing && points.length > 0 ? (
        <MessageFeedback message={message} />
      ) : null}
    </div>
  );
};

export default Summary;

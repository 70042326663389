import { useCallback } from "react";

import { ApolloCache, useApolloClient } from "@apollo/client";

import { UserFieldsFragment, UserFieldsFragmentDoc } from "generated/graphql";

const useUserCacheUpdate: () => {
  onNewUser: (user: UserFieldsFragment, cache?: ApolloCache<unknown>) => void;
} = () => {
  const apolloClient = useApolloClient();

  const onNewUser = useCallback(
    (
      user: UserFieldsFragment,
      cache: ApolloCache<unknown> = apolloClient.cache
    ) => {
      const existingUser = cache.readFragment<UserFieldsFragment>({
        fragment: UserFieldsFragmentDoc,
        fragmentName: "UserFields",
        id: cache.identify(user),
      });
      if (existingUser) return;

      cache.writeFragment<UserFieldsFragment>({
        data: user,
        fragment: UserFieldsFragmentDoc,
        fragmentName: "UserFields",
        id: cache.identify(user),
      });
    },
    [apolloClient.cache]
  );

  return { onNewUser };
};

export default useUserCacheUpdate;

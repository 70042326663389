import { createContext } from "react";

export const SIDEBAR_INITIAL_SWIPE_START_AREA = 50;

export enum NavigationState {
  SideBar = "sidebar",
  Main = "main",
  Detail = "detail",
}

export const NavigationStateContext = createContext({
  activeNavigation: NavigationState.Main,
  setActiveNavigation: (_: NavigationState) => {
    return;
  },
  setSidebarTransitioning: (_: boolean) => {
    return;
  },
  setSidebarWidth: (_: number) => {
    return;
  },
  sidebarInitialSwipeStartArea: SIDEBAR_INITIAL_SWIPE_START_AREA,
  sidebarTransitioning: false,
  sidebarWidth: 0,
});

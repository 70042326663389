import { RefObject, useCallback, useContext, useEffect, useRef } from "react";

import { useAbortController } from "use-abort-controller-hook";

import ProgressModal from "components/Modals/ProgressModal";
import { useLongPress } from "hooks/useLongPress";
import useModalStore from "store/useModalStore";
import useProgressStore from "store/useProgressStore";
import shareFileNativeMobile from "utils/shareFileNativeMobile";

import Context from "./Context";

type ChildrenFnProps = {
  bindLongPress: ReturnType<typeof useLongPress>["handlers"];
  /**
   * Function that opens the gallery at the current item's index
   */
  open: () => void;

  /**
   * Required `ref` object to any html node of item
   *
   * Can be omitted if there is only one item in gallery
   */
  ref: RefObject<HTMLImageElement>;
};

type Props = {
  /**
   * Render prop for exposing Gallery API
   */
  children: (props: ChildrenFnProps) => JSX.Element;

  fullSizeURL: string;

  /**
   * Item ID, for hash navigation
   */
  /**
   * Height of original image
   */
  height: string | number;

  /**
   * Html content, if you need to use it as modal
   */
  html?: string;

  id?: string | number;
  /**
   * Url of original image
   */
  originalURL: string;

  /**
   * Url of thumbnail
   */
  thumbnail?: string;

  /**
   * Title for Default UI
   */
  title: string;

  /**
   * Width of original image
   */
  width: string | number;
};

/**
 * Gallery item
 *
 * Should be a children of Gallery or CustomGallery component
 */
const PhotoSwipeItem = ({ children, ...props }: Props): JSX.Element => {
  const ref = useRef<HTMLImageElement>(null);
  const { handleClick, remove, set } = useContext(Context);
  const open = useCallback(() => handleClick(ref), [handleClick]);

  const abortController = useAbortController();
  const firstRenderRef = useRef(true);

  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const bindLongPress = useLongPress<HTMLImageElement>(
    event => {
      if (!event) return;

      const showProgress = () => {
        openModal(
          <ProgressModal
            autoCloseDelay={0}
            header={"Downloading image"}
            onCancel={abortController.abort}
          />
        );
      };

      shareFileNativeMobile({
        abortSignal: abortController.signal,
        dialogTitle: "Share image",
        fileName: props.title,
        onError: () => {
          useProgressStore.setState({
            message: {
              text: "Unknown error while sharing image.",
              type: "error",
            },
          });
        },
        showProgress,
        url: props.originalURL,
      });
    },
    { cancelOnMovement: 10 }
  ).handlers;

  useEffect(() => {
    set(ref, props);
    return () => remove(ref);
  }, [remove, props, set]);

  useEffect(() => {
    if (!firstRenderRef.current) return;
    firstRenderRef.current = false;
    const firstRender = firstRenderRef.current;

    return () => {
      !firstRender && abortController.abort();
    };
  }, [abortController]);

  return children({ bindLongPress, open, ref });
};

export default PhotoSwipeItem;

import { MouseEvent, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { DraftEdge } from "@utility-types";
import { Button } from "components/design-system/Button";
import useToastStore, {
  ToastType,
} from "components/design-system/ui/ToastKit/useToastStore";
import { SectionItem } from "components/design-system/ui/sections-sidebar";
import { routeToThread, useRouteParams } from "components/routing/utils";
import { DraftListDocument, useDeleteDraftMutation } from "generated/graphql";
import useCacheEvict from "hooks/state/useCacheEvict";
import { addToHistory } from "store/useHistoryStore";
import useLocalSettingsStore from "store/useLocalSettingsStore";

import MailboxSection from "./MailboxSection";
import { useDraftsUtils } from "./hooks";

import { useInboxSidebarSectionsState } from "components/views/inbox/providers/InboxSidebarSectionsProvider";
import useDraftMessagesStore from "store/useDraftMessagesStore";
import { InboxListItem } from ".";

const sectionKey = "Draft";
const pageSize = 9;

const Drafts = () => {
  const history = useHistory();
  const { evictNode } = useCacheEvict();
  const { superTab, threadID: selectedID, view } = useRouteParams();

  const { swipedOpenItemId, setState } = useInboxSidebarSectionsState(
    ({ swipedOpenItemId }) => ({
      swipedOpenItemId,
    })
  );
  const setSwipedOpenItemId = (id?: string) => {
    setState({ swipedOpenItemId: id });
  };

  const { data, fetchMore, handleUndo } = useDraftsUtils({ pageSize });

  const path = history.location.pathname + history.location.search;
  const title =
    data?.drafts.edges.find(d => d.node.id === selectedID)?.node.subject ||
    "No Subject";
  useEffect(() => {
    if (!path.includes("dft_")) return;

    addToHistory({
      icon: "draft",
      path,
      primaryType: "inbox",
      title,
    });
  }, [path, title]);

  const [deleteDraft] = useDeleteDraftMutation();

  const { openToast } = useToastStore(({ openToast }) => ({ openToast }));
  const { removeDraft } = useDraftMessagesStore(({ removeDraft }) => ({
    removeDraft,
  }));

  const { collapsedSidebarSections } = useLocalSettingsStore(
    ({ collapsedSidebarSections }) => ({ collapsedSidebarSections })
  );
  const collapsed = collapsedSidebarSections.includes(sectionKey);

  const reversedDrafts = data?.drafts.edges.slice().reverse() ?? [];

  const handleDeleteDraft = (e: MouseEvent, draft: DraftEdge) => {
    e.preventDefault();
    e.stopPropagation();
    deleteDraft({
      variables: { id: draft.node.id },
      refetchQueries: [DraftListDocument],
      update: c => evictNode(draft, c),
    }).then(() => {
      removeDraft({ draftID: draft.node.id });
      openToast({
        content: "Draft deleted",
        dismiss: 5000,
        icon: "Trash",
        type: ToastType.DRAFT,
        undo: () => handleUndo(draft.node.id),
      });
      if (selectedID === draft.node.id && reversedDrafts.length === 1)
        history.push(`/${superTab || "inbox"}`);
    });
  };

  const composeItem = (
    <SectionItem key="compose" className="mx-0 !px-0" flipId="compose">
      <InboxListItem
        bulkMode={false}
        icon="Edit"
        isSelected={true}
        subject={<span className="text-text-subtle">New thread</span>}
      />
    </SectionItem>
  );

  if ((!reversedDrafts.length && view !== "compose") || !reversedDrafts)
    return null;

  const sectionItems = (drafts: DraftEdge[]) => {
    if (collapsed) drafts = drafts.filter(d => d.node.id === selectedID);

    return drafts.map(d => (
      <SectionItem
        key={d.node.id}
        className="mx-0 !px-0"
        flipId={`draft-${d.node.id}`}
        onClick={(e: React.KeyboardEvent | React.MouseEvent) => {
          const to = e.ctrlKey || e.metaKey ? "secondary" : "primary";
          history.push(
            routeToThread({ superTab: "inbox", threadID: d.node.id, to })
          );
        }}
        itemData={d}
        setSwipedOpenItemId={setSwipedOpenItemId}
        swipedOpenItemId={swipedOpenItemId}
      >
        <InboxListItem
          bulkMode={false}
          icon="Edit"
          isSelected={d.node.id === selectedID}
          subject={
            d.node.subject || (
              <span className="italic text-text-secondary">No subject</span>
            )
          }
        >
          <div className="grow flex justify-end invisible group-hover/item:visible">
            <Button
              buttonStyle="subtle"
              className="pr-0"
              icon="Trash"
              iconClassName="w-20 h-20"
              onPointerDown={e => handleDeleteDraft(e, d)}
            />
          </div>
        </InboxListItem>
      </SectionItem>
    ));
  };

  const initialDrafts = reversedDrafts.slice(0, pageSize);
  const moreDrafts = reversedDrafts.slice(pageSize);

  const loadNextPage = () =>
    fetchMore({ variables: { before: data?.drafts.pageInfo.startCursor } });

  return (
    <MailboxSection
      divider="bottom"
      flipKey={data?.drafts.edges.map(d => d.node.id).join()}
      hasPreviousPage={data?.drafts.pageInfo.hasPreviousPage}
      moreItems={sectionItems(moreDrafts)}
      unreadCount={data?.drafts.totalCount}
      onClickMore={loadNextPage}
      sectionItems={[
        ...(view === "compose" ? [composeItem] : []),
        ...sectionItems(initialDrafts),
      ]}
      sectionKey={sectionKey}
      sectionTitle="Drafts"
      selectedItem={
        !initialDrafts.find(d => d.node.id === selectedID)
          ? sectionItems(moreDrafts.filter(d => d.node.id === selectedID))[0]
          : undefined
      }
    />
  );
};

export default Drafts;

import { useApolloClient } from "@apollo/client";
import Avatar from "components/design-system/Avatar/Avatar";
import { Button } from "components/design-system/Button";
import {
  WorkspaceModal,
  WorkspaceModalTabs,
} from "components/workspace/WorkspaceModal";
import {
  MemberRole,
  WorkspaceSubscriptionQuery,
  WorkspaceSubscriptionQueryDocument,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import { useEffect, useState } from "react";
import useModalStore from "store/useModalStore";
import { isNative } from "utils/platform";
import usePlanRemainingDays from "../hooks/usePlanRemainingDays";

const UpgradeButton = ({ isWideView }: { isWideView: boolean }) => {
  const apolloClient = useApolloClient();
  const { authData } = useAuthData();
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const [unsubscribedWorkspaces, setUnsubscribedWorkspaces] = useState<
    [string, WorkspaceSubscriptionQuery][]
  >([]);

  useEffect(() => {
    (async () => {
      const adminWorkspaces =
        authData?.workspaces.edges.filter(
          edge => edge.memberRole === MemberRole.Admin
        ) ?? [];

      const promises: Promise<[string, WorkspaceSubscriptionQuery]>[] = [];
      adminWorkspaces.forEach(wks => {
        const newPromise: Promise<[string, WorkspaceSubscriptionQuery]> =
          new Promise(resolve => {
            apolloClient
              .query<WorkspaceSubscriptionQuery>({
                query: WorkspaceSubscriptionQueryDocument,
                variables: { workspaceID: wks.node.id },
              })
              .then(({ data }) => ({
                data: resolve([wks.node.id, data]),
              }));
          });
        promises.push(newPromise);
      });

      const result = (await Promise.all(promises)).filter(
        ([_workspaceID, data]) => !data?.workspaceSubscription.plan
      );
      setUnsubscribedWorkspaces(result);
    })();
  }, [apolloClient, authData?.workspaces.edges]);

  const daysRemaining = usePlanRemainingDays();

  if (!unsubscribedWorkspaces.length || isNative()) return null;

  const daysLeft = unsubscribedWorkspaces[0]?.[1]
    ? daysRemaining(unsubscribedWorkspaces[0]?.[1])
    : 0;

  const pluralize = (word: string, num: number) =>
    num > 1 ? `${word}s` : word;

  return (
    <Button
      buttonFont="subheadBold"
      buttonStyle="action-inverse-subtle"
      buttonType="text"
      key={unsubscribedWorkspaces[0]?.[1]?.workspaceSubscription.id}
      onClick={() => {
        openModal(
          <WorkspaceModal
            defaultTab={WorkspaceModalTabs.Billing}
            workspaceID={unsubscribedWorkspaces[0]?.[0]}
          />,
          { id: unsubscribedWorkspaces[0]?.[0] }
        );
      }}
      type="button"
    >
      <span className="flex-nowrap inline-flex items-center">
        <Avatar
          className="!w-16 mr-6"
          background="transparent"
          emojiProps={{ emoji: daysLeft > 0 ? "🕒" : "🚀", size: 16 }}
        />
        {daysLeft > 0
          ? `${daysLeft} ${pluralize("day", daysLeft)} left`
          : isWideView
            ? "Trial Expired - Upgrade"
            : "Upgrade"}
      </span>
    </Button>
  );
};

export default UpgradeButton;

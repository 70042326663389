import { OptionProps, components } from "react-select";

import { Recipient } from "@utility-types";

import RecipientProfileItem from "components/ProfileItem/RecipientProfileItem";

const RecipientsOption = (props: OptionProps<Recipient, true>): JSX.Element => (
  <components.Option {...props}>
    <div className="flex items-center w-full min-w-0 select-none">
      {props.data.__typename === "Address" ? (
        <i>Invite {props.data.name}</i>
      ) : (
        <RecipientProfileItem recipient={props.data} />
      )}
    </div>
  </components.Option>
);

export default RecipientsOption;

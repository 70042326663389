import { Text } from "./blockTypes";

interface BlockPlainTextProps {
  text: Text;
}

const BlockKitPlainText = ({ text }: BlockPlainTextProps) => {
  return <span>{text.text}</span>;
};

export default BlockKitPlainText;

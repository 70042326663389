import { useEffect } from "react";

import {
  FieldPath,
  FieldValues,
  SubmitHandler,
  useFormContext,
  useWatch,
} from "react-hook-form";

import usePrevious from "hooks/usePrevious";

export const OnChangeSubmit = <TFieldValues extends FieldValues>({
  children,
  name,
  submitHandler,
}: {
  children: React.ReactNode;
  name: FieldPath<TFieldValues>;
  submitHandler: SubmitHandler<TFieldValues>;
}) => {
  const { handleSubmit } = useFormContext<TFieldValues>();
  const value = useWatch({ name });
  const prevValue = usePrevious(value);

  useEffect(() => {
    if (value === prevValue) return;
    handleSubmit(submitHandler)();
  }, [handleSubmit, prevValue, submitHandler, value]);
  return <div>{children}</div>;
};

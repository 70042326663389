import { Icon } from "components/design-system/icons";

type Props = {
  children: React.ReactNode;
};

export function PendingInvitationsBanner({ children }: Props) {
  return (
    <div
      role="alert"
      className="rounded bg-background-secondary border border-border-container px-16 py-12 flex items-center mb-16 text-footnote"
    >
      <Icon icon="Info" className="text-icon-action mr-8" size={20} />
      {children}
    </div>
  );
}

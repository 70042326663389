import BlockComponent, { BlockComponentProps } from "./BlockComponent";
import { ActionsBlock } from "./blockTypes";

type BlockActionsProps = BlockComponentProps & {
  block: ActionsBlock;
};

const BlockActions = ({ block, handlers }: BlockActionsProps) => {
  return (
    <div className="p-4 flex flex-col md:flex-row md:flex-wrap gap-x-4 gap-y-8">
      {block.controls.map(control => {
        return (
          <BlockComponent
            key={control.key}
            block={control}
            handlers={handlers}
            parentType={block.type}
          />
        );
      })}
    </div>
  );
};

export default BlockActions;

import { Thread, ThreadPreview, ThreadSimple } from "@utility-types";

const threadChatType = (
  thread: Thread | ThreadSimple | ThreadPreview | undefined
): "user" | "group" | null | undefined => {
  if (!thread || thread.__typename === "ThreadPreview") return undefined;
  if (!thread.isPersistentChat) return null;

  return thread.recipients.edges[0]?.node.__typename === "User"
    ? "user"
    : "group";
};

export default threadChatType;

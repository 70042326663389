import { useFormContext } from "react-hook-form";

import { Address, User, WorkspaceSimple } from "@utility-types";
import { TextInput } from "components/design-system/Forms";
import type { UpdateMeInput } from "generated/graphql";

import EditableAvatar from "components/Avatar/EditableAvatar";

export type FormData = Partial<UpdateMeInput> & {
  email: string | undefined;
  id: string;
};

type Props = {
  addresses?: Address[];
  company?: WorkspaceSimple;
  user: User;
};

const EditProfileForm = ({ user }: Props) => {
  const { setValue, watch } = useFormContext();

  const currentAvatarURL = watch("avatarURL");

  const handleChangeAvatar = (avatarURL: string) => {
    setValue("avatarURL", avatarURL, { shouldDirty: true });
  };

  return (
    <div className="flex flex-col md:flex-row">
      <div className="my-16 md:mt-0 md:mr-24">
        <div className="flex justify-center">
          <EditableAvatar
            avatarURL={currentAvatarURL}
            name={user.name}
            onAvatarUpdate={handleChangeAvatar}
          />
        </div>
      </div>
      <div className="flex flex-col md:grow">
        <div className="flex flex-col grow md:flex-row">
          <TextInput<FormData>
            config={{ required: true }}
            label="Name"
            name="name"
            wrapperClassName="mt-0 md:mr-24 grow"
          />
          <TextInput<FormData>
            label="Title"
            name="bio"
            wrapperClassName="mt-0 grow"
          />
        </div>

        <TextInput<FormData>
          className="disabled:!bg-background-ghost"
          label="Account Email (private)"
          name="email"
          wrapperClassName="mt-0"
          disabled
        />
      </div>
    </div>
  );
};

export default EditProfileForm;

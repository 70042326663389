import { HTMLProps, forwardRef } from "react";

type Props = HTMLProps<HTMLDivElement> & {
  badge: { color: "red" | "blue"; count: number };
};

const UnreadBadge = forwardRef<HTMLDivElement, Props>(
  (
    { badge: { color, count }, className, ...props }: Props,
    forwardedRef
  ): JSX.Element | null => {
    const badgeColor =
      color === "red" ? "bg-accent-error" : "bg-accent-primary";

    const badgeClasses = count
      ? `h-14 ${count > 99 ? "px-2" : "px-4"} border-1 ${badgeColor}`
      : `h-10 w-10 border-2 ${badgeColor}`;

    return (
      <div
        ref={forwardedRef}
        className={`border-background ${badgeClasses} text-center text-text-accent rounded-[0.625rem] text-[0.625rem] leading-[0.75rem] font-semibold z-1 ${className}`}
        {...props}
      >
        {(count || 0) > 99 ? "99+" : count || ""}
      </div>
    );
  }
);
export default UnreadBadge;

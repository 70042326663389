import { ErrorConstant, invariant, isElementDomNode } from "remirror";
import TurndownService from "turndown";

import { GLUE_URI_SCHEME } from "components/MessageEditor/types";

import { replaceHardbreaksPlaceHolder } from "./util";

type Rule = TurndownService.Rule;

enum Options {
  BLOCKQUOTE = "blockquote",
  FENCED_CODE_BLOCK = "fencedCodeBlock",
  MENTIONS = "mentions",
  PARAGRAPHS_INSIDE_LIST_ITEMS = "paragraphsInsideListItems",
  STRIKE_THROUGH = "strikethrough",
}

export default function getRules(): {
  [key in Options]: Rule;
} {
  return {
    blockquote: {
      filter: node => node.nodeName === "BLOCKQUOTE",
      replacement: content =>
        `> ${replaceHardbreaksPlaceHolder(content.trim(), true, "<br>")
          .replaceAll("\n\n", "<br>")
          .replaceAll("\n", "")}\n\n`,
    },
    fencedCodeBlock: {
      filter: (node, options) =>
        !!(
          options.codeBlockStyle === "fenced" &&
          node.nodeName === "PRE" &&
          node.firstChild &&
          node.firstChild.nodeName === "CODE"
        ),

      replacement: (_, node, options) => {
        invariant(isElementDomNode(node.firstChild), {
          code: ErrorConstant.EXTENSION,
          message: `Invalid node \`${node.firstChild?.nodeName}\` encountered for codeBlock when converting html to markdown.`,
        });

        const className = node.firstChild.getAttribute("class") ?? "";
        const language =
          className.match(/(?:lang|language)-(\S+)/)?.[1] ??
          node.firstChild.getAttribute("data-code-block-language") ??
          "";

        // Get the code node text content and escape code fence back ticks
        const code = (node.firstChild.textContent || "").replaceAll(
          "```",
          "\\`\\`\\`"
        );

        return `\n\n${options.fence}${language}\n${code}\n${options.fence}\n\n`;
      },
    },
    mentions: {
      filter: node =>
        node.nodeName === "SPAN" && !!node.dataset.mentionAtomName,
      replacement: (content, node) => {
        /* istanbul ignore next */
        if (!(node instanceof HTMLElement) || !node.dataset.mentionAtomId) {
          return content;
        }

        const { mentionAtomId } = node.dataset;

        return `[${content}](${GLUE_URI_SCHEME + mentionAtomId})`;
      },
    },
    paragraphsInsideListItems: {
      filter: node =>
        (node.nodeName === "P" || node.nodeName === "BR") &&
        hasAncestor(node, "LI"),
      replacement: (content, node) =>
        node.nodeName === "BR" ? "<br>" : `${content}\n\n`,
    },
    strikethrough: {
      filter: ["del", "s", "strike" as "del"],
      replacement: content => `~${content}~`,
    },
  };
}

function hasAncestor(child: Node, selector: string): boolean {
  let node = child.parentElement;
  while (node !== null) {
    if (node?.nodeName === selector) {
      return true;
    }
    node = node.parentElement;
  }
  return false;
}

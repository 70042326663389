import mime from "mime/lite";

import {
  ExternalObject,
  FileAttachmentType,
  FileOrImageUpload,
  FileType,
  GlueFile,
  StreamFileAttachment,
  StreamGlueAttachment,
  StreamImageAttachment,
  StreamLinkPreviewAttachment,
  StreamThreadAttachment,
  ThreadPreview,
} from "@utility-types";
import { ExternalObjectPreview } from "@utility-types/linkPreview";
import generateRandomId from "utils/generateRandomId";

const supportedFileTypes = Object.values<string>(FileType);

export const backwardsCompatibleMimeType = (type: string) => {
  if (isSupportedAttachmentType(type)) return `${type}/`;
  return type;
};

export const getAttachmentTypeFromMime = (mime: string): FileAttachmentType => {
  if (mime.includes("image/")) return FileType.Image;
  if (mime.includes("video/")) return FileType.Video;
  if (mime.includes("audio/")) return FileType.Audio;
  return FileType.File;
};

const isSupportedAttachmentType = (type: string): boolean =>
  supportedFileTypes.includes(type);

export const isFileAttachment = (
  attachment: StreamGlueAttachment
): attachment is StreamFileAttachment | StreamImageAttachment => {
  if (attachment.__typename === "ExternalObject") return false;
  if (attachment.__typename === "File") return true;
  return supportedFileTypes.includes(attachment.type || "");
};

export const fileUploadToStreamAttachment: (
  file: FileOrImageUpload
) => StreamGlueAttachment = file => {
  const {
    __typename = "File",
    contentLength,
    contentType,
    id: file_id,
    name,
    url,
  } = file;

  const attachment = {
    ...file,
    __typename,
    file_id,
    file_size: Number.parseInt(contentLength, 10),
    mime_type: backwardsCompatibleMimeType(contentType),
    uploadInfo: undefined,
  };

  const type = getAttachmentTypeFromMime(contentType);
  if (type === "image") {
    return {
      ...attachment,
      fallback: name,
      image_blurhash: file.metadata?.blurHash || undefined,
      image_height: file.metadata?.height || undefined,
      image_url: url,
      image_width: file.metadata?.width || undefined,
      type,
    };
  }
  return {
    ...attachment,
    asset_url: file.url,
    previewable: file.previewable || false,
    title: name,
    type,
  };
};

export const linkPreviewToStreamAttachment: (
  externalObject: ExternalObjectPreview
) => StreamGlueAttachment = externalObject => {
  const {
    description,
    image,
    previewId: _,
    state: __,
    title,
    url,
    ...rest
  } = externalObject;
  return {
    ...rest,
    description,
    image,
    image_url: image?.[0]?.url || undefined,
    text: description || undefined,
    title: title || url,
    title_link: url,
    url,
  };
};

export const threadToStreamAttachment: (
  thread: ThreadPreview
) => StreamThreadAttachment = thread => ({
  __typename: "ThreadPreview",
  id: thread.id,
  subject: thread.subject,
  firstMessage: undefined,
});

export const streamAttachmentToFileUpload: (
  attachment: StreamFileAttachment | StreamImageAttachment
) => FileOrImageUpload = attachment => {
  const {
    __typename = "File",
    file_id: id,
    file_size: size,
    type,
  } = attachment;
  const uploadInfo = {
    id: id || generateRandomId(),
    queued: false,
    state: "finished" as const,
  };
  const fileUpload = {
    __typename,
    contentLength: size.toString(),
    contentType: backwardsCompatibleMimeType(type),
    fileType: type,
    id,
    previewable: false,
    uploadInfo,
  };

  if (attachment.type === FileType.Image) {
    const {
      fallback: name,
      image_blurhash: blurHash = null,
      image_height: height = null,
      image_url: url,
      image_width: width = null,
    } = attachment;

    return {
      ...fileUpload,
      metadata: {
        __typename: "FileMetadata" as const,
        blurHash,
        height,
        width,
      },
      name,
      url,
    };
  }

  const {
    asset_url: url,
    mime_type: contentType,
    previewable,
    title: name,
  } = attachment;

  return {
    ...fileUpload,
    contentType,
    metadata: null,
    name,
    previewable: previewable || false,
    url,
  };
};

export const streamAttachmentToLinkPreviews: (
  attachment: StreamLinkPreviewAttachment
) => ExternalObjectPreview = attachment => ({
  ...attachment,
  previewId: attachment.id,
  state: "finished",
});

export const externalObjectToLinkPreview: (
  externalObject: ExternalObject
) => ExternalObjectPreview = externalObject => ({
  ...externalObject,
  previewId: externalObject.id,
  state: "finished",
});

export const streamAttachmentToThreadPreview: (
  attachment: StreamThreadAttachment
) => ThreadPreview = attachment => ({
  ...attachment,
  name: attachment.subject,
  subject: attachment.subject,
  admin: null,
  recipients: {
    __typename: "RecipientConnection",
    edges: [],
  },
});

export const fileToFileUpload: (file: File) => FileOrImageUpload = file => {
  const { lastModified, name, size, type, webkitRelativePath } = file;
  const id = webkitRelativePath + name + lastModified + size;
  const contentType = type === "" ? mime.getType(name) || type : type;
  const fileType = getAttachmentTypeFromMime(contentType);

  return {
    __typename: "File",
    contentLength: size.toString(),
    contentType,
    fileType,
    id,
    metadata: null,
    name,
    previewable: false,
    uploadInfo: { file, id, queued: true, state: "uploading" },
    url: "",
  };
};

export const glueFileToFileUpload: (f: GlueFile) => FileOrImageUpload = f => {
  return {
    ...f,
    __typename: "File",
    uploadInfo: { id: f.id, queued: false, state: "finished" },
  };
};

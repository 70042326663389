import React, { useLayoutEffect, useMemo } from "react";

import { useTransition } from "@react-spring/web";

import { defaultSpring } from "components/Animated/utils";
import { LayerProvider, LayerStackPriority } from "providers/LayerProvider";

import { Portal } from "components/Portal";

import useModalStore from "../../store/useModalStore";

import { Backdrop } from "./Backdrop";
import { bodyOverflowEffect } from "./bodyOverflowEffect";

export const modalOpacityTransitions = {
  config: defaultSpring,
  enter: {
    opacity: 1,
  },
  from: {
    opacity: 0,
  },
  leave: {
    opacity: 0,
  },
};

const isTestEnv = process.env.NODE_ENV === "test";

export const ModalRenderer = () => {
  const {
    backdropId,
    enqueuedToClose,
    immediate,
    modals,
    removeModal,
    setBackdropId,
  } = useModalStore(
    ({
      backdropId,
      enqueuedToClose,
      immediate,
      modals,
      removeModal,
      setBackdropId,
    }) => ({
      backdropId,
      enqueuedToClose,
      immediate,
      modals,
      removeModal,
      setBackdropId,
    })
  );

  const modalsExist = useMemo(() => modals.length > 0, [modals]);
  useLayoutEffect(() => bodyOverflowEffect(modalsExist), [modalsExist]);

  const transitions = useTransition(!!backdropId && modalsExist, {
    immediate: isTestEnv || immediate,
    ...modalOpacityTransitions,
  });

  return (
    <LayerProvider
      id={LayerStackPriority[LayerStackPriority.Modal]}
      isActive={modals.length > 0}
      zIndex={LayerStackPriority.Modal}
    >
      {transitions(
        (styles, open) =>
          open && (
            <Backdrop
              styles={{
                zIndex: LayerStackPriority.Modal - 1,
                ...styles,
              }}
            />
          )
      )}

      {modals.map(({ id, modal, state }) => {
        const isEnqueuedToClose = !!enqueuedToClose.find(
          enqueuedId => enqueuedId === id
        );

        const clonedModal = React.cloneElement(modal, {
          enqueuedToClose: isEnqueuedToClose,
          modalId: id,
          removeModal,
          setBackdropId,
          state,
        });

        return <Portal key={id}>{clonedModal}</Portal>;
      })}
    </LayerProvider>
  );
};

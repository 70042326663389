import { ComponentProps } from "react";

import { Skeleton } from "components/Skeleton";
import Avatar from "components/design-system/Avatar/Avatar";
import Button from "components/design-system/Button/Button";
import { Tooltip } from "components/design-system/FloatingUi";
import Hr from "components/design-system/Hr";
import { Icon } from "components/design-system/icons";
import FileIcon from "components/helper/FileIcon";

type Props = {
  avatarURL?: string;
  name?: string;
  description?: string;
  icon?: ComponentProps<typeof Icon>["icon"];
  mimeType?: string;
  leftIndent?: `${number}px`;
  onClick?: () => void;
  showSkeleton?: boolean;
  actionType?: "external" | "download" | "preview";
  tooltip?: string;
};

const SharedListItem = ({
  avatarURL,
  name,
  description,
  icon,
  mimeType,
  leftIndent = "0px",
  onClick,
  showSkeleton,
  actionType,
  tooltip,
}: Props) => (
  <div
    className="[&:last-child>div:last-child]:hidden hover:bg-background-body-hover cursor-pointer group/shared-item"
    style={{ paddingLeft: leftIndent }}
    onClick={onClick}
  >
    <div className="h-56 flex items-center">
      <div className="flex w-24 h-24 justify-center items-center shrink-0">
        {showSkeleton ? (
          <Skeleton width="24px" height="24px" />
        ) : (
          <>
            {avatarURL && (
              <Avatar
                avatarURL={avatarURL}
                background="transparent"
                size="tiny"
              />
            )}
            {mimeType && <FileIcon mimeType={mimeType} />}
            {icon && (
              <Icon className="text-icon-secondary" icon={icon} size={16} />
            )}
          </>
        )}
      </div>
      <div className="flex flex-col ml-8 overflow-hidden">
        {showSkeleton ? (
          <>
            <Skeleton width="240px" height="22px" />
            <Skeleton width="140px" height="18px" />
          </>
        ) : (
          <>
            <Tooltip
              disabled={!tooltip}
              content={
                <div className="m-6 text-footnote whitespace-normal break-words">
                  {tooltip}
                </div>
              }
              tooltipStyle="inverted"
            >
              <span className="text-body-bold truncate">{name}</span>
            </Tooltip>
            <span className="text-footnote text-text-subtle truncate">
              {description}
            </span>
          </>
        )}
      </div>
      <div className="grow" />
      {actionType && (
        <div className="mr-16 invisible group-hover/shared-item:visible">
          <Button
            buttonStyle="icon-secondary"
            icon={
              actionType === "external"
                ? "ExternalLink"
                : actionType === "download"
                  ? "Download"
                  : "Eye"
            }
            iconSize={20}
            tooltip={
              actionType === "external"
                ? "Open in new window"
                : actionType === "download"
                  ? "Download"
                  : "Preview"
            }
            tooltipStyle="inverted"
          />
        </div>
      )}
    </div>
    <div className="-mr-100">
      <Hr colorClassName="border-border-subtle" />
    </div>
  </div>
);

export default SharedListItem;

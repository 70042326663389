import { Footer, Header, Main } from "components/ModalKit";
import { ModalProps } from "components/ModalKit/Modal";
import { StandardModal } from "components/Modals";
import { Button } from "components/design-system/Button";
import { Form } from "components/design-system/Forms";
import DatePicker from "components/design-system/Forms/DatePicker";
import TimePicker from "components/design-system/Forms/TimePicker";
import { format, isToday } from "date-fns";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import useModalStore from "store/useModalStore";

type FormValues = {
  date: Date;
  time: string;
};

const FormContent = () => {
  const { watch, resetField } = useFormContext<FormValues>();
  const { date, time: _time } = watch();

  useEffect(() => {
    if (!isToday(date)) return;
    resetField("time");
  }, [date, resetField]);

  return (
    <>
      <div className="w-3/5">
        <DatePicker
          name="date"
          placeholder="Select a date"
          calendarProps={{ disabled: { before: new Date() } }}
        />
      </div>
      <div className="w-2/5">
        <TimePicker name="time" fromNow={isToday(date)} />
      </div>
    </>
  );
};

const ThreadReminderCustomModal = ({ ...props }: ModalProps) => {
  const { closeModal } = useModalStore(({ closeModal }) => ({ closeModal }));
  return (
    <StandardModal {...props}>
      <Header variant="bordered">
        Remind me
        <div className="flex grow" />
        <span className="bg-background-secondary rounded-half text-text-subtle text-footnote-bold px-12 py-3 mr-12">
          {format(new Date(), "z")}
        </span>
      </Header>
      <Main className="py-24 pl-32 pr-20">
        <Form<FormValues>
          className="flex gap-8"
          useFormProps={{ defaultValues: { date: new Date() } }}
        >
          <FormContent />
        </Form>
      </Main>
      <Footer>
        <Button buttonStyle="subtle" onClick={() => closeModal(props.modalId)}>
          Cancel
        </Button>
        <Button>Set reminder</Button>
      </Footer>
    </StandardModal>
  );
};

export default ThreadReminderCustomModal;

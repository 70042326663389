import { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";

import { Footer, Main } from "components/ModalKit/Parts";
import { Button } from "components/design-system/Button";
import Hr from "components/design-system/Hr";
import { routeToThread } from "components/routing/utils";
import { useStartSlackImportMutation } from "generated/graphql";
import type { Selections } from "./Reducer";
import type { StepsProps } from "./types";

type Props = StepsProps &
  Selections & { workspaceID: string; backButton: JSX.Element | null };

export const ReviewMain = ({
  groups,
  threads,
  skipChannels,
  importComplete,
}: {
  groups?: string[];
  threads?: string[];
  skipChannels?: string[];
  importComplete?: boolean;
}) => {
  const [openSection, setOpenSection] = useState<string | undefined>();
  const Section = ({
    emoji,
    name,
    title,
    items,
  }: { emoji?: string; name: string; title: string; items: string[] }) => {
    return (
      <div
        className="flex flex-col justify-center w-full px-16 border-1 border-border-container rounded-lg cursor-pointer"
        onClick={() => setOpenSection(v => (v === name ? undefined : name))}
      >
        <div className="flex justify-between items-center w-full h-56">
          <span className="text-subhead-bold">{`${emoji ? `${emoji}  ` : ""}${title}`}</span>
          <Button
            buttonType="icon"
            buttonStyle="icon-secondary"
            icon={openSection === name ? "ChevronDown" : "ChevronRight"}
          />
        </div>
        {openSection === name && (
          <>
            <Hr className="-mr-16" />
            {items.map((item, index, arr) => (
              <Fragment key={`review-${item}`}>
                <div
                  key={`review-${item}`}
                  className="flex items-center h-56 text-subhead-bold"
                >
                  #{item}
                </div>
                {index < arr.length - 1 && <Hr className="-mr-16" />}
              </Fragment>
            ))}
          </>
        )}
      </div>
    );
  };
  return (
    <Main className="flex flex-col px-32 py-16">
      <span className="text-body mb-10">
        {importComplete
          ? "Great news! Your import is complete."
          : "Carefully review the information below. To make adjustments, go back and change your selections before you continue. You'll be able to use Glue while this import processes."}
      </span>
      <div className="flex flex-col gap-16">
        {!!groups?.length && (
          <Section
            emoji={importComplete ? "✅" : undefined}
            name="groups"
            title={`${groups.length} channels ${!importComplete ? "will be" : ""} imported as groups`}
            items={groups}
          />
        )}
        {!!threads?.length && (
          <Section
            emoji={importComplete ? "✅" : undefined}
            name="threads"
            title={`${threads.length} channels ${!importComplete ? "will be" : ""} imported as threads`}
            items={threads}
          />
        )}
        {!!skipChannels?.length && (
          <Section
            emoji={importComplete ? "🚫" : undefined}
            name="skipped"
            title={`${skipChannels.length} channels ${!importComplete ? "will be" : ""} skipped`}
            items={skipChannels}
          />
        )}
      </div>
    </Main>
  );
};

const Review = ({
  backButton,
  cancelButton,
  includeChannels,
  skipChannels,
  groups,
  onClickNext,
  workspaceID,
}: Props) => {
  const history = useHistory();
  const [startSlackImport] = useStartSlackImportMutation();

  const threads = includeChannels?.filter(
    channel => !groups?.includes(channel)
  );

  const handleStartImport = () => {
    startSlackImport({ variables: { workspaceID } }).then(({ data }) => {
      const notificationsThreadID =
        data?.startSlackImport.notificationsThreadID;
      if (!notificationsThreadID) return;
      onClickNext();
      history.replace(
        routeToThread({
          threadID: notificationsThreadID,
          superTab: "inbox",
          to: "primary",
        })
      );
    });
  };

  return (
    <>
      <ReviewMain
        groups={groups}
        skipChannels={skipChannels}
        threads={threads}
      />
      <Footer>
        {backButton}
        <div className="flex grow" />
        {cancelButton}
        <Button onClick={handleStartImport}>Start import</Button>
      </Footer>
    </>
  );
};

export default Review;

import { MarkPasteRule } from "@remirror/pm/paste-rules";
import { StrikeExtension } from "remirror/extensions";

class GlueStrikeExtension extends StrikeExtension {
  createPasteRules(): MarkPasteRule[] {
    return []; // disable converting ~...~ to strike when pasting
  }
}

export default GlueStrikeExtension;

import { useEffect, useLayoutEffect, useRef } from "react";

import ResizeObserver from "resize-observer-polyfill";

const initialState = {
  blockSize: 0,
  inlineSize: 0,
};

export const useResizeObserver = (
  element: HTMLElement | null,
  callback: (rect: ResizeObserverSize) => void
) => {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useLayoutEffect(() => {
    if (!element) return;

    callbackRef.current({
      blockSize: element.clientWidth,
      inlineSize: element.offsetWidth,
    });
  }, [element, element?.offsetWidth]);

  useEffect(() => {
    if (!element) return;

    const observer = new ResizeObserver(entries => {
      // avoid "loop limit exceeded" warning
      window.requestAnimationFrame(() =>
        callbackRef.current(entries[0]?.borderBoxSize[0] || initialState)
      );
    });

    observer.observe(element);

    return () => observer.disconnect();
  }, [element]);
};

export default useResizeObserver;

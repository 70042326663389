import { StoreObject } from "@apollo/client";
import escapeStringRegex from "escape-string-regexp";

import { Node } from "generated/graphql";

const splitWords = (match: string) =>
  match.toLowerCase().split(/\s+/).filter(Boolean);

export const sortedMatches = <
  T extends StoreObject & { cursor?: string; node: Node; scoreBoost?: number },
>(
  objects: T[],
  match: string,
  objName: (obj: T) => string | undefined
) => {
  const terms = splitWords(match);
  const termsPos = Object.fromEntries(terms.map((t, i) => [t, i]));
  const regex = new RegExp(
    `(?:^|\\s+)(?<term>${terms.map(escapeStringRegex).join("|")})(?<rest>\\w*)`,
    "gi"
  );

  const matchScore = (
    words: string[],
    matches: RegExpMatchArray[],
    scoreBoost = 0
  ): number =>
    matches
      .map(m => ({
        index: m.index,
        pos: termsPos[m.groups?.term || ""] || 0,
        rest: m.groups?.rest || "",
        term: m.groups?.term || "",
      }))
      .reduce(
        (s, m, i, matches) =>
          s +
          // prefer better (or exact) matches
          (m.term.length * 2) / (m.term.length + m.rest.length) +
          // slightly prefer matches in the same order
          (matches.slice(0, i + 1).some(o => o.pos > m.pos) ? 0 : 0.5) -
          // Very small variation for early matches
          (m.index || 0) * 0.05 +
          scoreBoost,
        0
      ) -
    // slightly prefer higher ratio of matches
    (Math.max(terms.length, words.length) - matches.length) * 0.25;

  return objects
    .map<[T, string[], RegExpMatchArray[], number]>(obj => {
      const name = (objName?.(obj) ?? "").toLowerCase();
      let matches = Array.from(name.matchAll(regex));
      if (matches.length === 0) {
        // If no matches, try without spaces
        matches = Array.from(name.replaceAll(/\s/g, "").matchAll(regex));
      }
      return [obj, splitWords(name), matches, obj.scoreBoost ?? 0];
    })
    .filter(m => m[2].length > 0)
    .sort((m1, m2) => {
      const cursorBoost = (m2[0].cursor || 0) > (m1[0].cursor || 0) ? 0.5 : 0;
      return (
        matchScore(m2[1], m2[2], m2[3] + cursorBoost) -
        matchScore(m1[1], m1[2], m1[3])
      );
    })
    .map(m => m[0]);
};

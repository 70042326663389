import { HTMLAttributes } from "react";

import tw from "utils/tw";

const ViewScrollContainer = ({
  accessories,
  children,
  className,
  id,
  padding = "pb-72",
  scrollRef,
  ...props
}: WithChildren<
  HTMLAttributes<HTMLDivElement> & {
    accessories?: JSX.Element;
    padding?: `p${string}`;
    scrollRef?: (el: HTMLDivElement | null) => void;
  }
>) => {
  return (
    <div className="relative flex flex-col grow h-full min-h-0 w-full" id={id}>
      <div
        ref={scrollRef}
        className={tw("relative overflow-y-auto", padding, className)}
        {...props}
      >
        {children}
      </div>
      {accessories}
    </div>
  );
};

export default ViewScrollContainer;

import MasonrySessionManager from "./MasonrySessionManager";
import useMasonrySessionStore from "./useMasonrySessionStore";

const MasonrySessionRenderer = () => {
  const { sessions } = useMasonrySessionStore(({ sessions }) => ({ sessions }));

  return (
    <>
      {sessions.map(session => (
        <MasonrySessionManager
          key={session.id}
          origination={session.origination}
          input={session.input}
        />
      ))}
    </>
  );
};

export default MasonrySessionRenderer;

import { ApolloClient, QueryOptions } from "@apollo/client";
import { intersection } from "lodash-es";

// Hack to avoid "unknown query" warning in tests
// that don't have these queries active
export const filterActiveQueries = (
  apolloClient: ApolloClient<object>,
  queries: QueryOptions["query"][]
): QueryOptions["query"][] =>
  intersection(
    Array.from(apolloClient.getObservableQueries().values()).map(
      q => q.options.query
    ),
    queries
  );
export default filterActiveQueries;

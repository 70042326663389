import ThreadComposeModal from "components/threads/ThreadComposeModal";
import useAuthData from "hooks/useAuthData";
import useModalStore from "store/useModalStore";

import useGroupRecipients from "hooks/useGroupRecipients";

import tw from "utils/tw";
import Avatar from "../../design-system/Avatar/Avatar";

type Props = {
  className?: string;
};

const NewThreadFeedWidget = ({ className }: Props) => {
  const { authData } = useAuthData();
  const groupRecipients = useGroupRecipients();

  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const handleOnClick = () => {
    openModal(
      <ThreadComposeModal initialDraft={{ recipients: groupRecipients }} />
    );
  };

  return (
    <button
      className={tw(
        "bg-background-body cursor-pointer flex items-center py-16 px-20 select-none shadow-level1 gap-12 w-full md:rounded-lg",
        className
      )}
      onClick={handleOnClick}
    >
      <Avatar rounded="rounded-md" size="large" {...authData?.me} />
      <div className="hover:bg-background-ghost flex grow items-center h-36 px-8 rounded-md border-1 border-border-container text-text-subtle w-3/4">
        Start a new thread
      </div>
    </button>
  );
};

export default NewThreadFeedWidget;

import type { Emoji } from "@emoji-mart/data";

import { Button } from "components/design-system/Button";
import { EmojiSheet } from "components/design-system/EmojiSheet";
import tw from "utils/tw";

import { EmojiPlus } from "components/Icons";

const AddReactionButton = ({
  className,
  handleOnEmojiSelect,
}: {
  className?: string;
  handleOnEmojiSelect: (emoji: Emoji) => void | undefined;
}) => (
  <EmojiSheet onEmojiSelect={handleOnEmojiSelect} allowCustomEmojis>
    <span className="inline-block px-2">
      <Button
        buttonStyle="subtle"
        buttonType="none"
        className={tw(
          "w-25 hover:border-interactive-subtle mr-4 border-background-subtle border-1 select-none flex items-center cursor-pointer py-3 px-5 h-16 rounded-[16px] box-content justify-center",
          className
        )}
        data-testid="Add Reaction"
        icon={EmojiPlus}
        iconClassName="-ml-1"
        iconSize={16}
      />
    </span>
  </EmojiSheet>
);

export default AddReactionButton;

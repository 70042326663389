import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { Icon } from "components/design-system/icons";

import { GroupOrPreview, Workspace, nodeAs } from "@utility-types";
import { Button } from "components/design-system/Button";
import { useSnackbar } from "providers/SnackbarProvider";
import { formatGroupName } from "utils/group/formatGroupName";

import { Skeleton } from "components/Skeleton";
import { routePath } from "components/routing/utils";
import MembersAvatars from "components/workspace-group/MembersAvatars";
import useGroupMembership from "components/workspace-group/hooks/useGroupMembership";

type Props = {
  group: GroupOrPreview | Workspace;
};

const WorkspaceGroupItem = ({ group }: Props): JSX.Element => {
  const notInGroup = group.__typename?.endsWith("Preview");
  const description = nodeAs(group, ["Group", "GroupPreview"])?.description;
  const { openSnackbar } = useSnackbar();
  const history = useHistory();
  const {
    actionPending,
    cancelRequestToJoin,
    hasError,
    joinGroup,
    joinRequested,
    requestToJoin,
    loading: requestStatusLoading,
  } = useGroupMembership(group.id);

  const navigateToGroupProfile = useCallback(() => {
    history.push(routePath({ recipientID: group.id }));
  }, [group, history]);

  const handleGroupAction = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();

      // Don't do anything if already a member
      if (!notInGroup) return;

      // Cancel request to join
      if (joinRequested) {
        cancelRequestToJoin();
        return;
      }

      if (joinGroup) {
        joinGroup();
      } else {
        requestToJoin();
      }
    },
    [notInGroup, joinRequested, joinGroup, cancelRequestToJoin, requestToJoin]
  );

  if (hasError) {
    openSnackbar("error", "Something went wrong.");
  }

  const actions = (
    <div className="flex justify-end items-center mt-10 w-100 select-none md:mt-0">
      {requestStatusLoading ? (
        <Skeleton
          height="18px"
          width={`${Math.floor(Math.random() * (95 - 80 + 1) + 80)}px`}
        />
      ) : actionPending ? null : notInGroup ? (
        <Button
          className="text-nowrap"
          buttonStyle={joinRequested ? "simpleSecondary" : "simplePrimary"}
          buttonType="text"
          onClick={handleGroupAction}
          type="button"
        >
          {joinRequested && "Cancel Request"}
          {notInGroup &&
            (joinGroup ? "Join Group" : !joinRequested && "Request to Join")}
        </Button>
      ) : (
        <div className="text-interactive-subtle-disabled hidden font-bold md:flex md:items-center">
          <Icon icon="Check" size={15} strokeWidth={1.75} />
          &nbsp; Member
        </div>
      )}
    </div>
  );

  return (
    <li
      className="flex items-center first:mt-0 min-w-0 cursor-pointer"
      onClick={navigateToGroupProfile}
    >
      <div className="flex flex-col flex-1 pr-16">
        <p className="m-0 text-lg font-bold">
          {formatGroupName(group).nameWithEmoji}
        </p>
        <span className="mt-8 text-sm text-text-subtle">
          {group.__typename?.startsWith("Workspace")
            ? `All members of the ${group.name} workspace are in this group.`
            : description ?? ""}
        </span>
      </div>
      <div className="flex flex-col items-end md:hidden">
        <div className="flex items-center text-interactive-subtle">
          <Icon icon="Users" size={20} />
          <p className="m-0 ml-10 font-semibold">{group.members?.totalCount}</p>
        </div>
        {actions}
      </div>
      <MembersAvatars
        className={"hidden md:flex mr-24 self-start"}
        members={group.members}
      />
      <div className="hidden self-start h-30 md:flex">{actions}</div>
    </li>
  );
};

export default WorkspaceGroupItem;

import { animated, useTransition } from "@react-spring/web";

import { defaultSpring } from "./utils";

type Props = {
  className?: string;
  enter?: { opacity?: string } & ({ x: string } | { y: string });
  from?: { opacity?: string } & ({ x: string } | { y: string });
  leave?: { opacity?: string } & ({ x: string } | { y: string });
  show: boolean;
};
const SlideInOut = ({
  children,
  className,
  enter = { y: "0%" },
  from = { y: "100%" },
  leave = { y: "100%" },
  show,
}: WithChildren<Props>) => {
  const transitions = useTransition(show, {
    config: { ...defaultSpring },
    enter,
    from,
    leave,
    reverse: show,
  });

  return transitions(
    (styles, item) =>
      item && (
        <animated.div className={className} style={styles}>
          {children}
        </animated.div>
      )
  );
};

export default SlideInOut;

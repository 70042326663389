import useFloatingElement from "components/design-system/FloatingUi/useFloatingElement";
import { Form } from "components/design-system/Forms";

import QuickSearchInput from "./quick-search/QuickSearchInput";

const QuickSearch = () => {
  const { anchorProps, floatingProps, isOpen, setIsOpen } = useFloatingElement({
    placement: "bottom",
    strategy: "fixed",
  });

  return (
    <div className="w-[685px] min-w-[200px] ml-24" {...anchorProps}>
      <Form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <QuickSearchInput
          floatingProps={floatingProps}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </Form>
    </div>
  );
};

export default QuickSearch;

import { ImgHTMLAttributes, forwardRef } from "react";

import { Blurhash } from "react-blurhash";

import glueImageURL from "utils/glueImageURL";
import tw from "utils/tw";

type Props = ImgHTMLAttributes<HTMLImageElement> & {
  blurHash?: string | null;
  figureClassName?: string;
  resize?: Parameters<typeof glueImageURL>[1];
  src?: string;
};

const Image = forwardRef<HTMLImageElement, WithChildren<Props>>(
  (
    {
      alt,
      blurHash,
      children,
      className,
      figureClassName,
      onClick,
      resize,
      src,
      ...props
    },
    forwardedRef
  ) => {
    return (
      <figure
        className={tw("group/figure relative", figureClassName)}
        onClick={onClick}
      >
        {
          // API sent invalidate data in the past,
          // so validate proper length to avoid crash
          blurHash?.length === 54 ? (
            <Blurhash
              className={tw(className, "!absolute inset-0")}
              hash={blurHash}
              height="100%"
              punch={1}
              resolutionX={32}
              resolutionY={32}
              width="100%"
            />
          ) : (
            <div
              className={tw(
                className,
                "!absolute inset-0 bg-background-secondary"
              )}
            />
          )
        }
        {src ? (
          <picture
            className="relative w-full h-full"
            data-testid="gallery-image"
          >
            <source
              srcSet={`${glueImageURL(src, resize)} 1x, ${glueImageURL(
                src,
                resize,
                2
              )} 2x`}
            />
            <img
              ref={forwardedRef}
              alt={alt || ""}
              className={tw(
                "opacity-0 transition-opacity duration-300",
                className
              )}
              loading="lazy"
              onLoad={({ currentTarget }) =>
                currentTarget.classList.remove("opacity-0")
              }
              src={glueImageURL(src, resize)}
              {...props}
            />
          </picture>
        ) : null}
        {children}
      </figure>
    );
  }
);

export default Image;

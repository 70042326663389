import { useHistory } from "react-router-dom";

import { UserEdge } from "@utility-types";
import { Button } from "components/design-system/Button";
import { Dropdown } from "components/design-system/FloatingUi";
import {
  ActionSheetItemGroups,
  DropdownActions,
} from "components/design-system/FloatingUi/DropdownActions";
import { UserEdgeStatus } from "generated/graphql";
import useUserProfileAction from "hooks/user/useUserProfileActions";
import tw from "utils/tw";

import { routeToUser } from "components/routing/utils";

type Props = {
  userEdge?: UserEdge;
};

const UserProfileActionMenu = ({ userEdge }: Props): JSX.Element => {
  const { status, toggleUserStatus } = useUserProfileAction(userEdge);

  const history = useHistory();

  const isHidden = status === UserEdgeStatus.Hidden;
  const isBlocked = status === UserEdgeStatus.Blocked;

  const actions: ActionSheetItemGroups[] = [
    {
      items: [
        ...(userEdge
          ? [
              {
                icon: "User" as const,
                onClick: () => {
                  history.push(
                    routeToUser({ to: "secondary", userID: userEdge.node.id })
                  );
                },
                text: "User Profile",
              },
            ]
          : []),
      ],
      name: "UserProfile",
    },
  ];

  actions.push({
    items: [
      {
        className: tw({ hidden: isBlocked }),
        icon: isHidden ? "Eye" : "EyeOff",
        onClick: () => {
          !isBlocked && toggleUserStatus(UserEdgeStatus.Hidden);
        },
        text: `${isHidden ? "Unhide user" : "Hide user"}`,
      },
      {
        className: !isBlocked ? "text-interactive-alert" : "",
        icon: "Slash",
        iconClassName: !isBlocked ? "text-interactive-alert" : "",
        onClick: () => {
          toggleUserStatus(UserEdgeStatus.Blocked);
        },
        text: `${isBlocked ? "Unblock user" : "Block user"}`,
      },
    ],
    name: "block",
  });

  return (
    <Dropdown content={<DropdownActions actions={actions} />}>
      <Button
        buttonStyle="subtle"
        className="h-28 w-28 justify-center rounded-md !border-border-container hover:bg-background-secondary"
        data-testid="user-profile-action-menu-button"
        icon="MenuVertical"
        iconSize={20}
      />
    </Dropdown>
  );
};

export default UserProfileActionMenu;

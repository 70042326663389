import { getApp, getApps, initializeApp } from "firebase/app";
import {
  Auth,
  EmailAuthProvider,
  GoogleAuthProvider,
  browserPopupRedirectResolver,
  indexedDBLocalPersistence,
  initializeAuth,
} from "firebase/auth";

import { isNative, isStandalone } from "utils/platform";
import env from "utils/processEnv";

interface AuthProviders {
  apple: string;
  email: string;
  google: string;
}

interface ProxyHandler {
  [key: string]: string | Auth | AuthProviders;
}

interface Target extends ProxyHandler {
  auth: Auth;
  providers: AuthProviders;
}

// biome-ignore lint: no-explicit-any
class gluePopupRedirectResolver extends (browserPopupRedirectResolver as any) {
  get _shouldInitProactively(): boolean {
    // For the PWA in the MS Store, we need to proactively init the iframe
    // to avoid the popup being blocked. This only affects the app running from the
    // MS Store. It does not affect Edge or even Edge standalone.
    if (
      isStandalone() &&
      navigator.userAgent.includes("Edg/") &&
      navigator.userAgent.includes("Windows")
    ) {
      return true;
    }

    return super._shouldInitProactively;
  }
}

let auth: Auth | undefined;

export default new Proxy(
  {
    get auth() {
      if (!auth) {
        auth = initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence,
          popupRedirectResolver: isNative()
            ? undefined // uses Capacitor native auth flow
            : gluePopupRedirectResolver,
        });
      }

      return auth;
    },
    providers: {
      get apple() {
        return "apple.com";
      },
      get email() {
        return EmailAuthProvider.PROVIDER_ID;
      },
      get google() {
        return GoogleAuthProvider.PROVIDER_ID;
      },
    },
  },
  {
    get: (target: Target, handler: string) => {
      const apps = getApps();
      if (apps && !apps.length) {
        const { firebaseApiKey: apiKey, firebaseAuthDomain: authDomain } = env;
        initializeApp({ apiKey, authDomain });
      }
      return target[handler];
    },
  }
);

import { useWorkspaceAppsQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import { useEffect, useState } from "react";

type AppMap = Map<
  string,
  { avatarURL: string; id: string; name: string; workspaceIDs: string[] }
>;
type WorkspaceMap = Map<string, { avatarURL: string | null; name: string }>;

const useWorkspaceApps = () => {
  const { authReady } = useAuthData();

  const { data: workspaceApps } = useWorkspaceAppsQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
  });

  const [apps, setApps] = useState<AppMap>(new Map());
  const [workspaces, setWorkspaces] = useState<WorkspaceMap>(new Map());

  useEffect(() => {
    const apps = new Map();
    const workspaces = new Map();

    workspaceApps?.workspaces?.edges.forEach(workspace => {
      workspaces.set(workspace?.node?.id, {
        avatarURL: workspace?.node?.avatarURL,
        name: workspace?.node?.name,
      });

      workspace?.node?.apps?.edges.forEach(app => {
        if (!app?.node.publishedAt) return; // Skip unpublished apps (e.g. webhooks)

        const appObject = apps.get(app?.node?.id);
        if (appObject) {
          apps.set(app?.node?.id, {
            ...appObject,
            workspaceIDs: [...appObject.workspaceIDs, workspace?.node?.id],
          });
        } else {
          apps.set(app?.node?.id, {
            avatarURL: app?.node?.avatarURL,
            id: app?.node?.id,
            name: app?.node?.name,
            workspaceIDs: [workspace?.node?.id],
          });
        }
      });
    });

    setApps(apps);
    setWorkspaces(workspaces);
  }, [workspaceApps]);

  return { apps, workspaces };
};

export default useWorkspaceApps;

import { useState } from "react";

import { artificialSummaryDelay } from "components/Summaries/useSummarizeThread";
import Tooltip from "components/design-system/FloatingUi/Tooltip";
import { LoadingSpinner } from "components/design-system/LoadingSpinner";
import Icon from "components/design-system/icons/Icon";
import { useThreadSummaryLazyQuery } from "generated/graphql";
import tw from "utils/tw";

import Summary from "../Summaries/Summary";

type Props = {
  lastReadMessageID: string;
  summarize?: boolean;
  threadID: string;
};

const UnreadMarker = ({
  lastReadMessageID,
  summarize = false,
  threadID,
}: Props) => {
  const [busy, setBusy] = useState(false);
  const [showSummary, setShowSummary] = useState(false);

  const [unreadSummaryPoints, { data }] = useThreadSummaryLazyQuery({
    fetchPolicy: "no-cache",
  });

  const handleClick = async () => {
    if (busy) {
      return;
    }

    const startedAt = Date.now();
    setBusy(true);

    try {
      await unreadSummaryPoints({
        variables: { afterMessageID: lastReadMessageID, level: 1, threadID },
      });
    } finally {
      const elapsed = Date.now() - startedAt;
      setTimeout(
        () => {
          setBusy(false);
          setShowSummary(true);
        },
        Math.max(0, artificialSummaryDelay() - elapsed)
      );
    }
  };

  const summaryPoints = data?.threadSummaryPoints.edges.map(e => e.node) ?? [];

  return (
    <>
      <Tooltip
        content={"Summarize new messages"}
        disabled={!summarize || busy}
        placement="bottom"
        tooltipStyle="inverted"
      >
        <div
          className={tw(
            "flex before:block after:block justify-center items-center before:w-full after:w-full before:h-2 h-30 after:h-2 select-none text-base font-semibold text-interactive-primary  after:bg-interactive-primary/25 before:bg-interactive-primary/25 whitespace-nowrap",
            {
              "cursor-pointer": summarize && !busy,
              "hover:text-interactive-primary-hover": summarize && !busy,
            }
          )}
          onClick={summarize ? handleClick : undefined}
        >
          {busy ? (
            <>
              <span className="pl-12">Summarizing...</span>
              <span className="pr-12 ml-4">
                <LoadingSpinner
                  className={tw(
                    "w-18 h-18 text-accent-primary animate-spin transition-opacity",
                    "opacity-100"
                  )}
                />
              </span>
            </>
          ) : (
            <>
              <span className="pl-12">
                {summarize ? "Summarize new" : "New"}
              </span>
              <span className="pr-12 ml-4">
                <Icon
                  icon={summarize ? "SparkleFilled" : "ArrowDownCircle"}
                  size={20}
                />
              </span>
            </>
          )}
        </div>
      </Tooltip>
      {showSummary ? (
        <div className="mb-8">
          <Summary
            onClose={() => setShowSummary(false)}
            points={summaryPoints}
            summarizing={busy}
          />
        </div>
      ) : null}
    </>
  );
};

export default UnreadMarker;

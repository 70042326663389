import { devtools } from "zustand/middleware";

import { MessageEditor } from "components/MessageEditor/types";

import { glueCreateZustand } from "./helper/glueCreate";

const STORE_NAME = "MessageEditorStore";

type Editor = {
  editor: MessageEditor;
  uid: string;
};

type EditorStore = {
  addEditor: (editor: Editor) => void;
  editors: Map<string, Editor>;
  removeEditor: (uid: string) => void;
};

const useMessageEditorStore = glueCreateZustand<EditorStore>({
  name: STORE_NAME,
})(
  devtools(
    set => ({
      addEditor: editor =>
        set(state => {
          state.editors.set(editor.uid, editor);

          return { editors: state.editors };
        }),
      editors: new Map(),
      removeEditor: uid =>
        set(state => {
          state.editors.delete(uid);

          return { editors: state.editors };
        }),
    }),
    {
      name: STORE_NAME,
      serialize: true,
    }
  )
);

export default useMessageEditorStore;

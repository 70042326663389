import React, { CSSProperties } from "react";

import { TransitionValues, animated } from "@react-spring/web";

import useTrapFocus from "hooks/useTrapFocus";
import tw from "utils/tw";

import { useModalDragging } from "./useModalDragging";

type Props = {
  alignment: {
    x: "text-center" | "text-left" | "text-right";
    y: "align-bottom" | "align-middle" | "align-top";
  };
  children: React.ReactNode;
  contentClassName?: string;
  disableOpacityTransition?: boolean;
  dragStyles: ReturnType<typeof useModalDragging>["dragStyles"];
  styles: TransitionValues<CSSProperties>;
};

// When dragging a modal at mobile screen sizes,
// serves as background to cover the gap that occurs due to the rubberband value.
const RubberbandGuard = ({ dragStyles }: Pick<Props, "dragStyles">) => (
  <animated.div
    className="fixed inset-x-0 bottom-0 bg-background-modal md:hidden"
    style={{ height: dragStyles.height }}
  />
);

export const Content = ({
  alignment,
  children,
  contentClassName,
  disableOpacityTransition,
  dragStyles,
  styles,
}: Props) => {
  const bindTrapFocus = useTrapFocus();

  return (
    <animated.div
      className={tw(
        "modal-content",
        "inline-block w-full",
        alignment.x,
        alignment.y,
        {
          "!opacity-100": disableOpacityTransition,
        }
      )}
      {...bindTrapFocus}
      data-testid="modal-content"
      style={styles}
    >
      <RubberbandGuard dragStyles={dragStyles} />
      <animated.div
        className={tw(
          "inline-block relative text-base text-left bg-background-modal touch-none md:shadow-modal",
          contentClassName
        )}
        data-testid="modal-card"
        style={{
          x: dragStyles.x ?? 0,
          y: dragStyles.y ?? 0,
        }}
      >
        {children}
      </animated.div>
    </animated.div>
  );
};

/* spellchecker: disable */

import useOnce from "hooks/useOnce";
import useNativeKeyboardStore from "store/useNativeKeyboardStore";
import useSafeAreaInsetsStore from "store/useSafeAreaInsetsStore";
import { isNativeIOS } from "utils/platform";

const getPropertyInt = (styles: CSSStyleDeclaration, name: string): number =>
  Number.parseInt(styles.getPropertyValue(name) || "0", 10);

export const useSafeAreaInsetsManager = (): void => {
  useOnce(() => {
    let styles = getComputedStyle(document.documentElement);

    const updateSafeAreaInsets = (
      kbHeight = useNativeKeyboardStore.getState().keyboardHeight
    ) => {
      const insets = {
        bottom: kbHeight > 0 ? kbHeight : getPropertyInt(styles, "--saib"),
        left: getPropertyInt(styles, "--sail"),
        right: getPropertyInt(styles, "--sair"),
        top: getPropertyInt(styles, "--sait"),
      };

      // Fix for https://bugs.webkit.org/show_bug.cgi?id=191872
      // Note: will cause infinite loop in landscape mode!
      if (isNativeIOS() && insets.top === 0) {
        window.requestAnimationFrame(updateSafeAreaInsets);
        return;
      }

      useSafeAreaInsetsStore.setState(insets);
    };

    // set initial values
    updateSafeAreaInsets();

    const unsubscribeKeyboard = useNativeKeyboardStore.subscribe(
      ({ keyboardHeight }) => keyboardHeight,
      (keyboardHeight: number) => updateSafeAreaInsets(keyboardHeight)
    );

    const orientationHandler = () => {
      styles = getComputedStyle(document.documentElement);
      updateSafeAreaInsets();
    };
    window.addEventListener("orientationchange", orientationHandler);

    return () => {
      unsubscribeKeyboard();
      window.removeEventListener("orientationchange", orientationHandler);
    };
  });
};

export default useSafeAreaInsetsManager;

import { MouseEvent } from "react";

import { Recipient } from "@utility-types";
import Tooltip from "components/design-system/FloatingUi/Tooltip";
import getRandomInt from "utils/getRandomInt";
import { formatGroupName } from "utils/group/formatGroupName";
import threadChatType from "utils/thread/threadChatType";
import tw from "utils/tw";

import { Skeleton } from "components/Skeleton";
import { Icon } from "components/design-system/icons";
import IdentityBadge from "components/design-system/ui/IdentityBadge/IdentityBadge";

type Props = {
  chatType: ReturnType<typeof threadChatType>;
  showExternalLabel: boolean;
  onClick: (e: MouseEvent) => void;
  recipient: Recipient | undefined;
};

const ChatThreadInfo = ({
  chatType,
  showExternalLabel,
  onClick,
  recipient,
}: Props) => {
  const clickable = !!recipient;

  return (
    <>
      <div className="flex flex-col">
        <Tooltip
          content={
            clickable
              ? chatType === "user"
                ? "Open profile"
                : "Open group"
              : undefined
          }
          placement="bottom"
          tooltipStyle="inverted"
        >
          <div
            className={tw("relative -ml-4 px-4 self-start", {
              "hover:bg-background-secondary rounded-md cursor-pointer":
                clickable,
            })}
            onClick={clickable ? onClick : undefined}
          >
            <h2 className="relative flex flex-row items-center m-0 min-h-[24px] text-headline-bold leading-5">
              {recipient ? (
                <>
                  {formatGroupName(recipient).name}
                  {clickable && (
                    <Icon
                      className="ml-2 shrink-0 text-icon-secondary"
                      icon="ChevronDown"
                      size={12}
                    />
                  )}
                </>
              ) : (
                <Skeleton height="24px" width={`${getRandomInt(200, 300)}px`} />
              )}
            </h2>
          </div>
        </Tooltip>
        <div className="flex items-center">
          <div className="text-footnote text-text-secondary mr-6">
            {chatType === "group" ? (
              "Group chat"
            ) : chatType === "user" ? (
              "Direct message"
            ) : (
              <Skeleton height="100%" width={`${getRandomInt(200, 300)}px`} />
            )}
          </div>
          {chatType === "user" && (
            <IdentityBadge
              recipient={recipient}
              showLabel={showExternalLabel}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ChatThreadInfo;

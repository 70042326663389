import { nodeAs } from "@utility-types";
import { NotificationFieldsFragment } from "generated/graphql";
import ActivityItemAddedGroup from "./ActivityItemAddedGroup";
import ActivityItemAddedThread from "./ActivityItemAddedThread";
import ActivityItemAddedTo from "./ActivityItemAddedTo";
import ActivityItemCreatedMessageLink from "./ActivityItemCreatedMessageLink";
import ActivityItemDefault from "./ActivityItemDefault";
import ActivityItemReactedToMessage from "./ActivityItemReactedToMessage";
import ActivityItemSentJoinApproval from "./ActivityItemSentJoinApproval";

export default function ActivityItem({
  notification,
  ...props
}: {
  notification: NotificationFieldsFragment;
  isRead: boolean;
  onClick: (e: React.MouseEvent<HTMLLIElement>) => void;
  selected: boolean;
}) {
  const activity = notification.activities[0];

  if (!activity) return null;
  const { actor, object, target, time: timestamp } = activity;

  switch (notification.verb) {
    case "addedToGroup": {
      // group type
      const joinable = nodeAs(target, ["GroupPreview"]);
      if (!joinable) return null;
      return (
        <ActivityItemAddedTo
          actor={actor}
          target={joinable}
          timestamp={timestamp ?? ""}
          {...props}
        />
      );
    }
    case "addedGroupWorkspace":
    case "addedGroup": {
      // group type
      const group = nodeAs(object, ["GroupPreview"]);
      const workspace = nodeAs(target, ["WorkspacePreview"]);
      if (!group || !workspace) return null;
      return (
        <ActivityItemAddedGroup
          actor={actor}
          object={group}
          target={workspace}
          timestamp={timestamp ?? ""}
          {...props}
        />
      );
    }
    case "addedThread": {
      // group type
      const thread = nodeAs(object, ["ThreadPreview"]);
      const group = nodeAs(target, ["GroupPreview", "WorkspacePreview"]);
      if (!thread || !group) return null;
      return (
        <ActivityItemAddedThread
          actor={actor}
          object={thread}
          target={group}
          timestamp={timestamp ?? ""}
          {...props}
        />
      );
    }
    case "createdMessageLink": {
      // mention type
      const link = nodeAs(object, ["Link"]);
      const thread = nodeAs(target, ["ThreadPreview"]);
      if (!link || !thread) return null;
      return (
        <ActivityItemCreatedMessageLink
          actor={actor}
          object={link}
          target={thread}
          timestamp={timestamp ?? ""}
          {...props}
        />
      );
    }
    case "reactedToMessage": {
      // reaction type
      const message = nodeAs(object, ["Message"]);
      const thread = nodeAs(target, ["ThreadPreview"]);
      if (!message || !thread) return null;
      return (
        <ActivityItemReactedToMessage
          actor={actor}
          actorCount={notification.actorCount}
          object={message}
          reactions={notification.activities.map(a => a.meta?.reaction || "")}
          target={thread}
          timestamp={timestamp ?? ""}
          {...props}
        />
      );
    }
    case "sentJoinInvitation": // deprecated
    case "sentJoinRequest": // deprecated
    case "sentJoinApproval": {
      // request type
      return object?.__typename === "JoinApproval" ? (
        <ActivityItemSentJoinApproval
          actor={object.requester}
          joining={object.joining}
          object={object}
          target={object.joinable}
          timestamp={timestamp ?? ""}
          {...props}
        />
      ) : null;
    }
    default:
      return (
        <ActivityItemDefault
          actor={actor}
          target={target}
          text={notification.text}
          timestamp={timestamp ?? ""}
          {...props}
        />
      );
  }
}

import { StreamImageAttachment } from "@utility-types";
import tw from "utils/tw";

import PhotoSwipeImageWrapper from "./PhotoSwipeImageWrapper";

type GetThumbnailDimensions = {
  imageWidth: number;
  ratio: number;
  targetHeight: number;
  targetWidth: number;
};

export const getThumbnailDimensions = ({
  imageWidth,
  ratio,
  targetHeight,
  targetWidth,
}: GetThumbnailDimensions) => {
  let height = Math.ceil(targetWidth / ratio);
  let width = targetWidth;

  /**
   ** - the calculated height is insufficient to fill the grid cell
   **   - and we have enough width to work with
   ** ... then we can lock the height, and calculate a larger width.
   */
  if (height < targetHeight && imageWidth > targetWidth) {
    if (targetHeight * ratio >= targetWidth) {
      height = targetHeight;
      width = Math.ceil(targetHeight * ratio);
    }
  }

  return {
    height,
    width,
  };
};

const GalleryImage = ({
  galleryWidth,
  image,
  widthDivisor = 1,
}: {
  galleryWidth: number;
  image: StreamImageAttachment;
  widthDivisor: number;
}) => {
  const {
    fallback,
    image_blurhash: imageBlurHash,
    image_height: imageHeight = 768,
    image_url: imageURL,
    image_width: imageWidth = 768,
  } = image;

  const maxThumbnailHeight = 140;
  const maxThumbnailWidth = galleryWidth / widthDivisor;
  const ratio = imageWidth / imageHeight;

  const targetHeight = Math.min(maxThumbnailHeight, imageHeight);
  const targetWidth = Math.min(maxThumbnailWidth, imageWidth);

  const { height: thumbnailHeight, width: thumbnailWidth } =
    getThumbnailDimensions({
      imageWidth,
      ratio,
      targetHeight,
      targetWidth,
    });

  const resize = {
    fit: "max" as const,
    h: thumbnailHeight,
    w: thumbnailWidth,
  };

  const flexImage =
    thumbnailHeight >= maxThumbnailHeight &&
    thumbnailWidth >= maxThumbnailWidth;

  return (
    <PhotoSwipeImageWrapper
      alt={fallback}
      blurHash={imageBlurHash}
      className={tw("object-contain w-full h-full", {
        "object-cover": flexImage,
      })}
      figureClassName={tw(
        "cursor-pointer",
        "overflow-hidden rounded border border-background-subtle",
        "mr-6 mb-6 w-[calc(50%_-_6px)] h-140 md:w-[calc(33%_-_6px)]",
        {
          grow: flexImage,
        }
      )}
      height={thumbnailHeight}
      image={image}
      resize={resize}
      src={imageURL}
      width={thumbnailWidth}
    />
  );
};

export default GalleryImage;

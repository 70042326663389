import { devtools, subscribeWithSelector } from "zustand/middleware";

import { glueCreateZustand } from "./helper/glueCreate";

const STORE_NAME = "SafeAreaInsetsStore";

type AppStore = {
  bottom: number;
  left: number;
  right: number;
  setState: (values: Partial<AppStore>) => void;
  top: number;
};

const useSafeAreaInsetsStore = glueCreateZustand<AppStore>({
  name: STORE_NAME,
})(
  devtools(
    subscribeWithSelector(set => ({
      bottom: 0,
      left: 0,
      right: 0,
      setState: values => set(state => ({ ...state, ...values })),
      top: 0,
    })),
    { name: STORE_NAME }
  )
);

export default useSafeAreaInsetsStore;

import { ReactElement } from "react";

export const ThreadIcon = (): ReactElement => (
  <svg height="10" width="14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.516 3c4.78 0 4.972 6.5 4.972 6.5-1.6-2.906-2.847-3.184-4.972-3.184v2.872L3.772 4.994 8.516.5V3zM.484 5l4.5-4.237v1.78L2.416 5l2.568 2.125v1.828L.484 5z"
      fillRule="evenodd"
    />
  </svg>
);

export const ErrorIcon = (): ReactElement => (
  <svg height="14" width="14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 0a7 7 0 1 0 0 14A7 7 0 0 0 7 0zm.875 10.938a.438.438 0 0 1-.438.437h-.875a.438.438 0 0 1-.437-.438v-.874c0-.242.196-.438.438-.438h.875c.241 0 .437.196.437.438v.874zm0-2.626a.438.438 0 0 1-.438.438h-.875a.438.438 0 0 1-.437-.438v-5.25c0-.241.196-.437.438-.437h.875c.241 0 .437.196.437.438v5.25z"
      fill="#EA152F"
      fillRule="evenodd"
    />
  </svg>
);

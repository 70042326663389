import { Sizes } from "@utility-types";

export const parameters: Record<
  Sizes,
  {
    avatar: {
      groupClassNames: string;
      iconSize: number;
    };
    avatarSpacing: `mx-${number}` | `mr-${number}` | `ml-${number}` | undefined;
    classNames: string;
    descriptionClassNames: string;
    emojiClassNames: string;
    nameClassNames: string;
    starSize: number;
  }
> = {
  large: {
    avatar: {
      groupClassNames: "w-40 h-40 rounded-full bg-background-ghost",
      iconSize: 24,
    },
    avatarSpacing: "mr-10",
    classNames: "",
    descriptionClassNames: "text-sm",
    emojiClassNames: "text-2xl",
    nameClassNames: "text-lg font-semibold",
    starSize: 14,
  },
  medium: {
    avatar: {
      groupClassNames: "w-32 h-32 rounded-full bg-background-ghost",
      iconSize: 20,
    },
    avatarSpacing: "mr-10",
    classNames: "",
    descriptionClassNames: "text-sm",
    emojiClassNames: "text-xl",
    nameClassNames: "text-base font-semibold",
    starSize: 12,
  },
  small: {
    avatar: {
      groupClassNames: "w-24 h-24",
      iconSize: 18,
    },
    avatarSpacing: "mr-8",
    classNames: "h-32", // additional height for external badge
    descriptionClassNames: "text-xs",
    emojiClassNames: "text-lg",
    nameClassNames: "text-subhead",
    starSize: 4,
  },
  tiny: {
    avatar: {
      groupClassNames: "w-12 h-12",
      iconSize: 12,
    },
    avatarSpacing: "mr-8",
    classNames: "",
    descriptionClassNames: "text-xs",
    emojiClassNames: "text-md",
    nameClassNames: "text-subhead",
    starSize: 4,
  },
};

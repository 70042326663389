import { ComponentProps, useMemo } from "react";

import { Placement } from "@floating-ui/react";
import { PickedEmoji } from "components/design-system/Emoji/types";

import { Dropdown } from "../FloatingUi";

import useAppStateStore from "store/useAppStateStore";
import EmojiPicker from "./EmojiPicker";

type Props = {
  allowCustomEmojis?: boolean;
  children: JSX.Element;
  emojiButtonSize?: number;
  handleCloseFloatingUI?: () => void;
  onClose?: () => void;
  onEmojiSelect: (emoji: PickedEmoji) => void;
  onOpen?: () => void;
  placement?: Placement;
  skinTonePosition?: ComponentProps<typeof EmojiPicker>["skinTonePosition"];
  suggestedEmoji?: string;
};

const EmojiSheet = ({
  allowCustomEmojis = false,
  children,
  emojiButtonSize = 36,
  handleCloseFloatingUI,
  onClose,
  onEmojiSelect,
  onOpen,
  placement = "left",
  skinTonePosition = "none",
  suggestedEmoji,
}: Props) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const perLine = useMemo(() => {
    if (breakpointMD) return 10;

    const targetWidth = window.innerWidth - (12 + 16); // (12 + 16) is from the emoji-mart library source code;

    return Math.trunc(targetWidth / emojiButtonSize);
  }, [emojiButtonSize, breakpointMD]);

  return (
    <Dropdown
      content={
        <EmojiPicker
          allowCustomEmojis={allowCustomEmojis}
          emojiButtonSize={emojiButtonSize}
          handleCloseFloatingUI={handleCloseFloatingUI}
          onEmojiSelect={onEmojiSelect}
          perLine={perLine}
          previewPosition="none"
          skinTonePosition={skinTonePosition}
          suggestedEmoji={suggestedEmoji}
          theme="light"
        />
      }
      modalContentHandlesSafeArea={false}
      onClose={onClose}
      onStart={onOpen}
      placement={placement}
      toModal
    >
      {children}
    </Dropdown>
  );
};

export default EmojiSheet;

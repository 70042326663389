import { useEffect, useRef } from "react";
import { useHistory } from "react-router";

import { Modal, ModalProps } from "components/ModalKit/Modal";
import { useModalDragging } from "components/ModalKit/useModalDragging";
import useAppStateStore from "store/useAppStateStore";
import useModalStore from "store/useModalStore";
import useNativeKeyboardStore from "store/useNativeKeyboardStore";
import tw from "utils/tw";

type Props = {
  children?: React.ReactNode;
  contentClassName?: string;
  contentHandlesSafeArea?: boolean;
  header?: React.ReactNode;
  height?: "full" | "half";
  heightAuto?: boolean;
  mdHeightAuto?: boolean;
  mobileGap?: string | true;
} & ModalProps;

/**
 * This modal opens in the center on large displays,
 * and slides in from the bottom on small displays.
 */
export const StandardModal = ({
  children,
  contentClassName,
  contentHandlesSafeArea = true,
  header,
  height = "full",
  heightAuto = false,
  mdHeightAuto = true,
  mobileGap,
  ...props
}: Props) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const history = useHistory();
  const { closeModal } = useModalStore(({ closeModal }) => ({
    closeModal,
  }));

  // if location changes, close the modal.
  useEffect(
    () => history?.listen(() => closeModal(props.modalId)),
    [closeModal, history, props.modalId]
  );

  const { bind, dragStyles } = useModalDragging(props.modalId);

  const contentRef = useRef<HTMLDivElement>(null);

  const mobileGapValue = mobileGap === true ? "192px" : mobileGap;
  const computedHeightValue = `100vh - env(safe-area-inset-top) - ${mobileGapValue}`;

  useEffect(
    () =>
      useNativeKeyboardStore.subscribe(
        ({ keyboardHeight }) => keyboardHeight,
        keyboardHeight => {
          const { current: element } = contentRef;
          if (!element) return;

          const keyboardOffset = `${Math.ceil(keyboardHeight)}px`;
          element.style.paddingBottom = contentHandlesSafeArea
            ? `calc(${keyboardOffset} - env(safe-area-inset-bottom))`
            : keyboardOffset;

          if (mobileGapValue !== undefined) {
            element.style.height = `calc(${computedHeightValue} + ${keyboardOffset})`;
          }
        }
      ),
    [computedHeightValue, contentHandlesSafeArea, mobileGapValue]
  );

  return (
    <Modal
      alignment={
        breakpointMD
          ? undefined
          : {
              x: "text-center",
              y: "align-bottom",
            }
      }
      containerScrolling={false}
      contentClassName={tw(
        "overflow-hidden w-full rounded-t-lg", // mobile and up
        "md:my-50 md:max-w-[640px] md:rounded-lg md:border-thin md:border-interactive-strong/30", // desktop
        contentClassName
      )}
      contentTransitions={
        breakpointMD
          ? undefined
          : {
              enter: {
                y: "0%",
              },
              from: {
                y: "100%",
              },
              leave: {
                y: "100%",
              },
            }
      }
      disableOpacityTransition={!breakpointMD}
      dragStyles={props.closeable !== false ? dragStyles : undefined}
      {...props}
    >
      <div
        ref={contentRef}
        className={tw(
          "flex flex-col",
          "max-h-safe-viewport-9", // mobile and up
          "md:h-safe-viewport-[100px] md:max-h-[640px]", // desktop
          { "h-[100vh]": height === "full" },
          { "h-[50vh] min-h-[50vh]": height === "half" },
          {
            "!h-auto max-h-safe-viewport-9": heightAuto,
            "md:!h-auto md:!max-h-safe-viewport-[100px]": mdHeightAuto,

            "pb-[env(safe-area-inset-bottom)]": !contentHandlesSafeArea,
          }
        )}
        style={{
          ...(mobileGapValue !== undefined
            ? {
                height: `calc(${computedHeightValue})`,
              }
            : {}),
        }}
      >
        {header && (
          <div
            className={tw(
              "shrink-0 select-none touch-none",
              props.closeable !== false && "cursor-grab"
            )}
            {...(props.closeable !== false ? bind() : {})}
          >
            {header}
          </div>
        )}
        {children}
      </div>
    </Modal>
  );
};

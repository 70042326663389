import { useEffect } from "react";
import { useHistory } from "react-router";

import {
  GroupEdge,
  GroupPreviewEdge,
  WorkspaceEdge,
  WorkspacePreviewEdge,
  nodeAs,
} from "@utility-types";
import BackStackButton from "components/App/AppLayoutMobile/BackStackButton";
import { Tabs } from "components/Tabs";
import { GroupProfileTab } from "components/group/GroupModal/GroupProfileModal";
import { usePartitionState } from "components/routing/RoutingPartition";
import {
  GroupTabs,
  routeParams as getRouteParams,
  locationFromRoute,
  tabPath,
} from "components/routing/utils";
import GroupActionMenu from "components/views/groups/GroupActionMenu";
import WorkspaceActionMenu from "components/workspace/WorkspaceActionMenu";
import { useFetchThreadEdgeSimpleQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useMemberEdge from "hooks/useMemberEdge";
import { addToHistory, createHistoryPath } from "store/useHistoryStore";
import { formatGroupName } from "utils/group/formatGroupName";

import ThreadComposeTopButton from "components/threads/ThreadCompose/ThreadComposeTopButton";
import PinnedButton from "components/threads/ThreadHeader/PinnedButton";
import useThreadComposeMenuAction from "components/threads/ThreadHeader/hooks/useThreadComposeMenuAction";
import useAppStateStore from "store/useAppStateStore";
import { GroupAvatarAndTitle } from "./GroupAvatarAndTitle";
import { GroupNotificationsButton } from "./GroupNotificationsButton";
import { WORKSPACE_GENERAL_NAME } from "./consts";

type Edge = GroupEdge | GroupPreviewEdge | WorkspaceEdge | WorkspacePreviewEdge;

type GroupTabsType = keyof typeof GroupTabs;

const GroupHeader = ({
  edge,
  currentTab,
  onOpenModal,
  onOpenGroupModal,
}: {
  edge: Edge | undefined;
  currentTab: GroupTabsType;
  onOpenModal: () => void;
  onOpenGroupModal: (params: {
    groupId: string;
    defaultTab: GroupProfileTab;
  }) => void;
}) => {
  const history = useHistory();

  const { memberEdge, previewEdge } = useMemberEdge(edge);
  const groupEdge = nodeAs(edge, ["GroupEdge", "GroupPreviewEdge"]);
  const workspaceEdge = nodeAs(edge, ["WorkspaceEdge", "WorkspacePreviewEdge"]);

  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const { threadComposeAction, handleCompose } = useThreadComposeMenuAction();

  const { route } = usePartitionState(({ route }) => ({ route }));
  const location = locationFromRoute(route);
  const routeParams = getRouteParams(location);
  const { authData, authReady } = useAuthData();

  const { data: threadData } = useFetchThreadEdgeSimpleQuery({
    fetchPolicy: authReady ? "cache-first" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !authData?.me.id || !routeParams.d,
    variables: { id: `${routeParams.d}-${authData?.me.id}` },
  });

  const threadEdge = nodeAs(threadData?.node, [
    "ThreadEdge",
    "ThreadPreviewEdge",
  ]);

  const title = threadEdge?.node.subject || groupEdge?.node.name;
  const { emoji, name } = formatGroupName(memberEdge?.node);

  const path = createHistoryPath({ ...routeParams, location });
  const chat = memberEdge?.persistentChatEdge.node;
  const isWorkspaceEdge = workspaceEdge?.__typename === "WorkspaceEdge";

  const isFollowed =
    groupEdge?.__typename === "GroupEdge" &&
    groupEdge.threadSubscription === "inbox";

  useEffect(() => {
    if (!title) return;
    addToHistory({
      emoji,
      recipient: groupEdge?.node,
      isExplore: false,
      name,
      path,
      primaryType: "groups",
      secondaryType: routeParams.t as GroupTabs,
      title,
    });
  }, [emoji, groupEdge?.node, name, path, routeParams.t, title]);

  return (
    <>
      <div className="flex justify-between mb-8">
        <div className="flex gap-16 items-center truncate">
          <BackStackButton position="groupHeader" size="small" />

          <GroupAvatarAndTitle
            isLoading={!edge}
            avatarURL={
              isWorkspaceEdge ? workspaceEdge.node.avatarURL : undefined
            }
            emoji={emoji}
            onClick={onOpenModal}
          >
            {isWorkspaceEdge ? WORKSPACE_GENERAL_NAME : name}
          </GroupAvatarAndTitle>
        </div>

        {isWorkspaceEdge ? (
          <WorkspaceActionMenu workspaceEdge={workspaceEdge} />
        ) : (
          <div className="flex grow items-center justify-end gap-8">
            {breakpointMD && <ThreadComposeTopButton onClick={handleCompose} />}
            <GroupNotificationsButton
              isFollowed={isFollowed}
              onClick={() => {
                if (groupEdge) {
                  onOpenGroupModal({
                    groupId: groupEdge.node.id,
                    defaultTab: GroupProfileTab.Notifications,
                  });
                }
              }}
            />
            {chat && <PinnedButton threadID={chat.id} />}
            <GroupActionMenu
              groupEdge={groupEdge}
              additionalActions={!breakpointMD ? [threadComposeAction] : []}
            />
          </div>
        )}
      </div>
      {previewEdge ? (
        <div className="my-16" />
      ) : (
        <Tabs<keyof typeof GroupTabs>
          buttonClassName="flex justify-start items-center border-b-2 mr-8 p-8 pb-6 leading-[22px]"
          buttonSelectedClassName="border-accent-primary text-text-primary"
          buttonUnselectedClassName="border-transparent text-text-secondary cursor-pointer"
          buttonWrapperClassName="flex"
          onClickTab={tab =>
            history.push(
              tabPath(tab, {
                threadID: tab === "Chat" ? chat?.id : undefined,
              })
            )
          }
          selectedTab={currentTab}
          tabs={[
            {
              tab: GroupTabs.Chat,
              tabTitle: GroupTabs.Chat,
            },
            {
              tab: GroupTabs.Threads,
              tabTitle: GroupTabs.Threads,
            },
            {
              tab: GroupTabs.Feed,
              tabTitle: GroupTabs.Feed,
            },
            {
              tab: GroupTabs.Shared,
              tabTitle: GroupTabs.Shared,
            },
          ]}
        />
      )}
    </>
  );
};

export default GroupHeader;

import { MemberRole } from "generated/graphql";

import { WorkspaceEdgeSimple } from "@utility-types";
import Avatar from "components/design-system/Avatar/Avatar";
import { Dropdown } from "components/design-system/FloatingUi";
import { DropdownActionButton } from "components/design-system/FloatingUi/DropdownActionButton";
import { DropdownActionButtonGroup } from "components/design-system/FloatingUi/DropdownActionButtonGroup";
import { DropdownActions } from "components/design-system/FloatingUi/DropdownActions";
import {
  WorkspaceModal,
  WorkspaceModalTabs,
} from "components/workspace/WorkspaceModal";
import WorkspaceModalJoin from "components/workspace/WorkspaceModal/WorkspaceModalJoin";
import useAuthData from "hooks/useAuthData";
import useModalStore from "store/useModalStore";
import { isNative } from "utils/platform";
import tw from "utils/tw";

const WorkspaceMenu = () => {
  const { authData } = useAuthData();
  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));

  const primaryWorkspace = authData?.workspaces.edges[0]?.node;

  const PrimaryPill = () => (
    <span
      className={tw(
        "flex items-center justify-center shrink-0 h-20 px-8 py-4 rounded-[12px]",
        "bg-background-secondary text-caption-bold text-text-subtle",
        "group-hover/action-sheet-item:bg-background-secondary-hover"
      )}
    >
      Primary
    </span>
  );

  const openWorkspaceSettings = async (edge: WorkspaceEdgeSimple) => {
    openModal(
      <WorkspaceModal
        workspaceID={edge.node.id}
        defaultTab={
          edge.memberRole === MemberRole.Admin
            ? WorkspaceModalTabs.Settings
            : WorkspaceModalTabs.Groups
        }
      />
    );
  };

  return (
    <Dropdown
      content={
        <DropdownActions>
          {authData?.workspaces.edges.length && (
            <DropdownActionButtonGroup>
              <span className="px-16 text-footnote-bold text-text-subtle h-24">
                Workspace settings
              </span>
              {authData?.workspaces.edges.map((w, i) => (
                <DropdownActionButton
                  key={w.node.id}
                  onClick={() => openWorkspaceSettings(w)}
                >
                  <Avatar
                    avatarURL={w.node.avatarURL}
                    name={w.node.name}
                    size="small"
                  />
                  {w.node.name}
                  {i === 0 ? <PrimaryPill /> : null}
                </DropdownActionButton>
              ))}
            </DropdownActionButtonGroup>
          )}
          <DropdownActionButton
            className="my-8"
            icon="Plus"
            onClick={() => {
              openModal(<WorkspaceModalJoin />);
            }}
          >
            {isNative() ? "Join a workspace" : "Add workspace"}
          </DropdownActionButton>
        </DropdownActions>
      }
      placement="bottom-end"
    >
      <div className="flex items-center justify-center h-44 w-40 md:h-28 md:w-28 hover:cursor-pointer">
        <div className="w-24 h-24 rounded-md overflow-hidden">
          <Avatar
            avatarURL={primaryWorkspace?.avatarURL}
            name={primaryWorkspace?.name}
            className="text-icon-secondary"
            iconProps={{ icon: "Workspace" }}
            rounded="rounded-md"
            size="small"
          />
        </div>
      </div>
    </Dropdown>
  );
};

export default WorkspaceMenu;

import tw from "utils/tw";
import { Icon, IconName } from "../../icons";

export enum CreateMenuActionType {
  Thread = "Thread",
  AskGlueAI = "AskGlueAI",
  DirectMessage = "DirectMessage",
  NewGroup = "NewGroup",
  SlackImport = "SlackImport",
  InviteTeam = "InviteTeam",
}

const CreateMenuAction = ({
  label,
  description,
  onClick,
  icon,
  iconClassName,
  iconBackgroundClassName,
}: {
  label: string;
  description?: string;
  onClick: (e: React.MouseEvent<HTMLLIElement>) => void;
  icon?: IconName;
  iconClassName?: string;
  iconBackgroundClassName?: string;
}) => {
  return (
    <li
      className="flex items-center px-16 py-8 hover:bg-background-body-hover active:bg-background-body-hover hover:cursor-pointer h-56 select-none"
      onClick={onClick}
    >
      <div className="flex flex-row">
        {icon && (
          <div
            className={tw(
              "bg-background-secondary rounded-full p-10 mr-12",
              iconBackgroundClassName
            )}
          >
            {<Icon icon={icon} size={20} className={tw(iconClassName)} />}
          </div>
        )}
        <div className="flex flex-col justify-around">
          <span className="text-body-bold">{label}</span>
          {description && <span className="text-footnote">{description}</span>}
        </div>
      </div>
    </li>
  );
};

export default CreateMenuAction;

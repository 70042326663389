import { SubscriptionInterval, SubscriptionPlan } from "generated/graphql";

export const planDisplayName = (plan: SubscriptionPlan) => {
  switch (plan.interval) {
    case SubscriptionInterval.Month:
      return `${plan.name} ($${Math.ceil((plan.amount / 100) * 100) / 100}/user/month)`;

    case SubscriptionInterval.Year:
      return `${plan.name} ($${Math.ceil((plan.amount / 12 / 100) * 100) / 100}/user/month)`;

    default:
      return `${plan.name}`;
  }
};

import fixDevApiUrl from "./fixDevApiUrl";
import env from "./processEnv";

/**
 * Appends image resize parameters to a Glue image file download URL
 */
export default function glueImageURL(
  url: string,
  imgixParams: {
    crop?: "center" | "left" | "right";
    dl?: string | true; // string is name of downloaded file; if true, will use original file name
    fit?: "clip" | "crop" | "min" | "max";
    h?: number;
    w?: number;
  } = {},
  dpr: 0 | 1 | 2 = 1 // 0 uses original image
): string {
  const parsedUrl = fixDevApiUrl(url);

  if (!parsedUrl.startsWith(env.glueApiUrl)) {
    return parsedUrl;
  }

  if (parsedUrl.includes("?imgix=")) {
    return parsedUrl;
  }

  if (dpr > 1) {
    imgixParams = { ...imgixParams };
    if (imgixParams.h) imgixParams.h = imgixParams.h * dpr;
    if (imgixParams.w) imgixParams.w = imgixParams.w * dpr;
  }

  const imgixUrlParams =
    dpr === 0
      ? new URLSearchParams([])
      : new URLSearchParams([
          ["auto", "format"],
          ["auto", "compress"],
          ["q", "75"], // higher is less compressed
          ["gif-q", "50"], // higher is more compressed
        ]);

  const dl = imgixParams.dl
    ? typeof imgixParams.dl === "string"
      ? `dl=${imgixParams.dl}`
      : "dl"
    : "";
  delete imgixParams.dl;

  for (const [k, v] of Object.entries(imgixParams)) {
    if (v !== undefined) {
      imgixUrlParams.append(k, `${v}`);
    }
  }

  const imgixUrlParamsFinal = encodeURIComponent(
    [imgixUrlParams.toString(), dl].filter(Boolean).join("&")
  );
  return `${parsedUrl}?imgix=${imgixUrlParamsFinal}`;
}

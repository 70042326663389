/* spellchecker: disable */
import { base62Decode, base62Encode } from "utils/base62";

const KSUID_EPOCH = 14e11;
const TS_BYTE_LENGTH = 4;
const PAYLOAD_BYTE_LENGTH = 16;
const BYTE_LENGTH = TS_BYTE_LENGTH + PAYLOAD_BYTE_LENGTH;

export const ksuid = (ts = Date.now()): string => {
  const buff = new Uint32Array(BYTE_LENGTH);

  const tsPart = Math.floor((ts - KSUID_EPOCH) / 1e3);
  buff.set([tsPart >>> 24, tsPart >>> 16, tsPart >>> 8, tsPart & 0xff], 0);

  const randBuffer = new Uint32Array(PAYLOAD_BYTE_LENGTH);
  buff.set(window.crypto.getRandomValues(randBuffer), TS_BYTE_LENGTH);

  return base62Encode(Uint8Array.from(buff));
};

export const ksuidDate = (id: string): Date | undefined => {
  const buff = base62Decode(id);
  if (buff.length !== BYTE_LENGTH) {
    return;
  }

  let tsPart = 0;
  tsPart |= (buff[0] || 0) << 24;
  tsPart |= (buff[1] || 0) << 16;
  tsPart |= (buff[2] || 0) << 8;
  tsPart |= buff[3] || 0;

  return new Date(tsPart * 1e3 + KSUID_EPOCH);
};

import { useEffect } from "react";

import usePreviousRef from "./usePreviousRef";

const useInterval = (callback: () => void, delay: number | null): void => {
  const savedCallback = usePreviousRef(callback);

  useEffect(() => {
    if (delay === null) {
      return;
    }

    const interval = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(interval);
  }, [delay, savedCallback]);
};

export default useInterval;

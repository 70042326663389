import { memo } from "react";

import { EventComponentProps, useChatContext } from "stream-chat-react";
import { UserResponse } from "stream-chat/dist/types/types";

import { GlueDefaultStreamChatGenerics } from "@utility-types";
import MessageAvatar from "components/design-system/ui/MessageAvatar";
import tw from "utils/tw";

import {
  Author,
  ReadReceipt,
  ThreadAttachment,
  Timestamp,
} from "../Message/components";
import { renderText } from "../Message/utils";
import { MessageAttachments } from "../MessageAttachments";
import { GroupStyle } from "../MessageList/utils";

/**
 * MessageSystem - Custom render component to display system and channel event messages
 */
const UnMemoizedMessageSystem = <T extends GlueDefaultStreamChatGenerics>(
  props: EventComponentProps<T> & {
    groupStyle: GroupStyle;
    readBy?: UserResponse<T>[];
  }
) => {
  const { client } = useChatContext();
  const { groupStyle, message, readBy } = props;
  const { text: messageText, type, user } = message;
  const isMyMessage = client.userID === user?.id;

  const deletedMessageText = "This message was deleted.";

  const messageGlueStatus: JSX.Element = (
    <ReadReceipt isMyMessage={isMyMessage} message={message} readBy={readBy} />
  );

  const isShowingAuthor = groupStyle === "top" || groupStyle === "single";

  if (!user || (message.type !== "system" && message.type !== "deleted"))
    return null;

  const startedThreadID = messageText?.match(/glue:(thr_[^)]+)/)?.[1];
  const hideText = !!(
    startedThreadID && messageText.includes("started a thread")
  );

  // we don't accept all attachments in system messages
  const validAttachments = message.attachments?.filter(
    ({ __typename }) =>
      // @ts-ignore backwards compatibility for temporary "Thread" type usage
      __typename === "Thread" || __typename === "ThreadPreview"
  );
  const messageAttachments: JSX.Element | null = validAttachments?.length ? (
    <MessageAttachments
      attachments={validAttachments || []}
      compact={!hideText}
      messageId={message.id}
    />
  ) : startedThreadID ? (
    <ThreadAttachment threadID={startedThreadID} showFirstMessage={hideText} />
  ) : null;

  return (
    <div
      className={tw(
        "str-chat-message relative pl-16",
        `str-chat-message--${groupStyle} str-chat-message--system str-chat-message--received`
      )}
      data-testid="message-glue"
    >
      <div className="flex">
        <div className={tw("flex flex-col shrink-0 pr-12 w-48")}>
          {message.user && isShowingAuthor ? (
            <MessageAvatar
              image={message.user.image}
              isMyMessage={isMyMessage}
              name={message.user.name}
              userID={message.user.id}
            />
          ) : (
            <Timestamp
              customClass="text-xs mb-4 text-text-subtle text-right select-none invisible mt-auto capitalize transition-opacity duration-350 opacity-0 subsequent-timestamp"
              date={message.created_at}
              reactTestId="subsequent-timestamp"
            />
          )}
        </div>
        <article className="relative w-full min-w-0">
          {message.user && isShowingAuthor ? (
            <Author
              createdAt={message.created_at}
              isMyMessage={isMyMessage}
              messageType={message.type}
              name={message.user.name ?? ""}
              onUserClick={() => null}
              userID={message.user.id}
            />
          ) : null}

          <div className="relative w-full min-w-0">
            <div className="pr-16">
              <div
                className="str-chat-message-content"
                data-testid="message-glue-content"
              >
                {(!hideText || !messageAttachments) && (
                  <div
                    className="my-4 text-sm italic text-text-subtle"
                    data-testid="message-glue-message"
                  >
                    {type === "deleted"
                      ? deletedMessageText
                      : renderText(messageText)}
                  </div>
                )}

                {type !== "deleted" && messageAttachments}
              </div>
            </div>
            {messageGlueStatus}
          </div>
        </article>
      </div>
    </div>
  );
};

export const MessageSystem = memo(
  UnMemoizedMessageSystem
) as typeof UnMemoizedMessageSystem;

import { useCallback, useEffect, useRef, useState } from "react";

import { useSnackbar } from "providers/SnackbarProvider";

const EDITOR_ERROR =
  "The message editor crashed. Some of your changes may have been lost.";

export default function useRestoreEditorOnError() {
  const { openSnackbar } = useSnackbar();

  const [error, setError] = useState(false);
  const errorCount = useRef(0);
  const restoreEditor = useCallback(() => {
    if (error) return;
    errorCount.current++;
    setError(true);
  }, [error]);

  useEffect(() => {
    if (errorCount.current >= 3) return;
    if (error) setError(false);
  }, [error]);

  if (error && errorCount.current >= 3) {
    openSnackbar("error", EDITOR_ERROR);
  }

  return {
    error,
    restoreEditor,
  };
}

import { useLocation } from "react-router-dom";

import { nodeAs } from "@utility-types";
import { ActionSheetItemGroups } from "components/design-system/FloatingUi/DropdownActions";
import { routeParams } from "components/routing/utils";
import ThreadComposeModal from "components/threads/ThreadComposeModal";
import { useFetchThreadEdgeQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useChatRecipient from "hooks/useChatRecipient";
import useGroupRecipients from "hooks/useGroupRecipients";
import useModalStore from "store/useModalStore";

const useThreadComposeMenuAction = (): {
  threadComposeAction: ActionSheetItemGroups["items"][0];
  handleCompose: () => void;
} => {
  const { authData } = useAuthData();
  const { threadID } = routeParams(useLocation());
  const { data } = useFetchThreadEdgeQuery({
    fetchPolicy: "cache-first",
    variables: { id: `${threadID}-${authData?.me.id}` },
  });
  const threadEdge = nodeAs(data?.node, ["ThreadEdge"]);

  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));
  const groupRecipients = useGroupRecipients();
  const recipient = useChatRecipient()(threadEdge?.node ?? undefined);

  const handleCompose = () =>
    openModal(
      <ThreadComposeModal
        initialDraft={{
          recipients: recipient ? [recipient] : groupRecipients,
        }}
      />
    );

  return {
    threadComposeAction: {
      text: "New thread",
      icon: "Compose",
      onClick: handleCompose,
    },
    handleCompose,
  };
};

export default useThreadComposeMenuAction;

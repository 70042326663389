import { ComponentProps } from "react";

import { Recipient } from "@utility-types";

import { ProfileItem } from ".";

type ProfileItemProps = ComponentProps<typeof ProfileItem>;

export const parseItemData: (
  recipient: Recipient | undefined,
  recipientName?: string
) => Omit<ProfileItemProps, "size" | "isAdmin"> = (
  recipient,
  recipientName
) => ({
  avatarURL: recipient?.avatarURL,
  commonWorkspaceIDs: recipient?.commonWorkspaceIDs,
  id: recipient?.id,
  name: recipient?.__typename?.startsWith("Workspace")
    ? recipientName
      ? recipientName
      : `All ${recipient.name}`
    : recipient?.name,
  type: recipient?.__typename || "User",
  workspaceIDs: recipient?.workspaceIDs,
});

import { PlainExtension, ProsemirrorAttributes } from "remirror";

/**
 * Extension to add classes to ProseMirror content editable element
 */
export default class EditorClassListExtension extends PlainExtension<{
  className: string;
}> {
  get name() {
    return "glueEditorClassList";
  }

  createAttributes(): ProsemirrorAttributes {
    return {
      class: this.options.className,
    };
  }
}

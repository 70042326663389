import { AnimationOptions, animate as popAnimate } from "popmotion";

import { isNativeIOS } from "./platform";

export const animate = <T>(
  options: AnimationOptions<T>
): { stop: () => void } =>
  popAnimate({
    damping: 210,
    mass: 5,
    restDelta: 0.5,
    stiffness: 2000,
    type: "spring",
    ...options,
  });

export const animateScrollTo = (
  el: HTMLElement,
  to: number,
  onComplete?: () => void
): { stop: () => void } =>
  animate({
    from: el.scrollTop,
    onComplete: () => {
      // stop momentum on iOS -- apparently no other way :face-palm:
      if (isNativeIOS()) {
        el.style.setProperty("overflow-y", "hidden", "important");
        setTimeout(
          () => el.style.setProperty("overflow-y", "scroll", "important"),
          0
        );
      }

      onComplete?.();
    },
    onUpdate: v => el.scrollTo({ top: v }),
    to,
  });

import { useMemo, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { InitialState, Reducer } from "./Reducer";

import Start from "components/App/Start";

import { routePath } from "components/routing/utils";
import ResendEmail from "./ResendEmail";
import SignIn from "./SignIn";
import VerificationCode from "./VerificationCode";

const SignInMain = () => {
  const [state, dispatch] = useReducer(Reducer, { ...InitialState });
  const history = useHistory();

  const view = useMemo(() => {
    const onSignInSuccess = () => {
      history.replace(routePath({}));
    };
    switch (state.view) {
      case "SignIn":
        return <SignIn email={state.email} dispatch={dispatch} />;
      case "VerificationCode":
        return (
          <VerificationCode
            email={state.email}
            dispatch={dispatch}
            onSignInSuccess={onSignInSuccess}
          />
        );
      case "ResendEmail":
        return <ResendEmail email={state.email} dispatch={dispatch} />;
      default:
        return <div />;
    }
  }, [history, state.email, state.view]);

  return <Start>{view}</Start>;
};

export default SignInMain;

import { UnreadBadge } from "components/design-system/ui/unreadBadge";
import tw from "utils/tw";

import Icon from "components/design-system/icons/Icon";
import ThreadItemContentAttachment from "./ThreadItemContentAttachment";
import { ItemData, ItemType } from "./utils";

const Subject = ({
  isRead,
  subject,
}: { isRead?: boolean; subject: ItemData["subject"] }) => (
  <div className="flex items-baseline justify-start grow min-w-0 h-full">
    <span
      className={tw(
        "text-body truncate",
        isRead
          ? "font-normal text-text-secondary"
          : "font-bold text-text-primary"
      )}
    >
      {subject}
    </span>
  </div>
);

const Recipients = ({ recipients }: { recipients: ItemData["recipients"] }) => (
  <div className="flex items-center mt-1 min-w-0 max-w-full text-footnote text-text-secondary">
    {recipients}
  </div>
);

type Props = {
  compact?: boolean;
  isRead?: boolean;
  isStarred?: boolean;
  isSubscribed?: boolean;
  item?: ItemType;
  itemData: ItemData;
  recipientID?: string;
};

const ThreadItemContentPreview = ({
  compact,
  isRead,
  isStarred,
  isSubscribed,
  itemData,
}: Props) => {
  const timestamp = (
    <span
      className={tw(
        "timestamp shrink-0 pl-10 text-xs text-text-subtle leading-none"
      )}
    >
      {itemData.date}
    </span>
  );

  const unreadCount = itemData.unreadCount ? (
    isSubscribed && !compact ? (
      <UnreadBadge
        className="mr-4"
        count={itemData.unreadCount}
        important={!!itemData.unreadMentionCount}
      />
    ) : (
      <div className="text-xs text-text-subtle">
        {itemData.unreadCount}&nbsp;unread
      </div>
    )
  ) : null;

  return (
    <div className="flex flex-col grow overflow-hidden justify-center relative">
      <div className="flex flex-col grow overflow-hidden justify-center relative">
        <div className="flex items-baseline justify-start w-full h-22">
          <div className="flex grow items-center justify-start min-w-0 overflow-hidden">
            {isStarred && (
              <Icon
                className="mr-2 shrink-0 text-icon-favorite"
                icon="StarFilled"
                size={16}
              />
            )}
            <div className="flex gap-8 items-center">
              <Subject isRead={isRead} subject={itemData.subject} />

              {compact && itemData.chatType !== "user" && (
                <Recipients recipients={itemData.recipients} />
              )}
            </div>
          </div>

          {timestamp && (
            <div className="flex items-center justify-end shrink-0">
              {timestamp}
            </div>
          )}
        </div>

        {!compact && itemData.chatType !== "user" && (
          <Recipients recipients={itemData.recipients} />
        )}

        <div className="flex items-baseline justify-between mt-2 w-full">
          <div className="flex items-center pr-5 text-footnote text-text-subtle truncate">
            {itemData.messageSender ? (
              <>
                {(itemData.chatType !== "user" ||
                  itemData.messageSender === "You") && (
                  <>
                    <span>{itemData.messageSender}</span>
                    {itemData.showSkeleton ? "" : ":"}&nbsp;
                  </>
                )}

                {itemData.message ? (
                  <span className="min-w-0 truncate">{itemData.message}</span>
                ) : (
                  <ThreadItemContentAttachment
                    attachmentTypes={itemData.attachmentTypes}
                  />
                )}
              </>
            ) : (
              <span className="truncate text-sm leading-[18px]">
                No messages yet.
              </span>
            )}
          </div>

          <div className="flex items-center justify-end">{unreadCount}</div>
        </div>
      </div>
    </div>
  );
};

export default ThreadItemContentPreview;

import tw from "utils/tw";

type Props = {
  button?: React.ReactNode;
} & React.HTMLProps<HTMLDivElement>;

const ListHeaderWithAction = ({
  button,
  children,
  className,
  ...props
}: Props) => (
  <div
    className={tw("flex justify-between items-center mb-16", className)}
    {...props}
  >
    <div>{children}</div>
    <div>{button}</div>
  </div>
);

export default ListHeaderWithAction;

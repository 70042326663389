import { useEffect, useRef } from "react";

import useAppStateStore from "store/useAppStateStore";

const modifiers = new Set(["Meta", "Control", "Alt"]);

// Preferable to `useKeyPress` for multi-key combo or when
// you don't want the component to re-render on key press
function useShortcut(keys: string[], callback: (event: KeyboardEvent) => void) {
  const down = useRef<Set<string>>(new Set());
  const shortcut = useRef(new Set(keys));
  shortcut.current = new Set(keys);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    function downHandler(event: KeyboardEvent) {
      const { key } = event;

      if (shortcut.current.size === 1 && shortcut.current.has(key)) {
        return callback(event);
      }

      if (!shortcut.current.has(key)) {
        down.current.clear();
        return;
      }

      down.current.add(key);

      timer = setTimeout(() => {
        if (Array.from(shortcut.current).every(k => down.current.has(k))) {
          let isModifier = false;
          let isValidShortcut = true;
          // Validate that the modifier keys were pressed before any other key
          const pressedKeys = Array.from(down.current).reverse();
          for (const key of pressedKeys) {
            if (isModifier && !modifiers.has(key)) {
              isValidShortcut = false;
              break;
            }
            if (modifiers.has(key)) isModifier = true;
          }
          down.current.clear();
          isValidShortcut && callback(event);
        }
      }, 10);
    }

    function upHandler({ key }: { key: string }) {
      down.current.delete(key);
    }

    // Workaround for lack of "keyup" when window becomes inactive
    const unsubscribeAppState = useAppStateStore.subscribe(
      ({ appStatus }) => appStatus,
      appStatus => {
        if (appStatus === "inactive") down.current.clear();
      }
    );

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      unsubscribeAppState();
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
      clearTimeout(timer);
    };
  }, [callback]);
}

export default useShortcut;

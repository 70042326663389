/**
 * Check if element has an Ancestor with a specific TagName
 * @param {ElementType}   child        HTMLElement
 * @param {String}        tagName      tagName of type DOMString
 */
export default function hasAncestorWithTagName(
  child: HTMLElement | Node,
  tagName: string
): boolean {
  let node = child.parentElement;
  while (node !== null) {
    if (node.tagName === tagName.toUpperCase()) {
      return true;
    }
    node = node.parentElement;
  }
  return false;
}

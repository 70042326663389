import { isGlueFile } from "@utility-types";
import QuotedAttachments from "components/QuotedMessage/QuotedAttachments";
import { renderText } from "components/thread/ThreadView/stream-components/Message/utils";
import { FileType, MessageFieldsFragment } from "generated/graphql";
import { IAuthContext } from "providers/AuthProvider";

export function MessagePreview({
  authData,
  message,
  title,
}: {
  title: string;
  message: {
    __typename: "Message";
    id: string;
  } & MessageFieldsFragment;
  authData: IAuthContext["authData"];
}) {
  if (!authData) return null;

  const linkAttachmentsLength = message.attachments.filter(
    a => a.__typename === "ExternalObject"
  ).length;
  const glueAttachments = message.attachments.filter(isGlueFile);
  const firstAttachment = glueAttachments.slice(0, 1);
  const messagePreview = () => {
    const uniqueType = glueAttachments.every(
      (obj, _i, array) => obj.fileType === array[0]?.fileType
    );
    const attachmentType = firstAttachment[0]?.fileType;

    if (message.textPreview.length > 0) {
      return renderText(
        message.textPreview.replaceAll(
          authData.me.name,
          `[${authData.me.name}](glue:${authData.me.id})`
        )
      );
    }

    if (glueAttachments.length === 1) {
      return `sent ${
        attachmentType === FileType.Image || attachmentType === FileType.Audio
          ? "an"
          : "a"
      } ${attachmentType}.`;
    }

    if (glueAttachments.length > 1) {
      return `sent ${glueAttachments.length} ${
        !uniqueType ? "attachment" : attachmentType
      }s.`;
    }

    if (linkAttachmentsLength) return "sent a link.";

    return "";
  };

  return (
    <div className="flex justify-between">
      <div className="pointer-events-none">
        <div className="text-text-primary text-subhead-bold">{title}</div>
        <div className="text-text-primary text-body [&>p]:line-clamp-2">
          {messagePreview()}
        </div>
      </div>
      <div className="shrink-0 pl-12 flex items-end [&>*:first-child]:max-w-48">
        {glueAttachments.length > 0 && (
          <QuotedAttachments quotedAttachments={firstAttachment} />
        )}
      </div>
    </div>
  );
}

import type { BodyOverflowEffect } from "./types";

export const bodyOverflowEffect: BodyOverflowEffect = (
  modalsExist: boolean
) => {
  if (modalsExist) {
    const originalOverflow = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }
};

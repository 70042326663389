import { useEffect, useRef } from "react";

import { FetchResult } from "@apollo/client";

import {
  MasonryRequestMutation,
  useMasonryRequestMutation,
} from "generated/graphql";
import useModalStore from "store/useModalStore";

import {
  BlockInteractionHandlers,
  BlockOnAction,
  BlockOnLink,
  BlockOnOptionsLoad,
  BlockOnValueChanged,
} from "./Blocks/BlockComponent";
import BlockModal, { SurfaceUpdate } from "./Blocks/BlockModal";
import { ModalBlock, SurfaceBlock } from "./Blocks/blockTypes";
import {
  MasonryBlockValue,
  MasonryOptionsLoadRequest,
  MasonrySurfaceInteractionRequest,
  MasonrySurfaceLinkSelectedRequest,
  MasonrySurfaceSubmissionRequest,
  Session,
} from "./masonryTypes";

const MasonrySurfaceManager = (props: {
  session: Session;
  surface: SurfaceBlock;
  surfaceKey: string;
  handleMasonryResponse: (result: FetchResult<MasonryRequestMutation>) => void;
  onClose: () => void;
}) => {
  const surface = props.surface;
  const session = props.session;
  const [masonryRequest] = useMasonryRequestMutation();
  const modalRef = useRef<SurfaceUpdate>(null);

  const { openModal } = useModalStore(({ closeModal, openModal }) => ({
    closeModal,
    openModal,
  }));
  const commonMasonryInput = () => {
    return {
      session: session,
      surface: {
        controlValues: controlValues.current,
        id: surface.id,
        metadata: null,
        type: surface.type,
      },
      timestamp: new Date().toISOString(),
    };
  };

  const onLink: BlockOnLink = (path: string) => {
    (async () => {
      const input: MasonrySurfaceLinkSelectedRequest = {
        path,
        requestType: "surfaceLinkSelected",
        ...commonMasonryInput(),
      };
      const result = await masonryRequest({ variables: { input } });
      props.handleMasonryResponse(result);
    })();
  };

  const onAction: BlockOnAction = (block, value) => {
    const path = block.path;
    if (!path) {
      return;
    }

    (async () => {
      const input: MasonrySurfaceInteractionRequest = {
        data: {
          controlID: block.id,
          controlValue: value,
        },
        path,
        requestType: "surfaceInteraction",
        ...commonMasonryInput(),
      };
      const result = await masonryRequest({ variables: { input } });
      props.handleMasonryResponse(result);
    })();
  };

  const onOptionsLoad: BlockOnOptionsLoad = async (block, value) => {
    const input: MasonryOptionsLoadRequest = {
      data: {
        controlID: block.id,
        controlValue: value,
      },
      path: block.optionsPath,
      requestType: "optionsLoad",
      ...commonMasonryInput(),
    };

    const result = await masonryRequest({ variables: { input } });
    if (result.data?.masonryRequest) {
      return result.data?.masonryRequest.data;
    }

    return { options: undefined };
  };

  const onSubmit = () => {
    let path: string | undefined;
    if (surface.type === "modal") {
      path = surface.submitPath;
    }
    if (!path) {
      return;
    }

    const input: MasonrySurfaceSubmissionRequest = {
      data: {
        inputs: controlValues.current,
      },
      path,
      requestType: "surfaceSubmission",
      ...commonMasonryInput(),
    };

    (async () => {
      const result = await masonryRequest({ variables: { input } });
      props.handleMasonryResponse(result);
    })();
  };

  const controlValues = useRef<Record<string, MasonryBlockValue>>({});
  const onValueChanged: BlockOnValueChanged = (block, value) => {
    controlValues.current = {
      ...controlValues.current,
      [block.id]: {
        controlID: block.id,
        type: block.type,
        value,
      },
    };
  };

  const handlers: BlockInteractionHandlers = {
    onLink,
    onAction,
    onOptionsLoad,
    onValueChanged,
  };

  const modalOpened = useRef(false);
  useEffect(() => {
    if (modalOpened.current) {
      if (modalRef.current) {
        modalRef.current.update({
          block: surface as ModalBlock,
          surfaceKey: props.surfaceKey,
          handlers,
          onSubmit,
          onClose: props.onClose,
        });
      }
      return;
    }
    modalOpened.current = true;

    openModal(
      <BlockModal
        ref={modalRef}
        block={surface as ModalBlock}
        surfaceKey={props.surfaceKey}
        handlers={handlers}
        onSubmit={onSubmit}
        onClose={props.onClose}
      />
    );
  });

  return <></>;
};

export default MasonrySurfaceManager;

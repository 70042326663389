import { useEffect, useRef } from "react";

export const useIntersectionObserver = (
  element: HTMLElement | null,
  callback: (e: IntersectionObserverEntry) => void,
  options?: Pick<IntersectionObserverInit, "threshold" | "root" | "rootMargin">
) => {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    if (!element) return;

    const observer = new IntersectionObserver(
      entries =>
        window.requestAnimationFrame(
          () => entries[0] && callbackRef.current(entries[0])
        ),
      {
        root: options?.root,
        rootMargin: options?.rootMargin,
        threshold: options?.threshold,
      }
    );

    observer.observe(element);

    return () => observer.disconnect();
  }, [element, options?.threshold, options?.root, options?.rootMargin]);
};

export default useIntersectionObserver;

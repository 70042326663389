import {
  MutableRefObject,
  RefObject,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

import { GroupStyle } from "stream-chat-react/dist/components/MessageList/utils";

import { StreamGlueMessage } from "@utility-types";
import { HistoryState } from "components/Navigation/HistoryState";
import { Button } from "components/design-system/Button";
import MessageAvatar from "components/design-system/ui/MessageAvatar";
import { useThreadViewState } from "components/thread/ThreadView/provider/ThreadViewProvider";
import usePrevious from "hooks/usePrevious";
import { getReplyThreadID } from "utils/stream/message";
import tw from "utils/tw";

import { Icon } from "components/design-system/icons";
import useAuthData from "hooks/useAuthData";
import { Author } from "./Author";
import { ErrorIcon } from "./Icons";
import { MessageContent } from "./MessageContent";
import { ThreadAttachment, ThreadAttachmentDraft } from "./ThreadAttachment";
import { Timestamp } from "./Timestamp";

const TopSubsequentTSThreshold = 500;

export const MessageComposite = ({
  firstGroupStyle,
  handleRetry,
  initialMessage,
  isMyMessage,
  message,
  messageActionMenu,
  messageGlueStatus,
  messageText,
  messageWrapperRef,
  onUserClick,
  reactionsList,
}: {
  contentRef?: RefObject<HTMLDivElement>;
  firstGroupStyle: GroupStyle;
  handleRetry: () => void;
  initialMessage?: boolean;
  isMyMessage: boolean;
  lastReceivedId: string | null | undefined;
  message: StreamGlueMessage;
  messageActionMenu: JSX.Element | null;
  messageGlueStatus: JSX.Element | null;
  messageText: JSX.Element | null;
  messageWrapperRef: MutableRefObject<HTMLDivElement | null>;
  onUserClick: (event: SyntheticEvent) => void;
  reactionsList: JSX.Element | null;
}): JSX.Element | null => {
  const { authData } = useAuthData();
  const history = useHistory<HistoryState>();

  const { messageID, replyTo } = useThreadViewState(
    ({ messageID, recipientID, replyTo }) => ({
      messageID,
      recipientID,
      replyTo,
    })
  );

  const prevMessageID = usePrevious(messageID);

  // highlight messages when we link to them from search
  const [highlightState, setHighlightState] = useState(false);
  const [wasHighlighted, setWasHighlighted] = useState(false);

  useEffect(() => {
    if (!messageWrapperRef.current) return;

    if (messageID !== message.id && highlightState) {
      setHighlightState(false);
      return;
    }

    if (messageID !== message.id) return;

    if (wasHighlighted) return;

    const locationState = history.location.state?.historyLastRead || undefined;
    if (locationState) return;

    setHighlightState(true);
    setWasHighlighted(true);

    const timeout = setTimeout(() => {
      if (!messageWrapperRef.current) return;
      setHighlightState(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [
    history.location.state?.historyLastRead,
    message.id,
    messageWrapperRef,
    messageID,
    prevMessageID,
    highlightState,
    wasHighlighted,
  ]);

  useEffect(() => {
    if (!prevMessageID) return;
    if (!messageID || prevMessageID !== messageID) {
      setWasHighlighted(false);
    }
  }, [messageID, prevMessageID]);

  const showAuthor =
    firstGroupStyle === "top" || firstGroupStyle === "single" || initialMessage;
  const showSubsequentTS =
    !showAuthor || (message.text?.length || 0) > TopSubsequentTSThreshold;

  const showReplyTo =
    replyTo?.message.id === message.id ||
    replyTo?.message.streamID === message.id;
  const replyThreadID = getReplyThreadID(message);

  const pinned = !!message.pinned;

  return (
    <div
      ref={messageWrapperRef}
      className={tw(
        "str-chat-message relative z-0 pl-16",
        `str-chat-message--${firstGroupStyle} str-chat-message--${message.type} str-chat-message--${message.status}`,
        highlightState || pinned ? "bg-background-highlight" : "bg-transparent",
        {
          "transition-colors duration-500": !highlightState,
        }
      )}
      data-messageid={message.id}
      data-testid="message-glue"
    >
      {pinned && message.pinned_by && (
        <div className="flex gap-12 mb-4 pt-4 pl-24 text-caption text-text-subtle">
          <Icon
            className="text-icon-action-highlight"
            icon="Pin45Filled"
            size={12}
          />
          Pinned by{" "}
          {message.pinned_by.id === authData?.me.id
            ? "you"
            : message.pinned_by.name}
        </div>
      )}

      <div className="flex">
        <div className={tw("flex flex-col shrink-0 pr-12 w-48")}>
          {message.user && showAuthor ? (
            <MessageAvatar
              image={message.user.image}
              isMyMessage={isMyMessage}
              name={message.user.name}
              userID={message.user.id}
            />
          ) : null}
          {showSubsequentTS ? (
            <Timestamp
              customClass="text-xs mb-4 text-text-subtle text-right select-none invisible mt-auto capitalize transition-opacity duration-350 opacity-0 relative right-4 subsequent-timestamp"
              date={message.created_at}
              reactTestId="subsequent-timestamp"
            />
          ) : null}
        </div>

        <article className="relative w-full min-w-0">
          {message.user && showAuthor ? (
            <Author
              createdAt={message.created_at}
              isMyMessage={isMyMessage}
              messageActionMenu={messageActionMenu}
              messageID={message.id}
              messageThread={showReplyTo ? replyTo.thread : undefined}
              messageType={message.type}
              name={message.user.name ?? ""}
              onUserClick={onUserClick}
              userID={message.user.id}
            />
          ) : null}

          <MessageContent
            firstGroupStyle={firstGroupStyle}
            message={message}
            messageActionMenu={messageActionMenu}
            messageGlueStatus={messageGlueStatus}
            messageText={messageText}
            reactionsList={reactionsList}
          />

          {message.text !== "" && reactionsList}

          <div className="pr-16">
            {replyThreadID && !showReplyTo ? (
              <ThreadAttachment
                threadID={replyThreadID}
                showFirstMessage={false}
              />
            ) : (
              <ThreadAttachmentDraft replyToMessageID={message.id} />
            )}
          </div>
        </article>
      </div>

      <div className="ml-50">
        {message.status === "failed" && (
          <Button
            buttonStyle="simpleDestructive"
            buttonType="none"
            className="flex flex-row items-center"
            data-testid="message-glue-failed"
            onClick={
              message.errorStatusCode !== 403 ? () => handleRetry() : undefined
            }
          >
            <ErrorIcon />
            <span className="ml-5">
              {message.errorStatusCode !== 403
                ? "Message Failed · Click to try again"
                : "Message Failed · Unauthorized"}
            </span>
          </Button>
        )}
      </div>
    </div>
  );
};

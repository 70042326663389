import { glueCreateZustand } from "./helper/glueCreate";

const STORE_NAME = "ProgressStore";

type ProgressStore = {
  message?: {
    text: string;
    type: "error" | "success";
  };
  value: number;
};

const initialState: ProgressStore = {
  value: 0,
};

const useProgressStore = glueCreateZustand<ProgressStore>({
  name: STORE_NAME,
})(() => initialState);

export default useProgressStore;

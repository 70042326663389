import { AppFieldsFragment } from "generated/graphql";

export type Scope =
  | "unlimited"
  | "first_party"
  | "configuration"
  | "workspaces:read"
  | "threads:read"
  | "groups:read"
  | "threads.messages:write"
  | "app.actions:register";

export type ConsentDatum = {
  details: string[];
  scope: Scope;
};

export type ConsentSubsection = {
  id: string;
  data: ConsentDatum[];
  label: string;
};

export type ConsentSection = {
  label: string;
  subsections: ConsentSubsection[];
};

const generateConsentSections = (app: AppFieldsFragment): ConsentSection[] => {
  const appUser = `@${app.name} (App)`;
  const appName = app.name;

  const sectionRead: ConsentSection = (() => {
    const subsectionThreadRead: ConsentSubsection = {
      data: [
        {
          details: [
            "View basic information about publicly accessible threads in your workspace",
            "View basic information about threads it is added to",
          ],
          scope: "threads:read",
        },
      ],
      id: "threads-read",
      label: "Content and info about threads",
    };

    const subsectionGroupRead: ConsentSubsection = {
      data: [
        {
          details: [
            "View basic information about public groups in your workspace",
            `View basic information about groups that ${appName} is added to`,
          ],
          scope: "groups:read",
        },
      ],
      id: "groups-read",
      label: "Content and info about groups",
    };

    const subsectionWorkspaceRead: ConsentSubsection = {
      data: [
        {
          details: ["View the name, email domain, and icon for your workspace"],
          scope: "workspaces:read",
        },
      ],
      id: "workspaces-read",
      label: "Content and info about your workspace",
    };

    return {
      label: `What will the ${appName} be able to view?`,
      subsections: [
        subsectionThreadRead,
        subsectionGroupRead,
        subsectionWorkspaceRead,
      ],
    };
  })();

  const sectionWrite: ConsentSection = (() => {
    const subsectionThreadWrite: ConsentSubsection = {
      data: [
        {
          details: [`Send messages as ${appUser}`],
          scope: "threads.messages:write",
        },
      ],
      id: "threads-write",
      label: "Perform actions in chats and threads",
    };

    const subsectionWorkspaceWrite: ConsentSubsection = {
      data: [
        {
          details: ["Add actions that people can use"],
          scope: "app.actions:register",
        },
      ],
      id: "actions-write",
      label: "Perform actions in your workspace",
    };

    return {
      label: `What will ${appName} be able to do?`,
      subsections: [subsectionThreadWrite, subsectionWorkspaceWrite],
    };
  })();

  return [sectionRead, sectionWrite];
};

export const filterConsentSectionsForDisplay = (
  scopes: string[],
  app: AppFieldsFragment
): ConsentSection[] | null => {
  const requestedScopeMap = scopes.reduce(
    (agg: { [key: string]: boolean }, item) => {
      agg[item] = true;
      return agg;
    },
    {}
  );

  const consentSections = generateConsentSections(app);
  const allDetailedScopes: Scope[] = [];

  const filteredSections = consentSections
    .map(section => {
      const subsections = section.subsections
        .map(subsection => {
          const data = subsection.data.filter(datum => {
            allDetailedScopes.push(datum.scope);
            if (!requestedScopeMap[datum.scope]) {
              return false;
            }
            return true;
          });

          if (data.length === 0) {
            return null;
          }

          return {
            data: data,
            label: subsection.label,
          };
        })
        .filter((s): s is ConsentSubsection => s !== null);

      if (subsections.length === 0) {
        return null;
      }

      return {
        label: section.label,
        subsections: subsections,
      };
    })
    .filter((s): s is ConsentSection => s !== null);

  // There are some scopes that are defined but not shown on the consent screen.
  // Account for these when checking for unrecognized scopes.
  const notShownScopes: Scope[] = ["first_party"];
  const allKnownScopes = [...notShownScopes, ...allDetailedScopes] as string[];

  const difference = scopes.filter(x => !allKnownScopes.includes(x));
  if (difference.length > 0) {
    // Found unrecognized scopes
    return null;
  }

  return filteredSections;
};

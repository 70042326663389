import { useFormContext } from "react-hook-form";

import { RadioGroup } from "components/design-system/Forms";
import useAuthData from "hooks/useAuthData";

const GroupVisibility = () => {
  const { authData } = useAuthData();
  const { watch } = useFormContext<{ workspaceID: string }>();
  const { workspaceID } = watch();
  const selectedWorkspace = authData?.workspaces.edges.find(
    w => w.node.id === workspaceID
  )?.node.name;
  return (
    <>
      <span className="text-subhead-bold text-text-secondary">
        Choose the visibility for this group in{" "}
        {selectedWorkspace ?? "this workspace"}:
        <RadioGroup
          optionStyle="bordered"
          name="visibility"
          options={[
            {
              label: "Open",
              value: "open",
              description: "Anyone can find and join this group.",
              icon: "Eye",
            },
            {
              label: "Closed",
              value: "closed",
              description:
                "Anyone can find this group but must request to join to see its chat and threads.",
              icon: "Lock",
            },
          ]}
        />
        <div className="h-70" />
      </span>
    </>
  );
};

export default GroupVisibility;

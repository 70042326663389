import { Recipient } from "@utility-types";
import { isEqual } from "lodash-es";
import env from "utils/processEnv";

const isGlueAIRecipient = (recipients: Recipient[]) =>
  isEqual(
    recipients.map(({ id }) => id),
    [env.glueAIBotID]
  );

export default isGlueAIRecipient;

import tw from "utils/tw";

type Props = {
  total: number;
  step: number;
};

const ProgressTracker = ({ total, step }: Props) => {
  if (step > total) return null;
  const steps = Array.from({ length: total }, (_, i) => i + 1);
  return (
    <div className="flex">
      {steps.map(s => (
        <div
          key={s}
          className={tw(
            "w-8 h-8 bg-background-subtle rounded-half",
            { "ml-6": s <= steps.length },
            { "!bg-background-action": step === s }
          )}
        />
      ))}
    </div>
  );
};

export default ProgressTracker;

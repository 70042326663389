import { ComponentProps, ReactNode } from "react";
import { useHistory } from "react-router-dom";

import Avatar from "components/design-system/Avatar/Avatar";
import { Icon, IconName } from "components/design-system/icons";
import { FeedType } from "generated/graphql";
import { formatDate } from "utils/formatDate";
import tw from "utils/tw";

type ActivityTypes = {
  type?: Exclude<FeedType, FeedType.All | FeedType.Groups>;
};

type ActivityGroupType = {
  groupType?: "Default" | "Thread";
  type?: FeedType.Groups;
};

type Props = {
  avatarProps?: Pick<
    ComponentProps<typeof Avatar>,
    "emojiProps" | "name" | "avatarURL" | "background"
  >;
  isRead: boolean;
  linkTo?: (toSecondary: boolean) => string;
  onClick: (e: React.MouseEvent<HTMLLIElement>) => void;
  selected: boolean;
  timestamp: string;
  title: ReactNode;
} & (ActivityTypes | ActivityGroupType);

const ActivityItemLayout = ({
  avatarProps,
  isRead,
  children,
  linkTo,
  onClick,
  selected,
  timestamp,
  title,
  ...props
}: WithChildren<Props>): JSX.Element => {
  const history = useHistory();
  const typeIcon: IconName | null = (() => {
    switch (props.type) {
      case FeedType.Mentions:
        return "AtSign";
      case FeedType.Reactions:
        return "Reaction";
      case FeedType.Groups:
        return props.groupType === "Default" ? "Users" : "Thread";
      case FeedType.Requests:
        return "Check";
      default:
        return null;
    }
  })();

  return (
    <li
      className={tw(
        "cursor-pointer flex overflow-hidden select-none",
        "pl-8 md:px-20 md:py-12 ",
        "md:border-b-1 md:border-border-strong",
        "hover:bg-background-list-hover",
        {
          "!bg-background-list-selected": selected,
        }
      )}
      onClick={e => {
        onClick(e);
        linkTo && history.push(linkTo(e?.ctrlKey || e?.metaKey));
      }}
    >
      <div
        className={tw(
          "grow min-w-0",
          "ml-12 py-12 pr-16",
          "md:border-b-none md:ml-0 md:py-0 md:pr-0",
          "border-b-thin border-border-strong",
          {
            "border-b-transparent": selected,
          }
        )}
      >
        <div className="flex items-center mb-8 min-w-0 overflow-hidden w-full">
          <div className="mr-4 text-icon-primary">
            {typeIcon && (
              <Icon icon={typeIcon} className="w-16 h-16" strokeWidth={1} />
            )}
          </div>
          <div className="truncate text-text-primary text-footnote">
            {title}
          </div>
          <div
            className={tw(
              "relative flex ml-8 items-center grow shrink-0 justify-end text-text-subtle text-caption",
              { "pr-14": !isRead }
            )}
          >
            {!isRead && (
              <div className="absolute right-0 top-4 before:block before:w-6 before:h-6 before:content-[''] before:rounded-full before:bg-background-alert" />
            )}
            {formatDate(new Date(timestamp), { relative: "time" })}
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-12">
            <Avatar size="large" rounded="rounded-md" {...avatarProps} />
          </div>
          <div className="w-full -mt-2">{children}</div>
        </div>
      </div>
    </li>
  );
};

export default ActivityItemLayout;

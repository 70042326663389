import { memo, useEffect, useMemo, useState } from "react";

import { useDebounce, useDebouncedCallback } from "use-debounce";

import { useEditorContentState } from "components/MessageEditor/providers/EditorContentProvider";
import { Button } from "components/design-system/Button";
import useComponentMounted from "hooks/useComponentMounted";
import tw from "utils/tw";

import { Mode, SendButtonMode } from "../../../types";
import buttonStyles from "../../buttonStyles";

type Props = {
  mode: Mode;
  sendButtonMode: SendButtonMode;
  readOnly: boolean;
  submitForm: () => void;
};

const SendButton = ({
  mode,
  sendButtonMode,
  readOnly,
  submitForm,
}: Props): JSX.Element => {
  const isMounted = useComponentMounted();

  const { hasTextContent, hasUploads, isProcessing } = useEditorContentState(
    ({ hasTextContent, hasUploads, isProcessing }) => ({
      hasTextContent,
      hasUploads,
      isProcessing,
    })
  );

  const [state, setState] = useState(true);

  const [disabled] = useDebounce(state, 58);

  const debounceGetContent = useDebouncedCallback(
    (hasAttachments, hasTextContent, isProcessing) => {
      if (!isMounted.current) return;

      setState((!hasTextContent && !hasAttachments) || isProcessing);
    },
    258,
    { leading: true, trailing: true }
  );

  useEffect(() => {
    if (readOnly) {
      setState(true);

      return;
    }

    debounceGetContent(hasUploads, hasTextContent, isProcessing);
  }, [debounceGetContent, hasUploads, hasTextContent, isProcessing, readOnly]);

  // cancel debounce when component unmounts
  useEffect(() => () => debounceGetContent.cancel(), [debounceGetContent]);

  return useMemo(() => {
    const name = mode === "compose" ? "Send" : "Save";
    return (
      <Button
        buttonStyle={sendButtonMode === "send" ? "simplePrimary" : "primary"}
        className={tw(
          buttonStyles(false, sendButtonMode === "post"),
          "disabled:!text-interactive-subtle-disabled md:!mr-0 h-30 !pl-8 !pr-12",
          { "!mr-8": mode === "compose" },
          {
            "!text-text-action-inverse disabled:!text-text-action-inverse":
              sendButtonMode === "post",
          }
        )}
        disabled={disabled}
        onPointerDown={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        {...(!disabled ? { "data-testid": "send-enabled" } : {})}
        icon={
          sendButtonMode === "post"
            ? "CreateThread"
            : mode === "compose"
              ? "Send"
              : "Save"
        }
        iconClassName="!mr-5"
        onClick={event => {
          if (event.detail > 1) return;

          submitForm();
        }}
      >
        {name}
      </Button>
    );
  }, [sendButtonMode, mode, disabled, submitForm]);
};

export default memo(
  SendButton,
  (prev, next) =>
    /* istanbul ignore next */
    prev.readOnly === next.readOnly &&
    prev.submitForm === next.submitForm &&
    prev.sendButtonMode === next.sendButtonMode
);

// config for react-spring
export const defaultSpring = {
  friction: 200,
  mass: 5,
  tension: 2000,
};

// config for react-flip-toolkit
export const flipSpring = {
  damping: 60,
  stiffness: 800,
};

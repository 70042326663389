import { ComponentProps } from "react";

import { Flipper, spring } from "react-flip-toolkit";

import { flipSpring } from "components/Animated/utils";

export const flipInOut = (
  el: HTMLElement,
  i: number,
  removeItem?: () => void
) =>
  spring({
    config: flipSpring,
    delay: i * 15,
    onComplete: removeItem,
    onUpdate: value => {
      if (typeof value === "number") return;
      const { opacity, translateX } = value;
      el.style.opacity = `${opacity}`;
      el.style.transform = `translate3d(${translateX}%, 0, 0)`;
    },
    values: {
      opacity: removeItem ? [1, 0] : [0, 1],
      translateX: removeItem ? [0, 100] : [0, 0],
    },
  });

export const simultaneousTransition: ComponentProps<
  typeof Flipper
>["handleEnterUpdateDelete"] = ({
  animateEnteringElements,
  animateExitingElements,
  animateFlippedElements,
  hideEnteringElements,
}) => {
  hideEnteringElements();
  animateExitingElements();
  animateFlippedElements();
  animateEnteringElements();
};

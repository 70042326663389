import { ComponentProps, RefObject, createContext } from "react";

import { PhotoSwipeItem } from ".";

type InternalAPI = {
  handleClick: (ref: RefObject<HTMLElement | null>) => void;
  open: (i: number) => void;
  remove: (ref: RefObject<HTMLElement | null>) => void;
  set: (
    ref: RefObject<HTMLElement | null>,
    data: Omit<ComponentProps<typeof PhotoSwipeItem>, "children">
  ) => void;
};

const Context = createContext({} as InternalAPI);

export default Context;

import MediaModal from "./MediaModal";

type AudioModalProps = {
  url: string;
};

const AudioModal = ({ url, ...props }: AudioModalProps) => {
  return (
    <MediaModal {...props}>
      <audio
        className="absolute max-h-full max-w-full left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
        controls
        src={url}
        style={{ maxWidth: "300px" }}
        onCanPlay={e => e.target instanceof HTMLAudioElement && e.target.play()}
      >
        Your browser does not support the
        <code>audio</code> element.
      </audio>
    </MediaModal>
  );
};

export default AudioModal;

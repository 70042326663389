import { Button } from "components/design-system/Button";
import { currentPathWithoutDrawer } from "components/routing/utils";
import { useHistory } from "react-router";
import InboxAppCard from "./InboxAppCard";
import useApp from "./hooks/useApp";

const InboxApps = ({
  appID,
  secondaryPane,
}: { appID?: string; secondaryPane?: boolean }) => {
  const app = useApp(appID);
  const history = useHistory();

  if (!app) return null;

  const onClose = secondaryPane
    ? () => history.push(currentPathWithoutDrawer())
    : undefined;

  return (
    <div className="bg-background-secondary flex flex-col justify-center relative px-20 w-full h-full">
      {!!onClose && (
        <Button
          buttonStyle="subtle"
          buttonType="text"
          className="absolute left-16 top-16 p-10 -ml-6 mr-8"
          icon="Close"
          iconSize={20}
          iconStroke={2}
          onClick={e => {
            e.stopPropagation();
            onClose?.();
          }}
        />
      )}
      <InboxAppCard app={app} />
    </div>
  );
};

export default InboxApps;

import AppTabLayout from "components/App/AppLayoutMobile/AppTabLayout";
import { Form, TextInput } from "components/design-system/Forms";
import MobileProfilePane from "components/design-system/ui/ProfilePane/MobileProfilePane";
import ViewScrollContainer from "components/design-system/ui/ViewScrollContainer";
import { usePartitionState } from "components/routing/RoutingPartition";
import {
  routeParams as getRouteParams,
  locationFromRoute,
  useRouteParams,
} from "components/routing/utils";
import ThreadView from "components/threads/ThreadPane";
import DeepSearchResults from "components/views/search/deep-search/DeepSearchResults";
import { useInstantSearch } from "components/views/search/hooks";
import SearchRecents from "components/views/search/quick-search/SearchRecents";
import { UserEdgeStatus } from "generated/graphql";
import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useHistory } from "react-router";
import useSearchStore from "store/useSearchStore";
import tw from "utils/tw";

const SearchMobileInput = () => {
  const { setValue } = useFormContext();
  const searchTerm: string = useWatch({ name: "searchTerm" });

  const history = useHistory();

  return (
    <div className="flex items-center px-16 relative w-full">
      <TextInput
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        autoFocus={true}
        className={tw(
          "bg-background-app-base-subtle text-text-action-inverse",
          "border-none grow h-32 min-w-0 select-none",
          "w-full"
        )}
        name="searchTerm"
        placeholder="Jump to..."
        wrapperClassName="grow min-w-0 !my-0"
      />

      <button
        className="ml-16 text-body text-text-action-inverse disabled:opacity-50"
        onClick={() => {
          !searchTerm && history.goBack();
          setValue("searchTerm", "");
        }}
        type="button"
      >
        Cancel
      </button>
    </div>
  );
};

const SearchMobileResults = () => {
  const { superTab } = useRouteParams();
  const { setValue } = useFormContext();
  const searchTerm: string = useWatch({ name: "searchTerm" });

  const { searchHistory } = useSearchStore(({ searchHistory }) => ({
    searchHistory,
  }));

  const { search } = useInstantSearch({ sharedState: true });

  useEffect(() => {
    if (!searchTerm?.length) return search({ match: undefined });
    search({
      limit: superTab === "search" ? 50 : 20,
      limitLocal: 10,
      match: searchTerm,
      matchMessages: searchTerm,
      userEdgeStatus: [
        UserEdgeStatus.Blocked,
        UserEdgeStatus.Connected,
        UserEdgeStatus.Hidden,
        UserEdgeStatus.None,
        UserEdgeStatus.Starred,
      ],
    });
  }, [searchTerm, search, superTab]);

  return (
    <ViewScrollContainer
      className={tw(
        "grow min-h-0 pt-16 transition-colors",
        searchTerm ? "bg-background-app-secondary" : "bg-background-app-body"
      )}
    >
      {searchTerm ? (
        <DeepSearchResults />
      ) : searchHistory.length ? (
        <SearchRecents
          className="grow min-h-0"
          setSearchTerm={searchTerm => setValue("searchTerm", searchTerm)}
        />
      ) : (
        <div className="px-12 italic text-body text-text-secondary">
          Search for conversations, groups or people.
        </div>
      )}
    </ViewScrollContainer>
  );
};

const SearchMobile = () => {
  const { route } = usePartitionState(({ route }) => ({ route }));
  const { pathname, search } = locationFromRoute(route);
  const { d, messageID, threadID } = getRouteParams({
    pathname,
    search,
  });

  const detailContent: React.ReactNode = (() => {
    if (d) {
      return <MobileProfilePane id={d} />;
    }

    return threadID ? (
      <ThreadView messageID={messageID} threadID={threadID} />
    ) : null;
  })();

  return (
    <Form className="flex w-full" onSubmit={e => e.preventDefault()}>
      <AppTabLayout
        buttons={false}
        detailChildren={detailContent}
        headerContent={<SearchMobileInput />}
      >
        <div className="flex grow min-h-0 overflow-auto w-full">
          <SearchMobileResults />
        </div>
      </AppTabLayout>
    </Form>
  );
};

export default SearchMobile;

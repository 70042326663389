import { ComponentProps } from "react";
import { useFormContext } from "react-hook-form";

import type { WorkspaceEdgeSimple } from "@utility-types";
import { Header, Main } from "components/ModalKit";
import type { ModalProps } from "components/ModalKit/Modal";
import { StandardModal } from "components/Modals";
import { Button } from "components/design-system/Button";
import { Form, Toggle } from "components/design-system/Forms";
import EmailChipsArea from "components/design-system/Forms/EmailChipsArea";
import SelectDropdown from "components/design-system/Forms/SelectDropdown";
import { Icon } from "components/design-system/icons";
import {
  FetchWorkspaceOrPreviewEdgeDocument,
  MemberRole,
  useAddWorkspaceMembersMutation,
  useConnectWithUsersMutation,
  useWorkspaceInviteLinkQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";
import CopyInviteButton from "./CopyInviteButton";
import InviteLinkSettingsModal from "./InviteLinkSettingsModal";

type FormValues = {
  addToWorkspace: boolean;
  sentTo: string[];
  workspaceID: string;
};

const WorkspacePicker = ({
  workspaces,
}: { workspaces: WorkspaceEdgeSimple[] }) => {
  const options: Pick<
    ComponentProps<typeof SelectDropdown>,
    "options"
  >["options"] = workspaces.map(w => ({
    name: w.node.name,
    label: w.node.name,
    value: w.node.id,
    iconSrc: w.node.avatarURL ?? "",
  }));

  return (
    <div className="mt-32">
      <span className="text-subhead-bold text-text-secondary">
        Choose workspace
      </span>
      <div className="mt-4">
        <SelectDropdown name="workspaceID" options={options} />
      </div>
    </div>
  );
};

const FormContent = () => {
  const { authData } = useAuthData();
  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));
  const { watch } = useFormContext<FormValues>();
  const { sentTo, addToWorkspace, workspaceID } = watch();

  const userWorkspace = authData?.workspaces.edges[0];
  const hasMultipleWorkspaces = (authData?.workspaces.edges.length ?? 0) > 1;

  const isWorkspaceAdmin = hasMultipleWorkspaces
    ? authData?.workspaces.edges.filter(e => e.node.id === workspaceID)[0]
        ?.memberRole === MemberRole.Admin
    : userWorkspace?.memberRole === MemberRole.Admin;
  const workspaceDomain = hasMultipleWorkspaces
    ? authData?.workspaces.edges.filter(e => e.node.id === workspaceID)[0]?.node
        .domains[0]
    : userWorkspace?.node.domains[0];

  const { data } = useWorkspaceInviteLinkQuery({
    fetchPolicy: "cache-and-network",
    skip: !workspaceID || !isWorkspaceAdmin,
    variables: { workspaceID },
  });

  return (
    <>
      {!!userWorkspace && (
        <>
          <div className="flex justify-between items-center">
            <div className="flex flex-col">
              <span className="text-subhead-bold text-text-secondary">
                Add to your workspace
              </span>
              <span className="text-subhead text-text-subtle">
                {`New people will be added to ${
                  !hasMultipleWorkspaces && userWorkspace
                    ? userWorkspace.node.name
                    : "your workspace"
                } after they join.`}
              </span>
            </div>
            <Toggle
              name="addToWorkspace"
              wrapperClassName="!my-0"
              labelClassName="!mb-0"
              config={{ required: false }}
            />
          </div>
          {addToWorkspace &&
            authData?.workspaces.edges &&
            hasMultipleWorkspaces && (
              <WorkspacePicker workspaces={authData?.workspaces.edges} />
            )}
        </>
      )}
      <div className={tw({ "mt-32": !!userWorkspace })}>
        <EmailChipsArea
          config={{ required: true }}
          label="Send to"
          placeholder={
            addToWorkspace && workspaceDomain
              ? `someone@${workspaceDomain}`
              : "someone@company.com"
          }
          name="sentTo"
        />
      </div>
      <div className="flex justify-between mt-8">
        <div className="flex">
          <Icon className="text-icon-secondary" icon="Info" size={12} />
          <span className="ml-4 text-caption text-text-subtle">
            You can paste a list of emails.
          </span>
        </div>
        <Button buttonStyle="secondary" disabled={!sentTo?.length}>
          Send invites
        </Button>
      </div>
      {addToWorkspace && !!userWorkspace && (
        <div className="flex justify-between items-center mt-32 border-t-1 border-border-container pt-32">
          <div className="flex flex-col">
            <span className="text-subhead-bold text-text-secondary">
              Invite link
            </span>
            {data?.workspaceInviteLink.enabled && (
              <span
                className="text-footnote text-text-brand hover:underline cursor-pointer"
                onClick={() =>
                  openModal(
                    <InviteLinkSettingsModal workspaceID={workspaceID} />
                  )
                }
              >
                Edit link settings
              </span>
            )}
          </div>
          <CopyInviteButton workspaceID={workspaceID} width="half" />
        </div>
      )}
    </>
  );
};

type Props = {
  inviteToWorkspace?: boolean;
  defaultWorkspaceID?: string;
};

const InviteToGlueModal = ({
  inviteToWorkspace,
  defaultWorkspaceID,
  ...props
}: Props & ModalProps) => {
  const [connectWithUsers] = useConnectWithUsersMutation();
  const [updateWorkspaceMembers] = useAddWorkspaceMembersMutation();

  const { authData } = useAuthData();
  const userWorkspace = authData?.workspaces.edges[0];
  const hasMultipleWorkspaces = (authData?.workspaces.edges.length ?? 0) > 1;

  const handleSubmit = ({
    addToWorkspace,
    sentTo,
    workspaceID,
  }: FormValues) => {
    const inviteWorkspaceID = !hasMultipleWorkspaces
      ? userWorkspace?.node.id
      : workspaceID;
    if (!inviteWorkspaceID) return;
    return addToWorkspace
      ? updateWorkspaceMembers({
          variables: {
            id: inviteWorkspaceID,
            members: sentTo.map(m => ({ member: m, role: MemberRole.Default })),
          },
          refetchQueries: [FetchWorkspaceOrPreviewEdgeDocument],
        })
      : connectWithUsers({ variables: { users: sentTo } });
  };

  return (
    <StandardModal {...props}>
      <Header variant="bordered">Invite to Glue</Header>
      <Main className="p-32">
        <Form<FormValues>
          useFormProps={{
            defaultValues: {
              addToWorkspace: inviteToWorkspace,
              workspaceID:
                defaultWorkspaceID ??
                (hasMultipleWorkspaces ? userWorkspace?.node.id : undefined),
            },
          }}
          onSubmit={handleSubmit}
        >
          <FormContent />
        </Form>
      </Main>
    </StandardModal>
  );
};

export default InviteToGlueModal;

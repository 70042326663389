import { App, WorkspaceAppEdge } from "@utility-types";
import { Button } from "components/design-system/Button";
import { InformationBubble } from "components/design-system/InformationBubble";
import {
  WorkspaceModal,
  WorkspaceModalTabs,
} from "components/workspace/WorkspaceModal";
import { AddButton } from "components/workspace/WorkspaceModal/apps/StaticAppItems";
import { AppConfigureMethod } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";

import AppsSection from "./AppsSection";
import RemoveAppModal from "./ConfirmRemoveAppModal";

const ThreadsApps = ({
  addApp,
  appsAdded,
  appsAvailable,
  canChangeApps = true,
  configureApp,
  name,
  removeApp,
  workspaceID,
}: {
  addApp: (app: App) => void;
  canChangeApps?: boolean;
  configureApp: (app: App) => void;
  appsAdded?: WorkspaceAppEdge[];
  appsAvailable?: WorkspaceAppEdge[];
  name: string;
  removeApp: (appID: string) => Promise<unknown>;
  workspaceID?: string | null;
}) => {
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));
  const { authData } = useAuthData();

  const formatAddedSection = (apps: WorkspaceAppEdge[]) =>
    apps.map(app => {
      const { node } = app;
      const inAppWorkspace = authData?.me.workspaceIDs.includes(
        app.workspaceID
      );

      return {
        actions:
          canChangeApps && inAppWorkspace ? (
            <>
              {app.node.configureThread === AppConfigureMethod.Configure ? (
                <Button
                  buttonStyle="simplePrimary"
                  className="mr-10"
                  icon="EditSimple"
                  iconClassName="text-interactive-subtle"
                  onClick={() => configureApp(node)}
                />
              ) : null}
              <Button
                buttonStyle="simplePrimary"
                icon="Trash"
                iconClassName="text-interactive-subtle"
                onClick={() =>
                  openModal(
                    <RemoveAppModal
                      app={app.node}
                      container={name}
                      removeApp={() => removeApp(node.id)}
                    />
                  )
                }
              />
            </>
          ) : undefined,
        description: node.description,
        icon: { alt: node.name, src: node.avatarURL },
        id: app.id,
        title: node.name,
      };
    });

  const formatAvailableSection = (apps: WorkspaceAppEdge[]) =>
    apps.map(app => ({
      actions: <AddButton />,
      description: app.node.description,
      icon: { alt: app.node.name, src: app.node.avatarURL },
      id: app.id,
      onClick: () => addApp(app.node),
      title: app.node.name,
    }));

  const handleOpenWorkspaceProfileModal = () => {
    if (!workspaceID) return;
    openModal(
      <WorkspaceModal
        defaultTab={WorkspaceModalTabs.Apps}
        workspaceID={workspaceID}
      />
    );
  };

  return (
    <>
      {appsAdded && (
        <AppsSection items={formatAddedSection(appsAdded)} title="Added" />
      )}
      {appsAvailable && canChangeApps && (
        <AppsSection
          items={formatAvailableSection(appsAvailable)}
          title="Available"
        />
      )}
      {(appsAdded?.length || appsAvailable?.length) && canChangeApps ? (
        <div
          className={tw({
            "mt-10":
              !appsAdded?.length && (!appsAvailable?.length || !canChangeApps),
          })}
        >
          <InformationBubble>
            <div className="flex items-center">
              You can configure new apps in
              <Button
                buttonStyle="simplePrimary"
                className="pl-4"
                onClick={handleOpenWorkspaceProfileModal}
              >
                Workspace Settings
              </Button>
            </div>
          </InformationBubble>
        </div>
      ) : null}
    </>
  );
};

export default ThreadsApps;

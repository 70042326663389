import tw from "utils/tw";

const ProgressBar = ({
  progress = 0,
  indeterminate,
}: { progress?: number; indeterminate?: boolean }) => (
  <div
    className={tw("w-140 h-4 bg-background-secondary rounded-md", {
      "animate-pulse": indeterminate,
    })}
  >
    <div
      className="bg-background-action rounded-md h-4"
      style={{ width: `${Math.min(progress, 100)}%` }}
    />
  </div>
);

export default ProgressBar;

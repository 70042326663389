import { uniqBy } from "lodash-es";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";

import { Recipient, nodeAs } from "@utility-types";
import { useFetchUserEdgeQuery } from "generated/graphql";

import { SmallRecipientProfileItem } from "components/ProfileItem";
import { Button } from "components/design-system/Button";
import { Icon } from "components/design-system/icons";
import { useIsGroupMember } from "hooks/group/useIsGroupMember";
import useAuthData from "hooks/useAuthData";
import useComponentMounted from "hooks/useComponentMounted";
import env from "utils/processEnv";
import isGlueAIRecipient from "utils/thread/isGlueAIRecipient";
import tw from "utils/tw";

import { State } from "./DraftReducer";
import { useSuggestRecipients } from "./hooks";
import { DraftForm } from "./types";

type Props = {
  compose: State;
  mention?: Recipient;
  clearMention: () => void;
};

const ThreadRecipientSuggestions = ({
  compose,
  mention,
  clearMention,
}: Props): JSX.Element | null => {
  const { authData, authReady } = useAuthData();
  const isGroupMember = useIsGroupMember();
  const isMounted = useComponentMounted();
  const [suggestions, setSuggestions] = useState<Recipient[]>([]);
  const [rejectedIDs] = useState<Set<string>>(new Set());
  const { setValue } = useFormContext<DraftForm>();

  // Clear suggestions

  const clearMentionRef = useRef(clearMention);
  clearMentionRef.current = clearMention;

  const clearSuggestions = useCallback(() => {
    clearMentionRef.current();
    suggestions.forEach(s => rejectedIDs.add(s.id));
    setSuggestions([]);
  }, [rejectedIDs, suggestions]);

  const removeSuggestion = useCallback(
    (recipient: Recipient) => {
      if (mention?.id === recipient.id) clearMentionRef.current();
      rejectedIDs.add(recipient.id);
      setSuggestions(suggestions =>
        suggestions.filter(s => s.id !== recipient.id)
      );
    },
    [mention?.id, rejectedIDs]
  );

  const recipients = compose.draftForm.recipients;

  const addRecipient = useCallback(
    (recipient: Recipient) => {
      const newRecipients = uniqBy([...recipients, recipient], "id").filter(
        r => r.id !== authData?.me.id
      );
      setValue("recipients", newRecipients, {
        shouldDirty: true,
        shouldTouch: true,
      });
      removeSuggestion(recipient);
    },
    [authData?.me.id, recipients, removeSuggestion, setValue]
  );

  // Suggest recipients

  const { suggestRecipients, recipientsLoading: _ } =
    useSuggestRecipients(compose);

  const prevTextRef = useRef(compose.draftForm.message.text.length);

  const onTextChange = useDebouncedCallback((text: string) => {
    if (Math.abs(text.length - prevTextRef.current) < 100) return;
    prevTextRef.current = text.length;
    suggestRecipients?.().then(recipients => {
      setSuggestions(suggestions =>
        uniqBy(
          [...suggestions, ...recipients.filter(s => !rejectedIDs.has(s.id))],
          "id"
        )
      );
    });
  }, 350);

  const onTextChangeRef = useRef(onTextChange);
  onTextChangeRef.current = onTextChange;

  useEffect(() => {
    if (!compose.draftForm.message.text) return;
    onTextChangeRef.current(compose.draftForm.message.text);
  }, [compose.draftForm.message.text]);

  // Add mention to suggestions

  useEffect(() => {
    if (!mention) return;
    (async () => {
      if (recipients.find(r => r.id === mention.id)) return;

      for (const group of recipients.filter(r => r.__typename !== "User")) {
        if (await isGroupMember(group, mention)) {
          return;
        }
      }

      if (!isMounted.current) return;

      // ticket #5022 stipulates that we auto-add mentions when composing, so we'll do that here:
      addRecipient(mention);

      // // the suggestion UI is really nice, though, so let's not delete this; we might want it later.
      // setSuggestions(suggestions => uniqBy([...suggestions, mention], "id"));
    })();
  }, [addRecipient, isGroupMember, isMounted, mention, recipients]);

  // Remove redundant user suggestions

  const suggestionsRef = useRef(suggestions);
  suggestionsRef.current = suggestions;

  useEffect(() => {
    const groups = recipients.filter(r => r.__typename !== "User");
    const redundant: Set<Recipient> = new Set();

    Promise.all(
      suggestionsRef.current
        .filter(r => r.__typename === "User")
        .map(async user => {
          for (const group of groups) {
            if (await isGroupMember(group, user)) {
              redundant.add(user);
              return;
            }
          }
        })
    ).then(() => {
      if (!isMounted.current || redundant.size === 0) return;
      setSuggestions(suggestions => suggestions.filter(s => !redundant.has(s)));
    });
  }, [isGroupMember, isMounted, recipients]);

  // Glue AI

  const { data } = useFetchUserEdgeQuery({
    fetchPolicy: authReady ? "cache-first" : "cache-only",
    skip: !authData?.me.id || !env.glueAIBotID,
    variables: { id: `${env.glueAIBotID}-${authData?.me.id}` },
  });

  const glueAI = nodeAs(data?.node, ["UserEdge"])?.node;
  const otherRecipients = recipients.filter(r => r.id !== authData?.me.id);
  if (suggestions.length === 0) {
    // Suggest Glue AI if no other suggestions
    if (glueAI && !rejectedIDs.has(glueAI.id) && otherRecipients.length === 0) {
      suggestions.push(glueAI);
    } else {
      return null;
    }
  } else if (
    // Remove Glue AI if we have recipients or other suggestions
    suggestions[0]?.id === env.glueAIBotID &&
    (suggestions.length > 1 || otherRecipients.length > 0)
  ) {
    suggestions.shift();
  }

  return (
    <div className="w-full bottom-0 px-10 pb-18 animate-fadeIn">
      <div className="relative flex flex-row justify-between pr-8 rounded-lg border-border-magic border-1">
        <div
          className={tw(
            "relative flex flex-row items-center gap-6 h-56 pl-12 overflow-x-auto",
            "after:content-[''] after:sticky after:z-1 after:right-0 after:h-32 after:px-16",
            "after:bg-[linear-gradient(to_left,rgb(var(--color-background-body)),transparent_90%)] after:pointer-events-none"
          )}
        >
          {!isGlueAIRecipient(suggestions) && (
            <Icon
              className="text-icon-magic hidden md:block"
              icon="SparkleFilled"
              size={16}
            />
          )}

          {suggestions.map(suggestion => (
            <div key={suggestion.id} className="flex flex-row">
              <div className="flex flex-row px-4 bg-background-subtle rounded-l-lg">
                <SmallRecipientProfileItem recipient={suggestion} />
                <Button
                  buttonType="icon"
                  buttonStyle="icon-secondary"
                  className="ml-4"
                  icon="Close"
                  iconSize={16}
                  onClick={() => removeSuggestion(suggestion)}
                />
              </div>
              <Button
                buttonStyle="none"
                buttonType="none"
                className={tw(
                  "ml-1 px-8 text-caption-bold bg-background-magic-subtle text-text-magic-inverse rounded-r-lg",
                  "hover:bg-background-magic-subtle-hover hover:text-text-magic-inverse-hover"
                )}
                onClick={() => addRecipient(suggestion)}
              >
                Add
              </Button>
            </div>
          ))}
        </div>
        <Button
          buttonStyle="icon-secondary"
          buttonType="icon"
          className="!p-2 justify-self-end"
          icon="Close"
          iconSize={16}
          onClick={clearSuggestions}
        />
      </div>
    </div>
  );
};

export default memo(ThreadRecipientSuggestions);

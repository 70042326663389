import { createZustandStoreContext } from "create-zustand-store-context";

type InboxSidebarSectionsStore = {
  swipedOpenItemId: string | undefined;
};

const defaultState: InboxSidebarSectionsStore = {
  swipedOpenItemId: undefined,
};

const {
  storeContext,
  useState: useInboxSidebarSectionsState,
  useStore: useInboxSidebarSectionsStore,
} = createZustandStoreContext<InboxSidebarSectionsStore>({
  defaultState,
});

const InboxSidebarSectionsStoreContext = storeContext;

function InboxSidebarSectionsProvider({ children }: WithChildren) {
  const currentStore = useInboxSidebarSectionsStore();

  return (
    <InboxSidebarSectionsStoreContext.Provider value={currentStore}>
      {children}
    </InboxSidebarSectionsStoreContext.Provider>
  );
}

export { InboxSidebarSectionsProvider, useInboxSidebarSectionsState };

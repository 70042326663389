import { MutableRefObject, useEffect, useRef } from "react";

export default function useComponentMounted(): MutableRefObject<boolean> {
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true; // required because of StrictMode

    return () => {
      mounted.current = false;
    };
  }, []);
  return mounted;
}

import { useEffect } from "react";

import { useFormContext } from "react-hook-form";

import useRequiredFields from "./useRequiredFields";

const useRequiredErrors = ({ name }: { name: string }) => {
  const {
    clearErrors,
    formState: { errors, touchedFields },
    getValues,
    setError,
  } = useFormContext();
  const requiredFields = useRequiredFields();
  const value: string = getValues(name);

  useEffect(() => {
    if (
      !Object.keys(touchedFields).includes(name) ||
      !requiredFields.includes(name) ||
      !value
    )
      return;

    if (!Object.keys(errors).includes(name) && !value.length) {
      setError(name, {
        message: "This field is required.",
        type: "required",
      });
    } else if (
      Object.keys(errors).includes(name) &&
      value.length &&
      errors[name]?.type === "required"
    ) {
      clearErrors(name);
    }
  }, [
    clearErrors,
    errors,
    name,
    requiredFields,
    setError,
    touchedFields,
    value,
  ]);
};

export default useRequiredErrors;

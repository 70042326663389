import { ThreadActionInput } from "generated/graphql";

import { useCallback } from "react";
import useMasonrySessionStore from "./useMasonrySessionStore";

const usePerformThreadAction = () => {
  const { startSession } = useMasonrySessionStore(({ startSession }) => ({
    startSession,
  }));

  const perform = useCallback(
    (action: ThreadActionInput & { path: string }) => {
      const sessionInput = {
        ...action,
        type: "threadAction",
      };

      const input = {
        path: action.path,
        requestType: "threadAction",
      };

      startSession(sessionInput, input);
    },
    [startSession]
  );

  return { perform };
};

export default usePerformThreadAction;

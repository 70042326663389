const newSyntheticEvent = (name: string) => {
  const event = new Event(name);

  return {
    ...event,
    isDefaultPrevented: () => false,
    isPropagationStopped: () => false,
    nativeEvent: event,
    persist: () => undefined,
  };
};

export default newSyntheticEvent;

import { ComponentProps, useCallback } from "react";

import { ModalProps } from "components/ModalKit/Modal";
import {
  useUpdateSettingsMutation,
  useUserSettingsQuery,
} from "generated/graphql";
import useLocalSettingsStore from "store/useLocalSettingsStore";
import useModalStore from "store/useModalStore";

import PreferencesForm from "./PreferencesForm";

type PreferencesFormType = ComponentProps<typeof PreferencesForm>;
type DataType = Pick<PreferencesFormType, "data">;

const PreferencesModal = (props: ModalProps) => {
  const { data, loading } = useUserSettingsQuery({
    fetchPolicy: "cache-and-network",
  });

  const [updateSettings] = useUpdateSettingsMutation({
    errorPolicy: "all",
  });

  const { closeModal } = useModalStore(({ closeModal }) => ({
    closeModal,
  }));

  const { colorScheme, enterKeyBehavior } = useLocalSettingsStore(
    ({ colorScheme, enterKeyBehavior }) => ({
      colorScheme,
      enterKeyBehavior,
    })
  );

  const onUpdatePreferences = useCallback(
    ({
      colorScheme,
      enterKeyBehavior,
      notifyMessages,
      chatModel,
    }: DataType["data"]) => {
      if (!data || !notifyMessages) return;

      useLocalSettingsStore.setState({ colorScheme, enterKeyBehavior });

      const payload = chatModel
        ? {
            llmSettings: {
              ...data.settings.llmSettings,
              chatModel,
            },
            notifyMessages,
          }
        : { notifyMessages };

      return updateSettings({
        optimisticResponse: {
          updateSettings: {
            ...data.settings,
            ...payload,
          },
        },
        variables: {
          settings: payload,
        },
      }).then(() => closeModal(`${props.modalId}`));
    },
    [closeModal, data, props.modalId, updateSettings]
  );

  const formData: DataType["data"] = {
    colorScheme,
    chatModel: data?.settings.llmSettings.chatModel,
    enterKeyBehavior,
    notifyMessages: data?.settings.notifyMessages,
  };

  return (
    <PreferencesForm
      data={formData}
      loading={loading}
      onUpdate={onUpdatePreferences}
      {...props}
    />
  );
};

export default PreferencesModal;

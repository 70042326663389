import { useCallback } from "react";

import { ApolloCache, StoreObject, useApolloClient } from "@apollo/client";

export const useCacheEvict = (): {
  evictNode: (node: StoreObject, cache?: ApolloCache<unknown>) => void;
  pruneNodeCache: (cache?: ApolloCache<unknown>) => void;
} => {
  const apolloClient = useApolloClient();

  const pruneNodeCache = useCallback(
    (
      cache: ApolloCache<unknown> = apolloClient.cache,
      args?: Record<string, unknown>
    ) => {
      // Safe to remove node cache entirely because we have a read type policy
      // that returns a ref to the right object if the query isn't in cache.
      cache.evict({
        args: args,
        broadcast: false,
        fieldName: "node",
        id: "ROOT_QUERY",
      });
      cache.gc();
    },
    [apolloClient.cache]
  );

  const evictNode = useCallback(
    (node: StoreObject, cache: ApolloCache<unknown> = apolloClient.cache) => {
      // Can't do this in update(cache) or will cause refetch:
      //    https://github.com/apollographql/apollo-client/issues/6746
      cache.performTransaction(c => {
        c.evict({ broadcast: false, id: c.identify(node) });
        pruneNodeCache(c, { id: node.id });
      });
    },
    [apolloClient.cache, pruneNodeCache]
  );

  return { evictNode, pruneNodeCache };
};

export default useCacheEvict;

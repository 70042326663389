import { memo, useCallback, useEffect, useMemo, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Message, ThreadEdge } from "@utility-types";
import glueLogo from "assets/icons-png/glue-logo.png";
import { routeToThread } from "components/routing/utils";
import useComponentMounted from "hooks/useComponentMounted";
import { markdownToPlainText } from "md";
import useAppStateStore from "store/useAppStateStore";

import CreateNotification, {
  GetNotificationInstance,
} from "./CreateNotification";
import ShowNotificationFavicon from "./ShowNotificationFavicon";

type Props = {
  getInstance?: (M: GetNotificationInstance) => void;
  onShow?: () => void;
};

type MemoizedProps = Props & {
  changePath: (threadID: string) => void;
  activeNotification:
    | {
        message: Message;
        threadID: string;
        threadEdge: ThreadEdge;
      }
    | undefined;
  clearNotification: () => void;
  urlPath: string;
};

const BrowserNotification = ({
  getInstance: getInstanceProp,
  onShow,
}: Props): JSX.Element | null => {
  const { activeNotification, setState } = useAppStateStore(
    ({ activeNotification, setState }) => ({
      activeNotification,
      setState,
    })
  );

  const clearNotification = useCallback(() => {
    setState({ activeNotification: undefined });
  }, [setState]);

  const history = useHistory();
  const location = useLocation();

  const urlPath = location.pathname.replace("/", "");
  const changePath = (threadID: string) => {
    const threadPath = routeToThread({
      threadID,
      superTab: "inbox",
      to: "primary",
    });
    history.push(threadPath);
  };

  return (
    <MemoizedBrowserNotification
      changePath={changePath}
      getInstance={getInstanceProp}
      onShow={onShow}
      activeNotification={activeNotification}
      clearNotification={clearNotification}
      urlPath={urlPath}
    />
  );
};

const MemoizedBrowserNotification = memo(
  ({
    changePath,
    getInstance,
    onShow,
    activeNotification,
    clearNotification,
    urlPath,
  }: MemoizedProps): JSX.Element => {
    const favicon = useMemo(() => new ShowNotificationFavicon(), []);
    const isMounted = useComponentMounted();
    const getInstanceRef = useRef<GetNotificationInstance | null>(null);
    const instancesRef = useRef(new Map<string, Notification | undefined>());

    const getInstances = [
      (method: GetNotificationInstance) => {
        getInstanceRef.current = method;
      },
    ];

    const clearInstance = useCallback((key: string) => {
      instancesRef.current.get(key)?.close();
      instancesRef.current.delete(key);
    }, []);

    const clearCurrentNotification = useCallback(() => {
      favicon.show(false);
      clearNotification();
      clearInstance(urlPath);
    }, [urlPath, clearNotification, clearInstance, favicon]);

    const setInstancesRef = (
      key: string,
      instance: Notification | undefined
    ) => {
      clearInstance(key);
      instancesRef.current.set(key, instance);
    };

    if (getInstance) {
      getInstances.push(getInstance);
    }

    useEffect(
      () =>
        useAppStateStore.subscribe(
          ({ appStatus }) => appStatus,
          appStatus => {
            if (appStatus === "inactive") return;
            clearCurrentNotification();
          }
        ),
      [clearCurrentNotification]
    );

    if (!activeNotification) {
      return <div data-testid="no-browser-notification" />;
    }

    const { message, threadID, threadEdge } = activeNotification;

    return (
      <CreateNotification
        getNotificationInstance={getInstances}
        onClick={(e: Event) => {
          const notification = e.target as Notification | null | undefined;
          if (!notification) return;
          const threadID = notification.data?.threadID;
          if (typeof threadID !== "string") return;

          window.focus();
          changePath(threadID);
          clearInstance(threadID);
        }}
        onClose={() => {
          isMounted.current && clearCurrentNotification();
        }}
        onShow={() => {
          const instance = getInstanceRef.current?.(message.id);
          setInstancesRef(threadID, instance);

          onShow?.();
        }}
        options={{
          body: `${message.user.name}: ${markdownToPlainText(message.text)}`,
          data: { threadID },
          icon: glueLogo,
          requireInteraction: true,
          tag: message.id,
          title: threadEdge
            ? `New message in "${threadEdge.node.subject}"`
            : "New message",
        }}
        timeout={0}
      />
    );
  },
  /* istanbul ignore next */
  (prev, next) =>
    prev.urlPath === next.urlPath &&
    prev.activeNotification?.message?.id ===
      next.activeNotification?.message?.id
);

export default BrowserNotification;

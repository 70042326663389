import { useRef, useState } from "react";
import { useFormContext } from "react-hook-form";

import Avatar from "components/design-system/Avatar/Avatar";
import { Button } from "components/design-system/Button";

type FormValues = {
  avatarURL?: string;
};

const ProfileImage = ({
  onChange,
  name,
  type = "user",
}: {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  type?: "user" | "workspace";
}) => {
  const [loading, setLoading] = useState(false);
  const { watch } = useFormContext<FormValues>();
  const { avatarURL } = watch();
  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.files?.length && setLoading(true);
    onChange(e);
  };

  return (
    <div className="flex h-36">
      <input
        ref={inputFileRef}
        accept="image/png, image/gif, image/jpg, image/jpeg"
        className="hidden"
        type="file"
        name="avatarURL"
        onChange={handleChange}
      />
      <div
        className="flex cursor-pointer group/profile-image"
        onClick={() => (!loading ? inputFileRef.current?.click() : null)}
      >
        <Avatar
          className="!my-0"
          rounded="rounded-md text-icon-secondary"
          avatarURL={avatarURL && !loading ? avatarURL : undefined}
          iconProps={{
            icon: type === "user" ? "User" : "Workspace",
          }}
          name={name}
          loading={loading}
        />
        <img
          className="hidden"
          src={avatarURL}
          onLoad={() => setLoading(false)}
        />
        <Button
          buttonStyle="subtle"
          buttonType="text"
          className="text-caption text-text-secondary hover:text-text-secondary ml-8 group-hover/profile-image:underline"
          type="button"
          disabled={loading}
        >
          {avatarURL || loading ? "Change photo" : "Add photo"}
        </Button>
      </div>
    </div>
  );
};

export default ProfileImage;

import { useApolloClient } from "@apollo/client";
import { useState } from "react";
import { SubmitHandler, useFormContext } from "react-hook-form";
import { useHistory } from "react-router";

import { Recipient } from "@utility-types";
import { CloseButton, Footer, Header, Main } from "components/ModalKit";
import { ModalProps } from "components/ModalKit/Modal";
import { StandardModal } from "components/Modals";
import type { RecipientValue } from "components/RecipientsSelect/types";
import { StepCounter } from "components/StepCounter";
import { Button } from "components/design-system/Button";
import { Form } from "components/design-system/Forms";
import { GroupTabs, groupPath } from "components/routing/utils";
import {
  FetchWorkspaceOrPreviewEdgeDocument,
  JoinableBy,
  MemberRole as MemberRoleValue,
  PersistentChatsDocument,
  WorkspacesAndGroupsListDocument,
  useCreateGroupMutation,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import uniqBy from "lodash-es/uniqBy";
import useAppStateStore from "store/useAppStateStore";
import useModalStore from "store/useModalStore";
import filterActiveQueries from "utils/filterActiveQueries";
import { formatGroupName } from "utils/group/formatGroupName";
import tw from "utils/tw";
import GroupSetup from "./GroupSetup";
import GroupVisibility from "./GroupVisibility";

type FormValues = {
  name: string;
  emoji: string;
  members: RecipientValue[];
  admins: RecipientValue[];
  visibility: "open" | "closed";
  workspaceID?: string;
};

const FormContent = ({
  modalID,
  onSubmit,
}: { modalID?: string; onSubmit: SubmitHandler<FormValues> }) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const [step, setStep] = useState(1);
  const { closeModal } = useModalStore(({ closeModal }) => ({ closeModal }));

  const { watch, handleSubmit } = useFormContext<FormValues>();
  const { name, emoji } = watch();

  const handleNext = () => {
    if (step < totalSteps) {
      setStep(s => s + 1);
      return;
    }
    handleSubmit(onSubmit)();
  };

  const isSubmitDisabled = !name?.length;

  const groupName = formatGroupName({
    name: `${emoji}${name}`,
  }).nameWithEmoji;

  const isFirstPage = step === 1;

  const BackButtonMobile = () => (
    <Button
      className="h-24 w-24 mr-8 !px-0"
      icon="ChevronLeft"
      iconSize={24}
      iconClassName="-ml-2"
      buttonStyle="subtle"
      onClick={() => setStep(s => s - 1)}
    />
  );

  const BackButtonDesktop = () => (
    <Button
      icon="ArrowLeft"
      iconSize={20}
      buttonStyle="secondary"
      onClick={() => setStep(s => s - 1)}
    >
      {breakpointMD ? "Back" : undefined}
    </Button>
  );

  const NextButton = () => (
    <Button
      className={tw({ "!px-2": !breakpointMD })}
      buttonStyle={breakpointMD ? "primary" : "simplePrimary"}
      onClick={handleNext}
      disabled={isSubmitDisabled}
      type="button"
    >
      {step < totalSteps ? "Next" : breakpointMD ? "Create group" : "Create"}
    </Button>
  );

  return (
    <>
      <Header className="!px-16 md:!px-32" variant="bordered">
        <>
          {!breakpointMD ? (
            !isFirstPage ? (
              <BackButtonMobile />
            ) : (
              <CloseButton
                className="h-24 w-24 mr-8"
                handleClose={() => closeModal(modalID)}
              />
            )
          ) : null}
          <div className="flex justify-center md:justify-start grow overflow-hidden pr-4">
            <span className="truncate">
              {step === 1 ? "Create group" : groupName}
            </span>
          </div>
          <div className="hidden md:flex grow" />
          {!breakpointMD && <NextButton />}
          <StepCounter
            className="-mr-12 md:mr-16 hidden md:block"
            step={step}
            total={totalSteps}
          />
        </>
      </Header>
      <Main className="px-16 py-32 md:p-32">
        {step === 1 && <GroupSetup />}
        {step === 2 && <GroupVisibility />}
      </Main>
      <Footer className="hidden md:block" flexboxClassName="flex-row w-full">
        {!isFirstPage && <BackButtonDesktop />}
        <div className="flex grow" />
        <Button buttonStyle="subtle" onClick={() => closeModal(modalID)}>
          Cancel
        </Button>
        <NextButton />
      </Footer>
    </>
  );
};

type MemberRole = { member: string; role: MemberRoleValue };

const memberRoles = (
  recipients: Recipient[],
  role = MemberRoleValue.Default
): MemberRole[] =>
  uniqBy(
    recipients.map(r => ({ member: r.id, role })),
    "member"
  );

type Props = { workspaceID?: string } & ModalProps;

const totalSteps = 2;
const CreateGroupModal = ({
  workspaceID: workspaceIDProp,
  ...props
}: Props) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const { authData } = useAuthData();
  const { closeModal } = useModalStore(({ closeModal }) => ({ closeModal }));
  const apolloClient = useApolloClient();
  const history = useHistory();

  const [createGroup] = useCreateGroupMutation({
    refetchQueries: filterActiveQueries(apolloClient, [
      FetchWorkspaceOrPreviewEdgeDocument,
      PersistentChatsDocument,
      WorkspacesAndGroupsListDocument,
    ]),
  });

  const createNewGroup = ({
    name,
    emoji,
    admins,
    members = [],
    visibility,
    workspaceID: workspaceIDProp,
  }: FormValues) => {
    const groupName = formatGroupName({
      name: `${emoji}${name}`,
    }).nameWithEmoji;
    const workspaceID = workspaceIDProp ?? authData?.me?.workspaceIDs[0];
    createGroup({
      variables: {
        input: {
          name: groupName,
          workspaceID,
          joinableBy:
            visibility === "open" ? JoinableBy.Workspace : JoinableBy.Approval,
          members: [
            ...memberRoles(admins, MemberRoleValue.Admin),
            ...memberRoles(members, MemberRoleValue.Default),
          ],
        },
      },
    }).then(({ data }) => {
      if (data?.createGroup) {
        closeModal(props.modalId);
        history.push(groupPath(data.createGroup.id, undefined, GroupTabs.Chat));
      }
    });
  };

  return (
    <StandardModal closeButton={breakpointMD} {...props}>
      <Form<FormValues>
        useFormProps={{
          defaultValues: {
            name: "",
            emoji: "",
            admins: [authData?.me],
            visibility: "open",
            workspaceID: workspaceIDProp,
          },
        }}
      >
        <FormContent modalID={props.modalId} onSubmit={createNewGroup} />
      </Form>
    </StandardModal>
  );
};

export default CreateGroupModal;

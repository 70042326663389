import { ReactComponent as GlueLogo } from "assets/glue/glue-drop-full-color.svg";

const EmptyView = () => {
  return (
    <div className="flex items-center justify-center h-full w-full bg-background-app-secondary">
      <GlueLogo className="bg-contain bg-no-repeat w-[81px] h-[108px]" />
    </div>
  );
};

export default EmptyView;

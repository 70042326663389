import { useSpring } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import { defaultSpring } from "components/Animated/utils";
import { NavigationStateContext } from "components/Navigation/NavigationState";
import useNativeHaptics from "hooks/native/useNativeHaptics";
import useInboxThreadActions from "hooks/thread/useInboxThreadActions";
import { useCallback, useContext, useEffect, useState } from "react";
import { SwipeProps } from "../types";

type SwipingState = "left" | "right" | "rest";

const OPEN_THRESHOLD = 0;
const LEFT_SWIPE_THRESHOLD = 58;

export function useSwipe({
  rightSwipe = false,
  rightSwipeAndDismiss,
  leftLimit,
  setSwipedOpenItemId,
  swipedOpenItemId,
  rightLimit,
  threadID,
}: Omit<Partial<SwipeProps>, "itemData" | "dismissOnRightSwipe"> & {
  /** enables right swipe with dismiss action e.g. delete draft or archive  */
  rightSwipe: boolean;
  rightSwipeAndDismiss: (resetSwipe: () => void) => boolean;
  /** the max width that is revealed when swiping left  */
  leftLimit: number;
  rightLimit: number;
  threadID: string;
}) {
  const inboxThreadActions = useInboxThreadActions();
  const { sidebarTransitioning } = useContext(NavigationStateContext);

  const leftLimitPositiveMargin = leftLimit + LEFT_SWIPE_THRESHOLD;

  const { lightImpactHaptic } = useNativeHaptics();
  const [isDragging, setIsDragging] = useState(false);
  const [shouldImpactHaptic, setShouldImpactHaptic] = useState(false);
  const [swipeState, setSwipeState] = useState<SwipingState>("rest");

  const springCallback = (onRest: boolean) => {
    const pos = x.get();

    if (pos !== 0) {
      setSwipeState(pos > OPEN_THRESHOLD ? "right" : "left");
    } else {
      setSwipeState("rest");
    }
    onRest &&
      setSwipedOpenItemId?.(
        Math.abs(pos) > OPEN_THRESHOLD ? threadID : undefined
      );
  };

  const [{ x }, api] = useSpring(() => ({
    config: { ...defaultSpring },
    onChange: () => springCallback(false),
    onRest: () => springCallback(true),
    x: 0,
  }));

  const resetSwipe = useCallback(() => api.start({ x: 0 }), [api]);

  const bind = useDrag(
    ({ down, movement: [mx] }) => {
      if (!inboxThreadActions) {
        return;
      }

      // Prevent drag if sidebar is dragged
      if (sidebarTransitioning) return resetSwipe();

      let dismiss = false;
      let position = mx;
      const swipingLeft = position < 0;
      const swipeLeftLimit = position <= leftLimit;
      const swipeRightLimit = position >= rightLimit;
      const autoLeftLimit = leftLimit > position - LEFT_SWIPE_THRESHOLD;

      // Update the press state and check right and left limit
      setIsDragging(down && x.get() !== 0);

      // On left swipe show the actions and stop the animation
      if (swipingLeft) {
        if (autoLeftLimit && down) {
          setShouldImpactHaptic(true);
        }

        // On the left limit or close enough and not touching just
        // move it to the left limit
        if ((swipeLeftLimit || autoLeftLimit) && !down) {
          position = leftLimit;
        } else if (leftLimitPositiveMargin < position && !down) {
          position = 0;
        }
        if (!down) {
          setShouldImpactHaptic(false);
        }
      } else {
        // Reset the flag so we track the swipe when still pressing
        setShouldImpactHaptic(false);
        if (swipeRightLimit) {
          // on right limit give some feedback
          setShouldImpactHaptic(true);

          if (!down) {
            // still on right limit and release, so call the func.
            dismiss = rightSwipeAndDismiss(resetSwipe);

            if (dismiss === false) return;

            position = rightLimit * 100;

            setShouldImpactHaptic(false);
          }
        } else if (!down) {
          // If not on limit and release then swipe back
          position = 0;
          setShouldImpactHaptic(false);
        }
      }

      api.start({ immediate: down && !dismiss, x: position });
    },
    {
      axis: "x",
      bounds: {
        left: isDragging ? leftLimitPositiveMargin : leftLimit,
        right: rightSwipe ? Number.POSITIVE_INFINITY : 0,
      },
      delay: true,
      eventOptions: {
        passive: true,
      },
      from: () => [x.get(), 0],
      preventScroll: true,
      rubberband: x.get() < 0 || !rightSwipe,
    }
  );

  const pos = x.get();

  useEffect(() => {
    if (!shouldImpactHaptic) return;

    lightImpactHaptic();
  }, [shouldImpactHaptic, lightImpactHaptic]);

  useEffect(() => {
    if (
      swipedOpenItemId &&
      pos < 0 &&
      swipedOpenItemId !== threadID &&
      !isDragging
    ) {
      resetSwipe();
    }
  }, [isDragging, pos, resetSwipe, swipedOpenItemId, threadID]);

  return {
    bind,
    pos,
    resetSwipe,
    swipeState,
    x,
  };
}

import { Channel, PushNotifications } from "@capacitor/push-notifications";

import { isNative, isNativeAndroid } from "utils/platform";

export const hasDefaultPermission = async () =>
  isNative()
    ? (await PushNotifications.checkPermissions()).receive === "prompt"
    : window.Notification?.permission === "default";

export const hasGrantedPermission = async () =>
  isNative()
    ? (await PushNotifications.checkPermissions()).receive === "granted"
    : window.Notification?.permission === "granted";

export const requestPermission = () =>
  isNative()
    ? requestNativePermission()
    : window.Notification?.requestPermission();

const requestNativePermission = () => {
  if (isNativeAndroid()) {
    /*
     * Create an Android notification channel with high importance that will be
     * used as the default channel. This will cause notifications to appear in
     * a popup rather than going to the system tray.
     *
     * This needs to be run just once when the application first starts up
     * after installation, but is also safe to rerun.
     */
    const channel: Channel = {
      description: "The default channel for Glue notifications.",
      id: "com.gluegroups.notification-channel.default",
      importance: 4, // IMPORTANCE_HIGH: Higher notification importance: shows everywhere, makes noise and peeks. May use full screen intents.
      name: "Default",
    };
    PushNotifications.createChannel(channel);
  }

  PushNotifications.requestPermissions().then(result => {
    if (result.receive === "granted") {
      PushNotifications.register();
    }
  });
};

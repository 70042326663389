import {
  Recipient,
  Sizes,
  WorkspacePreview,
  WorkspaceSimple,
} from "@utility-types";
import WorkspaceProfileItem from "components/workspace/WorkspaceProfileItem";

import { RecipientProfileItem } from "components/ProfileItem";

type Props = {
  target:
    | {
        size?: Exclude<Sizes, "tiny">;
        workspace: WorkspaceSimple | WorkspacePreview | undefined;
      }
    | { recipient: Recipient; size?: Exclude<Sizes, "tiny" | "small"> };
};

export const ProfileItemHeader = ({
  children,
  target,
}: WithChildren<Props>): JSX.Element => (
  <>
    {"workspace" in target ? (
      <WorkspaceProfileItem {...target} />
    ) : (
      <RecipientProfileItem {...target} />
    )}
    {children}
  </>
);

import { useCallback, useEffect, useRef, useState } from "react";

import { useApolloClient } from "@apollo/client";
import { useAbortController } from "use-abort-controller-hook";

import {
  SubjectSuggestionDocument,
  SubjectSuggestionQuery,
  SubjectSuggestionQueryVariables,
} from "generated/graphql";

import useComponentMounted from "hooks/useComponentMounted";
import { State, formToInput } from "../DraftReducer";

export const minSuggestLen = 40;

type Props = Pick<Partial<State>, "draftForm" | "draftID">;

const useSuggestSubject = ({ draftForm, draftID }: Props) => {
  const abortController = useAbortController();
  const apolloClient = useApolloClient();
  const isMounted = useComponentMounted();

  const [loading, setLoading] = useState(false);

  const variablesRef = useRef<SubjectSuggestionQueryVariables>();

  const textPresent =
    draftForm?.message?.text && draftForm.message.text.length >= minSuggestLen;

  if (textPresent) {
    variablesRef.current = {
      input: formToInput(draftForm),
      replyToMessageID: draftForm?.replyToMessage?.id,
    };
  } else if (draftForm?.replyToMessage) {
    variablesRef.current = { replyToMessageID: draftForm?.replyToMessage.id };
  } else {
    variablesRef.current = undefined;
  }

  const suggestEnabled = !!variablesRef.current;

  const suggestSubject = useCallback(() => {
    setLoading(true);
    return apolloClient
      .query<SubjectSuggestionQuery>({
        query: SubjectSuggestionDocument,
        context: {
          batch: false,
          fetchOptions: abortController,
          notifyErrors: false,
        },
        fetchPolicy: "no-cache",
        variables: variablesRef.current,
      })
      .then(({ data }) => data?.draftSuggestion?.subject)
      .finally(() => {
        if (!isMounted.current) return;
        setLoading(false);
      });
  }, [abortController, apolloClient, isMounted]);

  useEffect(() => {
    if (!draftID) return;
    return () => abortController.abort();
  }, [abortController, draftID]);

  return {
    suggestSubject: suggestEnabled ? suggestSubject : undefined,
    loadingSubject: loading,
  };
};

export default useSuggestSubject;

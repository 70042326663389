import analytics from "analytics";
import { FeedbackProvided } from "analytics/events/feedback";
import useGlueAIFeedback from "components/GlueAIFeedback/hooks/useGlueAIFeedback";
import { FeedbackType } from "components/GlueAIFeedback/types";
import { Button } from "components/design-system/Button";
import { SUBJECT_MARKDOWN } from "md/util";
import { useState } from "react";

type Props = {
  message: {
    feedback_type?: FeedbackType;
    text?: string;
    thread_id?: string;
  };
};

const MessageFeedback = ({ message }: Props) => {
  const [feedbackProvided, setFeedbackProvided] = useState(false);
  const { sendFeedback } = useGlueAIFeedback();

  let feedbackPrompt = "Was this helpful?";
  if (message.feedback_type === FeedbackType.THREAD_SUMMARY) {
    feedbackPrompt = "Was this summary accurate?";
  }

  const onThumbsDownClick = () => {
    setFeedbackProvided(true);

    const subject = "Thread summarization feedback";
    const messageText = `${
      message.thread_id ? `Thread: \`${message.thread_id}\`\n\n` : ""
    }\`\`\`\n${message.text || ""}\n\`\`\``;
    const feedbackPrompt =
      "Please provide feedback on how this summary could be improved:";

    sendFeedback({
      text: `${SUBJECT_MARKDOWN} ${subject} \n\n${messageText}\n\n${feedbackPrompt}\n\n`,
      feedbackType: message.feedback_type,
      threadID: message.thread_id,
    });
  };

  const onThumbsUpClick = () => {
    setFeedbackProvided(true);
    if (!message.feedback_type) return;

    analytics.track(FeedbackProvided, {
      feedbackType: message.feedback_type,
      score: 1,
      threadId: message.thread_id,
    });
  };

  return (
    <div className="w-full">
      {/* align content to the right */}
      <div className="flex items-center justify-end py-8 text-sm text-text-subtle">
        {feedbackProvided ? (
          <span className="mr-16">Thanks for your feedback!</span>
        ) : (
          <>
            <span className="mr-10">{feedbackPrompt}</span>
            <Button
              buttonStyle="subtle"
              icon="ThumbsUp"
              iconSize={20}
              name="thumbs-up"
              onClick={onThumbsUpClick}
              type="button"
            />
            <Button
              buttonStyle="subtle"
              icon="ThumbsDown"
              iconSize={20}
              name="thumbs-down"
              onClick={onThumbsDownClick}
              type="button"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default MessageFeedback;

import {
  ComponentProps,
  ContextType,
  MutableRefObject,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

import { noop } from "lodash-es";

import usePreviousRef from "hooks/usePreviousRef";

import SnackBar from "components/SnackBar/SnackBar";

type SnackbarContent =
  | Pick<ComponentProps<typeof SnackBar>, "content">["content"]
  | undefined;

type SnackbarTypes =
  | Pick<ComponentProps<typeof SnackBar>, "type">["type"]
  | undefined;

type ProviderContextValue = {
  closeSnackbar: () => void;
  openSnackbar: (
    type: SnackbarTypes,
    content?: SnackbarContent,
    dismissTimer?: number,
    containerClassName?: string,
    hideCloseButton?: boolean
  ) => void;
  openType?: MutableRefObject<SnackbarTypes>;
};

type SnackbarProps = Pick<
  ComponentProps<typeof SnackBar>,
  "className" | "content" | "type" | "hideCloseButton"
>;

export const SnackbarProvider = ({ children }: WithChildren) => {
  const [show, setShow] = useState<boolean>(false);
  const [snackbarProps, setSnackbarProps] = useState<SnackbarProps>();
  const openTypeRef = usePreviousRef<SnackbarTypes>(snackbarProps?.type);
  const dismissTimerRef = useRef<ReturnType<typeof setTimeout>>();

  const close = useCallback(() => {
    setShow(false);
    if (dismissTimerRef.current) {
      clearTimeout(dismissTimerRef.current);
      dismissTimerRef.current = undefined;
    }
  }, []);

  const contextPayload: ProviderContextValue = useMemo(
    () => ({
      closeSnackbar: close,
      openSnackbar: (
        type,
        content,
        dismissTimer,
        className,
        hideCloseButton
      ) => {
        close();
        setShow(true);
        setSnackbarProps({ type, content, className, hideCloseButton });
        if (dismissTimer) {
          dismissTimerRef.current = setTimeout(close, dismissTimer);
        }
      },
      openType: openTypeRef,
    }),
    [openTypeRef, close]
  );

  return (
    <SnackbarContext.Provider value={contextPayload}>
      {children}
      {snackbarProps?.type && (
        <SnackBar onClose={close} show={show} {...snackbarProps} />
      )}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar: () => ContextType<typeof SnackbarContext> = () =>
  useContext(SnackbarContext);

export const SnackbarContext = createContext<ProviderContextValue>({
  closeSnackbar: noop,
  openSnackbar: (
    _type: SnackbarTypes,
    _content?: SnackbarContent,
    _dismissTimer?: number
  ) => noop,
});

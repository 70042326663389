import { useMemo } from "react";

import { Icon } from "components/design-system/icons";
import { FieldValues, useFormContext } from "react-hook-form";

import tw from "utils/tw";

import Error from "./Error";
import { CheckboxProps } from "./types";

export const Checkbox = <TFieldValues extends FieldValues>({
  children,
  config,
  className,
  label,
  labelClassName,
  name,
  wrapperClassName,
  indeterminate,
  ...props
}: CheckboxProps<TFieldValues>) => {
  const {
    formState: { errors, isSubmitting },
    register,
    watch,
  } = useFormContext<TFieldValues>();

  const isChecked = watch(name);
  const disabled = props.disabled || isSubmitting;

  const stateBasedStyling = useMemo(
    () => ({
      "text-accent-error": !!errors[name],
      "text-text-disabled": disabled,
    }),
    [disabled, errors, name]
  );

  return (
    <div
      className={tw(
        "block my-20 text-base font-normal select-none",
        wrapperClassName
      )}
    >
      <input
        className="hidden"
        id={name}
        type="checkbox"
        {...props}
        disabled={disabled}
        {...register(name, config)}
      />
      <label
        className={tw(
          "flex",
          {
            "cursor-pointer": !disabled,
            "text-text-disabled": disabled,
          },
          labelClassName
        )}
        htmlFor={name}
      >
        <Icon
          className={tw(
            "mr-5",
            {
              "text-icon-primary-selected": isChecked || indeterminate,
            },
            stateBasedStyling,
            className
          )}
          icon={
            indeterminate
              ? "CheckboxIndeterminate"
              : !isChecked
                ? "Checkbox"
                : "ThreadSelected"
          }
          size={20}
        />
        {label ?? children}
      </label>

      <Error name={name} />
    </div>
  );
};

import MediaModal from "./MediaModal";

type VideoModalProps = {
  title: string;
  url: string;
};

const VideoModal = ({ title, url, ...props }: VideoModalProps) => (
  <MediaModal {...props}>
    <video
      className="absolute max-h-full max-w-full left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
      controls
      title={title}
      onCanPlay={e => e.target instanceof HTMLVideoElement && e.target.play()}
      onClick={e => e.stopPropagation()}
    >
      <source src={url} />
      Sorry, your browser doesn't support embedded videos.
    </video>
  </MediaModal>
);

export default VideoModal;

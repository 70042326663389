import { pick } from "lodash-es";
import { forwardRef, useCallback } from "react";
import { useHistory } from "react-router-dom";

import { ModalProps } from "components/ModalKit/Modal";
import {
  FetchGroupOrPreviewEdgeDocument,
  GroupFieldsFragment,
  MailboxCountsDocument,
  NotInGroupDocument,
  UpdateGroupInput,
  WorkspacesAndGroupsListDocument,
  useDeleteGroupMutation,
  useLeaveGroupMutation,
  useUpdateGroupMutation,
} from "generated/graphql";
import { useCacheEvict } from "hooks/state/useCacheEvict";
import useModalStore from "store/useModalStore";

import { superTabsDefaults } from "components/routing/utils";
import EditGroupForm, { FormRef } from "./EditGroupForm";

const memberUpdatable = ["name", "description"] as const;

type Props = {
  group: GroupFieldsFragment;
  isAdmin: boolean;
  focusOnDescriptionField?: boolean;
} & ModalProps;

const GroupSettings = forwardRef<FormRef, Props>(
  ({ group, ...props }: Props, ref): JSX.Element | null => {
    const { closeModal } = useModalStore(({ closeModal }) => ({
      closeModal,
    }));
    const { evictNode } = useCacheEvict();
    const history = useHistory();

    const [updateGroup] = useUpdateGroupMutation({});

    const [deleteGroup] = useDeleteGroupMutation({
      errorPolicy: "all",
    });

    const [leaveGroup] = useLeaveGroupMutation({
      errorPolicy: "all",
    });

    const onSave = (input: UpdateGroupInput) =>
      updateGroup({
        refetchQueries: [WorkspacesAndGroupsListDocument],
        variables: {
          id: group.id,
          input: props.isAdmin ? input : pick(input, memberUpdatable),
        },
      })
        .then(({ data }) => {
          if (!data) return;
          closeModal(props.modalId);
        })
        .catch(err => {
          console.warn("Error: [updateGroup] - ", err);
        });

    const onDelete = useCallback(async () => {
      if (!group) return Promise.resolve();

      return deleteGroup({
        awaitRefetchQueries: true,
        refetchQueries: [
          MailboxCountsDocument,
          WorkspacesAndGroupsListDocument,
        ],
        variables: { id: group.id },
      }).then(({ data }) => {
        if (!data?.deleteGroup) return;
        evictNode(group);
        history.replace(superTabsDefaults.groups);
        closeModal(props.modalId);
      });
    }, [group, deleteGroup, evictNode, history, closeModal, props.modalId]);

    const onLeave = useCallback(() => {
      if (!group) return Promise.resolve();

      return leaveGroup({
        awaitRefetchQueries: true,
        refetchQueries: [
          FetchGroupOrPreviewEdgeDocument,
          MailboxCountsDocument,
          NotInGroupDocument,
          WorkspacesAndGroupsListDocument,
        ],
        variables: { id: group.id },
      }).then(({ data }) => {
        if (!data?.leaveGroup) return;
        evictNode(group);
        history.replace(superTabsDefaults.groups);
        closeModal(props.modalId);
      });
    }, [closeModal, evictNode, group, history, leaveGroup, props.modalId]);

    return (
      <EditGroupForm
        ref={ref}
        group={group}
        onDelete={onDelete}
        onLeave={onLeave}
        onSave={onSave}
        {...props}
      />
    );
  }
);

export default GroupSettings;

export const trimUrl = (url?: string | null): string | null => {
  if (url !== undefined && url !== null) {
    const [trimmedUrl] = url
      .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
      .split("/");

    return trimmedUrl ?? null;
  }
  return null;
};

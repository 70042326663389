import { forwardRef } from "react";

import { ModalProps } from "components/ModalKit/Modal";
import {
  UpdateWorkspaceInput,
  WorkspaceFieldsFragment,
  WorkspacesAndGroupsListDocument,
  useUpdateWorkspaceMutation,
} from "generated/graphql";

import WorkspaceModalForm, { FormRef } from "./WorkspaceModalForm";

type Props = {
  loading?: boolean;
  workspace?: WorkspaceFieldsFragment;
} & ModalProps;

const WorkspaceSettings = forwardRef<FormRef, Props>(
  ({ loading, workspace, ...props }: Props, ref): JSX.Element => {
    const [updateWorkspace] = useUpdateWorkspaceMutation({
      errorPolicy: "all",
    });

    const onSave = (workspaceID: string, input: UpdateWorkspaceInput) =>
      updateWorkspace({
        refetchQueries: [WorkspacesAndGroupsListDocument],
        variables: { id: workspaceID, input },
      });

    return (
      <WorkspaceModalForm
        ref={ref}
        loading={loading}
        onSave={onSave}
        workspace={workspace}
        editMode
        {...props}
      />
    );
  }
);

export default WorkspaceSettings;

import { Mailbox } from "@utility-types";
import ThreadView from "components/threads/ThreadView";
import { ComponentProps } from "react";
import AICompose from "../AIViews/AICompose";
import AIThreadView from "./AIThreadView";

const AIThreadPane = (props: Partial<ComponentProps<typeof ThreadView>>) => {
  if (!props.threadID) {
    return <AICompose />;
  }

  return <AIThreadView mailbox={Mailbox.All} stackPriority={0} {...props} />;
};

export default AIThreadPane;

import { useContext, useEffect, useRef } from "react";

import { UnknownType } from "stream-chat/dist/types/types";

import { EphemeralMessage, StreamCustomEvents } from "@utility-types";
import useEphemeralMessage from "hooks/state/useEphemeralMessage";
import useStateUpdateFromStream from "hooks/state/useStateUpdateFromStream";
import useSyncAppState from "hooks/state/useSyncAppState";
import useAuthData from "hooks/useAuthData";
import { StreamClientContext } from "providers/StreamClientProvider";
import useAppStateStore from "store/useAppStateStore";
import useOnboardingStore from "store/useOnboardingStore";

export const useStateSyncManager = (): void => {
  const { authData } = useAuthData();
  const { streamClient } = useContext(StreamClientContext);
  const stateUpdateFromStream = useStateUpdateFromStream();
  const { postEphemeralMessage } = useEphemeralMessage();

  const { onboarding } = useOnboardingStore(({ onboarding }) => ({
    onboarding,
  }));

  const { syncAppState, syncNotificationsState, syncStreamState } =
    useSyncAppState();
  const syncAppStateRef = useRef(syncAppState);
  syncAppStateRef.current = syncAppState;

  // sync app data on foregrounding / reconnecting
  useEffect(
    () =>
      useAppStateStore.subscribe(
        ({ appStatus, networkStatus: { connected } }) =>
          appStatus === "active" && connected,
        activeAndConnected => {
          if (activeAndConnected && authData?.me.id && !onboarding) {
            syncAppStateRef.current();
          }
        },
        { fireImmediately: true }
      ),
    [authData?.me.id, onboarding]
  );

  // respond to Stream events indicating available data
  useEffect(() => {
    if (!streamClient) return;

    const { unsubscribe } = streamClient.on(async event => {
      switch ((event as UnknownType).type) {
        case "connection.changed":
          if (event.online) {
            syncStreamState?.();
          }
          break;
        case StreamCustomEvents.NOTIFICATION_NEW:
          syncNotificationsState();
          break;
        case StreamCustomEvents.EPHEMERAL_MESSAGE:
          const message = event.ephemeralMessage as EphemeralMessage;
          postEphemeralMessage(message);
          break;
        case StreamCustomEvents.LAMBDA_LOGS:
          // console.log("lambda logs", event);
          // TODO: forward these to the lambda IDE
          break;
        default:
          stateUpdateFromStream(event);
      }
    });

    return () => unsubscribe();
  }, [
    postEphemeralMessage,
    stateUpdateFromStream,
    streamClient,
    syncNotificationsState,
    syncStreamState,
  ]);
};

export default useStateSyncManager;

import { Footer, Header, Main } from "components/ModalKit";
import { ModalProps } from "components/ModalKit/Modal";
import { StandardModal } from "components/Modals";
import { Button } from "components/design-system/Button";
import { Form, SubmitButton } from "components/design-system/Forms";
import SelectDropdown from "components/design-system/Forms/SelectDropdown";
import {
  useResetWorkspaceInviteLinkMutation,
  useUpdateWorkspaceInviteLinkMutation,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useModalStore from "store/useModalStore";

type FormValues = {
  expiresInDays: string;
};

const DeactivateLinkModal = ({
  workspaceID,
  linkSettingsModalID,
  ...props
}: { workspaceID: string; linkSettingsModalID?: string } & ModalProps) => {
  const { closeModals } = useModalStore(({ closeModals }) => ({ closeModals }));

  const { authData } = useAuthData();
  const workspaceName = authData?.workspaces.edges.filter(
    w => w.node.id === workspaceID
  )[0]?.node.name;

  const [resetWorkspaceInviteLink] = useResetWorkspaceInviteLinkMutation();
  const handleDeactivateLink = () =>
    resetWorkspaceInviteLink({
      variables: { workspaceID },
    }).then(() =>
      closeModals([props.modalId ?? "", linkSettingsModalID ?? ""])
    );

  return (
    <StandardModal {...props}>
      <Header variant="bordered">Deactivate link?</Header>
      <Main className="p-32">
        <span className="text-subhead text-text-secondary">
          The invite link for{" "}
          <span className="text-subhead-bold">{workspaceName}</span> will stop
          working. Anyone who received this link will no longer be able to join
          your workspace.
        </span>
      </Main>
      <Footer flexboxClassName="justify-end pb-safe-area">
        <Button
          buttonStyle="subtle"
          onClick={() =>
            closeModals(props.modalId ? [props.modalId] : undefined)
          }
        >
          Cancel
        </Button>
        <Button
          buttonStyle="secondaryDestructive"
          onClick={handleDeactivateLink}
        >
          Deactivate Link
        </Button>
      </Footer>
    </StandardModal>
  );
};

const InviteLinkSettingsModal = ({
  workspaceID,
  ...props
}: { workspaceID: string } & ModalProps) => {
  const [updateWorkspaceInviteLink] = useUpdateWorkspaceInviteLinkMutation();

  const { closeModal, openModal } = useModalStore(
    ({ closeModal, openModal }) => ({ closeModal, openModal })
  );

  const options = [
    { label: "1 day", value: "1" },
    { label: "7 days", value: "7" },
    { label: "30 day", value: "30" },
    { label: "365 days", value: "365" },
  ];

  const handleSubmit = ({ expiresInDays }: FormValues) =>
    updateWorkspaceInviteLink({
      variables: {
        input: {
          workspaceID,
          expiresInDays: Number(expiresInDays),
        },
      },
    }).then(() => closeModal(props.modalId));

  return (
    <StandardModal {...props}>
      <Form
        className="flex flex-col grow pb-safe-area"
        onSubmit={handleSubmit}
        useFormProps={{ defaultValues: { expiresInDays: "30" } }}
      >
        <Header variant="bordered">Invite link settings</Header>
        <Main className="p-32">
          <span className="text-subhead text-text-secondary">
            For security purposes links must expire. You can always deactivate
            the current link. A link can be shared with up to 500 people.
          </span>
          <div className="mt-16">
            <span className="text-subhead-bold text-text-secondary">
              Link expires after
            </span>
            <div className="mt-4">
              <SelectDropdown name="expiresInDays" options={options} />
            </div>
          </div>
        </Main>
        <Footer flexboxClassName="w-full">
          <Button
            buttonStyle="secondaryDestructive"
            type="button"
            onClick={() =>
              openModal(
                <DeactivateLinkModal
                  workspaceID={workspaceID}
                  linkSettingsModalID={props.modalId}
                />
              )
            }
          >
            Deactivate link
          </Button>
          <div className="grow" />
          <div className="flex">
            <Button
              buttonStyle="subtle"
              type="button"
              onClick={() => closeModal(props.modalId)}
            >
              Cancel
            </Button>
            <SubmitButton requireChanges={false}>Save</SubmitButton>
          </div>
        </Footer>
      </Form>
    </StandardModal>
  );
};

export default InviteLinkSettingsModal;

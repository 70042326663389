import { useCallback } from "react";

import { Address } from "@utility-types/graphql";
import { ModalProps } from "components/ModalKit/Modal";
import { Footer, Header, Main } from "components/ModalKit/Parts";
import {
  Form,
  Label,
  SubmitButton,
  useDisableSubmit,
} from "components/design-system/Forms";
import { AuthConfigQuery, useUpdateProfileMutation } from "generated/graphql";
import type { UpdateMeInput } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useModalStore from "store/useModalStore";

import { StandardModal } from "components/Modals";

import AccountOptions from "./AccountOptions";

import WorkspaceItem from "components/design-system/ui/ProfilePane/WorkspaceItem";
import { EditProfileForm, FormData } from ".";

const ModalWithContext = (
  props: ModalProps & {
    addresses: Address[];
    authData: AuthConfigQuery;
  }
) => {
  const { addresses, authData, ...modalProps } = props;
  const disabled = useDisableSubmit();

  return (
    <StandardModal
      contentHandlesSafeArea={false}
      header={
        <Header
          mobileCtaLabel="Save"
          mobileCtaProps={{ disabled, type: "submit" }}
          variant="bordered"
        >
          <h3 className="m-0">Edit Profile</h3>
        </Header>
      }
      {...modalProps}
    >
      <Main className="flex flex-col grow px-16 pt-16 md:px-32">
        <EditProfileForm
          addresses={addresses}
          company={authData.workspaces?.edges?.[0]?.node}
          user={authData.me}
        />

        <div className="my-15">
          <Label>Workspaces</Label>
          <div className="flex flex-col gap-8 mt-10 mb-20">
            {authData.workspaces?.edges?.map(wks => (
              <WorkspaceItem key={wks.id} workspaceID={wks.node.id} />
            ))}
          </div>
          <AccountOptions modalId={props.modalId} />
        </div>
      </Main>

      <Footer className="hidden md:flex">
        <SubmitButton>Save</SubmitButton>
      </Footer>
    </StandardModal>
  );
};

const EditProfileModal = (props: ModalProps): JSX.Element | null => {
  const { authData, fetchAuthData } = useAuthData();
  const { closeModal } = useModalStore(({ closeModal }) => ({
    closeModal,
  }));
  const [updateProfile] = useUpdateProfileMutation({
    errorPolicy: "all",
  });

  const onUpdateProfile = useCallback(
    (data: Partial<UpdateMeInput>) =>
      updateProfile({
        variables: {
          input: data,
        },
      }).then(async () => {
        await fetchAuthData().catch(err => {
          console.warn("Error: [EditProfileModal] -", err);
        });

        closeModal(`${props.modalId}`);
      }),
    [closeModal, fetchAuthData, props.modalId, updateProfile]
  );

  if (!authData) return null;
  const addresses = authData.addresses.edges.map(item => item.node);
  const user = authData.me;

  const onSubmit = (data: FormData) =>
    onUpdateProfile({
      ...data,
    });

  return (
    <Form
      className="contents"
      onSubmit={onSubmit}
      useFormProps={{
        defaultValues: {
          avatarURL: user.avatarURL,
          bio: user.bio,
          email: addresses?.map(a => a.address).join(", "),
          id: user.id,
          name: user.name,
        },
      }}
    >
      <ModalWithContext addresses={addresses} authData={authData} {...props} />
    </Form>
  );
};

export default EditProfileModal;

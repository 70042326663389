import { Recipient } from "@utility-types";

import { parseItemData } from "./parseItemData";

import ProfileItem from "./ProfileItem";

type Props = {
  avatarClassName?: string;
  badgeUrl?: string;
  className?: string;
  nameClassName?: string;
  onClick?: (type: string, id?: string) => void;
  onlyAvatar?: boolean;
  recipient?: Recipient;
  recipientName?: string;
  role?: "admin" | "starter";
  secondaryText?: string;
  selected?: boolean;
};

const SmallRecipientProfileItem = ({
  avatarClassName,
  badgeUrl,
  className,
  nameClassName,
  onClick,
  onlyAvatar,
  recipient,
  recipientName,
  role,
  secondaryText,
  selected,
}: Props): JSX.Element | null => (
  <ProfileItem
    {...{
      ...parseItemData(recipient, recipientName),
      avatarClassName,
      badgePosition: "avatar",
      badgeUrl,
      className,
      nameClassName,
      nameLabel: role,
      onClick,
      onlyAvatar,
      recipient,
      secondaryText,
      selected: selected,
      size: "small",
    }}
  />
);

export default SmallRecipientProfileItem;

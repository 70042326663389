import {
  Locale,
  differenceInSeconds,
  endOfDay,
  format,
  formatDistanceToNow,
  formatRelative,
} from "date-fns";
import enUS from "date-fns/locale/en-US";

// https://date-fns.org/docs/I18n-Contribution-Guide#formatrelative
// https://github.com/date-fns/date-fns/blob/master/src/locale/en-US/_lib/formatRelative/index.js
// https://github.com/date-fns/date-fns/issues/1218
// https://stackoverflow.com/questions/47244216/how-to-customize-date-fnss-formatrelative
const formatRelativeLocale: { [key: string]: string } = {
  lastWeek: "'Last' eeee",
  nextWeek: "'Next' eeee",
  other: "MMM d, y",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  yesterday: "'Yesterday'",
};

// https://date-fns.org/docs/I18n-Contribution-Guide#formatdistance
const formatDistanceLocale: { [key: string]: string } = {
  lessThanXSeconds: "just now",
  xSeconds: "just now",
  halfAMinute: "just now",
  lessThanXMinutes: "just now",
  xMinutes: "{{count}}m",
  aboutXHours: "{{count}}h",
  xHours: "{{count}}h",
  xDays: "{{count}}d",
  aboutXWeeks: "{{count}}w",
  xWeeks: "{{count}}w",
  aboutXMonths: "{{count}}m",
  xMonths: "{{count}}m",
  aboutXYears: "{{count}}y",
  xYears: "{{count}}y",
  overXYears: "{{count}}y",
  almostXYears: "{{count}}y",
};

const locale: Locale = {
  ...enUS,
  formatRelative: (token: string) => formatRelativeLocale[token],
  formatDistance: (token: string, count: string) =>
    formatDistanceLocale[token]?.replace("{{count}}", count),
};

const formatDistance = (then: Date): string =>
  formatDistanceToNow(then, { locale });

const formatTime = (then: Date): string => format(then, "p");

// e.g. Jun 14 | June 14, 2023 | 6/14/23
export const formatDate = (
  then: Date,
  { full, relative }: { full?: boolean; relative?: "day" | "time" } = {}
): string => {
  const midnight = endOfDay(new Date());

  const diff = differenceInSeconds(midnight, then);
  if (diff < 86400) {
    return relative === "day"
      ? formatRelative(then, new Date(), { locale })
      : relative === "time"
        ? formatDistance(then)
        : formatTime(then);
  }
  if (then.getFullYear() !== midnight.getFullYear()) {
    return format(then, full ? "MMMM do, y" : "M/d/y");
  }
  return format(then, full ? "MMMM do" : "MMM d");
};

// e.g. Jun 14 at 3:00 PM
export const formatDateWithTime = (then: Date): string => {
  const midnight = endOfDay(new Date());

  const relative = differenceInSeconds(midnight, then);
  if (relative < 86400) {
    return formatTime(then);
  }
  if (then.getFullYear() !== midnight.getFullYear()) {
    return `${formatDate(then)} at ${formatTime(then)}`;
  }
  return `${formatDate(then)} at ${formatTime(then)}`;
};

export const formatFullRelativeDate = (then: Date): string =>
  formatDate(then, { full: true, relative: "day" });

export const formatRelativeTime = (then: Date) =>
  formatDistanceToNow(then, {
    addSuffix: true,
    includeSeconds: false,
  })
    .replace(/about|less than/, "")
    .trim();

import { useEffect, useRef, useState } from "react";

import {
  Placement,
  autoUpdate,
  offset,
  shift,
  size,
  useFloating,
} from "@floating-ui/react";
import type { StringPositioner } from "@remirror/extension-positioner";
import { useCommands, usePositioner } from "@remirror/react";
import { ExtensionPositionerTheme } from "remirror";

import useComponentMounted from "hooks/useComponentMounted";
import usePreviousRef from "hooks/usePreviousRef";
import useNativeKeyboardStore from "store/useNativeKeyboardStore";
import useSafeAreaInsetsStore from "store/useSafeAreaInsetsStore";
import { isNativeMobile } from "utils/platform";
import tw from "utils/tw";

import { Portal } from "components/Portal";

type Props = {
  defaultPlacement?: Placement;
  enabled: boolean;
  onPlacementChange?: (placement: Placement) => void;
  positioner?: StringPositioner | "combined";
};

const Floater = ({
  children,
  defaultPlacement = "top-start",
  enabled,
  onPlacementChange,
  positioner = "combined",
}: WithChildren<Props>) => {
  const { forceUpdatePositioners } = useCommands();
  const isMounted = useComponentMounted();

  const combinedPositioner = positioner === "combined";

  const {
    height,
    ref: positionerRef,
    width,
    x: left,
    y: top,
  } = usePositioner(combinedPositioner ? "cursor" : positioner, enabled);

  const { ref: nearestWordRef, x: nearestWordLeft } = usePositioner(
    "nearestWord",
    enabled
  );

  const safeAreaInsets = useSafeAreaInsetsStore.getState();

  const [myPlacement, setMyPlacement] = useState(defaultPlacement);
  const [open, setOpen] = useState(false);

  const keyboardStateRef = useRef(useNativeKeyboardStore.getState().state);

  const { floatingStyles, placement, refs } = useFloating({
    middleware: [
      offset(({ placement }) => ({
        mainAxis: placement.startsWith("bottom") ? 24 : 0,
      })),
      size({
        apply({ availableHeight, elements, placement }) {
          if (!isMounted.current) return;
          const referenceY = elements.reference.getBoundingClientRect().y;

          if (
            referenceY - safeAreaInsets.top <
            window.innerHeight - referenceY - safeAreaInsets.bottom
          ) {
            setMyPlacement("bottom-start");
          }

          Object.assign(elements.floating.style, {
            maxHeight: `calc(${availableHeight}px - ${
              placement.startsWith("bottom")
                ? `${safeAreaInsets.bottom + 4}px`
                : `${safeAreaInsets.top}px`
            })`,
          });
        },
      }),
      shift(),
    ],
    onOpenChange: setOpen,
    open,
    placement: myPlacement,
    whileElementsMounted: autoUpdate,
  });

  const onPlacementChangeRef = usePreviousRef(onPlacementChange);

  useEffect(() => {
    setOpen(!!positionerRef && !!refs.setReference && !!refs.setFloating);
  }, [positionerRef, refs]);

  useEffect(
    () =>
      (isNativeMobile() &&
        useNativeKeyboardStore.subscribe(
          ({ state }) => ({
            state,
          }),
          ({ state }) => {
            keyboardStateRef.current = state;

            ["open", "close"].includes(state) && forceUpdatePositioners();
          }
        )) ||
      undefined,
    [forceUpdatePositioners]
  );

  useEffect(() => {
    placement && onPlacementChangeRef.current?.(placement);
  }, [placement, onPlacementChangeRef]);

  useEffect(() => {
    left <= -99999 && forceUpdatePositioners();
  }, [forceUpdatePositioners, left]);

  return (
    <Portal id="overlays" zIndex="100">
      {open && (
        <>
          <div
            ref={positionerRef}
            className={tw(
              "absolute z-100",
              ExtensionPositionerTheme.POSITIONER
            )}
            style={{
              height,
              left: combinedPositioner
                ? nearestWordLeft > 0 && nearestWordLeft < left
                  ? nearestWordLeft
                  : left
                : left,
              top,
              width,
            }}
          >
            <div ref={refs.setReference} />
          </div>
          <div ref={nearestWordRef} />
          <div
            ref={refs.setFloating}
            className={tw(
              "flex overflow-hidden z-100 flex-col bg-background-modal rounded-lg",
              "border-thin border-interactive-strong/30 shadow-strong-ui",
              {
                "opacity-0": !(
                  keyboardStateRef.current === "close" ||
                  keyboardStateRef.current === "open"
                ),
              }
            )}
            style={floatingStyles}
          >
            {children}
          </div>
        </>
      )}
    </Portal>
  );
};

export default Floater;

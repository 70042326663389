import { ThreadsOrder } from "generated/graphql";

const formatThreadEdgeCursor = (
  threadEdge: {
    cursor: string;
    isRead: boolean;
    isSeen: boolean;
  },
  threadOrder: ThreadsOrder
): string => {
  let cursorPrefix = "";

  switch (threadOrder) {
    case ThreadsOrder.Unread:
      cursorPrefix = threadEdge.isRead ? "0-" : "1-";
      break;
    case ThreadsOrder.Unseen:
      cursorPrefix = threadEdge.isSeen ? "0-" : "1-";
      break;
  }

  const cursor = threadEdge.cursor.replace(/^[01]-/, "");
  return cursorPrefix + cursor;
};

export default formatThreadEdgeCursor;

import { ComponentProps, useCallback } from "react";

import { Button } from "components/design-system/Button";
import tw from "utils/tw";

import buttonStyles from "../../buttonStyles";

type BlockTypes = "toggleBlockquote" | "toggleCodeBlock" | "toggleBulletList";

type Props = ComponentProps<typeof Button> & {
  active: boolean;
  commands: (method: BlockTypes, active: boolean) => void;
  disabled: boolean;
  toggle: BlockTypes;
};

export const BlockButton = ({
  active,
  commands,
  disabled,
  icon,
  toggle,
}: Props): JSX.Element => (
  <Button
    buttonStyle="subtle"
    className={tw(buttonStyles(active))}
    disabled={disabled}
    icon={icon}
    iconSize={20}
    onClick={useCallback(
      () => commands(toggle, active),
      [active, commands, toggle]
    )}
    onMouseDown={event => event.preventDefault()}
  />
);

export default BlockButton;

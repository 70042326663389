import { ObservableQuery } from "@apollo/client";
import { useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { Workspace, nodeAs } from "@utility-types";
import InviteToGlueModal from "components/InviteToGlue/InviteToGlueModal";
import { MembersList } from "components/MembersList";
import { MembersListHeader } from "components/MembersList/MembersListHeader";
import { MembersListItem } from "components/MembersList/MembersListItem";
import { PendingMembersListItem } from "components/MembersList/PendingMembersListItem";
import { ConfirmationModal } from "components/Modals";
import { Button } from "components/design-system/Button";
import { useSnackbar } from "providers/SnackbarProvider";
import useModalStore from "store/useModalStore";

import { ModalProps } from "components/ModalKit/Modal";
import ConfirmationRequiredModal from "components/Modals/ConfirmationRequiredModal";
import {
  FetchWorkspaceOrPreviewEdgeQuery,
  MemberRole,
  useAddWorkspaceMembersMutation,
  useApproveJoinApprovalMutation,
  useCancelRequestJoinThreadMutation,
  useLeaveWorkspaceMutation,
  useRemindWorkspaceMembersMutation,
  useRemoveWorkspaceMembersMutation,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import { WorkspacePendingInvitationsBanner } from "./members/WorkspacePendingInvitationsBanner";

const remindAllCountThreshold = 2;

type Props = {
  workspace: Workspace;
  fetchMore: ObservableQuery<FetchWorkspaceOrPreviewEdgeQuery>["fetchMore"];
  hasError: boolean;
  isLoading: boolean;
  isAdmin: boolean;
  modalId: ModalProps["modalId"];
};

const WorkspaceMembers = ({
  workspace,
  isAdmin,
  hasError,
  fetchMore,
  isLoading,
  modalId,
}: Props) => {
  const [hasResentInvitations, setHasResentInvitations] = useState(false);
  const [memberIdsResentInvitation, setMemberIdsResentInvitation] = useState<
    string[]
  >([]);
  const { authData, fetchAuthData } = useAuthData();
  const [editingMemberId, setEditingMemberId] = useState<string | null>(null);
  const { members, pendingApprovals } = workspace;
  const [approveJoinApproval] = useApproveJoinApprovalMutation({
    refetchQueries: ["FetchWorkspaceOrPreviewEdge"],
  });

  const [cancelRequestJoinThread] = useCancelRequestJoinThreadMutation({
    refetchQueries: ["FetchWorkspaceOrPreviewEdge"],
  });
  const [leaveWorkspace] = useLeaveWorkspaceMutation();
  const [removeWorkspaceMembers] = useRemoveWorkspaceMembersMutation();
  const [updateWorkspaceMembers] = useAddWorkspaceMembersMutation();
  const [remindWorkspaceMembers] = useRemindWorkspaceMembersMutation();
  const { openModal, closeModal } = useModalStore(
    ({ openModal, closeModal }) => ({
      openModal,
      closeModal,
    })
  );
  const [loadMoreRef] = useInfiniteScroll({
    disabled: hasError,
    hasNextPage: members.pageInfo.hasNextPage,
    loading: isLoading,
    onLoadMore: () => {
      fetchMore({
        variables: { membersAfter: members.pageInfo.endCursor },
      }).catch(err => {
        console.warn("Error: [WorkspaceMembers] - ", err.message);
      });
    },
    rootMargin: "0px 0px 400px 0px",
  });

  const totalPendingInvitations =
    members.edges.filter(m => m.pending).length + pendingApprovals.totalCount;

  const { openSnackbar } = useSnackbar();

  const handleAddMember = () => {
    openModal(
      <InviteToGlueModal defaultWorkspaceID={workspace.id} inviteToWorkspace />
    );
  };

  const handleUpdateRole = ({ id, role }: { id: string; role: MemberRole }) =>
    updateWorkspaceMembers({
      variables: {
        id: workspace.id,
        members: [{ member: id, role }],
      },
    });

  const handleDelete = (memberId: string) => {
    const member = members.edges.find(m => m.node.id === memberId);

    if (!member) {
      return;
    }

    const isManagedByWorkspace = workspace.domains.some(domain =>
      member.node.addressDomains.includes(domain)
    );

    if (isManagedByWorkspace) {
      openModal(
        <ConfirmationModal
          confirmLabel="Revoke access"
          header={`Remove ${member.node.name} from Glue?`}
          message={`Removing ${member.node.name} will revoke their access to Glue, which is managed by ${workspace.name}. They may also lose access to other associated workspaces.`}
          isDestructive
          onConfirm={() =>
            removeWorkspaceMembers({
              variables: { id: workspace.id, memberIDs: [member.node.id] },
            })
          }
        />
      );
    } else {
      openModal(
        <ConfirmationModal
          confirmLabel="Remove access"
          header={`Remove ${member.node.name} from ${workspace.name}?`}
          message={`${member.node.name} will no longer be able to access ${workspace.name}.`}
          isDestructive
          onConfirm={() =>
            removeWorkspaceMembers({
              variables: { id: workspace.id, memberIDs: [member.node.id] },
            })
          }
        />
      );
    }
  };

  const handleLeave = () => {
    openModal(
      <ConfirmationRequiredModal
        title={`Leave ${workspace.name}?`}
        subtitle={`Are you sure you want to leave the workspace ${workspace.name}? This action is irreversible, and you will lose access to all associated groups and threads.`}
        confirmButtonText="Leave workspace"
        confirmationWord="LEAVE"
        onConfirm={() =>
          leaveWorkspace({
            variables: { id: workspace.id },
          })
            .then(() => {
              fetchAuthData({ refresh: true });
              closeModal(modalId);
              openSnackbar("success", `Left workspace ${workspace.name}`);
            })
            .catch(err => {
              console.warn("Error: [WorkspaceMembers] - ", err.message);

              if (err.message.includes("cannot remove last admin")) {
                openSnackbar(
                  "error",
                  "Cannot remove last admin from workspace"
                );
              }
            })
        }
      />
    );
  };

  const handleApprovePendingApproval = async (approvalId: string) => {
    const approval = pendingApprovals.edges.find(a => a.node.id === approvalId);

    if (!approval) {
      return;
    }

    await approveJoinApproval({ variables: { joinApprovalID: approvalId } });
  };

  const handleCancelPendingApproval = async (approvalId: string) => {
    const approval = pendingApprovals.edges.find(a => a.node.id === approvalId);

    if (!approval) {
      return;
    }

    await cancelRequestJoinThread({ variables: { id: approvalId } });
  };

  const handleResendPendingApproval = async (approvalId: string) => {
    const approval = pendingApprovals.edges.find(a => a.node.id === approvalId);

    if (!approval) {
      return;
    }

    await remindWorkspaceMembers({
      variables: {
        id: workspace.id,
        input: {
          memberIDs: [approval.node.joining.id],
        },
      },
      onCompleted: () =>
        setMemberIdsResentInvitation(s => [...s, approval.node.joining.id]),
    });
  };

  const sortedMembers = [...members.edges].sort((a, b) => {
    if (
      a.memberRole === MemberRole.Admin &&
      b.memberRole !== MemberRole.Admin
    ) {
      return -1;
    }

    if (
      a.memberRole !== MemberRole.Admin &&
      b.memberRole === MemberRole.Admin
    ) {
      return 1;
    }

    return a.node.name.localeCompare(b.node.name);
  });

  return (
    <div className="native:pb-safe-area py-16">
      <MembersListHeader
        className="mb-16"
        addNewMemberButton={
          isAdmin && (
            <Button
              icon="Plus"
              buttonStyle="simplePrimary"
              buttonType="text"
              onClick={handleAddMember}
            >
              Add new member
            </Button>
          )
        }
      >
        {members.totalCount} members
        {totalPendingInvitations > 0 && `, ${totalPendingInvitations} pending`}
      </MembersListHeader>

      {isAdmin &&
        totalPendingInvitations >= remindAllCountThreshold &&
        !hasResentInvitations && (
          <WorkspacePendingInvitationsBanner
            workspace={workspace}
            count={totalPendingInvitations}
            onCompleteResend={() => setHasResentInvitations(true)}
          />
        )}

      <MembersList>
        {pendingApprovals.edges.map(({ node: approval }) => {
          const joining = nodeAs(approval.joining, ["User"]);
          return (
            joining && (
              <PendingMembersListItem
                key={approval.id}
                approvalID={approval.id}
                isAdmin={isAdmin}
                isByMe={approval.requester.id === authData?.me.id}
                isForMe={approval.joining.id === authData?.me.id}
                isInvite={approval.requester.id === approval.admin?.id}
                joining={joining}
                requestedAt={new Date(approval.requestedAt)}
                onApprove={handleApprovePendingApproval}
                onCancel={handleCancelPendingApproval}
                onResend={
                  hasResentInvitations ||
                  memberIdsResentInvitation.includes(approval.joining.id)
                    ? undefined
                    : handleResendPendingApproval
                }
              />
            )
          );
        })}

        {sortedMembers.map(member => (
          <MembersListItem
            key={member.node.id}
            member={{
              ...member.node,
              role: member.memberRole,
              pending: member.pending,
            }}
            onUpdateRole={handleUpdateRole}
            onDelete={handleDelete}
            onLeave={handleLeave}
            onResend={
              !hasResentInvitations &&
              !memberIdsResentInvitation.includes(member.node.id)
                ? handleResendPendingApproval
                : undefined
            }
            isEditing={editingMemberId === member.node.id}
            setIsEditing={(state: boolean) =>
              setEditingMemberId(state ? member.node.id : null)
            }
            isAdmin={isAdmin}
            isMe={authData?.me.id === member.node.id}
          />
        ))}

        {members.pageInfo.hasNextPage && (
          <li ref={loadMoreRef} className="text-text-subtle">
            Loading…
          </li>
        )}
      </MembersList>
    </div>
  );
};

export default WorkspaceMembers;

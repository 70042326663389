import { memo } from "react";

import { capitalize } from "lodash-es";

import getRandomInt from "utils/getRandomInt";
import { formatGroupName } from "utils/group/formatGroupName";
import tw from "utils/tw";

import { Skeleton } from "components/Skeleton";

import { Icon } from "components/design-system/icons";
import IdentityBadge from "components/design-system/ui/IdentityBadge/IdentityBadge";
import AvatarWithBadge from "./AvatarWithBadge";
import ProfileAvatar from "./ProfileAvatar";
import ProfileDescription from "./ProfileDescription";
import { parameters } from "./profileParameters";
import { ProfileItemProps } from "./types";

type Props = ProfileItemProps;

const ProfileItem = ({
  avatarURL,
  badgeUrl,
  className = "",
  description,
  domains,
  id,
  isStarred,
  name,
  nameClassName,
  nameLabel,
  onClick,
  onlyAvatar = false,
  recipient,
  secondaryText,
  size,
  type,
}: Props): JSX.Element | null => {
  const { emoji, name: formattedName } = formatGroupName({ id, name });

  const showSkeleton = !id;

  return (
    <div
      className={tw(
        "flex flex-row items-center justify-start min-w-0 truncate cursor-pointer",
        { "justify-center": onlyAvatar },
        parameters[size].classNames,
        className
      )}
      data-testid="profile-item"
      onClick={() => id && onClick && onClick(type, id)}
    >
      {emoji ||
      (id && size !== "large") ||
      type === "User" ||
      type === "Address" ? (
        <AvatarWithBadge
          badge={
            badgeUrl ? (
              <ProfileAvatar
                avatarClassName="rounded-sm !bg-background"
                avatarContainerClassName={
                  parameters.tiny.avatar.groupClassNames
                }
                avatarURL={badgeUrl}
                className="relative right-3"
                rounded={false}
                size="tiny"
                type="Workspace"
                useAvatar
              />
            ) : undefined
          }
          badgeClassName={tw({ "right-0": onlyAvatar })}
          className="flex shrink-0 items-center"
        >
          <ProfileAvatar
            avatarURL={avatarURL}
            className={tw(
              { "!m-0 w-32": onlyAvatar },
              parameters[size].avatarSpacing
            )}
            emoji={emoji}
            id={id}
            name={name}
            size={size}
            type={type}
            useAvatar
          />
        </AvatarWithBadge>
      ) : null}
      {!onlyAvatar && (
        <div className="flex flex-col min-w-0">
          <div className="flex items-center">
            <div
              className={`${parameters[size].nameClassNames} flex w-full items-center`}
            >
              {showSkeleton ? (
                <Skeleton height="16px" width={`${getRandomInt(60, 100)}px`} />
              ) : (
                <div className="min-w-0 flex items-start">
                  {isStarred && (
                    <Icon
                      className="shrink-0 h-20 mr-5 text-accent-badge fill-current"
                      icon="Star"
                      size={14}
                    />
                  )}
                  <div className="min-w-0 flex flex-col">
                    <div
                      className={tw(
                        "truncate",
                        {
                          "h-18": !!secondaryText,
                        },
                        nameClassName
                      )}
                    >
                      {formattedName}
                    </div>
                    {secondaryText && (
                      <div className="truncate text-text-subtle text-sm h-18 flex items-center">
                        {secondaryText}
                      </div>
                    )}
                  </div>
                </div>
              )}

              {nameLabel ? <>&nbsp;({capitalize(nameLabel)})</> : null}

              {size === "small" && (
                <div className="ml-4">
                  <IdentityBadge recipient={recipient} showLabel={false} />
                </div>
              )}
            </div>
          </div>
          {size !== "small" ? (
            showSkeleton ? (
              <Skeleton height="14px" width={`${getRandomInt(80, 140)}px`} />
            ) : (
              <ProfileDescription
                description={description}
                domains={domains}
                recipient={recipient}
                size={size}
              />
            )
          ) : null}
        </div>
      )}
    </div>
  );
};

export default memo(
  ProfileItem,
  (prev, next) =>
    prev.isStarred === next.isStarred &&
    prev.nameClassName === next.nameClassName &&
    prev.name === next.name &&
    prev.nameLabel === next.nameLabel &&
    prev.description === next.description &&
    prev.domains?.join() === next.domains?.join() &&
    prev.avatarURL === next.avatarURL &&
    prev.onClick === next.onClick &&
    prev.onlyAvatar === next.onlyAvatar
);

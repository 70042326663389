// we only use the `md` breakpoint; if ever we need the others, the values should be:
// sm:640, lg:1024, xl:1280
type Breakpoints = {
  // Medium screen size breakpoint in pixels.
  MD: number;
};

export const BREAKPOINTS: Breakpoints = {
  MD: 768,
};

import { FormatOptionLabelMeta } from "react-select";

import { Option } from "./blockTypes";

type Props = {
  formatOptionLabelMeta: FormatOptionLabelMeta<Option>;
  option: Option;
};

const BlockSelectOption = ({ formatOptionLabelMeta, option }: Props) => {
  if (option.description && formatOptionLabelMeta.context === "menu") {
    return (
      <div>
        <span>{option.text.text}</span>
        <span>{option.description.text}</span>
      </div>
    );
  }
  return <span>{option.text.text}</span>;
};

export const formatOptionLabel = (
  option: Option,
  formatOptionLabelMeta: FormatOptionLabelMeta<Option>
) => {
  return (
    <BlockSelectOption
      formatOptionLabelMeta={formatOptionLabelMeta}
      option={option}
    />
  );
};

export default BlockSelectOption;

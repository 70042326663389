import { SVGProps, useEffect, useState } from "react";

type Image = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string;
  }
>;
type ImageProps = SVGProps<SVGSVGElement> & { title?: string | undefined };

const ThemedImage = ({
  LightImage,
  DarkImage,
  ...props
}: { LightImage: Image; DarkImage: Image } & ImageProps) => {
  const [isDarkTheme, setIsDarkTheme] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "class"
        ) {
          setIsDarkTheme(document.body.classList.contains("dark"));
        }
      });
    });

    observer.observe(document.body, {
      attributes: true,
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (isDarkTheme !== undefined) return;
    setIsDarkTheme(document.body.classList.contains("dark"));
  }, [isDarkTheme]);

  return isDarkTheme ? <LightImage {...props} /> : <DarkImage {...props} />;
};

export default ThemedImage;

import { Button } from "components/design-system/Button";
import { TextInput } from "components/design-system/Forms";
import { useGenerateEmojiImageMutation } from "generated/graphql";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

type EmojiPreview = {
  id?: string;
  name?: string;
  shortcut?: string;
  url?: string;
};

const EmojiSuggestionForm = ({
  loading,
  setLoading,
  setPreview,
  setPreviewWithSuggestion,
}: {
  loading: boolean;
  setLoading: (value: boolean) => void;
  setPreview: (preview: Partial<EmojiPreview>) => void;
  setPreviewWithSuggestion: (
    preview: Partial<EmojiPreview> & { description: string }
  ) => void;
}) => {
  const [generateEmojiImage] = useGenerateEmojiImageMutation();

  const { watch, resetField } = useFormContext();
  const description: string = watch("description");

  const generateEmoji = () => {
    if (!description) return;
    setLoading(true);
    setPreviewWithSuggestion({ url: undefined, description });
    generateEmojiImage({
      variables: { input: { prompt: description } },
    })
      .then(({ data }) => {
        const emoji = data?.generateEmojiImage;
        if (!emoji) return;
        setPreview(emoji);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    return () => resetField("description");
  }, [resetField]);

  return (
    <>
      <TextInput
        disabled={loading}
        wrapperClassName="flex grow !my-0"
        name="description"
        placeholder="Describe an emoji..."
        icon={loading ? "LoaderCircle" : undefined}
        iconClassName="animate-spin"
        iconPosition="end"
        autoFocus
      />
      <Button
        disabled={!description || loading}
        className="mx-8"
        role="button"
        onClick={generateEmoji}
      >
        Generate
      </Button>
    </>
  );
};

export default EmojiSuggestionForm;

import { formatNameEmoji } from "utils/formatNameEmoji";

export const formatGroupName = (group?: {
  id?: string;
  name?: string;
}): { emoji?: string; name: string; nameWithEmoji: string } => {
  let name = group?.name || "";

  if (group?.id?.startsWith("wks")) {
    name = group.name?.includes("General")
      ? group.name
      : `${name.replace("All ", "")} General`;
  }

  return formatNameEmoji({ name });
};

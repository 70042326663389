import { useCallback } from "react";

import {
  ThreadListDocument,
  ThreadSubscription,
  useUpdateGroupEdgeMutation,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";

type UseGroupActionsResult = {
  updateGroupEdge: (input: {
    threadSubscription: ThreadSubscription;
  }) => Promise<void> | undefined;
};

const useGroupActions = (group?: { id: string }): UseGroupActionsResult => {
  const { authData } = useAuthData();

  const [updateGroupEdgeMutation] = useUpdateGroupEdgeMutation({
    errorPolicy: "all",
  });

  const updateGroupEdge = useCallback(
    (input: { threadSubscription: ThreadSubscription }) => {
      if (!group?.id || !authData?.me.id) return;

      const groupEdgeID = `${group?.id}-${authData.me.id}`;

      return updateGroupEdgeMutation({
        refetchQueries: [ThreadListDocument],
        update(c) {
          c.modify({
            fields: {
              threadSubscription: () => input.threadSubscription,
            },
            id: `GroupEdge:${groupEdgeID}`,
          });
        },
        variables: {
          id: groupEdgeID,
          ...input,
        },
      }).then(() => void 0);
    },
    [authData?.me.id, group?.id, updateGroupEdgeMutation]
  );

  return {
    updateGroupEdge,
  };
};

export default useGroupActions;

import { FieldValues, useFormContext } from "react-hook-form";

import { variantProps } from "components/helper/classNameVariants";
import tw from "utils/tw";

import type { CheckboxProps } from "./types";

export const Toggle = <TFieldValues extends FieldValues>({
  config,
  label,
  labelClassName,
  name,
  toggleStyle = "default",
  wrapperClassName,
  ...props
}: CheckboxProps<TFieldValues> & {
  toggleStyle?: "default" | "simple";
}) => {
  const {
    formState: { isSubmitting },
    register,
    watch,
  } = useFormContext<TFieldValues>();

  const isChecked = watch(name);
  const disabled = props.disabled || isSubmitting;

  const className = {
    base: tw(
      "flex relative shrink-0 items-center text-sm rounded-md transition-colors",
      "before:block before:absolute before:left-3 before:content-[''] before:bg-background before:rounded-sm before:transition",
      "after:block after:absolute after:left-6 after:text-background after:transition",
      disabled ? "" : "cursor-pointer",
      {
        "!bg-accent-primary/50": disabled && isChecked,
        "!bg-background-ghost": disabled && !isChecked,
        "bg-accent-primary": isChecked,
        "bg-interactive-strong": !isChecked,
      }
    ),
    variants: {
      toggleStyle: {
        default: tw(
          "w-60 h-30 before:w-24 before:h-24 before:top-3",
          isChecked
            ? "after:content-['Yes'] before:transform-gpu before:translate-x-30"
            : "after:content-['No'] after:transform-gpu after:translate-x-30"
        ),
        simple: tw(
          "w-30 h-16 before:w-12 before:h-12 before:top-2",
          isChecked
            ? "before:transform-gpu before:translate-x-12"
            : "after:transform-gpu after:translate-x-12"
        ),
      },
    },
  };

  const toggleProps = variantProps(className);

  return (
    <div className={tw("flex items-center my-20", wrapperClassName)}>
      <input
        checked={!!isChecked}
        className="hidden"
        id={name}
        type="checkbox"
        {...props}
        disabled={disabled}
        {...register(name, config)}
      />
      <label
        className={tw(
          "flex items-center mb-5 font-normal text-text-subtle select-none",
          { "text-base": toggleStyle === "default" },
          { "text-[11px] leading-3": toggleStyle === "simple" },
          labelClassName
        )}
        htmlFor={name}
      >
        <div
          className={tw(
            { "mr-15": toggleStyle === "default" },
            { "mr-8": toggleStyle === "simple" },
            { "cursor-pointer": disabled }
          )}
        >
          {label}
        </div>
        <div {...toggleProps({ toggleStyle, ...props })} />
      </label>
    </div>
  );
};

import { captureException } from "@sentry/react";
import useOnce from "hooks/useOnce";
import { setSessionStorage } from "utils/sessionStorage";

const allowedHosts = [
  "api.typeform.com",
  "zoom.us",
  "github.com",
  "www.figma.com",
  "accounts.google.com",
  "app.clickup.com",
];

const validateLocation = (location: string | null) => {
  if (!location) return null;
  const url = new URL(location);
  if (url.protocol !== "https:") return null;
  if (allowedHosts.includes(url.host)) {
    return url.toString();
  }
  return null;
};

/*
 * When doing Masonry auth for 3rd party apps from the native app, we need to invoke
 * the native app at the end of the auth process. This is used to set a flag so that we
 * know to invoke the native app in IntegrationCallbackHandler at the end of the process.
 */
const IntegrationRedirectHandler = (): null => {
  useOnce(() => {
    const params = new URLSearchParams(window.location.search);
    const location = validateLocation(params.get("location"));
    if (location) {
      setSessionStorage("native-app-callback", "true");
      window.location.href = location;
    } else {
      captureException(`Invalid integration redirect location: ${location}`);
    }
  });
  return null;
};

export default IntegrationRedirectHandler;

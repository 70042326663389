import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { find, isEqual, uniqBy, xorWith } from "lodash-es";
import { GroupBase, SelectInstance } from "react-select";
import CreatableSelect from "react-select/creatable";

import { Recipient, User } from "@utility-types";
import { usePortal } from "components/Portal/usePortal";
import { ResultType, SearchResults } from "components/views/search/types";
import useAuthData from "hooks/useAuthData";
import { isMobile } from "utils/platform";
import env from "utils/processEnv";
import tw from "utils/tw";

import RecipientsMenu from "./RecipientsMenu";
import RecipientsMultiValue from "./RecipientsMultiValue";
import RecipientsOption from "./RecipientsOption";
import { useFetchRecipients } from "./hooks/useFetchRecipients";
import useInsertRecipients from "./hooks/useInsertRecipients";
import { RecipientValue } from "./types";

declare module "react-select/dist/declarations/src/Select" {
  export interface Props<
    Option,
    // biome-ignore lint/correctness/noUnusedVariables: ts(2428) requires this
    IsMulti extends boolean,
    // biome-ignore lint/correctness/noUnusedVariables: ts(2428) requires this
    Group extends GroupBase<Option>,
  > {
    contacts?: User[];
    contactsPermission?: boolean;
    getContactsPermission: () => Promise<boolean>;
    onSelectRecipient: (formattedRecipients: RecipientValue[]) => void;
    searchResults: SearchResults;
    valueFixed: (r: RecipientValue) => boolean;
  }
}

type marginOrPadding =
  | `${number}px`
  | `${number}px ${number}px`
  | `${number}px ${number}px ${number}px ${number}px`;

type Props = Pick<
  Parameters<typeof useFetchRecipients>[0],
  "userEdgeStatus"
> & {
  autoFocus?: boolean;
  borderWidth?: number;
  className?: string;
  containerHeight?: `${number}px`;
  containerPadding?: marginOrPadding;
  disabled?: boolean;
  excludeRecipients?: Recipient[];
  includeGlueAI?: boolean;
  filterMe?: boolean;
  fixedRecipients?: Recipient[];
  hideMenu?: boolean;
  maxLines?: number;
  onChange: (value: RecipientValue[]) => void;
  placeholder?: string;
  placeholderMargin?: marginOrPadding;
  selectGroups?: boolean;
  value?: RecipientValue[];
};

const hasDifference = (one: RecipientValue[], two: RecipientValue[]) =>
  xorWith(one, two, isEqual).length > 0;

const RecipientsSelect = ({
  autoFocus = false,
  borderWidth = 1,
  className,
  containerHeight,
  containerPadding,
  disabled = false,
  excludeRecipients = [],
  includeGlueAI = false,
  filterMe,
  fixedRecipients,
  hideMenu,
  maxLines,
  onChange,
  placeholder,
  placeholderMargin,
  selectGroups = true,
  userEdgeStatus,
  value: valueProp = [],
}: Props): JSX.Element | null => {
  const { authData } = useAuthData();

  const value = useMemo(
    () =>
      filterMe ? valueProp.filter(r => r.id !== authData?.me.id) : valueProp,
    [authData?.me.id, filterMe, valueProp]
  );

  const initialRecipients = useRef<RecipientValue[]>(value);
  const [selectedRecipients, setSelectedRecipients] =
    useState<RecipientValue[]>(value);

  const selectRef = useRef<SelectInstance<RecipientValue, true> | null>(null);
  const containerRef = useRef<HTMLInputElement>(null);
  const portalContainer = usePortal({ id: "overlays", zIndex: "100" });

  const onInsertRecipients = useCallback(
    (newRecipients: RecipientValue[]) => {
      setSelectedRecipients(
        uniqBy([...selectedRecipients, ...newRecipients], "id")
      );
    },
    [selectedRecipients]
  );

  const { inputValue, setInputValue } = useInsertRecipients({
    containerRef,
    excludeRecipients,
    onInsertRecipients,
  });

  const resultsOrder = useMemo<ResultType[]>(
    () => (selectGroups ? ["groups", "users"] : ["users"]),
    [selectGroups]
  );

  const {
    contacts,
    contactsPermission,
    fetchRecipients,
    getContactsPermission,
    searchResults,
  } = useFetchRecipients({
    excludeIDs: [
      ...[...excludeRecipients, ...selectedRecipients].map(r_2 => r_2.id),
      ...(!includeGlueAI && env.glueAIBotID ? [env.glueAIBotID] : []),
    ],
    resultsOrder,
    reverse: false,
    userEdgeStatus,
  });

  const onSelectRecipient = useCallback(
    (formattedRecipients: RecipientValue[]) => {
      setInputValue("");
      onInsertRecipients(formattedRecipients);
    },
    [setInputValue, onInsertRecipients]
  );

  const valueFixed = (r: RecipientValue) =>
    r?.id === authData?.me.id || !!find(fixedRecipients, { id: r.id });

  useEffect(() => {
    fetchRecipients(inputValue);
  }, [fetchRecipients, inputValue]);

  useEffect(() => {
    if (!hasDifference(initialRecipients.current, value)) return;
    setSelectedRecipients((initialRecipients.current = value));
  }, [value]);

  useEffect(() => {
    if (!hasDifference(selectedRecipients, initialRecipients.current)) return;
    onChange(selectedRecipients);
    initialRecipients.current = selectedRecipients;
  }, [onChange, selectedRecipients]);

  useEffect(() => {
    if (!autoFocus) return;
    selectRef.current?.focus();
  }, [autoFocus]);

  const creatableSelectRef = (ref: SelectInstance<RecipientValue, true>) => {
    if (!ref) return;
    selectRef.current = ref;
    if (autoFocus) ref.focus();
  };

  return (
    <div
      ref={containerRef}
      className={tw(
        "flex flex-row items-center justify-between w-full min-w-[200px]",
        className
      )}
      onKeyDown={e => {
        if (e.key !== "Escape") return;
        e.stopPropagation();
        selectRef.current?.blur();
      }}
    >
      <CreatableSelect<Recipient, true>
        ref={creatableSelectRef}
        blurInputOnSelect={false}
        className="max-w-full pointer-events-auto grow" // needed to fix react-select touch events
        components={{
          DropdownIndicator: null,
          Menu: RecipientsMenu,
          MultiValue: RecipientsMultiValue,
          Option: RecipientsOption,
        }}
        contacts={contacts}
        contactsPermission={contactsPermission}
        createOptionPosition="first"
        data-testid="recipients-select"
        formatCreateLabel={input => input}
        getContactsPermission={getContactsPermission}
        getNewOptionData={(value, label) => ({
          __typename: "Address",
          address: value,
          id: value,
          name: label?.toString() || value,
        })}
        getOptionLabel={({ name }) => name}
        getOptionValue={({ id }) => id}
        inputValue={inputValue}
        isClearable={false}
        isDisabled={disabled}
        isLoading={searchResults.searching}
        isMulti={true}
        isValidNewOption={input => !!input.match(/^\S+@\S+$/)}
        loadingMessage={({ inputValue }) =>
          inputValue.length ? "Searching..." : null
        }
        maxMenuHeight={(isMobile() ? 280 : 350) + (contactsPermission ? 30 : 0)}
        menuIsOpen={!!inputValue && !hideMenu}
        menuPlacement="auto"
        menuPortalTarget={portalContainer}
        menuPosition="fixed"
        noOptionsMessage={({ inputValue }) =>
          inputValue.length ? "No results. Enter an email to invite." : null
        }
        onChange={(value, { removedValue }) => {
          if (removedValue && valueFixed(removedValue)) return;
          setSelectedRecipients(Array.isArray(value) ? [...value] : [value]);
        }}
        onInputChange={value => setInputValue(value)}
        onSelectRecipient={onSelectRecipient}
        openMenuOnFocus={autoFocus}
        placeholder={placeholder}
        searchResults={searchResults}
        styles={{
          control: base => ({
            ...base,
            ...{
              "&:hover": {
                borderColor: "rgb(var(--color-border-container))",
              },
              alignItems: "start",
              backgroundColor: "transparent",
              borderColor: "rgb(var(--color-border-container))",
              borderWidth: borderWidth,
              boxShadow: "none",
              minHeight: "38px",
              transition: "none",
            },
          }),
          input: base => ({
            ...base,
            ...{
              color: "hsl(var(--text-strong))",
              cursor: "text",
              display: "flex",
              flexBasis: "min-content",
              flexGrow: 1,
              height: "32px",
              margin: "0 0 4px 0",
              padding: "0",
            },
          }),
          loadingIndicator: () => ({ display: "none" }),
          menu: (base, state) => ({
            ...base,
            backgroundColor: "hsl(var(--background-modal))",
            display: "flex",
            maxHeight: state.maxMenuHeight,
            overflow: "hidden",
          }),
          menuList: base => base,
          menuPortal: base => ({ ...base, zIndex: "initial" }),
          option: (base, { isFocused }) => ({
            ...base,
            backgroundColor: isFocused
              ? "hsl(var(--accent-highlight), 25%)"
              : "transparent",
          }),
          placeholder: base => ({
            ...base,
            ...{
              color: "hsl(var(--text-subtle))",
              height: "100%",
              lineHeight: "32px",
              pointerEvents: "none",
              userSelect: "none",
              marginLeft: placeholderMargin || "0",
            },
          }),
          valueContainer: base => ({
            ...base,
            ...{
              alignItems: undefined,
              gap: "6px",
              marginBottom: "-4px",
              height: containerHeight || undefined,
              maxHeight: maxLines ? `${maxLines * 38 - 12}px` : undefined,
              overflowY: "auto",
              padding: containerPadding || "4px",
            },
          }),
        }}
        value={selectedRecipients}
        valueFixed={valueFixed}
      />
    </div>
  );
};

export default memo(RecipientsSelect);

import { StreamGlueMessage } from "@utility-types";
import { renderTextWithOptions } from "components/thread/ThreadView/stream-components/Message/utils";
import { BlockInteractionHandlers } from "./Blocks/BlockComponent";
import { Option, OptionGroup } from "./Blocks/blockTypes";
import useMasonrySessionStore, {
  type MasonryInitialRequestInput,
} from "./useMasonrySessionStore";

const useMessageInteraction = ({
  message,
  threadID,
}: {
  message: StreamGlueMessage;
  threadID: string;
}) => {
  const { startSession } = useMasonrySessionStore(({ startSession }) => ({
    startSession,
  }));

  const handleInteraction = (initialRequest: MasonryInitialRequestInput) => {
    const unfurlMessageID =
      typeof message.unfurl_message_id === "string"
        ? message.unfurl_message_id
        : undefined;
    const sessionInput = {
      threadID,
      messageID: message.id,
      messageSenderID: message.user?.id,
      unfurlMessageID,
      type: "ephemeralMessage",
    };

    startSession(sessionInput, initialRequest);
  };

  const blockInteractionHandlers: BlockInteractionHandlers = {
    onLink: path => {
      const messageSenderID = message.user?.id;
      if (!messageSenderID) return;

      const initialRequest = {
        path,
        requestType: "surfaceLinkSelected",
      };

      handleInteraction(initialRequest);
    },
    onAction: (block, value) => {
      const messageSenderID = message.user?.id;
      if (!messageSenderID || !block.path) return;

      const initialRequest = {
        path: block.path,
        data: {
          controlID: block.id,
          controlValue: value,
        },
        requestType: "surfaceInteraction",
      };

      handleInteraction(initialRequest);
    },
    onValueChanged: (_block, _value) => {},
    onOptionsLoad: async (
      _block,
      _value
    ): Promise<{ optionGroups?: OptionGroup[]; options?: Option[] }> => {
      return { options: [] };
    },
  };

  const renderText = (text?: string) => {
    return renderTextWithOptions(text, {
      handleMasonryLink: url => {
        const messageSenderID = message.user?.id;
        if (!messageSenderID) return;

        const path = url.replace("masonry:", "");

        const initialRequest = {
          path: path,
          requestType: "surfaceLinkSelected",
        };

        handleInteraction(initialRequest);
      },
    });
  };

  return { renderText, blockInteractionHandlers };
};

export default useMessageInteraction;

import { base62Encode } from "./base62";

export const hashAddr = async (address: string) => {
  const msgBuffer = new TextEncoder().encode(address.toLowerCase());
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);

  return base62Encode(new Uint8Array(hashBuffer)).slice(0, 27);
};

export default hashAddr;

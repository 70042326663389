import { Recipient } from "@utility-types";
import VerifiedDomains from "components/ProfileItem/VerifiedDomains";
import { parseItemData } from "components/ProfileItem/parseItemData";
import { Skeleton } from "components/Skeleton";
import { cloneElementForSkeletons } from "components/Skeleton/Skeleton";
import Avatar from "components/design-system/Avatar/Avatar";
import { routeToGroup } from "components/routing/utils";
import useGroupEdge from "hooks/useGroupEdge";
import useRecipientDomains from "hooks/workspace/useRecipientDomains";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import getRandomInt from "utils/getRandomInt";
import { formatGroupName } from "utils/group/formatGroupName";
import { Results } from "../types";

const DeepSearchGroup = ({ recipient }: { recipient: Recipient }) => {
  const data = parseItemData(recipient);
  const { emoji, name: formattedName } = formatGroupName({
    id: data.id,
    name: data.name,
  });
  const { domains } = useRecipientDomains(recipient);
  const { edge } = useGroupEdge(data.id);
  const membersCount = edge?.node?.members.totalCount ?? 0;

  return (
    <Link
      key={recipient.id}
      to={routeToGroup({ groupID: recipient.id, to: "secondary" })}
    >
      <div
        className="bg-background-body hover:bg-background-list-hover mb-16 py-8 pr-16 pl-24 overflow-hidden md:rounded-lg select-none shadow-level1"
        data-testid="deep-search-group"
      >
        <div className="flex items-center">
          <Avatar
            avatarURL={data.avatarURL}
            background="transparent"
            emojiProps={{ emoji }}
            margin="mr-8"
            name={data.name}
            rounded="rounded-md"
            size="medium"
          />
          <div className="my-1">
            <div className="text-subhead-bold">{formattedName}</div>
            <div className="flex items-baseline text-footnote text-text-subtle">
              <div className="">
                {membersCount} member{membersCount === 1 ? "" : "s"}
              </div>
              <div className="mx-4 shrink-0 text-footnote text-text-subtle">
                {"\u00B7"}
              </div>
              <div className="mr-4 grow-0 shrink-0 text-footnote text-text-subtle">
                <VerifiedDomains domains={domains} type={data.type} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

const DeepSearchGroupsSkeleton = () => (
  <div
    className="bg-background-body mb-16 py-8 pr-16 pl-24 overflow-hidden md:rounded-lg shadow-level1"
    data-testid="deep-search-group"
  >
    <div className="flex gap-8 items-center">
      <Avatar loading size="medium" />
      <div className="">
        <div className="text-subhead-bold">
          <Skeleton height="22px" width={`${getRandomInt(200, 300)}px`} />
        </div>
      </div>
    </div>
  </div>
);

const DeepSearchGroups = ({
  searching,
  groups,
}: {
  searching: boolean;
  groups: Results<"groups">;
}) => {
  const skeletons = useMemo(
    () => cloneElementForSkeletons(<DeepSearchGroupsSkeleton />, 5),
    []
  );

  if (!searching && !groups.edges.length)
    return (
      <div className="md:mt-[128px] px-24 py-16 text-center">
        <div className="text-body-bold text-text-primary">No groups found</div>
        <div className="text-body text-text-secondary">
          Try using different keywords or check for typos.
        </div>
      </div>
    );

  return (
    <>
      {groups.edges?.map(edge => (
        <DeepSearchGroup key={edge.id} recipient={edge.node} />
      ))}
      {searching && skeletons}
    </>
  );
};

export default DeepSearchGroups;

import { useHistory } from "react-router";

import { Button } from "components/design-system/Button";
import useHistoryStore, { goBackHistory } from "store/useHistoryStore";
import { isNative } from "utils/platform";
import tw from "utils/tw";

const GoBack = () => {
  const history = useHistory();
  const { backStack } = useHistoryStore();

  const handleOnClick = () => {
    const url = goBackHistory(1)?.path;
    url && history.replace(url);
  };

  if (!isNative()) return null;

  return (
    <Button
      buttonStyle="none"
      className={tw("text-icon-action-inverse-subtle", {
        "!text-icon-action-inverse-subtle-disabled": backStack.length <= 1,
      })}
      icon="ArrowLeft"
      iconSize={20}
      iconStroke={1.5}
      onClick={handleOnClick}
      tooltip="Back in history"
      type="button"
    />
  );
};

export default GoBack;

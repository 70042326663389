import {
  VariantPropsOf,
  variantProps,
} from "components/helper/classNameVariants";
import tw from "utils/tw";

import { ToastType } from "./useToastStore";

const className = {
  base: "",
  defaultVariants: {
    buttonType: ToastType.ARCHIVE as const,
  },

  variants: {
    buttonType: {
      [ToastType.ARCHIVE]: "hover:bg-background-list-done-hover",
      [ToastType.DRAFT]: "hover:bg-background-list-delete-hover",
      [ToastType.READ]: "hover:bg-background-list-done-hover",
      [ToastType.STARRED]: "hover:bg-background-list-favorite-hover",
      [ToastType.SUBSCRIPTION]: "hover:bg-background-list-delete-hover",
    },
  },
};

const undoProps = variantProps(className);

const Undo = ({
  buttonType,
  onUndo,
}: {
  onUndo?: () => void;
} & VariantPropsOf<typeof undoProps>) => (
  <button
    type="button"
    {...undoProps({
      buttonType,
      className: tw(
        "flex items-center justify-center",
        "text-sm font-semibold h-28 px-12 py-5 rounded-md"
      ),
      onClick: onUndo,
    })}
  >
    Undo
  </button>
);

export default Undo;

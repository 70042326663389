import { Recipient, nodeAs } from "@utility-types";
import { ItemType } from "components/threads-list/ThreadItem/utils";

const getRecipients = (item?: ItemType): Recipient[] => {
  const draft = nodeAs(item, ["Draft"]);
  const threadEdge = nodeAs(item, ["ThreadEdge", "ThreadPreviewEdge"]);
  const thread = threadEdge?.node || nodeAs(item, ["Thread", "ThreadPreview"]);

  return draft
    ? draft.recipients
    : thread?.recipients.edges.map(r => r.node) || [];
};

export default getRecipients;

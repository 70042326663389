import tw from "utils/tw";

const StepCounter = ({
  className,
  step = 1,
  total,
}: {
  className?: string;
  step?: number;
  total?: number;
}) => {
  return (
    <div
      className={tw(
        "flex items-center justify-center",
        "px-12 py-3 rounded-[12px] text-footnote-bold",
        "bg-background-secondary text-text-subtle",
        className
      )}
    >
      <span>
        {step} of {total ?? step}
      </span>
    </div>
  );
};

export default StepCounter;

import { formatRelativeDate } from "utils/formatRelativeDate";

type Items<T> = {
  createdAt: Date | string;
  item: T;
}[];

const useGroupByDate = <T>(items: Items<T>) => {
  const dateRanges = new Map<string, T[]>();

  items.forEach(({ createdAt, item }) => {
    const dateRange = formatRelativeDate(createdAt);

    if (dateRanges.has(dateRange)) {
      dateRanges.get(dateRange)?.push(item);
    } else {
      dateRanges.set(dateRange, [item]);
    }
  });

  return dateRanges;
};

export default useGroupByDate;

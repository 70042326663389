import { ComponentProps } from "react";
import { useHistory } from "react-router";

import SidebarHeader from "components/SideBar/SidebarHeader/SidebarHeader";
import { usePartitionState } from "components/routing/RoutingPartition";
import {
  ThreadsTabs,
  routeParams as getRouteParams,
  locationFromRoute,
  tabPath,
} from "components/routing/utils";
import useThreadSelection from "components/thread/ThreadList/hooks/useThreadSelection";
import { ThreadList } from "components/threads-list/ThreadList";
import { useThreadListData } from "components/threads-list/hooks";
import { ThreadsMailbox } from "generated/graphql";
import useLocalSettingsStore from "store/useLocalSettingsStore";

import { Tabs } from "components/Tabs";
import useRoutesStore from "store/useRoutesStore";

type ThreadsTabsType = keyof typeof ThreadsTabs;

const ThreadItemHeight = 66;

const ThreadsMain = ({
  buttons,
  desktopHeader,
  headerType,
  title,
}: {
  buttons?: React.ReactNode;
  desktopHeader?: boolean;
  headerType?: ComponentProps<typeof ThreadList>["headerType"];
  showSearch?: boolean;
  title?: string;
}) => {
  const { currentThreadSort } = useLocalSettingsStore(
    ({ currentThreadSort }) => ({ currentThreadSort })
  );

  const history = useHistory();

  const { routes } = useRoutesStore(({ routes }) => ({
    routes,
  }));

  const { route } = usePartitionState(({ route }) => ({ route }));
  const routeParams = getRouteParams(
    locationFromRoute(route || routes.threads)
  );

  const currentTab = (() => {
    switch (routeParams.t) {
      case ThreadsTabs.All:
        return ThreadsTabs.All;
      case ThreadsTabs.Archived:
        return ThreadsTabs.Archived;
      case ThreadsTabs.Drafts:
        return ThreadsTabs.Drafts;
      case ThreadsTabs.Sent:
        return ThreadsTabs.Sent;
      case ThreadsTabs.Starred:
        return ThreadsTabs.Starred;
      default:
        return ThreadsTabs.Following;
    }
  })();

  const selectedThreadId = routeParams.threadID;

  const tabs = [
    {
      tab: ThreadsTabs.Following,
      tabTitle: ThreadsTabs.Following,
    },
    {
      tab: ThreadsTabs.Archived,
      tabTitle: ThreadsTabs.Archived,
    },
    {
      tab: ThreadsTabs.Sent,
      tabTitle: ThreadsTabs.Sent,
    },
    {
      tab: ThreadsTabs.All,
      tabTitle: ThreadsTabs.All,
    },
  ];

  // FOLLOWING LIST
  // ====
  const followingThreadListData = useThreadListData({
    excludeChats: true,
    mailbox: ThreadsMailbox.Inbox,
    pageSize: Math.ceil(window.innerHeight / ThreadItemHeight),
  });

  const followingThreadSelection = useThreadSelection({
    excludeChats: true,
    mailbox: ThreadsMailbox.Inbox,
    threadEdges: followingThreadListData?.result.threadEdges ?? [],
  });

  // ARCHIVE LIST
  // ====
  const archivedThreadListData = useThreadListData({
    excludeChats: true,
    mailbox: ThreadsMailbox.Archived,
    order: currentThreadSort,
    pageSize: Math.ceil(window.innerHeight / ThreadItemHeight),
  });

  const archivedThreadSelection = useThreadSelection({
    excludeChats: true,
    mailbox: ThreadsMailbox.Archived,
    threadEdges: archivedThreadListData?.result.threadEdges ?? [],
  });

  // SENT LIST
  // ====
  const sentThreadListData = useThreadListData({
    excludeChats: true,
    mailbox: ThreadsMailbox.Sent,
    order: currentThreadSort,
    pageSize: Math.ceil(window.innerHeight / ThreadItemHeight),
  });

  const sentThreadSelection = useThreadSelection({
    excludeChats: true,
    mailbox: ThreadsMailbox.Sent,
    threadEdges: sentThreadListData?.result.threadEdges ?? [],
  });

  // ALL LIST
  // ====
  const allThreadListData = useThreadListData({
    excludeChats: true,
    mailbox: ThreadsMailbox.All,
    order: currentThreadSort,
    pageSize: Math.ceil(window.innerHeight / ThreadItemHeight),
  });

  const allThreadSelection = useThreadSelection({
    excludeChats: true,
    mailbox: ThreadsMailbox.All,
    threadEdges: allThreadListData?.result.threadEdges ?? [],
  });

  return (
    <>
      {desktopHeader ? (
        <SidebarHeader
          buttons={buttons}
          onClose={() => history.push(routes.inbox)}
          title={title}
        >
          <Tabs<ThreadsTabsType>
            buttonClassName="border-b-2 capitalize h-[23px] mr-24 pb-7 px-0 text-sm"
            buttonSelectedClassName="border-accent-primary text-text-primary"
            buttonUnselectedClassName="border-transparent text-text-secondary cursor-pointer"
            className="flex items-center justify-start pl-20"
            onClickTab={tab =>
              history.push(tabPath(tab, { superTab: "threads" }))
            }
            selectedTab={currentTab}
            tabs={tabs}
          />
        </SidebarHeader>
      ) : (
        <Tabs<ThreadsTabsType>
          buttonClassName="flex items-center justify-between h-34 mr-8 px-16 rounded-[100px] text-footnote"
          buttonSelectedClassName="bg-background-action border-1 border-transparent text-text-action-inverse"
          buttonUnselectedClassName="border-1 border-border-container cursor-pointer text-text-secondary"
          className="mt-16 mb-8 pl-20"
          onClickTab={tab =>
            history.push(tabPath(tab, { superTab: "threads" }))
          }
          selectedTab={currentTab}
          tabs={tabs}
        />
      )}

      {currentTab === ThreadsTabs.Following && (
        <ThreadList
          headerType={headerType}
          listClassName="pb-72"
          mailbox={ThreadsMailbox.Inbox}
          selectedID={selectedThreadId}
          showRecentHeader={false}
          threadBulkEditData={followingThreadSelection}
          threadListData={followingThreadListData}
          canArchive
        />
      )}

      {currentTab === ThreadsTabs.Archived && (
        <ThreadList
          headerType={headerType}
          listClassName="pb-72"
          mailbox={ThreadsMailbox.Archived}
          selectedID={selectedThreadId}
          showRecentHeader={false}
          threadBulkEditData={archivedThreadSelection}
          threadListData={archivedThreadListData}
          canArchive
        />
      )}
      {currentTab === ThreadsTabs.Sent && (
        <ThreadList
          headerType={headerType}
          listClassName="pb-72"
          mailbox={ThreadsMailbox.Sent}
          selectedID={selectedThreadId}
          showRecentHeader={false}
          threadBulkEditData={sentThreadSelection}
          threadListData={sentThreadListData}
        />
      )}
      {currentTab === ThreadsTabs.All && (
        <ThreadList
          headerType={headerType}
          listClassName="pb-72"
          mailbox={ThreadsMailbox.All}
          selectedID={selectedThreadId}
          showRecentHeader={false}
          threadBulkEditData={allThreadSelection}
          threadListData={allThreadListData}
          canArchive
        />
      )}
    </>
  );
};

export default ThreadsMain;

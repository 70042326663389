import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useApolloClient } from "@apollo/client";
import { App as CapApp } from "@capacitor/app";
import { Device as CapDevice } from "@capacitor/device";
import {
  PushNotificationSchema,
  PushNotifications,
  Token,
} from "@capacitor/push-notifications";
import { Badge } from "@capawesome/capacitor-badge";
import { useDebouncedCallback } from "use-debounce";

import { readThreadEdge } from "apollo/cache/threadHelpers";
import useSidebarCounts from "components/SideBar/hooks/useSidebarCounts";
import { routeToGroup, routeToThread } from "components/routing/utils";
import {
  RegisterDeviceDocument,
  RegisterDeviceMutation,
  RegisterDeviceMutationVariables,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useAppStateStore from "store/useAppStateStore";
import { getPlatform, isNative } from "utils/platform";

import { hasGrantedPermission } from "components/Notification";

export const useNativeDeviceManager = (): void => {
  const apolloClient = useApolloClient();
  const { authData } = useAuthData();
  const [deviceRegistered, setDeviceRegistered] = useState<boolean>(false);
  const history = useHistory();
  const { joinApprovalsCount, unreadThreadCount } = useSidebarCounts();
  const { appStatus } = useAppStateStore(({ appStatus }) => ({ appStatus }));

  const registerDevice = useCallback(
    (tokens: { fcmToken?: string; pushToken?: string }) => {
      (async () => {
        const { build: appBuild, version: appVersion } = await CapApp.getInfo();

        const { uuid } = await CapDevice.getId();

        const { manufacturer, model, osVersion } = await CapDevice.getInfo();

        apolloClient
          .mutate<RegisterDeviceMutation, RegisterDeviceMutationVariables>({
            mutation: RegisterDeviceDocument,
            variables: {
              input: {
                appBuild,
                appVersion,
                manufacturer,
                model,
                osVersion,
                platform: getPlatform(),
                uuid,
                ...tokens,
              },
            },
          })
          .catch(err => {
            console.warn("Error: [NativeDeviceManager] -", err);
          });
      })();
    },
    [apolloClient]
  );

  const debouncedRegisterDevice = useDebouncedCallback(registerDevice, 1000);

  // Listen to all notification events
  useEffect(() => {
    if (!isNative()) {
      return;
    }

    const listeners = [
      PushNotifications.addListener("registration", (token: Token) => {
        let registrationToken: {
          fcmToken: string;
          pushToken?: string;
        };
        try {
          registrationToken = JSON.parse(token.value);
        } catch (_e) {
          registrationToken = { fcmToken: token.value };
        }
        debouncedRegisterDevice(
          (({ fcmToken, pushToken }) => ({
            fcmToken,
            pushToken,
          }))(registrationToken)
        );

        setDeviceRegistered(true);
      }),
      PushNotifications.addListener("registrationError", error => {
        console.warn(
          "Error: [NativePushNotificationManager] -",
          JSON.stringify(error)
        );
      }),
      // PushNotifications.addListener(
      //   "pushNotificationReceived",
      //   ({ id, title, body, data }) => {
      //     console.info(id, title, body, data);
      //   }
      // ),
      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        ({ notification: { data } }) => {
          const { target } = data;
          if (typeof target !== "string") return;

          switch (target.split("_")[0]) {
            case "thr":
              const threadPath = routeToThread({
                threadID: target,
                superTab: "inbox",
                to: "primary",
              });

              history.push(threadPath);
              break;
            case "usr":
            case "grp":
            case "wks":
              history.push(routeToGroup({ groupID: target }));
              break;
          }
        }
      ),
    ];

    return () => {
      debouncedRegisterDevice.cancel();
      listeners.forEach(async l => (await l).remove());
    };
  }, [debouncedRegisterDevice, history]);

  // Register to receive token if permissions are granted
  // (permissions flow is handled by Notification/permissions)
  useEffect(() => {
    if (!isNative()) {
      return;
    }

    (async () => {
      if (await hasGrantedPermission()) {
        PushNotifications.register();
      }
    })();
  }, []);

  // Set badge count and remove notifications after reading
  const badgeCount = joinApprovalsCount + unreadThreadCount;
  useEffect(() => {
    if (!isNative()) {
      return;
    }

    const setBadge = async () => {
      if (!(await hasGrantedPermission())) return;

      Badge.set({ count: badgeCount }).catch(_ => {
        if (badgeCount === 0) {
          // If for some reason setting badge count fails, we can only
          // safely clear the badge by removing all notifications.
          PushNotifications.removeAllDeliveredNotifications();
        }
      });
    };

    setBadge();

    if (!deviceRegistered) return;

    PushNotifications.getDeliveredNotifications().then(({ notifications }) => {
      PushNotifications.removeDeliveredNotifications({
        notifications: notifications
          .map(notification => {
            const target = notification.data?.target;
            if (typeof target !== "string" || !target.match(/^thr_/))
              return null;
            const threadEdgeID = `${target}-${authData?.me.id}`;
            return readThreadEdge(threadEdgeID, apolloClient.cache)?.isRead
              ? notification
              : null;
          })
          .filter((n): n is PushNotificationSchema => n !== null),
      });
    });
  }, [appStatus, authData, deviceRegistered, badgeCount, apolloClient.cache]);
};

export type EventProperties = {
  [index: string]:
    | string
    | number
    | boolean
    | EventProperties
    | (string | number | boolean | EventProperties)[]
    | undefined
    | null;
};

export type EventPropertiesOrVoid = EventProperties | void;

export abstract class AnalyticsEvent<T extends EventPropertiesOrVoid> {
  abstract readonly event: string;
  readonly properties: T;

  eventName() {
    return this.event;
  }

  constructor(properties: T) {
    this.properties = properties;
  }
}

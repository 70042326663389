import Hr from "components/design-system/Hr";
import tw from "utils/tw";

const ResultList = ({
  children,
  isReversed,
  label,
}: WithChildren<{
  label: string;
  isReversed?: boolean;
  searching: boolean;
}>): JSX.Element => (
  <div>
    {isReversed && <Hr className="grow mx-16 my-8" />}

    <div
      className={tw(
        "sticky -top-4 z-1 text-footnote-bold bg-background-body text-text-subtle",
        "flex items-center justify-start h-28 mb-4 px-16"
      )}
    >
      {label}
    </div>
    <ul className="list">{children}</ul>

    {!isReversed && <Hr className="grow mx-16 my-8" />}
  </div>
);

export default ResultList;

import { ComponentProps, FunctionComponent } from "react";

import { devtools, subscribeWithSelector } from "zustand/middleware";

import Icon from "components/design-system/icons/Icon";
import { glueCreateZustand } from "store/helper/glueCreate";
import generateRandomId from "utils/generateRandomId";

const STORE_NAME = "ToastStore";

export enum ToastType {
  ARCHIVE = "archive",
  DRAFT = "draft",
  READ = "read",
  STARRED = "starred",
  SUBSCRIPTION = "subscription",
}

type ToastConfig = {
  content?: FunctionComponent | JSX.Element | string | null | undefined;
  dismiss?: number;
  icon?: ComponentProps<typeof Icon>["icon"];
  id?: string;
  show: boolean;
  type?: ToastType;
  undo?: () => void;
};

type ToastStore = {
  openToast: (nextToastConfig: Omit<ToastConfig, "show">) => void;
  resetToastConfig: () => void;
  toastConfig: ToastConfig;
};

const defaultToastConfig: ToastConfig = {
  content: undefined,
  dismiss: 5000,
  id: undefined,
  show: false,
  undo: undefined,
};

const useToastStore = glueCreateZustand<ToastStore>({
  name: STORE_NAME,
})(
  subscribeWithSelector(
    devtools<ToastStore>(
      set => ({
        openToast: (nextToastConfig: Omit<ToastConfig, "show">) => {
          set({
            toastConfig: {
              ...defaultToastConfig,
              ...nextToastConfig,
              id: generateRandomId(),
              show: true,
            },
          });
        },
        resetToastConfig: () => {
          set({
            toastConfig: defaultToastConfig,
          });
        },
        toastConfig: defaultToastConfig,
      }),
      {
        name: STORE_NAME,
      }
    )
  )
);

export default useToastStore;

import useAuthData from "hooks/useAuthData";
import { getSessionStorage, setSessionStorage } from "utils/sessionStorage";

const useInvitationLink = () => {
  const { authNeeded } = useAuthData();
  const currentInviteLink = getSessionStorage("workspace-invite-link");
  if (!authNeeded || currentInviteLink) return;

  const params = new URL(window.location.href).searchParams;
  const inviteLink = params.get("i");

  if (!inviteLink) return;

  setSessionStorage("workspace-invite-link", inviteLink);
};

export default useInvitationLink;

import { Suspense, forwardRef, lazy, useRef } from "react";

import tw from "utils/tw";

import { CodeEditor as CodeEditorRef } from "./types";
import type { CodeEditorProps } from "./types";

const ImperativeHandle = lazy(() => import("./components/ImperativeHandle"));

const CodeEditor = forwardRef<CodeEditorRef, CodeEditorProps>(
  ({ className, value, ...props }, ref) => {
    const editorRef = useRef<HTMLDivElement>(null);
    return (
      <div ref={editorRef} className={tw(`theme-${props.theme}`, className)}>
        <Suspense fallback={null}>
          <ImperativeHandle
            editorRef={editorRef}
            forwardedRef={ref}
            {...props}
            value={value}
          />
        </Suspense>
      </div>
    );
  }
);

CodeEditor.displayName = "CodeEditor";

export default CodeEditor;

import { matchPath } from "react-router-dom";

import * as Sentry from "@sentry/react";

import env from "utils/processEnv";

import { PathMatch } from "components/routing/utils";

import { history } from "./InstrumentedRouter";
import appVersion from "./version";

(async () => {
  const release = (await appVersion).fullVersionString;
  Sentry.setTag("client_version", release);
  Sentry.getCurrentScope().addEventProcessor(event => ({
    ...event,
    release,
  }));
})();

const {
  glueApiUrl,
  glueEnv: environment,
  sentryDsn: dsn,
  sentryTracesSampleRate,
  sentryReplaysSampleRate,
} = env;

if (dsn) {
  const routes = Object.values(PathMatch);
  Sentry.init({
    dsn,
    environment,
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({
        history,
        routes,
        matchPath,
      }),
      Sentry.replayIntegration({
        blockAllMedia: true,
        maskAllText: true,
        maskAllInputs: true,
        minReplayDuration: 5 * 1000, // 5 seconds
        maxReplayDuration: 5 * 60 * 1000, // 5 minutes
      }),
    ],
    maxBreadcrumbs: 5,
    replaysSessionSampleRate: sentryReplaysSampleRate,
    replaysOnErrorSampleRate: 0, // buffered recording is too expensive
    tracePropagationTargets: [new URL(glueApiUrl).origin],
    tracesSampleRate: sentryTracesSampleRate,
  });
}

import { ComponentProps } from "react";
import { useHistory } from "react-router";

import { ThreadEdgeSimple } from "@utility-types";
import { SectionItem } from "components/design-system/ui/sections-sidebar";
import LessButton from "components/design-system/ui/sections-sidebar/LessButton";
import { routeToThread, useRouteParams } from "components/routing/utils";
import type { ThreadBulkEditData } from "components/thread/ThreadList/hooks/useThreadSelection";

import { useInboxSidebarSectionsState } from "components/views/inbox/providers/InboxSidebarSectionsProvider";
import InboxThreadListItem from "./InboxThreadListItem";

const FollowingThreadEdges = ({
  bulkSelect,
  bulkSelectEnabled = !!bulkSelect?.selection,
  flipIdPrefix,
  inboxHasData,
  initialShownEdges,
  onClickLess,
  threadEdges,
  ...props
}: Pick<
  ComponentProps<typeof InboxThreadListItem>,
  "sectionIsOpen" | "canArchive"
> & {
  bulkSelect?: ThreadBulkEditData;
  bulkSelectEnabled?: boolean;
  flipIdPrefix?: string;
  inboxHasData?: boolean;
  initialShownEdges?: number;
  onClickLess?: () => void;
  threadEdges?: ThreadEdgeSimple[];
}) => {
  const history = useHistory();
  const routeParams = useRouteParams();

  const { isSelected } = bulkSelect ?? {};
  const { swipedOpenItemId, setState } = useInboxSidebarSectionsState(
    ({ swipedOpenItemId }) => ({
      swipedOpenItemId,
    })
  );
  const setSwipedOpenItemId = (id?: string) => {
    setState({ swipedOpenItemId: id });
  };

  const bulkMode = (edge: ThreadEdgeSimple) =>
    bulkSelect
      ? bulkSelectEnabled
        ? isSelected?.(edge)
          ? "selected"
          : "unselected"
        : "default"
      : false;

  const onClickThreadItem = (
    e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>,
    thread: ThreadEdgeSimple
  ) => {
    if (bulkSelect?.selection) {
      bulkSelect.toggleEdgeSelected(thread, e.shiftKey);
      return;
    }

    const to = e.ctrlKey || e.metaKey ? "secondary" : "primary";
    setSwipedOpenItemId();
    history.push(
      routeToThread({ superTab: "inbox", threadID: thread.node.id, to })
    );
  };

  const initialItems = threadEdges?.slice(0, initialShownEdges) ?? [];
  const overflowItems = threadEdges?.slice(initialShownEdges) ?? [];

  const sectionItem = (edge: ThreadEdgeSimple) => (
    <SectionItem
      key={edge.node.id}
      flipId={`${flipIdPrefix}${edge.node.id}`}
      onClick={e => onClickThreadItem(e, edge)}
      itemData={edge}
      setSwipedOpenItemId={setSwipedOpenItemId}
      swipedOpenItemId={swipedOpenItemId}
      dismissOnRightSwipe={true}
      canArchive={!!props.canArchive}
    >
      <InboxThreadListItem
        bulkMode={bulkMode(edge)}
        isSelected={routeParams.threadID === edge.node.id}
        onToggleSelected={bulkSelect?.toggleEdgeSelected}
        threadID={edge.node.id}
        {...props}
      />
    </SectionItem>
  );

  const allCaughtUp =
    inboxHasData && initialItems.length === 0 && overflowItems.length === 0;
  if (allCaughtUp) {
    return (
      <div className="flex items-center h-32 px-12 text-footnote text-text-subtle bg-background-secondary rounded-md">
        You're all caught up!
      </div>
    );
  }

  return (
    <>
      {initialItems?.map(edge => sectionItem(edge))}

      {!!onClickLess && overflowItems?.length > 0 && (
        <LessButton onClick={onClickLess} />
      )}

      {overflowItems?.map(edge => sectionItem(edge))}
    </>
  );
};

export default FollowingThreadEdges;

import { Workspace } from "@utility-types/graphql";
import Paywall from "components/Payment/Paywall";
import useOnboardingStore from "store/useOnboardingStore";
import Footer from "./Footer";

const PlanAndPaymentDesktop = ({
  onComplete,
  workspace,
}: {
  onComplete: () => void;
  workspace?: Pick<Workspace, "id" | "name" | "avatarURL">;
}) => {
  const { setState, currentStep } = useOnboardingStore(
    ({ setState, currentStep }) => ({ setState, currentStep })
  );
  return (
    <div className="flex items-center justify-center w-full h-full overflow-auto bg-background-app-base py-16">
      <Paywall
        onComplete={onComplete}
        workspace={workspace}
        formProps={{
          className:
            "flex h-full min-w-[840px] max-h-[900px] mx-100 my-50 rounded-[32px] bg-background-subtle overflow-hidden",
        }}
      >
        <div className="flex flex-col grow justify-end w-full max-w-[600px] mt-16">
          <Footer
            onClickBack={() =>
              setState({
                view: "CreateWorkspace",
                currentStep: currentStep - 1,
              })
            }
            fullHeight={false}
            hideSkip
          >
            {null}
          </Footer>
        </div>
      </Paywall>
    </div>
  );
};

export default PlanAndPaymentDesktop;

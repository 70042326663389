import { State } from "components/threads/ThreadCompose/DraftReducer";
import {
  ComponentProps,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";
import ThreadSubjectInput from "./ThreadSubjectInput";
import useSuggestSubject, { minSuggestLen } from "./hooks/useSuggestSubject";
import { DraftForm } from "./types";

const AutoSuggestSubjectInput = ({
  autoSuggestSubject,
  compose,
  onSubjectChange,
  ...props
}: {
  autoSuggestSubject: boolean;
  compose: State;
  onSubjectChange?: (subject: string) => void;
} & Pick<
  ComponentProps<typeof ThreadSubjectInput>,
  "isGlueAI" | "readOnly" | "wrapperClassName"
>) => {
  const { getFieldState, setValue, watch } = useFormContext<DraftForm>();

  const [autoSuggest, setAutoSuggest] = useState(autoSuggestSubject);

  const { suggestSubject, loadingSubject } = useSuggestSubject(compose);

  const suggestAndSetSubject = useCallback(
    () =>
      suggestSubject?.().then(suggestion => {
        if (!suggestion) return;
        setAutoSuggest(true);
        setValue("subject", suggestion, {
          shouldDirty: true,
          shouldTouch: true,
        });
        onSubjectChange?.(suggestion ?? "");
      }),
    [onSubjectChange, setValue, suggestSubject]
  );

  const prevTextRef = useRef(compose.draftForm.message.text.length);

  const onTextChange = useDebouncedCallback((text: string) => {
    const lengthDiff = Math.abs(text.length - prevTextRef.current);
    if (autoSuggest && lengthDiff >= minSuggestLen) {
      prevTextRef.current = text.length;
      suggestAndSetSubject();
    }
  }, 350);

  // Auto-suggest subject when replying
  useEffect(() => {
    if (compose.draftForm.replyToMessage && !compose.draftID) {
      suggestAndSetSubject();
    }
  }, [compose.draftForm.replyToMessage, compose.draftID, suggestAndSetSubject]);

  // Watch form values and call callbacks
  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      if (type !== "change") return;
      switch (name) {
        case "subject":
          if (getFieldState(name).isDirty) {
            setAutoSuggest(false);
          }
          onSubjectChange?.(values.subject ?? "");
          break;
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [getFieldState, onSubjectChange, watch]);

  const dirtyRef = useRef(compose.dirty);
  const onTextChangeRef = useRef(onTextChange);
  dirtyRef.current = compose.dirty;
  onTextChangeRef.current = onTextChange;

  useEffect(() => {
    if (!dirtyRef.current) return;
    onTextChangeRef.current(compose.draftForm.message.text);
  }, [compose.draftForm.message.text]);

  useEffect(() => {
    if (compose.draftID || compose.pending) {
      onTextChangeRef.current.cancel();
    }
  }, [compose.draftID, compose.pending]);

  const suggestEnabled = !!suggestSubject;

  return (
    <ThreadSubjectInput
      autoSuggest={autoSuggest}
      loadingSubject={loadingSubject}
      suggestEnabled={suggestEnabled}
      suggestAndSetSubject={suggestAndSetSubject}
      {...props}
    />
  );
};

export default AutoSuggestSubjectInput;

import { Header, Main } from "components/ModalKit";
import { ModalProps } from "components/ModalKit/Modal";
import { StandardModal } from "components/Modals";
import { Skeleton } from "components/Skeleton";
import Avatar from "components/design-system/Avatar/Avatar";
import { Dropdown } from "components/design-system/FloatingUi";
import { Icon } from "components/design-system/icons";
import tw from "utils/tw";

type Props = {
  children: JSX.Element;
  avatarURL?: string;
  name?: string;
  description?: string;
};

const MessageMetadataContent = ({
  avatarURL,
  name,
  description,
}: Omit<Props, "children">) => (
  <div className="flex flex-col">
    <div className="flex items-center">
      {avatarURL ? (
        <Avatar avatarURL={avatarURL} size="small" />
      ) : (
        <Skeleton
          className="bg-background-secondary"
          height="24px"
          width="24px"
        />
      )}
      {name ? (
        <span className="text-body-bold ml-8">Powered by {name}</span>
      ) : (
        <Skeleton
          className="ml-8 bg-background-secondary"
          height="24px"
          width="85px"
        />
      )}
    </div>
    {description ? (
      <div className="mt-12 p-12 bg-background-secondary rounded-lg">
        {description}
      </div>
    ) : (
      <Skeleton
        className="mt-12 bg-background-secondary"
        height="24px"
        flexGrow
      />
    )}
    <div className="flex items-center mt-16">
      <Icon className="text-icon-secondary" icon="Info" size={24} />
      <span className="text-footnote text-text-secondary ml-8">
        You can mention specific threads or groups to expand what Glue AI can
        reference.
      </span>
    </div>
  </div>
);

export const MessageMetadataInfoDropdown = ({ children, ...rest }: Props) => (
  <Dropdown
    content={
      <div
        className={tw(
          "flex flex-col w-400 px-32 py-24 rounded-lg z-2",
          "bg-background-body shadow-level2"
        )}
      >
        <MessageMetadataContent {...rest} />
      </div>
    }
    placement="bottom-end"
  >
    <div>{children}</div>
  </Dropdown>
);

export const MessageMetadataInfoModal = ({
  avatarURL,
  name,
  description,
  ...modalProps
}: Omit<Props, "children"> & ModalProps) => (
  <StandardModal {...modalProps} contentHandlesSafeArea={false} heightAuto>
    <Header variant="bordered" />
    <Main className="px-20 py-24">
      <MessageMetadataContent
        avatarURL={avatarURL}
        name={name}
        description={description}
      />
    </Main>
  </StandardModal>
);

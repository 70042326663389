import { Button } from "components/design-system/Button";

const ThreadComposeTopButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      className="h-28 p-4 pr-8 whitespace-nowrap border-1 rounded-md border-border-container text-text-subtle text-footnote hover:bg-background-secondary"
      buttonType="none"
      buttonStyle="none"
      icon="Compose"
      iconClassName="mr-4 text-icon-secondary"
      iconSize={20}
      onClick={onClick}
    >
      New thread
    </Button>
  );
};

export default ThreadComposeTopButton;

import { Skeleton } from "components/Skeleton";
import Avatar from "components/design-system/Avatar/Avatar";
import getRandomInt from "utils/getRandomInt";

type Props = {
  isLoading?: boolean;
  avatarURL?: string | null;
  emoji?: string;
  onClick: () => void;
  children: string;
};

export const GroupAvatarAndTitle = ({
  isLoading,
  avatarURL,
  emoji,
  onClick,
  children,
}: Props) => {
  if (isLoading) {
    return (
      <div className="flex items-center gap-8">
        <Skeleton className="w-24 h-24" />
        <Skeleton width={`${getRandomInt(200, 250)}px`} />
      </div>
    );
  }
  return (
    <button
      className="flex items-center gap-8 overflow-hidden"
      data-testid="recipient-heading"
      onClick={onClick}
    >
      <Avatar
        avatarURL={avatarURL}
        background="transparent"
        emojiProps={{
          emoji,
        }}
        name={children}
        rounded="rounded-sm"
        size="small"
      />

      <div className="text-title-3 truncate">{children}</div>
    </button>
  );
};

import { Button } from "components/design-system/Button";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";
import ComposeDMModal from "../../views/dms/ComposeDMModal";

const NewDirectMessageButton = () => {
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    openModal(<ComposeDMModal />);
  };

  return (
    <Button
      buttonFont="normal"
      buttonStyle="none"
      className={tw(
        "bg-background-action justify-center px-16 py-8 rounded-[38px] shrink-0 text-text-action-inverse z-1",
        "h-56 w-56 md:h-44 md:w-44",
        "absolute right-16 bottom-12",
        "md:relative md:right-auto md:bottom-auto"
      )}
      icon="Compose"
      iconSize={20}
      iconStroke={1.5}
      onClick={handleOnClick}
      tooltip="New direct message"
      type="button"
    />
  );
};

export default NewDirectMessageButton;

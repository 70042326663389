import { useHistory } from "react-router";

import { Button } from "components/design-system/Button";
import useHistoryStore, { goForwardHistory } from "store/useHistoryStore";
import { isNative } from "utils/platform";
import tw from "utils/tw";

const GoForward = () => {
  const history = useHistory();

  const { navigatedBack } = useHistoryStore();
  const handleOnClick = () => {
    const url = goForwardHistory(1)?.path;
    url && history.replace(url);
  };

  if (!isNative()) return null;

  return (
    <Button
      buttonStyle="none"
      className={tw("text-icon-action-inverse-subtle-disabled", {
        "!text-icon-action-inverse-subtle": navigatedBack,
      })}
      icon="ArrowRight"
      iconSize={20}
      iconStroke={1.5}
      onClick={handleOnClick}
      tooltip="Forward in history"
      type="button"
    />
  );
};

export default GoForward;

import { useEffect, useState } from "react";

import appVersion from "components/App/version";

const AppVersion = () => {
  const [version, setVersion] = useState("");
  useEffect(() => {
    if (version) return;
    (async () => {
      const fullVersionString = (await appVersion).fullVersionString;
      setVersion(fullVersionString);
    })();
  }, [version]);

  return (
    <div className="text-interactive-ghost" data-testid="app-version">
      Version: <span className="select-text">{version}</span>
    </div>
  );
};

export default AppVersion;

import { useEffect, useRef } from "react";

import useFileUploader from "hooks/useFileUploader";
import useForceUpdate from "hooks/useForceUpdate";
import usePrevious from "hooks/usePrevious";

import { HorizontalScrollingList } from "components/HorizontalScrollingList";

import DisposableElement from "../DisposableElement";

import { FileAttachment, ImageAttachment } from ".";

type CallBack = Parameters<typeof useFileUploader>[0]["onChange"];

type State = Parameters<CallBack>[0];

type Props = {
  onChange: CallBack;
  orderedUploads: State;
};

const FileUploader = ({
  onChange,
  orderedUploads,
}: Props): JSX.Element | null => {
  const uploadsRef = useRef(orderedUploads);
  const forceUpdate = useForceUpdate();
  const uploads = [...uploadsRef.current.values()];
  const prevOrderedUploads = usePrevious(orderedUploads);

  const onUploadChange = (state = uploadsRef.current) => {
    uploadsRef.current = state;

    forceUpdate();

    if (![...state.values()].some(u => u.uploadInfo.state === "uploading")) {
      onChange(state);
    }
  };

  const { processedUploads } = useFileUploader({
    onChange: onUploadChange,
    orderedUploads: uploadsRef,
  });

  const handleRemove = (id: string) => {
    const upload = [...uploadsRef.current.values()].find(u => u.id === id);

    /* istanbul ignore next */
    if (!upload) return;

    uploadsRef.current.get(upload.id)?.uploadInfo.axiosCancelToken?.cancel();
    uploadsRef.current.delete(upload.id);

    processedUploads.current = processedUploads.current.filter(
      u => u.glueId !== upload.id && u.tempId !== upload.id
    );

    onUploadChange(uploadsRef.current);
  };

  const handleRetry = (id: string) => {
    const upload = [...uploadsRef.current.values()].find(u => u.id === id);

    /* istanbul ignore next */
    if (!upload) return;

    upload.uploadInfo.queued = true;
    upload.uploadInfo.state = "uploading";

    uploadsRef.current.set(upload.id, upload);

    onUploadChange(uploadsRef.current);
  };

  const imageAttachments = uploads
    .filter(u => u.contentType.startsWith("image"))
    .map(image => (
      <DisposableElement key={image.id} onRemove={() => handleRemove(image.id)}>
        <ImageAttachment
          handleRetry={handleRetry}
          id={image.id}
          loadingState={image.uploadInfo.state}
          url={image.uploadInfo.previewUri || image.url}
        />
      </DisposableElement>
    ));

  const fileAttachments = uploads
    .filter(u => !u.contentType.startsWith("image"))
    .map(file => (
      <DisposableElement key={file.id} onRemove={() => handleRemove(file.id)}>
        <FileAttachment
          handleRetry={handleRetry}
          id={file.id}
          loadingState={file.uploadInfo.state}
          name={file.name}
          size={Number.parseInt(file.contentLength, 10)}
          type={file.contentType}
          url={file.url}
        />
      </DisposableElement>
    ));

  useEffect(() => {
    if (orderedUploads.size === 0) {
      processedUploads.current = [];
    }

    if (
      [...prevOrderedUploads.keys()].join() ===
      [...orderedUploads.keys()].join()
    ) {
      return;
    }

    const tempUploads = new Map(orderedUploads);

    processedUploads.current.forEach(({ tempId }) => {
      tempUploads.delete(tempId);
    });

    uploadsRef.current = tempUploads;

    onChange(uploadsRef.current);
  }, [
    onChange,
    orderedUploads,
    prevOrderedUploads,
    prevOrderedUploads.size,
    processedUploads,
  ]);

  if (orderedUploads.size === 0) {
    return null;
  }

  return (
    <HorizontalScrollingList
      className="pr-4 pb-10 pl-10" //adjusting right padding for overflowing close btn
      columns={2}
      gutter={0}
      type="flex"
    >
      {[...imageAttachments, ...fileAttachments]}
    </HorizontalScrollingList>
  );
};
export default FileUploader;
